<template>
  <div>
    <form class="needs-validation" novalidate>
      <div v-if="isAdmin" class="mb-3">
        <label for="release_upc"
          >Release UPC (Optional)<BSPopover
            class="d-inline"
            content="If you do not already have a UPC leave this blank - a UPC will be assigned on release."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_upc"
          type="text"
          class="form-control"
          id="release_upc"
          required
        />
        <div class="invalid-feedback">Please enter a valid UPC.</div>
      </div>

      <div class="mb-3">
        <label for="release_owner_org"
          >Owner (Org)<BSPopover
            class="d-inline"
            content="This is the organisation to which you belong."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>

        <v-select
          v-model="release.release_owner_org"
          :options="display_orgs"
          :getOptionLabel="(org) => `${org.name} (${org.royalties_name_code})`"
          placeholder="Choose Owner (Org) ..."
          @input="release.release_main_artist = null"
          :selectOnTab="true"
          :clearable="false"
        ></v-select>

        <div class="invalid-feedback">Please enter org</div>
      </div>

      <div class="mb-3">
        <label for="release_main_artist"
          >Primary Artist<BSPopover
            class="d-inline"
            content="Artist must be associated with the Owner (Org) above."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>

        <v-select
          v-model="release.release_primary_artist"
          :options="display_org_artists"
          :getOptionLabel="(org) => org.name"
          placeholder="Choose Artist ..."
          :selectOnTab="true"
          :disabled="!release.release_owner_org"
          :clearable="false"
        ></v-select>
        <div class="invalid-feedback">Please enter display artist</div>
      </div>

      <div class="card p-3 mb-3">
        <div class="row">
          <div class="col-md-6">
            <label for="release_dsiplay_artist" class="form-label">
              Other Primary Artist(s)
            </label>
          </div>
        </div>

        <div
          v-for="(artist, i) in release.release_main_artists"
          :key="artist.id"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_artists"
                @search="(query) => debounce_artists_search(query)"
                @option:selected="add_record"
                v-model="release.release_main_artists[i]"
                :options="algolia_artists_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'release_main_artists',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing artist.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2">
              <button
                @click.prevent="add_line(release.release_main_artists, i)"
                @keydown.tab="add_line(release.release_main_artists, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add main artist"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(release.release_main_artists, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove main artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${artist.apple_id}`"
                >apple: {{ artist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${artist.spotify_id}`"
                >spotify: {{ artist.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="row">
          <label for="release_featured_artists" class="form-label">
            Featured Artist(s)
          </label>
        </div>

        <div
          v-for="(artist, i) in release.release_featured_artists"
          :key="artist.id"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search="(query) => debounce_artists_search(query)"
                @option:selected="add_record"
                v-model="release.release_featured_artists[i]"
                :options="algolia_artists_options"
                :create-option="
                  (artist_name) => ({
                    record_type: 'release_featured_artists',
                    index: i,
                    name: artist_name,
                    primary: false,
                    _highlightResult: {
                      name: { value: `<i>${artist_name}</i>` },
                    },
                  })
                "
                label="name"
                :selectOnTab="true"
                :clearable="false"
                taggable
              >
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>

            <div class="col-md-2">
              <button
                @click.prevent="add_line(release.release_featured_artists, i)"
                @keydown.tab="add_line(release.release_featured_artists, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add featured artist"
              >
                +
              </button>
              <button
                @click.prevent="
                  delete_line(release.release_featured_artists, i)
                "
                class="btn btn-x-sm btn-outline-danger"
                title="remove featured artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${artist.apple_id}`"
                >apple: {{ artist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${artist.spotify_id}`"
                >spotify: {{ artist.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="release_title">Release Title</label>

        <input
          v-model="release.release_title"
          type="text"
          class="form-control"
          id="release_title"
          :class="{
            'is-invalid':
              is_incomplete &&
              (!release.release_title ||
                release.release_title.includes('[NEW RELEASE')),
            'is-valid': release.release_title,
          }"
          required
        />
        <div class="invalid-feedback">Please enter a valid title.</div>
      </div>

      <div class="mb-3">
        <label for="release_title">Version Title</label>

        <input
          v-model="release.release_version_title"
          type="text"
          class="form-control"
          id="release_version_title"
          :class="{
            'is-valid': release.release_version_title,
          }"
        />
        <div class="invalid-feedback">Please enter a version title.</div>
      </div>

      <div class="mb-3">
        <label for="label "> Label</label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_label"
            type="text"
            class="form-control"
            id="release_label"
            :class="{
              'is-valid': release.release_label,
            }"
            disabled
          />

          <div class="invalid-feedback">Please enter label.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="production_owner">
          Production Owner<BSPopover
            class="d-inline"
            content="This is the person who owns the recording. It will usually be the person or party who paid for the production."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_production_owner"
            type="text"
            class="form-control"
            id="release_production_owner"
            :class="{
              'is-invalid': is_incomplete && !release.release_production_owner,
              'is-valid': release.release_production_owner,
            }"
            required
          />

          <div class="invalid-feedback">Please enter production owner.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="copyright_owner"
          >Copyright Owner<BSPopover
            class="d-inline"
            content="This is the person or party who owns the cover art (below)."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_copyright_owner"
            type="text"
            class="form-control"
            id="release_copyright_owner"
            :class="{
              'is-invalid': is_incomplete && !release.release_copyright_owner,
              'is-valid': release.release_copyright_owner,
            }"
            required
          />

          <div class="invalid-feedback">Please enter copyright owner.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="release_format">Format Type</label>
        <select
          v-model="release.release_format_type"
          class="form-select d-block w-100"
          id="release_format_type"
          :class="{
            'is-invalid': is_incomplete && !release.release_format_type,
            'is-valid': release.release_format_type,
          }"
          required
        >
          <option value="ALBUM">Album</option>
          <option value="SINGLE">Single</option>
          <option value="EP">EP</option>
          <option value="BOXSET">Boxset</option>
        </select>
        <div class="invalid-feedback">Please specify format type</div>
      </div>

      <div class="mb-3">
        <label for="release_genre">Genre</label>

        <v-select
          v-model="release.release_genre"
          :options="display_genres"
          :selectOnTab="true"
          :clearable="false"
        ></v-select>

        <div class="invalid-feedback">Please enter valid genre</div>
      </div>

      <div class="mb-3">
        <label for="release_genre">Subgenre</label>

        <v-select
          v-model="release.release_subgenre"
          :options="display_subgenres"
          :selectOnTab="true"
          :clearable="false"
        ></v-select>

        <div class="invalid-feedback">Please enter valid subgenre</div>
      </div>

      <div class="mb-3">
        <label for="release_parental_advisory">Parental Advisory Type</label>
        <select
          v-model="release.release_parental_advisory"
          class="form-select d-block w-100"
          id="release_parental_advisory"
          :class="{
            'is-invalid':
              is_incomplete &&
              !release.release_parental_advisory === true &&
              !release.release_parental_advisory === false &&
              !release.release_parental_advisory === 'CLEAN',
            'is-valid':
              release.release_parental_advisory === true ||
              release.release_parental_advisory === false ||
              release.release_parental_advisory === 'CLEAN',
          }"
          required
        >
          <option :value="false">Not Explicit</option>
          <option :value="true">Explicit</option>
          <option value="CLEAN">Cleaned / Edited</option>
        </select>
        <div class="invalid-feedback">
          Please specify parental advisory type
        </div>
      </div>

      <div class="mb-3">
        <label for="release_territories">
          Territories to Deliver<BSPopover
            class="d-inline"
            content="This will usually be 'Worldwide'.  If the release is restricted to certain territories, choose 'Selected Territories' and then click 'Edit Selection' to choose which territories apply."
            trigger="hover"
          >
            <info-icon size="1.0x" class="text-success ms-2 mb-1"></info-icon>
          </BSPopover>
        </label>

        <div class="input-group">
          <select
            v-model="release_territories_selection"
            class="form-select"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
          >
            <option>Worldwide</option>
            <option>Selected Territories</option>
          </select>
          <button
            @click="show_picker"
            v-show="release_territories_selection == 'Selected Territories'"
            class="btn btn-outline-success"
            type="button"
          >
            Edit Selection...
          </button>
        </div>
        <div class="invalid-feedback">Please select a valid territory.</div>
      </div>

      <CountriesPicker
        ref="countries_picker"
        :release="release"
        @update_territories="update_territories"
      />

      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="release_production_year"
            >Production Year<BSPopover
              class="d-inline"
              content="Corresponds to the original release date (below).  For new releases, use this year."
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>
          <select
            v-model="release.release_production_year"
            class="form-select d-block w-100"
            id="release_production_year"
            :class="{
              'is-invalid': is_incomplete && !release.release_production_year,
              'is-valid': release.release_production_year,
            }"
            required
          >
            <option disabled value>Choose...</option>
            <option v-for="year in years" :key="year">
              {{ year }}
            </option>
          </select>
          <div class="invalid-feedback">
            Please provide a valid production year.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="release_territories">
          Release Price Tier<BSPopover
            class="d-inline"
            content="This determines the price per download.  Typically, you would chose DIGITAL45 for a single, BUDGET for an EP and MIDFRONT for an album."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <select
          v-model="release.release_price_tier"
          class="form-select d-block w-100 m-1"
          id="release_pricing"
          :class="{
            'is-invalid':
              is_incomplete &&
              release.release_price_tier &&
              !release.release_price_tier,
            'is-valid':
              release.release_price_tier && release.release_price_tier,
          }"
          required
        >
          <option value="" disabled>Choose ...</option>
          <option value="DIGITAL45">DIGITAL45 (EUR $1.49)</option>
          <option value="BUDGET">BUDGET (EUR $5.99)</option>
          <option value="MIDFRONT">MIDFRONT (EUR $9.99)</option>
          <option value="" disabled>-------------------------</option>
          <option v-for="tier in price_tiers" :key="tier.code">
            {{ tier.display }}
          </option>
        </select>
        <div class="invalid-feedback">Please select a valid price tier.</div>
      </div>

      <div class="mb-3">
        <label for="release_original_release_date"
          >Original Release Date<BSPopover
            class="d-inline"
            content="This is the non-digital release date e.g. the date first released on CD or vinyl.  If never previously released, this will be the same date as the release date below."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_original_release_date"
          type="date"
          class="form-control"
          id="release_original_release_date"
          :class="{
            'is-invalid':
              is_incomplete && !release.release_original_release_date,
            'is-valid': release.release_original_release_date,
          }"
          required
        />
        <div class="invalid-feedback">Please enter original release date.</div>
      </div>

      <div class="mb-3">
        <label for="release_release_date"
          >Release Date<BSPopover
            class="d-inline"
            content="This is the proposed digital release date.  You should allow at least 2 weeks."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_release_date"
          type="date"
          class="form-control"
          id="release_release_date"
          :class="{
            'is-invalid': is_incomplete && !release.release_release_date,
            'is-valid': release.release_release_date,
          }"
          required
        />
        <div class="invalid-feedback">Please enter release date.</div>
      </div>

      <div v-if="release.release_art_url" class="card">
        <div class="card-header bg-transparent">
          <h6 class="text-uppercase">Cover Art</h6>
        </div>
        <div class="card-body text-center">
          <img :src="release.release_art_url" width="200px" height="auto" />
        </div>

        <div class="card-footer text-center">
          <button
            @click.prevent="upload_image"
            type="button"
            class="btn btn-sm btn-outline-secondary"
          >
            Replace Art
          </button>
        </div>
      </div>
      <div v-else class="card mt-2">
        <div class="card-header">
          <h6 class="text-uppercase">Upload Cover Art</h6>
          <p>
            Please upload cover art file in the format .jpg or .png, minimum
            1500 x 1500 pixels
          </p>
        </div>
        <div class="card-body">
          <button
            @click.prevent="upload_image"
            type="button"
            class="btn btn-sm btn-outline-secondary"
          >
            Upload
          </button>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button
            @click.prevent="submit_form"
            class="btn btn-success btn-sm mr-2"
          >
            Validate and Save
          </button>

          <button
            v-if="is_incomplete"
            @click.prevent="save_form"
            class="btn btn-warning btn-sm ms-1"
          >
            Save for Later
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch";
import BSPopover from "@/components/BSPopover";
import { orderBy, unionBy } from "lodash";
import CountriesPicker from "@/components/CountriesPicker_2";
import ArtistCreateNew from "@/components/ArtistCreateNew";
import { db } from "@/firebaseConfig";
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import Countries from "../assets/Countries.json";
// import Languages from "../assets/Languages.json";
// import Genres from "../assets/GenresBelieve.json";
import ReleasePriceTiers from "../assets/ReleasePriceTiers.json";
import { InfoIcon } from "vue-feather-icons";

const releases = db.collection("release_submissions");
const orgs = db.collection("orgs");
const artists = db.collection("artists");

const genres = db.collection("genres");
const subgenres = db.collection("subgenres");

export default {
  name: "release_form",
  props: {
    release_id: String,
  },
  components: {
    ArtistCreateNew,
    BSPopover,
    InfoIcon,
    CountriesPicker,
  },

  created: async function () {
    this.$bind("visible_genres", genres);
    this.$bind("visible_subgenres", subgenres);

    this.artists_index = this.searchClient.initIndex("artists");
    // this.artists_hits = await this.artists_index.search("");
  },

  data() {
    return {
      release: {
        release_territories: [],
        // release_territories_included: [],
        // release_territories_excluded: [],
        release_owner_org: { id: "", name: "", label: "", description: "" },
        release_main_artist: { id: "", name: "", label: "" },
      },
      countries: Countries,
      // languages: Languages,
      //   genres: Genres,
      price_tiers: ReleasePriceTiers,

      searchClient: algoliasearch(
        "LC1Z0QAV0G",
        "2a00525f080a41df0186dbb1115f4a36"
      ),
      artists_search: "",
      artists_index: null,
      artists_hits: {},
      debounce: null,
      required: [
        "release_title",
        "release_primary_artist",
        "release_label",
        "release_genre",
        "release_parental_advisory",
        "release_production_year",
        "release_original_production_year",
        "release_release_date",
        "release_release_date",
      ],

      is_incomplete: false,
      changed_fields: [],
      visible_genres: [{ name: "", label: "" }],
      visible_subgenres: [{ name: "", label: "" }],
      visible_orgs: [],
      visible_org_artists: [],
      new_artists_records: [],

      release_territories_selection: "",
      show_new_artist: false,
    };
  },

  methods: {
    debounce_artists_search(search) {
      let delay = 200;
      clearTimeout(this.debounce);
      if (search.length > 4) delay = 0;
      this.debounce = setTimeout(() => {
        this.artists_search = search;
      }, delay);
    },

    refresh_index_artists() {
      this.artists_search = "";
      this.artists_index = this.searchClient.initIndex("artists");
    },

    add_record: async function (value) {
      console.log("VALUE: " + JSON.stringify(value));

      if (!value.objectID) {
        const new_record = {
          name: value.name,
          fuga_id: "",
          apple_id: "",
          spotify_id: "",
        };

        if (value.record_type === "release_main_artists") {
          let org = { ...this.owner_org };
          org.id = this.release.track_owner_org_id;
          new_record.orgs = [org];
          new_record.org_ids = [this.release.track_owner_org_id];
          let res = await db.collection("artists").add(new_record);
          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.release.release_main_artists, value.index, new_record);
          this.new_artists_records.push(new_record);
        } else if (value.record_type === "release_featured_artists") {
          let org = { ...this.owner_org };
          org.id = this.release.track_owner_org_id;
          new_record.orgs = [org];
          new_record.org_ids = [this.release.track_owner_org_id];
          let res = await db.collection("artists").add(new_record);
          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(
            this.release.release_featured_artists,
            value.index,
            new_record
          );
          this.new_artists_records.push(new_record);
        }
        document.activeElement.nextElementSibling.focus();
      }
    },

    close_new_artist() {
      this.show_new_artist = false;
      // this.artist.artist = {};
    },

    show_picker() {
      this.$refs.countries_picker.open_modal();
    },

    add_line: function (items, i) {
      items.splice(i + 1, 0, {});
    },
    delete_line(items, i) {
      if (items.length === 1) {
        this.$set(items, i, { name: "" });
      } else {
        items.splice(i, 1);
      }
    },

    submit_form: function () {
      if (!this.is_complete()) {
        this.is_incomplete = true;
        this.$toast.warning(
          "You are missing some required information.  Please complete the highlighted fields or, save this form and come back to it later.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.save_form(true);
      }
    },

    save_form: async function (close) {
      this.is_incomplete = false;

      this.update_release();

      this.$toast.success("Saved.", {
        position: "top-right",
        timeout: 5000,
      });

      if (close) {
        const route_name = this.isAdmin
          ? "ReleasesAdminList"
          : "ReleasesMyDraftsList";
        this.$router.push({
          name: route_name,
        });
      }
    },
    update_release: async function () {
      let release = this.release;

      const release_primary_artist_object = {
        name: release.release_primary_artist.name,
        id: release.release_primary_artist.id || "",
        fuga_id: release.release_primary_artist.fuga_id || "",
        apple_id: release.release_primary_artist.apple_id || "",
        spotify_id: release.release_primary_artist.spotify_id || "",
        primary: true,
      };

      const release_main_artists = release.release_main_artists.map(
        (main_artist) => {
          return {
            name: main_artist.name,
            id: main_artist.objectID || main_artist.id || "",
            fuga_id: main_artist.fuga_id || "",
            apple_id: main_artist.apple_id || "",
            spotify_id: main_artist.spotify_id || "",
            primary: true,
          };
        }
      );

      const release_featured_artists = release.release_featured_artists.map(
        (featured_artist) => {
          return {
            name: featured_artist.name,
            id: featured_artist.objectID || featured_artist.id || "",
            fuga_id: featured_artist.fuga_id || "",
            apple_id: featured_artist.apple_id || "",
            spotify_id: featured_artist.spotify_id || "",
            primary: false,
          };
        }
      );

      release.release_primary_artist = release_primary_artist_object;
      release.release_main_artists = release_main_artists;
      release.release_featured_artists = release_featured_artists;
      if (this.release_territories_selection === "Worldwide") {
        release.release_territories = ["Worldwide"];
      }

      const release_update = {
        ...release,
        is_new: false,
        is_incomplete: !this.is_complete(),
      };
      console.log(release_update);
      await db
        .collection("release_submissions")
        .doc(this.release_id)
        .update(release_update);
    },

    is_complete: function () {
      const release = this.release;

      let is_complete;

      if (
        !release.release_title ||
        release.release_title.includes("[NEW RELEASE") ||
        release.release_title.includes("[COPY]")
      ) {
        is_complete = false;
        return is_complete;
      }

      const entries = Object.entries(release);

      const missing_fields = entries
        .filter(([k, v]) => {
          return (
            k &&
            (v === undefined ||
              v === null ||
              v === "" ||
              v[0]?.name === "" ||
              v?.name === "")
          );
        })
        .map(([k]) => k);

      is_complete = !missing_fields.some((missing_field) => {
        return this.required.includes(missing_field);
      });

      return is_complete;
    },

    upload_image() {
      this.save_form();
      window.cloudinary
        .openUploadWidget(
          {
            cloud_name: "drm-nz-portal",
            uploadPreset: "album_cover",
            clientAllowedFormats: ["jpg", "png"],
            cropping: true,
            croppingAspectRatio: 1.0,
            minImageWidth: 1500,
            minImageHeight: 1500,
            croppingShowDimensions: true,
            croppingValidateDimensions: true,
            croppingShowBackButton: true,
            showSkipCropButton: false,
            theme: "minimal",
            showPoweredBy: false,
            buttonClass: "btn btn-outline-success",
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.save_image(result.info);
            }
          }
        )
        .open();
    },
    save_image: async function (info) {
      // console.log(info);
      const release_art_url = info.secure_url;
      const release_thumbnail_url = info.thumbnail_url;
      const release_art_info = {
        height: info.height,
        width: info.width,
        format: info.format,
        bytes: info.bytes,
        hashsum: info.etag,
      };

      await db
        .collection("release_submissions")
        .doc(this.release.id)
        .update({ release_art_url, release_thumbnail_url, release_art_info });
    },

    open_territory_picker() {
      this.modal.show();
    },

    save_territories() {
      this.$refs.countries_picker.save_territories();
    },

    update_territories(e) {
      this.release.release_territories = e.release_territories;
    },
  },

  watch: {
    artists_search: {
      handler: async function (search) {
        console.log("watch artists search: " + search);
        this.artists_hits = await this.artists_index.search(search);
      },
    },

    isAdmin: {
      handler(isAdmin) {
        if (isAdmin) {
          this.$bind("visible_orgs", orgs);
        } else {
          let query = orgs.where("__name__", "in", this.userProfile.org_ids);
          this.$bind("visible_orgs", query);
        }
      },
      immediate: true,
    },

    release_id: function (new_release) {
      this.$bind("release", releases.doc(new_release));
    },
    "release.release_owner_org": function (owner_org) {
      if (owner_org && owner_org.id) {
        this.$bind(
          "visible_org_artists",
          artists.where("org_ids", "array-contains", owner_org.id)
        );
        this.release.release_label = `${owner_org.name}`;
      } else {
        // this.visible_org_artists = [];
        this.release.release_label = "";
      }
    },

    "release.release_territories": function (included) {
      if (included && included.length === 1 && included[0] === "Worldwide") {
        this.release_territories_selection = "Worldwide";
      } else {
        this.release_territories_selection = "Selected Territories";
      }
    },

    "org.name"(newQuestion, oldQuestion) {
      if (newQuestion !== oldQuestion) {
        this.org.royalties_name_code = newQuestion
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
          .substring(0, 12);
      }
    },
    deep: true,
  },

  computed: {
    algolia_artists_options() {
      const hits = this.artists_hits?.hits;
      const new_artists_records = this.new_artists_records.map((artist) => {
        artist._highlightResult = {
          name: { value: `${artist.name}` },
        };
        return artist;
      });

      const all_records = unionBy(hits, new_artists_records, "objectID");

      return all_records?.map((artist) => {
        artist.id = artist.objectID;
        return artist;
      });
    },

    display_orgs: function () {
      let orgs = [...this.visible_orgs];
      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          // label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description || "",
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");

      return orgs;
    },

    display_org_artists: function () {
      let org_artists = [...this.visible_org_artists];

      org_artists = org_artists.map((a) => {
        let display_artist = {
          id: a.id,
          fuga_id: a.fuga_id || "",
          // label: a.name,
          name: a.name,
          tier: a.tier || "Tier 3",
          apple_id: a.apple_id || "",
          spotify_id: a.spotify_id || "",
        };

        return display_artist;
      });
      org_artists = orderBy(org_artists, "name", "asc");
      return org_artists;
    },

    //============

    display_genres: function () {
      let genres = this.visible_genres.slice();

      genres = genres.map((genre) => {
        let display_genre = {
          id: genre.id,
          label: genre.name,
          name: genre.name,
          fuga_id: genre.fuga_id,
        };

        return display_genre;
      });
      genres = orderBy(genres, "name", "asc");
      return genres;
    },

    display_subgenres: function () {
      let subgenres = this.visible_subgenres.slice();

      subgenres = subgenres.map((genre) => {
        let display_genre = {
          id: genre.id,
          label: genre.name,
          name: genre.name,
          fuga_id: genre.fuga_id,
        };

        return display_genre;
      });
      subgenres = orderBy(subgenres, "name", "asc");
      return subgenres;
    },

    //============

    years() {
      const year_start = new Date().getFullYear();
      const years_array = [];
      let i;

      for (i = 1960; i < year_start + 2; i++) {
        years_array.push(i);
      }

      return years_array.reverse();
    },
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        (this.userProfile &&
          (this.userProfile.role === "admin" ||
            this.userProfile.role === "superadmin")) ||
        false
      );
    },
  },
};
</script>

<style>
.popover {
  max-width: 95%;
}
.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
}
</style>
