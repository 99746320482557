<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import { state } from "@/store";
export default {
  mounted() {
    // this.chatra(document, window, "Chatra");
  },
  methods: {
    chatra(d, w, c) {
      w.ChatraID = "uNnrrnjvg3gqLRsft";
      var s = d.createElement("script");
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      s.async = true;
      s.src = "https://call.chatra.io/chatra.js";
      if (d.head) d.head.appendChild(s);

      Chatra("setIntegrationData", {
        /* main properties */
        name: this.userProfile.name,
        email: this.userProfile.email,
        phone: this.userProfile.mobile,
        orgs: this.userProfile.orgs.map((o) => o.name).join(", "),
      });

      console.log(this.userProfile.orgs.map((o) => o.name).join(", "));
    },
  },

  computed: {
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>



<style>
#app {
  min-height: 100vh;
  margin: 0;
  height: 100vh;
}
</style>
