<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">Org Profile</h6>
          <h2 v-if="org.name">{{ org.name }}</h2>
          <h2 v-else>Org</h2>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-6">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <form
                    class="needs-validation"
                    novalidate>
                    <div class="mb-3">
                      <label
                        for="release_title"
                        class="form-label"
                        >Name</label
                      >

                      <input
                        v-model="org.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.name,
                          'is-valid': org.name,
                        }" />
                      <div class="invalid-feedback">Please enter a valid name.</div>
                    </div>

                    <div class="mb-3">
                      <label
                        for="description"
                        class="form-label"
                        >Description</label
                      >

                      <input
                        v-model="org.description"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.description,
                          'is-valid': org.description,
                        }"
                        required />
                      <div class="invalid-feedback">Please enter a valid description.</div>
                    </div>
                    <div class="mb-3">
                      <label for="description">Royalties ID</label>

                      <input
                        v-model="org.royalties_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.royalties_id,
                          'is-valid': org.royalties_id,
                        }"
                        required />
                      <div class="invalid-feedback">Please enter a valid royalties id.</div>
                    </div>

                    <div class="mb-3">
                      <label for="description">Royalties Code</label>

                      <input
                        v-model="org.royalties_name_code"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.royalties_name_code,
                          'is-valid': org.royalties_name_code,
                        }"
                        required />
                      <div class="invalid-feedback">Please enter a valid royalties code.</div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label"> Type </label>

                      <select
                        v-model="org.type"
                        class="form-select d-block w-100 m-1"
                        :class="{
                          'is-invalid': submitted && !org.type,
                          'is-valid': org.type,
                        }">
                        <option
                          v-for="org_type in types"
                          :key="org_type">
                          {{ org_type }}
                        </option>
                      </select>
                      <div class="invalid-feedback">Please select a valid org type.</div>
                    </div>

                    <div class="form-group mb-3">
                      <label
                        for="country"
                        class="form-label">
                        Country</label
                      >

                      <select
                        v-model="org.country"
                        class="form-select d-block w-100 m-1"
                        id="country"
                        :class="{
                          'is-invalid': submitted && !org.country,
                          'is-valid': org.country && org.country,
                        }">
                        <option
                          disabled
                          value>
                          Choose...
                        </option>
                        <option>New Zealand</option>
                        <option>Australia</option>
                        <option
                          disabled
                          value>
                          ----------
                        </option>
                        <option
                          v-for="country in countries"
                          :key="country">
                          {{ country }}
                        </option>
                      </select>
                      <div class="invalid-feedback">Please select a valid country.</div>
                    </div>

                    <label
                      for="release_title"
                      class="form-label"
                      >Commission</label
                    >
                    <div class="input-group mb-3">
                      <input
                        v-model="org.commission"
                        type="number"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.commission,
                          'is-valid': org.commission,
                        }" />
                      <span class="input-group-text">%</span>
                      <div class="invalid-feedback">Please enter a valid commission.</div>
                    </div>

                    <div class="mb-3">
                      <label
                        for="description"
                        class="form-label"
                        >GST Number</label
                      >

                      <input
                        v-model="org.gst_number"
                        type="text"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        maxlength="9"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !org.gst_number,
                          'is-valid': org.gst_number && org.gst_number.length > 7,
                        }"
                        placeholder="123-456-789"
                        required />
                      <div class="invalid-feedback">Please enter a valid GST number.</div>
                    </div>
                    <div class="mb-3">
                      <div class="form-check">
                        <input
                          v-model="org.is_exempt_new_contract"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault" />
                        <label
                          class="form-check-label"
                          for="flexCheckDefault">
                          Exempt from signing new contract
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button
                          @click.prevent="save_org"
                          type="submit"
                          class="btn btn-secondary">
                          Save
                        </button>
                      </div>
                      <div class="col text-end">
                        <button
                          @click.prevent="delete_org()"
                          type="submit"
                          class="btn btn-outline-danger">
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div
            v-if="org_users.length"
            class="row">
            <div class="col">
              <div
                v-if="org.name"
                class="card border-dark">
                <div class="card-header">
                  <h6>USERS</h6>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in org_users"
                        :key="user.id">
                        <td>{{ user.name }}</td>
                        <td>
                          <a
                            class="text-secondary"
                            :href="`mailto:${user.email}`"
                            >{{ user.email }}</a
                          >
                        </td>
                        <td class="w-10">
                          <router-link
                            :to="{
                              name: 'UserProfile',
                              params: { user_id: user.id },
                            }"
                            title="edit user">
                            <edit-icon
                              size="1x"
                              class="text-success"></edit-icon>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="org_artists.length"
            class="row mt-3">
            <div class="col">
              <div
                v-if="org.name"
                class="card border-dark">
                <div class="card-header">
                  <h6>ARTISTS</h6>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>

                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="artist in org_artists"
                        :key="artist.id">
                        <td>{{ artist.name }}</td>

                        <td class="w-10">
                          <router-link
                            :to="{
                              name: 'ArtistProfile',
                              params: { artist_id: artist.id },
                            }"
                            title="edit artist">
                            <edit-icon
                              size="1x"
                              class="text-success"></edit-icon>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <JobNotes
            :org_id="org.id"
            class="mt-2"></JobNotes>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import JobNotes from "@/components/JobNotes";
import Countries from "../assets/Countries.json";
import { EditIcon } from "vue-feather-icons";

export default {
  name: "orgProfile",
  data() {
    return {
      org: {},
      org_users: [],
      org_artists: [],
      countries: Countries,
      types: ["Label", "Manager", "Artist"],
      submitted: false,
    };
  },
  firestore() {
    return {
      org: db.collection("orgs").doc(this.$route.params.org_id),
      org_users: db.collection("users").where("org_ids", "array-contains", this.$route.params.org_id),
      org_artists: db.collection("artists").where("org_ids", "array-contains", this.$route.params.org_id),
    };
  },
  components: {
    basicLayout,
    EditIcon,
    JobNotes,
  },

  methods: {
    save_org() {
      console.log(this.org.id);
      db.collection("orgs").doc(this.org.id).set(this.org);
      this.$router.push("/orgs");
    },
    delete_org() {
      console.log(this.org.id);
      db.collection("orgs").doc(this.org.id).update({ is_active: false });
      this.$router.push("/orgs");
    },
  },
};
</script>
