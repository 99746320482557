<template>
  <div>
    <form
      class="needs-validation"
      novalidate>
      <div class="mb-3">
        <label for="release_owner_org"
          >Owner (Org)<BSPopover
            class="d-inline"
            content="This is the organisation to which you belong."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>

        <v-select
          v-model="release.release_owner_org"
          :options="display_orgs"
          :getOptionLabel="(org) => `${org.name} (${org.royalties_name_code})`"
          placeholder="Choose Owner (Org) ..."
          @input="release.release_main_artist = null"
          :selectOnTab="true"
          :clearable="true"></v-select>

        <div class="invalid-feedback">Please enter org</div>
      </div>

      <div class="mb-3">
        <label for="release_main_artist"
          >Artist / Group / Band<BSPopover
            class="d-inline"
            content="Artist must be associated with the Owner (Org) above."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>

        <v-select
          v-model="release.release_main_artist"
          :options="display_org_artists"
          :getOptionLabel="(org) => org.name"
          placeholder="Choose Artist ..."
          :selectOnTab="true"
          :disabled="!release.release_owner_org"
          :clearable="true"></v-select>
        <div class="invalid-feedback">Please enter artist</div>
      </div>

      <div class="mb-3">
        <label for="release_title">Release Title</label>

        <input
          v-model="release.release_title"
          type="text"
          class="form-control"
          id="release_title"
          :class="{
            'is-invalid': is_incomplete && (!release.release_title || release.release_title.includes('[NEW RELEASE')),
            'is-valid': release.release_title,
          }"
          required />
        <div class="invalid-feedback">Please enter a valid title.</div>
      </div>

      <div class="mb-3">
        <label for="label "> Label</label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_label"
            type="text"
            class="form-control"
            id="release_label"
            :class="{
              'is-valid': release.release_label,
            }"
            disabled />

          <div class="invalid-feedback">Please enter label.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="production_owner">
          Production Owner<BSPopover
            class="d-inline"
            content="This is the person who owns the recording. It will usually be the person or party who paid for the production."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_production_owner"
            type="text"
            class="form-control"
            id="release_production_owner"
            :class="{
              'is-invalid': is_incomplete && !release.release_production_owner,
              'is-valid': release.release_production_owner,
            }"
            required />

          <div class="invalid-feedback">Please enter production owner.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="copyright_owner"
          >Copyright Owner<BSPopover
            class="d-inline"
            content="This is the person or party who owns the cover art (below)."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <div class="input-group mb-3">
          <input
            v-model="release.release_copyright_owner"
            type="text"
            class="form-control"
            id="release_copyright_owner"
            :class="{
              'is-invalid': is_incomplete && !release.release_copyright_owner,
              'is-valid': release.release_copyright_owner,
            }"
            required />

          <div class="invalid-feedback">Please enter copyright owner.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="release_genre">Genre</label>

        <v-select
          v-model="release.release_genre"
          :options="display_genres"
          :selectOnTab="true"
          :clearable="true"></v-select>
        <div class="invalid-feedback">Please enter valid genre</div>
      </div>

      <div class="mb-3">
        <label for="release_parental_advisory">Parental Advisory Type</label>
        <select
          v-model="release.release_parental_advisory"
          class="form-select d-block w-100"
          id="release_parental_advisory"
          :class="{
            'is-invalid': is_incomplete && !release.release_parental_advisory,
            'is-valid': release.release_parental_advisory,
          }"
          required>
          <option value="NotExplicit">Not Explicit</option>
          <option value="Explicit">Explicit</option>
          <option value="Explicit">Cleaned / Edited</option>
        </select>
        <div class="invalid-feedback">Please specify parental advisory type</div>
      </div>

      <div class="mb-3">
        <label for="release_territories">
          Territories to Deliver<BSPopover
            class="d-inline"
            content="This will usually be 'Worldwide'.  If the release is restricted to certain territories, choose 'Selected Territories' and then click 'Edit Selection' to choose which territories apply."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon>
          </BSPopover>
        </label>

        <div class="input-group">
          <select
            v-model="release_territories_selection"
            class="form-select"
            id="inputGroupSelect04"
            aria-label="Example select with button addon">
            <option>Worldwide</option>
            <option>Selected Territories</option>
          </select>
          <button
            @click="show_picker"
            v-show="release_territories_selection == 'Selected Territories'"
            class="btn btn-outline-success"
            type="button">
            Edit Selection...
          </button>
        </div>
        <div class="invalid-feedback">Please select a valid territory.</div>
      </div>

      <CountriesPicker
        ref="countries_picker"
        :release="release"
        @update_territories="update_territories" />

      <div class="row">
        <div class="col-md-3 mb-3">
          <label for="release_production_year"
            >Production Year<BSPopover
              class="d-inline"
              content="Corresponds to the original release date (below).  For new releases, use this year."
              trigger="hover">
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"></info-icon> </BSPopover
          ></label>
          <select
            v-model="release.release_production_year"
            class="form-select d-block w-100"
            id="release_production_year"
            :class="{
              'is-invalid': is_incomplete && !release.release_production_year,
              'is-valid': release.release_production_year,
            }"
            required>
            <option
              disabled
              value>
              Choose...
            </option>
            <option
              v-for="year in years"
              :key="year">
              {{ year }}
            </option>
          </select>
          <div class="invalid-feedback">Please provide a valid production year.</div>
        </div>
      </div>

      <div class="mb-3">
        <label for="release_territories">
          Release Price Tier<BSPopover
            class="d-inline"
            content="This determines the price per download.  Typically, you would chose DIGITAL45 for a single, BUDGET for an EP and MIDFRONT for an album."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <select
          v-model="release.release_price_tier"
          class="form-select d-block w-100 m-1"
          id="release_pricing"
          :class="{
            'is-invalid': is_incomplete && release.release_price_tier && !release.release_price_tier,
            'is-valid': release.release_price_tier && release.release_price_tier,
          }"
          required>
          <option
            value=""
            disabled>
            Choose ...
          </option>
          <option value="DIGITAL45">DIGITAL45 (EUR $1.49)</option>
          <option value="BUDGET">BUDGET (EUR $5.99)</option>
          <option value="MIDFRONT">MIDFRONT (EUR $9.99)</option>
          <option
            value=""
            disabled>
            -------------------------
          </option>
          <option
            v-for="tier in price_tiers"
            :key="tier.code">
            {{ tier.display }}
          </option>
        </select>
        <div class="invalid-feedback">Please select a valid price tier.</div>
      </div>

      <div class="mb-3">
        <label for="release_original_release_date"
          >Original Release Date<BSPopover
            class="d-inline"
            content="This is the non-digital release date e.g. the date first released on CD or vinyl.  If never previously released, this will be the same date as the release date below."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_original_release_date"
          type="date"
          class="form-control"
          id="release_original_release_date"
          :class="{
            'is-invalid': is_incomplete && !release.release_original_release_date,
            'is-valid': release.release_original_release_date,
          }"
          required />
        <div class="invalid-feedback">Please enter original release date.</div>
      </div>

      <div class="mb-3">
        <label for="release_release_date"
          >Release Date<BSPopover
            class="d-inline"
            content="This is the proposed digital release date.  You should allow at least 2 weeks."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_release_date"
          type="date"
          class="form-control"
          id="release_release_date"
          :class="{
            'is-invalid': is_incomplete && !release.release_release_date,
            'is-valid': release.release_release_date,
          }"
          required />
        <div class="invalid-feedback">Please enter release date.</div>
      </div>

      <div
        v-if="isAdmin"
        class="mb-3">
        <label for="release_upc"
          >Release UPC (Optional)<BSPopover
            class="d-inline"
            content="If you do not already have a UPC leave this blank - a UPC will be assigned on release."
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <input
          v-model="release.release_upc"
          type="text"
          class="form-control"
          id="release_upc"
          required />
        <div class="invalid-feedback">Please enter a valid UPC.</div>
      </div>

      <div
        v-if="release.release_art_url"
        class="card">
        <div class="card-header bg-transparent">
          <h6 class="text-uppercase">Cover Art</h6>
        </div>
        <div class="card-body text-center">
          <img
            :src="release.release_art_url"
            width="200px"
            height="auto" />
        </div>

        <div class="card-footer text-center">
          <button
            @click.prevent="upload_image"
            type="button"
            class="btn btn-sm btn-outline-secondary">
            Replace Art
          </button>
        </div>
      </div>
      <div
        v-else
        class="card mt-2">
        <div class="card-header">
          <h6 class="text-uppercase">Upload Cover Art</h6>
          <p>Please upload cover art file in the format .jpg or .png, minimum 1500 x 1500 pixels</p>
        </div>
        <div class="card-body">
          <button
            @click.prevent="upload_image"
            type="button"
            class="btn btn-sm btn-outline-secondary">
            Upload
          </button>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button
            @click.prevent="submit_form"
            class="btn btn-success btn-sm mr-2">
            Validate and Save
          </button>

          <button
            v-if="is_incomplete"
            @click.prevent="save_form"
            class="btn btn-warning btn-sm ms-1">
            Save for Later
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BSPopover from "@/components/BSPopover";
import { orderBy } from "lodash";
import CountriesPicker from "@/components/CountriesPicker";
import { db } from "@/firebaseConfig";
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import Countries from "../assets/Countries.json";
// import Languages from "../assets/Languages.json";
import Genres from "../assets/GenresBelieve.json";
import ReleasePriceTiers from "../assets/ReleasePriceTiers.json";
import { InfoIcon } from "vue-feather-icons";

const releases = db.collection("release_submissions");
const orgs = db.collection("orgs");
const artists = db.collection("artists");

export default {
  name: "release_form",
  props: {
    release_id: String,
  },
  components: {
    BSPopover,
    InfoIcon,
    CountriesPicker,
  },
  data() {
    return {
      release: {
        release_territories_included: [],
        release_territories_excluded: [],
        release_owner_org: { id: "", name: "", label: "", description: "" },
        release_main_artist: { id: "", name: "", label: "" },
      },
      countries: Countries,
      // languages: Languages,
      genres: Genres,
      price_tiers: ReleasePriceTiers,
      required: [
        "release_title",
        "release_main_artist",
        "release_label",
        "release_genre",
        "release_parental_advisory",
        "release_production_year",
        "release_original_production_year",
        "release_release_date",
        "release_release_date",
      ],
      is_incomplete: false,
      changed_fields: [],
      visible_orgs: [],
      visible_org_artists: [],
      release_territories_selection: "",
    };
  },

  methods: {
    show_picker() {
      this.$refs.countries_picker.open_modal();
    },

    submit_form: function () {
      // console.log("SUBMITTING RELEASE");

      // console.log("IS_COMPLETE: " + this.is_complete());
      if (!this.is_complete()) {
        this.is_incomplete = true;
        this.$toast.warning(
          "You are missing some required information.  Please complete the highlighted fields or, save this form and come back to it later.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.save_form(true);
      }
    },

    save_form: async function (close) {
      this.is_incomplete = false;

      // console.log("UPDATING RELEASE");
      this.update_release();

      this.$toast.success("Saved.", {
        position: "top-right",
        timeout: 5000,
      });

      if (close) {
        // console.log("CLOSING FORM");
        const route_name = this.isAdmin ? "ReleasesAdminList" : "ReleasesMyDraftsList";
        this.$router.push({
          name: route_name,
        });
      }
    },
    update_release: async function () {
      // this.release.release_upc = this.release.release_upc || this.generate_upc();

      const release_update = {
        ...this.release,
        is_new: false,
        is_incomplete: !this.is_complete(),
      };
      // console.log(release_update);
      // console.log("RELEASE ID: " + this.release_id);
      await db.collection("release_submissions").doc(this.release_id).update(release_update);
    },

    is_complete: function () {
      // console.log("CHECKING FORM IS COMPLETE");
      const release = this.release;

      let is_complete;

      if (
        !release.release_title ||
        release.release_title.includes("[NEW RELEASE") ||
        release.release_title.includes("[COPY]")
      ) {
        is_complete = false;
        return is_complete;
      }

      const entries = Object.entries(release);

      const missing_fields = entries
        .filter(([k, v]) => {
          return k && (v === undefined || v === null || v === "" || v === []);
        })
        .map(([k]) => k);

      // console.log(missing_fields);

      is_complete = !missing_fields.some((missing_field) => {
        return this.required.includes(missing_field);
      });
      // console.log("IS COMPLETE?: " + is_complete);

      return is_complete;
    },

    upload_image() {
      this.save_form();
      window.cloudinary
        .openUploadWidget(
          {
            cloud_name: "drm-nz-portal",
            uploadPreset: "album_cover",
            clientAllowedFormats: ["jpg", "png"],
            cropping: true,
            croppingAspectRatio: 1.0,
            minImageWidth: 1500,
            minImageHeight: 1500,
            croppingShowDimensions: true,
            croppingValidateDimensions: true,
            croppingShowBackButton: true,
            showSkipCropButton: false,
            theme: "minimal",
            showPoweredBy: false,
            buttonClass: "btn btn-outline-success",
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.save_image(result.info);
            }
          }
        )
        .open();
    },
    save_image: async function (info) {
      // console.log(info);
      const release_art_url = info.secure_url;
      const release_thumbnail_url = info.thumbnail_url;
      const release_art_info = {
        height: info.height,
        width: info.width,
        format: info.format,
        bytes: info.bytes,
        hashsum: info.etag,
      };

      await db
        .collection("release_submissions")
        .doc(this.release.id)
        .update({ release_art_url, release_thumbnail_url, release_art_info });
    },

    open_territory_picker() {
      this.modal.show();
    },

    save_territories() {
      this.$refs.countries_picker.save_territories();
    },

    update_territories(e) {
      // console.log("FROM PICKER: " + JSON.stringify(e));

      this.release.release_territories_included = e.release_territories_included;
      this.release.release_territories_excluded = e.release_territories_excluded;
    },
  },

  // created() {
  //   if (this.isAdmin) {
  //     // let query = orgs.where("royalties_name_code", "==", "msfinnj");
  //     this.$bind("visible_orgs", orgs);
  //   } else {
  //     let query = orgs.where("__name__", "in", this.userProfile.org_ids);
  //     this.$bind("visible_orgs", query);
  //   }
  // },

  watch: {
    isAdmin: {
      handler(isAdmin) {
        if (isAdmin) {
          // let query = orgs.where("royalties_name_code", "==", "msfinnj");
          this.$bind("visible_orgs", orgs);
        } else {
          let query = orgs.where("__name__", "in", this.userProfile.org_ids);
          this.$bind("visible_orgs", query);
        }
      },
      immediate: true,
    },

    release_id: function (new_release) {
      // console.log("release loaded");
      this.$bind("release", releases.doc(new_release));
    },
    "release.release_owner_org": function (owner_org) {
      if (owner_org && owner_org.id) {
        this.$bind("visible_org_artists", artists.where("org_ids", "array-contains", owner_org.id));
        this.release.release_label = `${owner_org.name}`;
      } else {
        // this.visible_org_artists = [];
        this.release.release_label = "";
      }
    },

    "release.release_territories_included": function (included) {
      // console.log(JSON.stringify(included));
      // console.log(included.length);
      // console.log(included == ["Worldwide"]);
      // console.log(included[0] == "Worldwide");
      if (included.length === 1 && included[0] == "Worldwide") {
        // console.log("WORLDWIDE DISTRIBUTION");
        this.release_territories_selection = "Worldwide";
      } else {
        // console.log("SELECTED TERRITORIES");
        this.release_territories_selection = "Selected Territories";
      }
    },

    "org.name"(newQuestion, oldQuestion) {
      if (newQuestion !== oldQuestion) {
        // console.log(newQuestion);
        this.org.royalties_name_code = newQuestion.toUpperCase().trim().split(" ").join("").substring(0, 12);
      }
    },
    deep: true,
  },

  computed: {
    display_orgs: function () {
      let orgs = [...this.visible_orgs];
      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          // label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description || "",
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");

      return orgs;
    },

    display_org_artists: function () {
      let org_artists = [...this.visible_org_artists];

      org_artists = org_artists.map((a) => {
        let display_artist = {
          id: a.id,
          // label: a.name,
          name: a.name,
          tier: a.tier,
          apple_id: a.apple_id,
          spotify_id: a.spotify_id,
        };

        return display_artist;
      });
      org_artists = orderBy(org_artists, "name", "asc");
      return org_artists;
    },

    display_genres: function () {
      let genres = this.genres.slice();

      genres = genres.map((g) => {
        g.label = `${g.genre} ${g.subgenre && "|"} ${g.subgenre}`;
        return g;
      });
      genres = orderBy(genres, "genre", "asc");
      return genres;
    },

    years() {
      const year_start = new Date().getFullYear();
      const years_array = [];
      let i;

      for (i = 1960; i < year_start + 2; i++) {
        years_array.push(i);
      }

      return years_array.reverse();
    },
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        (this.userProfile && (this.userProfile.role === "admin" || this.userProfile.role === "superadmin")) || false
      );
    },
  },
};
</script>

<style>
.popover {
  max-width: 95%;
}
.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
}
</style>
