<template>
  <div class="form-group mb-3">
    <label
      for="upload"
      class="form-label"
      >Upload a sample music file<small class="text-muted ms-3">.wav or .mp3 files only</small></label
    >
    <div class="row">
      <div class="col-xl-6">
        <input
          class="form-control"
          type="file"
          @change.prevent="file_picker"
          accept=".wav,.mp3,.m4a,.aac" />
      </div>
      <div
        class="col-xl-6"
        v-cloak
        @drop.prevent="drag"
        @dragover.prevent>
        <input
          class="form-control progress-bar-striped alert-dark"
          placeholder="...or drag file here"
          accept=".wav,.mp3,.m4a,.aac" />
      </div>
    </div>
    <div class="row mt-2">
      <div
        v-if="upload_progress > 0 && upload_progress < 99"
        class="col mt-3">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            :style="{ width: upload_progress + '%' }"
            :key="upload_progress"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "@/firebaseConfig";

export default {
  props: {
    contact: Object,
  },
  data() {
    return {
      audio_file_url: "",
      upload_progress: 0,
    };
  },

  methods: {
    drag(event) {
      const file = event.dataTransfer.files[0];
      console.log(file.type);
      this.upload(file);
      event.target.value = "";
    },
    file_picker: async function (event) {
      console.log("file picker");
      const file = event.target.files[0];
      console.log(file.name);
      this.upload(file);
    },

    upload: async function (file) {
      this.$emit("audio_file_upload");

      console.log("CHECK FILE EXT: " + JSON.stringify(file));

      const allowedExtensions = /(\.wav|\.mp3|\.m4a|\.aac)$/i;

      if (!allowedExtensions.exec(file.name)) {
        this.$toast.warning("Please upload a WAV, MP3, M4A or AAC file only.", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      this.$toast.success("Please do not leave or refresh this page during upload.", {
        position: "top-right",
        timeout: 5000,
      });

      const audio_file_url = await this.uploadTaskPromise(file);
      console.log(`Successfully uploaded file and got download link - ${audio_file_url}`);

      this.$emit("add_audio_file_url", audio_file_url);

      this.$toast.success("Audio file successfully uploaded.", {
        position: "top-right",
        timeout: 5000,
      });
    },

    uploadTaskPromise: async function (file) {
      return new Promise((resolve, reject) => {
        const storageRef = storage.ref();
        const wav_ref = storageRef.child(`contact_audio/${this.contact.id}.wav`);
        const uploadTask = wav_ref.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.upload_progress = progress;
          },
          function error(err) {
            console.log("error", err);
            reject();
          },
          function complete() {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },
};
</script>

<style scoped>
</style>
