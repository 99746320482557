<template>
  <form class="needs-validation" novalidate>
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="track_type" class="form-label"
          >New Track
          <BSPopover
            class="d-inline"
            content="Indicate if this is an existing track that has already been released."
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <select
          v-model="track.is_existing_track"
          class="form-select d-block w-100"
          id="track_new"
          :class="{
            'is-invalid':
              is_incomplete &&
              !track.is_existing_track === true &&
              !track.is_existing_track === false,
            'is-valid':
              track.is_existing_track === true ||
              track.is_existing_track === false,
          }"
          required
        >
          <option :value="false">New Track</option>
          <option :value="true">Existing Track</option>
        </select>
        <div class="invalid-feedback">
          Please specify whether a new track or existing track
        </div>
      </div>
    </div>

    <div v-if="track.is_existing_track" class="row mb-3">
      <div class="col-md-6">
        <label for="track_genre" class="form-label"
          >Find Existing Track <i>(by ISRC, title or artist)</i>
          <BSPopover
            class="d-inline"
            content="If you do not already have an ISRC leave this blank - an ISRC will be assigned on release."
            trigger="hover"
          >
            <info-icon size="1.0x" class="text-success ms-2 mb-1"></info-icon>
          </BSPopover>
        </label>

        <v-select
          :value="existing_track"
          :options="display_tracks"
          @input="set_existing_track"
          :selectOnTab="true"
          :clearable="false"
        ></v-select>
        <div class="invalid-feedback">Please enter valid genre</div>
      </div>
    </div>

    <div v-if="!track.is_existing_track" class="row mb-3">
      <div class="col-md-6">
        <label for="track_isrc" class="form-label">
          Track ISRC <i>(International Sound Recording Code)</i>
          <BSPopover
            class="d-inline"
            content="If you do not already have an ISRC leave this blank - an ISRC will be assigned on release."
            trigger="hover"
          >
            <info-icon size="1.0x" class="text-success ms-2 mb-1"></info-icon>
          </BSPopover>
        </label>

        <input
          v-model="track.track_isrc"
          type="text"
          class="form-control"
          id="track_isrc"
          required
        />

        <div class="invalid-feedback">Please enter a valid ISRC.</div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3 mb-3 mt-0">
        <label for="track_number" class="form-label">Track Number*</label>
        <input
          v-model="track.track_number"
          type="number"
          class="form-control"
          id="track_number"
          :class="{
            'is-invalid': is_incomplete && !track.track_number,
            'is-valid': track.track_number,
          }"
          required
        />
        <div class="invalid-feedback">please enter a track number</div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="volume_number" class="form-label"
          >Volume Number*<BSPopover
            class="d-inline"
            content="Usually 1"
            trigger="hover"
          >
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"
            ></info-icon> </BSPopover
        ></label>
        <input
          v-model="track.track_volume_number"
          type="number"
          class="form-control"
          id="volume_number"
          value="1"
          :class="{
            'is-invalid': is_incomplete && !track.track_volume_number,
            'is-valid': track.track_volume_number,
          }"
          required
        />
        <div class="invalid-feedback">Please enter a volume number.</div>
      </div>
    </div>
    <div v-if="!track.is_existing_track">
      <div class="row align-items-center mb-3">
        <div class="col-md-6">
          <label for="track_title" class="form-label">Track Title*</label>
          <input
            v-model="track.track_title"
            type="text"
            class="form-control"
            id="track_title"
            :class="{
              'is-invalid': is_incomplete && !track.track_title,
              'is-valid': track.track_title,
            }"
            required
          />
          <div class="invalid-feedback">Please enter a track title.</div>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-md-6">
          <label for="track_version_title" class="form-label"
            >Version Title<BSPopover
              class="d-inline"
              content="Optional field to add further details to the track title e.g. 'Remastered', 'DJ Mix', 'Acoustic'.  This will appear in brackets after the title on the digital platforms."
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>
          <input
            v-model="track.track_version_title"
            type="text"
            class="form-control"
            id="track_version_title"
            required
          />
          <div class="invalid-feedback">Please enter a version title.</div>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-md-6">
          <label for="track_subtitle" class="form-label"
            >Track Subtitle<BSPopover
              class="d-inline"
              content="Optional field to add further details to the track title e.g. 'Escape (The Piña Colada Song)'"
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>
          <input
            v-model="track.track_subtitle"
            type="text"
            class="form-control"
            id="track_title"
            :class="{
              'is-valid': track.track_subtitle,
            }"
            required
          />
          <div class="invalid-feedback">Please enter a track subtitle.</div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="row align-items-center mb-3">
          <div class="col-md-6">
            <label for="track_main_artists" class="form-label">
              Main Artist(s)*
            </label>
          </div>
        </div>

        <div
          v-for="(artist, i) in track.track_main_artists"
          :key="artist?.objectID"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_artists"
                @search="(query) => debounce_artists_search(query)"
                @option:selected="add_record"
                v-model="track.track_main_artists[i]"
                :options="algolia_artists_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_main_artists',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing artist.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>

            <div class="col-md-2">
              <button
                @click.prevent="add_line(track.track_main_artists, i)"
                @keydown.tab="add_line(track.track_main_artists, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add main artist"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_main_artists, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove main artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${artist.apple_id}`"
                >apple: {{ artist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${artist.spotify_id}`"
                >spotify: {{ artist.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_featured_artists" class="form-label">
            Featured Artist(s)
          </label>
        </div>

        <div
          v-for="(artist, i) in track.track_featured_artists"
          :key="artist.objectID"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_artists"
                @search="(query) => debounce_artists_search(query)"
                @option:selected="add_record"
                v-model="track.track_featured_artists[i]"
                :options="algolia_artists_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_featured_artists',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing artist.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2">
              <button
                @click.prevent="add_line(track.track_featured_artists, i)"
                @keydown.tab="add_line(track.track_featured_artists, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add featured artist"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_featured_artists, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove featured artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${artist.apple_id}`"
                >apple: {{ artist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="artist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${artist.spotify_id}`"
                >spotify: {{ artist.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_remix_artist_name" class="form-label">
            Remix Artist(s)
          </label>
        </div>

        <div
          v-for="(remix_artist, i) in track.track_remix_artists"
          :key="remix_artist.objectID"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_people"
                @search="(query) => debounce_people_search(query)"
                @option:selected="add_record"
                v-model="track.track_remix_artists[i]"
                :options="algolia_people_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_remix_artists',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing contributor.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2 mb-3 mt-auto">
              <button
                @click.prevent="add_line(track.track_remix_artists, i)"
                @keydown.tab="add_line(track.track_remix_artists, i)"
                title="add remix artist"
                class="btn btn-x-sm btn-outline-success me-1"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_remix_artists, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove remix artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="remix_artist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${remix_artist.apple_id}`"
                >apple: {{ remix_artist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="remix_artist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${remix_artist.spotify_id}`"
                >spotify: {{ remix_artist.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_composers" class="form-label"> Composer(s) </label>
        </div>

        <div v-for="(composer, i) in track.track_composers" :key="composer.id">
          <div class="row">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_people"
                @search="(query) => debounce_people_search(query)"
                @option:selected="add_record"
                v-model="track.track_composers[i]"
                :options="algolia_people_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_composers',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing contributor.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2 mb-3 mt-auto">
              <button
                @click.prevent="add_line(track.track_composers, i)"
                @keydown.tab="add_line(track.track_composers, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add composer"
              >
                +
              </button>

              <button
                @click.prevent="delete_line(track.track_composers, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove composer"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="composer.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${composer.apple_id}`"
                >apple: {{ composer.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="composer.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${composer.spotify_id}`"
                >spotify: {{ composer.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_lyricists" class="form-label">
            Lyric Author(s)
          </label>
        </div>

        <div v-for="(lyricist, i) in track.track_lyricists" :key="lyricist.id">
          <div class="row mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_people"
                @search="(query) => debounce_people_search(query)"
                @option:selected="add_record"
                v-model="track.track_lyricists[i]"
                :options="algolia_people_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_lyricists',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing contributor.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2 mb-3 mt-auto">
              <button
                @click.prevent="add_line(track.track_lyricists, i)"
                @keydown.tab="add_line(track.track_lyricists, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add lyricist"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_lyricists, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove lyricist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="lyricist.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${lyricist.apple_id}`"
                >apple: {{ lyricist.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="lyricist.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${lyricist.spotify_id}`"
                >spotify: {{ lyricist.spotify_id }}</span
              >
            </div>
            <span
              :class="{
                'is-invalid': is_incomplete && !track.track_lyricists[0].name,
                'is-valid': track.track_lyricists[0].name,
              }"
            ></span>
            <div class="invalid-feedback">Please specify track lyricists</div>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_producer_name" class="form-label">
            Producer(s)
          </label>
        </div>

        <div
          v-for="(producer, i) in track.track_producers"
          :key="producer.objectID"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <v-select
                @search:focus="refresh_index_people"
                @search="(query) => debounce_people_search(query)"
                @option:selected="add_record"
                v-model="track.track_producers[i]"
                :options="algolia_people_options"
                label="name"
                :selectOnTab="true"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <div class="row p-1">
                      <div class="col">
                        No results found for <em>{{ search }}</em>
                      </div>
                    </div>
                    <div class="row p-1">
                      <div class="col">
                        <button
                          @click.prevent="
                            add_record({
                              name: search,
                              record_type: 'track_producers',
                              index: i,
                            })
                          "
                          class="btn btn-sm btn-outline-success"
                        >
                          add new record
                        </button>
                      </div>
                    </div>
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Start typing to search for an existing contributor.</em
                  >
                </template>
                <template #option="{ _highlightResult }">
                  <div>
                    <span v-html="_highlightResult.name.value"></span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-2 mb-3 mt-auto">
              <button
                @click.prevent="add_line(track.track_producers, i)"
                @keydown.tab="add_line(track.track_producers, i)"
                title="add remix artist"
                class="btn btn-x-sm btn-outline-success me-1"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_producers, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove remix artist"
              >
                -
              </button>
            </div>
            <div class="col-md-2">
              <span
                v-if="producer.apple_id"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`apple id: ${producer.apple_id}`"
                >apple: {{ producer.apple_id }}</span
              >
            </div>
            <div class="col-md-2">
              <span
                v-if="producer.spotify_id"
                type="text"
                class="form-control form-control-sm bg-light d-inline-block text-truncate"
                :title="`spotify id: ${producer.spotify_id}`"
                >spotify: {{ producer.spotify_id }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card p-3 mb-3">
        <div class="mb-3">
          <label for="track_producers" class="form-label"> Producer(s) </label>
        </div>

        <div v-for="(producer, i) in track.track_producers" :key="producer.id">
          <div class="row">
            <div class="col-md-6">
              <label for="track_producer_name" class="form-label text-muted"
                >Name ({{ i + 1 }})</label
              >
              <input
                v-model="producer.name"
                type="text"
                class="form-control"
                id="track_producer_name"
                placeholder="Name"
                required
              />
              <div class="invalid-feedback">
                Please provide an producer name.
              </div>
            </div>
            <div class="col-md-2 mb-3 mt-auto">
              <button
                @click.prevent="add_line(track.track_producers, i)"
                @keydown.tab="add_line(track.track_producers, i)"
                class="btn btn-x-sm btn-outline-success me-1"
                title="add producer"
              >
                +
              </button>
              <button
                @click.prevent="delete_line(track.track_producers, i)"
                class="btn btn-x-sm btn-outline-danger"
                title="remove producer"
              >
                -
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_genre" class="form-label">Genre*</label>

          <v-select
            v-model="track.track_genre"
            :options="display_genres"
            :selectOnTab="true"
            :clearable="false"
          ></v-select>
          <div class="invalid-feedback">Please enter valid genre</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_subgenre" class="form-label">Subgenre*</label>

          <v-select
            v-model="track.track_subgenre"
            :options="display_subgenres"
            :selectOnTab="true"
            :clearable="false"
          ></v-select>
          <div class="invalid-feedback">Please enter valid subgenre</div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_type" class="form-label">Track Type</label>
          <select
            v-model="track.track_type"
            class="form-select d-block w-100"
            id="track_type"
            :class="{
              'is-invalid': is_incomplete && !track.track_type,
              'is-valid': track.track_type,
            }"
            required
          >
            <option>Original</option>
            <option>Cover</option>
            <option>Karaoke</option>
            <option>Medley</option>
            <option>Cover by Cover Band</option>
          </select>
          <div class="invalid-feedback">Please specify track type</div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_lyric_language" class="form-label">
            Track Lyric Language*
            <BSPopover
              class="d-inline"
              content="Please choose 'Instrumental' for instrumental tracks."
              trigger="hover"
            >
              <info-icon size="1.0x" class="text-success ms-2 mb-1"></info-icon>
            </BSPopover>
          </label>
          <select
            v-model="track.track_lyric_language"
            class="form-select d-block w-100"
            id="track_lyric_language"
            :class="{
              'is-invalid': is_incomplete && !track.track_lyric_language,
              'is-valid': track.track_lyric_language,
            }"
            required
          >
            <option disabled value>Choose...</option>
            <option value="EN">English</option>
            <option value="MI">Maori</option>
            <option value="ZXX">Instrumental</option>
            <option disabled value>----------</option>

            <option
              v-for="language in languages"
              :value="language.id"
              :key="language.id"
            >
              {{ language.name }}
            </option>
          </select>
          <div class="invalid-feedback">Please select a valid language.</div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_title_language" class="form-label">
            Track Title Language*
          </label>
          <select
            v-model="track.track_title_language"
            class="form-select d-block w-100"
            id="track_tile_language"
            :class="{
              'is-invalid': is_incomplete && !track.track_title_language,
              'is-valid': track.track_title_language,
            }"
            required
          >
            <option disabled value>Choose...</option>
            <option value="EN">English</option>
            <option value="MI">Maori</option>
            <option disabled value>----------</option>

            <option
              v-for="language in languages"
              :value="language.id"
              :key="language.id"
            >
              {{ language.name }}
            </option>
          </select>
          <div class="invalid-feedback">Please select a valid language.</div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="track_type" class="form-label"
            >Parental Advisory Type</label
          >
          <select
            v-model="track.track_parental_advisory"
            class="form-select d-block w-100"
            id="track_parental_advisory"
            :class="{
              'is-invalid':
                is_incomplete &&
                !track.track_parental_advisory === true &&
                !track.track_parental_advisory === false &&
                !track.track_parental_advisory === 'CLEAN',
              'is-valid':
                track.track_parental_advisory === true ||
                track.track_parental_advisory === false ||
                track.track_parental_advisory === 'CLEAN',
            }"
            required
          >
            <option :value="false">Not Explicit</option>
            <option :value="true">Explicit</option>
            <option value="CLEAN">Cleaned / Edited</option>
          </select>
          <div class="invalid-feedback">
            Please specify parental advisory type
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 mb-3 mt-0">
          <label for="description" class="form-label"
            >Preview Audio Start (secs)<BSPopover
              class="d-inline"
              :content="`The start point for a preview of this track in seconds e.g. 30`"
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>

          <input
            v-model="track.track_preview_audio_start"
            type="number"
            class="form-control"
            :class="{
              'is-invalid':
                is_incomplete && track.track_preview_audio_start === null,
              'is-valid': track.track_preview_audio_start,
            }"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid preview start time.
          </div>
        </div>
        <div class="col-md-3 mb-3 mt-0">
          <label for="description" class="form-label"
            >Preview Duration (secs)<BSPopover
              class="d-inline"
              :content="`Duration of the preview of this track in seconds e.g. 90`"
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>

          <input
            v-model="track.track_preview_audio_length"
            type="number"
            class="form-control"
            :class="{
              'is-invalid':
                is_incomplete && track.track_preview_audio_length === null,
              'is-valid': track.track_preview_audio_length,
            }"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid preview start time.
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="tier">
            Track Price Tier<BSPopover
              class="d-inline"
              content="This is the download price for a single track.  All tracks in the same release should have the same price tier.  Typically, you would choose FRONT."
              trigger="hover"
            >
              <info-icon
                size="1.0x"
                class="text-success ms-2 mb-1"
              ></info-icon> </BSPopover
          ></label>
          <select
            v-model="track.track_price_tier"
            class="form-select d-block w-100 m-1"
            id="track_price_tier"
            :class="{
              'is-invalid':
                is_incomplete &&
                track.track_price_tier &&
                !track.track_price_tier,
              'is-valid': track.track_price_tier && track.track_price_tier,
            }"
            required
          >
            <option
              v-for="tier in price_tiers"
              :key="tier.code"
              :value="tier.code"
            >
              {{ tier.display }}
            </option>
          </select>
          <div class="invalid-feedback">Please select a valid price tier.</div>
        </div>
      </div>

      <div class="card mt-2" id="audio-upload">
        <div class="card-header">
          <h6 class="text-uppercase">Audio File</h6>
        </div>
        <div class="card-body text-center">
          <audio v-if="track.track_audio_url" controls>
            <source
              :src="this.track.track_audio_url"
              type="audio/wav"
              preload="auto"
            />
          </audio>

          <p v-else>
            Please upload a .wav file for this track
            <br /><small
              >Do not close or refresh this page while file is uploading</small
            >
          </p>
        </div>
        <div class="card-body">
          <MusicUploader
            :track="track"
            v-on:save_track="save_form"
          ></MusicUploader>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <button
          v-on:click.prevent="submit_track"
          class="btn btn-success btn-sm mr-2"
        >
          Validate and Save
        </button>

        <button
          v-if="is_incomplete"
          v-on:click.prevent="save_form(true)"
          class="btn btn-warning btn-sm ms-1"
        >
          Save for Later
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { db, fb } from "@/firebaseConfig.js";
import { state } from "@/store";
import { orderBy, unionBy } from "lodash";
import algoliasearch from "algoliasearch";
import BSPopover from "@/components/BSPopover";
import ArtistCreateNew from "@/components/ArtistCreateNew";
import MusicUploader from "@/components/ReleaseTrackMusicUploader";
import Countries from "../assets/Countries.json";
import Languages from "../assets/LanguagesFuga.json";
import TrackPriceTiers from "../assets/TrackPriceTiers.json";
import { InfoIcon, ExternalLinkIcon } from "vue-feather-icons";
const tracks = db.collection("release_submissions_tracks");
const genres = db.collection("genres");
const subgenres = db.collection("subgenres");
const orgs = db.collection("orgs");

export default {
  name: "release_track_form",
  props: {
    track_id: String,
  },
  data() {
    return {
      new_track: true,
      algolia_test: null,
      track: {},
      existing_track: {},
      countries: Countries,
      languages: Languages,
      owner_org: {},
      price_tiers: TrackPriceTiers,
      last_index: {},
      visible_tracks: [],
      visible_genres: [{ name: "", label: "" }],
      visible_subgenres: [{ name: "", label: "" }],

      searchClient: algoliasearch(
        "LC1Z0QAV0G",
        "2a00525f080a41df0186dbb1115f4a36"
      ),
      artists_search: "",
      people_search: "",

      artists_index: null,
      artists_hits: {},

      people_index: null,
      people_hits: {},

      new_artists_records: [],
      new_people_records: [],

      debounce: null,

      required: [
        "track_title",
        "track_number",
        "track_volume_number",
        "track_main_artists",
        "track_lyricists",
        "track_genre",
        "track_type",
        "track_lyric_language",
        "track_title_language",
        "track_parental_advisory",
      ],
      is_incomplete: false,
      show_new_artist: false,
      new_record: {},
    };
  },
  components: {
    ArtistCreateNew,
    MusicUploader,
    BSPopover,
    InfoIcon,
    ExternalLinkIcon,
  },
  firestore() {
    return {
      last_index: db.collection("isrc_codes").doc("last_index"),
    };
  },

  created: async function () {
    this.$bind("visible_genres", genres);
    this.$bind("visible_subgenres", subgenres);
    this.artists_index = this.searchClient.initIndex("artists");
    this.people_index = this.searchClient.initIndex("people");
  },

  methods: {
    set_existing_track: function (value) {
      let main_artists = value.track_main_artists.map((a) => a.name).join("; ");

      let label_string = `${value.track_isrc} | ${value.track_title} | ${main_artists}`;

      if (label_string.length > 80) {
        label_string = `${label_string.substring(0, 80)}...`;
      }

      let existing_track = { ...this.track };

      console.log("EXISTING TRACK: " + existing_track);
      existing_track.label = label_string;
      existing_track.track_title = value.track_title || "";
      existing_track.track_isrc = value.track_isrc || "";
      existing_track.track_fuga_id = value.track_fuga_id || "";
      existing_track.track_audio_url = value.track_audio_url || "";
      existing_track.track_metadata = value.track_metadata || {};
      existing_track.track_main_artists = value.track_main_artists;

      this.existing_track = existing_track;
    },

    add_record: async function (value) {
      console.log("VALUE: " + JSON.stringify(value));

      if (!value.objectID) {
        const new_record = {
          name: value.name,
          fuga_id: "",
          apple_id: "",
          spotify_id: "",
        };

        if (value.record_type === "track_main_artists") {
          let org = { ...this.owner_org };
          org.id = this.track.track_owner_org_id;
          new_record.orgs = [org];
          new_record.org_ids = [this.track.track_owner_org_id];
          let res = await db.collection("artists").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_main_artists, value.index, new_record);
          this.new_artists_records.push(new_record);
        } else if (value.record_type === "track_featured_artists") {
          let org = { ...this.owner_org };
          org.id = this.track.track_owner_org_id;
          new_record.orgs = [org];
          new_record.org_ids = [this.track.track_owner_org_id];
          let res = await db.collection("artists").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_featured_artists, value.index, new_record);
          this.new_artists_records.push(new_record);
        } else if (value.record_type === "track_remix_artists") {
          let res = await db.collection("people").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_remix_artists, value.index, new_record);
          this.new_artists_records.push(new_record);
        } else if (value.record_type === "track_composers") {
          let res = await db.collection("people").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_composers, value.index, new_record);
          this.new_people_records.push(new_record);
        } else if (value.record_type === "track_lyricists") {
          let res = await db.collection("people").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_lyricists, value.index, new_record);
          this.new_people_records.push(new_record);
        } else if (value.record_type === "track_producers") {
          let res = await db.collection("people").add(new_record);

          new_record.id = res.id;
          new_record.objectID = res.id;
          this.$set(this.track.track_producers, value.index, new_record);
          this.new_people_records.push(new_record);
        }
        document.activeElement.nextElementSibling.focus();
      }
    },

    debounce_artists_search(search) {
      // console.log(search);
      let delay = 200;
      clearTimeout(this.debounce);
      if (search.length > 4) delay = 0;
      // console.log("delay: " + delay);
      this.debounce = setTimeout(() => {
        this.artists_search = search;
      }, delay);
    },

    debounce_people_search(search) {
      // console.log(search);
      let delay = 200;
      clearTimeout(this.debounce);
      if (search.length > 4) delay = 0;
      // console.log("delay: " + delay);
      this.debounce = setTimeout(() => {
        this.people_search = search;
      }, delay);
    },

    refresh_index_artists() {
      this.artists_search = "";
      this.artists_index = this.searchClient.initIndex("artists");
    },

    refresh_index_people() {
      this.people_search = "";
      this.people_index = this.searchClient.initIndex("people");
    },

    add_line: function (items, i) {
      items.splice(i + 1, 0, { name: "" });
    },
    delete_line(items, i) {
      if (items.length === 1) {
        this.$set(items, i, { name: "" });
      } else {
        items.splice(i, 1);
      }
    },
    submit_track: function () {
      console.log("SUBMITTING TRACK");
      // console.log("IS_COMPLETE: " + this.is_complete());
      if (!this.is_complete()) {
        this.is_incomplete = true;
        this.$toast.warning(
          "You are missing some required information.  Please complete the highlighted fields or, save this form and come back to it later.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.save_form(true);
      }
    },

    save_form: async function (close) {
      this.is_incomplete = false;
      console.log("UPDATING TRACK");
      this.update_track();
      // console.log("CLOSE? " + close);

      this.$toast.success("Saved.", {
        position: "top-right",
        timeout: 5000,
      });

      if (close) {
        this.$router.push({
          name: "ReleaseTracksList",
          params: { release_id: this.track.release_id },
        });
      }
    },

    update_track: async function () {
      let track;

      if (this.existing_track?.is_existing_track) {
        track = this.existing_track;
      } else {
        track = this.track;
      }

      const track_main_artists = track.track_main_artists.map((main_artist) => {
        return {
          name: main_artist.name,
          id: main_artist.objectID || main_artist.id || "",
          fuga_id: main_artist.fuga_id || "",
          apple_id: main_artist.apple_id || "",
          spotify_id: main_artist.spotify_id || "",
          primary: true,
        };
      });

      const track_featured_artists = track.track_featured_artists.map(
        (featured_artist) => {
          return {
            name: featured_artist.name,
            id: featured_artist.objectID || featured_artist.id || "",
            fuga_id: featured_artist.fuga_id || "",
            apple_id: featured_artist.apple_id || "",
            spotify_id: featured_artist.spotify_id || "",
            primary: false,
          };
        }
      );

      const track_composers = track.track_composers.map((composer) => {
        return {
          name: composer.name,
          id: composer.objectID || composer.id || "",
          fuga_id: composer.fuga_id || "",
          apple_id: composer.apple_id || "",
          spotify_id: composer.spotify_id || "",
          role: "COMPOSER",
        };
      });

      const track_remix_artists = track.track_remix_artists.map(
        (remix_artist) => {
          return {
            name: remix_artist.name,
            id: remix_artist.objectID || remix_artist.id || "",
            fuga_id: remix_artist.fuga_id || "",
            apple_id: remix_artist.apple_id || "",
            spotify_id: remix_artist.spotify_id || "",
            role: "REMIXER",
          };
        }
      );

      const track_lyricists = track.track_lyricists.map((lyricist) => {
        return {
          name: lyricist.name,
          id: lyricist.objectID || lyricist.id || "",
          fuga_id: lyricist.fuga_id || "",
          apple_id: lyricist.apple_id || "",
          spotify_id: lyricist.spotify_id || "",
          role: "LYRICIST",
        };
      });

      const track_producers = track.track_producers.map((producer) => {
        return {
          name: producer.name,
          id: producer.objectID || producer.id || "",
          fuga_id: producer.fuga_id || "",
          apple_id: producer.apple_id || "",
          spotify_id: producer.spotify_id || "",
          role: "PRODUCER",
        };
      });

      track.track_main_artists = track_main_artists;
      track.track_featured_artists = track_featured_artists;
      track.track_composers = track_composers;
      track.track_remix_artists = track_remix_artists;
      track.track_lyricists = track_lyricists;
      track.track_producers = track_producers;

      const track_update = {
        ...track,
        is_new: false,
        is_incomplete: !this.is_complete(),
      };

      console.log(JSON.stringify(track_update));

      await db
        .collection("release_submissions_tracks")
        .doc(this.track_id)
        .update({ ...track_update });
    },

    is_complete: function () {
      console.log("CHECKING FORM IS COMPLETE");
      const track = this.track;
      const entries = Object.entries(track);

      console.log(entries);

      let required = this.required;

      // console.log()

      if (track.track_lyric_language?.toUpperCase() === "ZXX") {
        required = required.filter((r) => !"track_lyricists");
      }

      const missing_fields = entries
        // eslint-disable-next-line no-unused-vars

        .filter(([k, v]) => {
          return (
            v === undefined ||
            v === null ||
            v === "" ||
            v[0]?.name === "" ||
            v?.name === ""
          );
        })
        // eslint-disable-next-line no-unused-vars
        .map(([k, v]) => k);

      console.log("missing_fields: " + missing_fields);

      const is_complete =
        track.is_existing_track ||
        !missing_fields.some((missing_field) => {
          return required.includes(missing_field);
        });
      console.log("IS COMPLETE?: " + is_complete);

      return is_complete;
    },
    is_fullname(name) {
      const reg_name = /^(\w.+\s).+/;
      return reg_name.test(name);
    },
  },

  watch: {
    track_id: {
      immediate: true,
      handler: async function (track_id) {
        console.log("track loaded");
        this.$bind("track", tracks.doc(track_id));
        this.existing_track = this.track;
      },
    },

    track: {
      immediate: true,
      handler: async function (track) {
        console.log("track loaded");
        this.existing_track = this.track;
      },
    },

    artists_search: {
      handler: async function (search) {
        this.artists_hits = await this.artists_index.search(search);
      },
    },

    people_search: {
      handler: async function (search) {
        this.people_hits = await this.people_index.search(search);
      },
    },
    "track.track_owner_org_id"(selected_org) {
      if (selected_org) {
        this.$bind(
          "visible_tracks",
          tracks.where("track_owner_org_id", "==", selected_org)
        );
        this.$bind("owner_org", orgs.doc(selected_org));
      }
    },
  },

  computed: {
    algolia_artists_options() {
      const hits = this.artists_hits?.hits;
      const new_artists_records = this.new_artists_records.map((artist) => {
        artist._highlightResult = {
          name: { value: `${artist.name}` },
        };
        return artist;
      });

      const all_records = unionBy(hits, new_artists_records, "objectID");

      return all_records?.map((artist) => {
        artist.id = artist.objectID;
        return artist;
      });
    },

    algolia_people_options() {
      const hits = this.people_hits?.hits;
      const new_people_records = this.new_people_records.map((person) => {
        person._highlightResult = {
          name: { value: `${person.name}` },
        };
        return person;
      });

      const all_records = unionBy(hits, new_people_records, "objectID");

      return all_records?.map((person) => {
        person.id = person.objectID;
        return person;
      });
    },

    years() {
      const year_start = new Date().getFullYear();
      const years_array = [];
      let i;

      for (i = 1960; i < year_start + 1; i++) {
        years_array.push(i);
      }

      return years_array.reverse();
    },

    //============

    display_tracks: function () {
      let tracks = [...this.visible_tracks];

      tracks = tracks
        .filter((t) => t.track_isrc)
        .map((t) => {
          let main_artists = t.track_main_artists.map((a) => a.name).join("; ");

          let label_string = `${t.track_isrc} | ${t.track_title} | ${main_artists} | ${t.fuga_id}`;

          if (label_string.length > 80) {
            label_string = `${label_string.substring(0, 80)}...`;
          }

          let display_track = {
            ...t,
            label: label_string,
          };

          return display_track;
        });
      tracks = orderBy(tracks, "name", "asc");
      return tracks;
    },

    display_genres: function () {
      let genres = this.visible_genres.slice();

      genres = genres.map((genre) => {
        let display_genre = {
          id: genre.id,
          label: genre.name,
          name: genre.name,
          fuga_id: genre.fuga_id,
        };

        return display_genre;
      });
      genres = orderBy(genres, "name", "asc");
      return genres;
    },

    display_subgenres: function () {
      let subgenres = this.visible_subgenres.slice();

      subgenres = subgenres.map((genre) => {
        let display_genre = {
          id: genre.id,
          label: genre.name,
          name: genre.name,
          fuga_id: genre.fuga_id,
        };

        return display_genre;
      });
      subgenres = orderBy(subgenres, "name", "asc");
      return subgenres;
    },
  },
};
</script>

<style>
li span em {
  font-style: normal;
  font-weight: bold;
}
</style>
