<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import { Popover } from "bootstrap";

export default {
  props: {
        content: {
            required: false,
            default: '',
        },
       
        trigger: {
            default: 'click',
        },
        delay: {
            default: 0,
        },
        html: {
            default: false,
        },
    },
    mounted() {
        // pass bootstrap popover options from props
        var options = this.$props
        var ele = this.$slots.default[0].elm
        new Popover(ele,options)
    },
};
</script>

<style>
</style>




