<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h2 v-if="user.name">{{ user.name }}</h2>
          <h2 v-else>New User</h2>
        </div>
      </div>
      <hr />
      <div class="row m-3">
        <div class="col-xl-6">
          <form
            class="needs-validation"
            novalidate>
            <!-- <button @click.prevent="generate_upc">generate_upc</button> -->
            <div class="mb-3">
              <label for="release_title">Name</label>

              <input
                v-model="user.name"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !user.name,
                  'is-valid': user.name,
                }" />
              <div class="invalid-feedback">Please enter a valid name.</div>
            </div>

            <div class="form-group mb-3">
              <label for="email">Email</label>

              <input
                v-model="user.email"
                type="email"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !user.email,
                  'is-valid': user.email,
                }"
                required />
              <div class="invalid-feedback">Please enter a valid email address.</div>
            </div>

            <div class="form-group mb-3">
              <label for="mobile">Mobile</label>

              <input
                v-model="user.mobile"
                type="mobile"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !user.mobile,
                  'is-valid': user.mobile,
                }"
                required />
              <div class="invalid-feedback">Please enter a valid mobile address.</div>
            </div>

            <!-- <div class="form-group mb-3">
              <label for="release_main_artist">Password</label>

              <input
                v-model="user.password"
                type="password"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !user.password,
                  'is-valid': user.password,
                }"
                required />
              <div class="invalid-feedback">Please enter a valid passwords.</div>
            </div> -->

            <div class="form-group mb-3">
              <label> Role </label>

              <select
                v-model="user.role"
                class="form-select d-block w-100 m-1"
                :class="{
                  'is-invalid': submitted && !user.role,
                  'is-valid': user.role,
                }">
                <option
                  v-for="role in roles"
                  :key="role">
                  {{ role }}
                </option>
              </select>
              <div class="invalid-feedback">Please select a valid role.</div>
            </div>

            <div class="mb-3">
              <label for="user_org">Org</label>

              <v-select
                v-model="user.orgs[0]"
                :options="display_orgs"
                :selectOnTab="true"
                :clearable="true"></v-select>

              <div class="invalid-feedback">Please enter org</div>
            </div>

            <!-- <div class="form-group mb-3">
              <label> Org </label>

              <select
                v-model="user.orgs"
                class="form-select d-block w-100 m-1"
                :class="{
                  'is-invalid': submitted && !user.org,
                  'is-valid': user.org,
                }">
                <option
                  v-for="org in orgs"
                  :key="org.id"
                  :value="[{ ...org, id: org.id }]">
                  {{ org.name }}
                </option>
              </select>
              <div class="invalid-feedback">Please select a valid org.</div>
            </div> -->

            <button
              @click.prevent="create_user"
              type="submit"
              class="btn btn-outline-success">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<style>
</style>

<script>
import basicLayout from "@/components/basicLayout";
import { orderBy } from "lodash";
import { fb, db } from "@/firebaseConfig";

export default {
  name: "createUser",
  data: function () {
    return {
      user: { name: "", email: "", orgs: [], role: "user" },
      orgs: [],
      roles: ["user", "agent", "admin"],
      submitted: false,
    };
  },
  firestore() {
    return {
      orgs: db.collection("orgs").orderBy("name"),
    };
  },
  components: {
    basicLayout,
  },
  methods: {
    create_user: async function () {
      console.log(this.user);
      const user = this.user;
      this.submitted = true;
      const createUser = fb.httpsCallable("createUser");
      if (!user.name || !user.email || !user.orgs || !user.role) {
        this.$toast.warning("You are missing some required information.  Please complete the highlighted fields.", {
          position: "top-right",
          timeout: 5000,
        });
        return;
      }

      let res = await createUser(user);

      if (res.data.error) {
        this.$toast.error(res.data.error.errorInfo.message, {
          position: "top-right",
          timeout: 5000,
        });
      } else {
        this.$router.push("/users?filter=" + user.role);
      }
    },
  },
  computed: {
    display_orgs: function () {
      let orgs = this.orgs.slice();

      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },
  },
};
</script>
