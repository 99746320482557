<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>FUGA Export</h2>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="form-group mb-3">
                    <label for="type">Record Type</label>

                    <select
                      v-model="record_type"
                      class="form-select d-block w-100 m-1"
                      id="country"
                    >
                      <option value="org">Orgs</option>
                      <option value="artist">Artists</option>
                      <option value="artist_identifiers">
                        Artist Identifiers
                      </option>
                      <option value="user">Users</option>
                      <option value="people">People</option>

                      <option value="tracks">Tracks</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a record type.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <input
                v-model="fuga_download_table"
                class="form-control"
                type="string"
                name=""
              />
            </div>

            <div class="col">
              <button
                @click="fuga_table_download"
                class="btn btn-sm btn-outline-success"
              >
                Start
              </button>
            </div>
            <div class="col">
              <button
                @click="get_storage_files"
                class="btn btn-sm btn-outline-success"
              >
                Get Files
              </button>
            </div>
          </div>

          <div class="row">{{ files }}</div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <table class="table table-hover">
                    <thead class="table-dark">
                      <tr>
                        <th class="w-5"></th>
                        <th>Name</th>

                        <th class="text-center w-10">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="file in files"
                        :key="file.name"
                        @click="select_file(file)"
                      >
                        <td>
                          <file-text-icon size="1.5x"></file-text-icon>
                        </td>
                        <td>
                          {{ file }}
                          {{ file.name }}
                        </td>

                        <td
                          v-show="target_file == file.name"
                          class="text-center"
                        >
                          <div
                            class="spinner-border spinner-border-sm text-secondary"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </td>
                        <td
                          v-show="target_file != file.name"
                          class="text-center"
                        >
                          <span
                            @click.prevent="get_blob(file)"
                            role="button"
                            title="download this file"
                            ><download-icon
                              size="1.5x"
                              class="text-secondary"
                            ></download-icon>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, storage } from "@/firebaseConfig";
import { getters, actions } from "@/store";
// import axios from "axios";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";

import {
  EditIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserXIcon,
  MailIcon,
  UserCheckIcon,
  StarIcon,
  DownloadIcon,
  FileTextIcon,
} from "vue-feather-icons";

//   const artists = db.collection("artists");

export default {
  name: "FugaExport",
  data() {
    return {
      files: [],
      target_file: "",
      fuga_download_table: "assets",
    };
  },
  components: {
    basicLayout,

    EditIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    UserXIcon,
    MailIcon,
    UserCheckIcon,
    StarIcon,
    DownloadIcon,
    FileTextIcon,
  },

  methods: {
    get_storage_files: async function () {
      console.log("fetching files");
      const storageRef = storage.ref().child("fuga_downloads");
      const list = await storageRef.listAll();
      this.files = list.items;
    },

    fuga_table_download: async function () {
      const fuga_table_download = fb.httpsCallable("fuga_table_download");

      let download_options = {
        end_point: this.fuga_download_table,
        page_size: 500,
      };

      console.log(download_options);

      const fuga_table_res = await fuga_table_download(download_options);
      console.log(fuga_table_res.data);

      //   let end_point = "assets";

      //   const storageRef = storage.ref();
      //   const mtlRef = storageRef.child(`fuga_downloads/fuga_${end_point}.json`);
      //   const download_url = await mtlRef.getDownloadURL();

      //   let res = await fetch(download_url);
      //   let blob = await res.blob();

      //   const url = URL.createObjectURL(blob);
      //   const anchor = document.createElement("a");
      //   anchor.href = url;
      //   //   anchor.download = "mtl_download.csv";
      //   anchor.click();

      //   const status = res.data.success && res.data.records;

      //   console.log(status);

      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    select_file(file) {
      this.selected_file = file;
    },

    get_blob: async function (file) {
      this.target_file = file.name;
      console.log(file.name);
      let download_url = await file.getDownloadURL();
      let res = await fetch(download_url);
      let blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = file.name;
      anchor.click();
      this.target_file = "";
    },
  },
};
</script>

<style scoped>
@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
