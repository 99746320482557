import moment from 'moment'

export default {
  filters: {
    currency: function (number) {
      return (
        number &&
        number.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' })
      )
    },
    double: function (number) {
      return number && number.toFixed(2)
    },
    number: function (number) {
      return number && number.toLocaleString('en-NZ')
    },
    date_standard: function (date) {
      return date && moment
        .utc(date, 'YYYYMMDD')
        .local()
        .format('DD/MM/YYYY')
        || ""
    },
    date_short: function (date) {
      return date && moment
        .utc(date)
        .local()
        .format('ll')
        || ""
    },
    moment_short: function (date) {
      return date && moment
        .utc(date.toDate())
        .local()
        .format('lll')
        || ""
    },
    moment_long: function (date) {
      return date && moment
        .utc(date.toDate())
        .local()
        .format('LLLL')
        || ""
    }
  }
}
