var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basicLayout',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row sticky-top bg-light m-1"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"text-uppercase"},[(!_vm.release.release_thumbnail_url)?_c('span',{attrs:{"title":"cover art missing for this release"}},[_c('image-icon',{staticClass:"custom-class",attrs:{"size":"1.5x"}})],1):_c('img',{staticClass:"pr-2",attrs:{"src":_vm.release.release_thumbnail_url,"alt":_vm.release.release_title,"width":"40px","loading":"lazy","title":"cover art"}}),_vm._v(" "+_vm._s(_vm.release.release_title)+" - "+_vm._s(_vm.release.release_main_artist && _vm.release.release_main_artist.name)+" ")]),_c('button',{staticClass:"btn btn-sm btn-outline-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('arrow-left-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.0x"}}),_vm._v(" Back ")],1)])]),_c('hr'),(Object.keys(_vm.release).length)?_c('div',[(_vm.release)?_c('div',{staticClass:"row m-1 mt-3"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('h5',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.release.release_title)+" - "+_vm._s(_vm.release.release_main_artist && _vm.release.release_main_artist.name)+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',[_vm._v("release_title")]),_c('th',[_vm._v("release_main_artist")]),_c('th',[_vm._v("release_label")]),_c('th',[_vm._v("release_genre")]),_c('th',[_vm._v("release_parental_advisory")]),_c('th',[_vm._v("release_release_date")]),_c('th',[_vm._v("release_original_release_date")]),_c('th',[_vm._v("release_production_year")]),_c('th',[_vm._v("release_upc")])])]),_c('tbody',[_c('tr',[_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_title'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_title)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_main_artist'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_main_artist && _vm.release.release_main_artist.name)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_label'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_label)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_genre'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_genre.genre)+" | "+_vm._s(_vm.release.release_genre.subgenre)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_parental_advisory'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_parental_advisory)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_release_date'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_release_date)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_original_release_date'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_original_release_date)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_production_year'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_production_year)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && _vm.missing_fields_release.includes('release_upc'),
                            }},[_vm._v(" "+_vm._s(_vm.release.release_upc)+" ")])])])])])])])]),_c('div',{staticClass:"card-footer"},[(!_vm.release.is_submitted_to_drm)?_c('router-link',{staticClass:"btn btn-sm text-nowrap routerlink",class:_vm.release.is_incomplete ? 'btn-outline-secondary' : 'btn-outline-success',attrs:{"to":{
                  name: 'ReleaseEdit',
                  params: { release_id: _vm.release.id },
                }}},[_c('edit-icon',{attrs:{"size":"1.0x"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-2"},[_vm._v("Edit Release")])],1):_vm._e()],1)])])]):_vm._e(),_c('div',{staticClass:"row m-1 mt-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('h5',{staticClass:"card-header"},[_vm._v("Tracks")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("audio_file")]),_c('th',[_vm._v("isrc")]),_c('th',[_vm._v("title")]),_c('th',[_vm._v("subtitle")]),_c('th',[_vm._v("version_title")]),_c('th',[_vm._v("title_language")]),_c('th',[_vm._v("main_artists")]),_c('th',[_vm._v("featured_artists")]),_c('th',[_vm._v("composers")]),_c('th',[_vm._v("lyricists")]),_c('th',[_vm._v("genre")]),_c('th',[_vm._v("type")]),_c('th',[_vm._v("lyric_language")]),_c('th',[_vm._v("parental_advisory")]),_c('th',[_vm._v("price_tier")])])]),_c('tbody',_vm._l((_vm.tracks),function(track){return _c('tr',{key:track.track_number},[_c('td',[_vm._v(_vm._s(track.track_number))]),(track.track_audio_url)?_c('td',{staticClass:"text-center"},[_c('play-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.2x"}})],1):_c('td',{staticClass:"text-center"},[_c('slash-icon',{staticClass:"custom-class",attrs:{"size":"1.2x"}})],1),_c('td',{class:{
                              'bg-danger bg-opacity-25': _vm.is_incomplete && !track.track_isrc,
                            }},[_vm._v(" "+_vm._s(track.track_isrc)+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25': _vm.is_incomplete && !track.track_title,
                            }},[_vm._v(" "+_vm._s(track.track_title)+" ")]),_c('td',[_vm._v(_vm._s(track.track_subtitle))]),_c('td',[_vm._v(_vm._s(track.track_version_title))]),_c('td',[_vm._v(_vm._s(track.track_title_language))]),_c('td',{class:{
                              'bg-danger bg-opacity-25':
                                _vm.is_incomplete && !track.track_main_artists && !track.track_main_artists[0].name,
                            }},[_vm._v(" "+_vm._s(track.track_main_artists.map((artist) => artist.name).join(", "))+" ")]),_c('td',[_vm._v(" "+_vm._s(track.track_featured_artists.map((artist) => artist.name).join(", "))+" ")]),_c('td',[_vm._v(" "+_vm._s(track.track_composers.map((composer) => composer.name).join(", "))+" ")]),_c('td',[_vm._v(" "+_vm._s(track.track_lyricists.map((lyricist) => lyricist.name).join(", "))+" ")]),_c('td',{class:{
                              'bg-danger bg-opacity-25': _vm.is_incomplete && !track.track_genre,
                            }},[_vm._v(" "+_vm._s(track.track_genre.genre)+" | "+_vm._s(track.track_genre.subgenre)+" ")]),_c('td',[_vm._v(_vm._s(track.track_type))]),_c('td',[_vm._v(_vm._s(track.track_lyric_language))]),_c('td',{class:{
                              'bg-danger bg-opacity-25': _vm.is_incomplete && !track.track_parental_advisory,
                            }},[_vm._v(" "+_vm._s(track.track_parental_advisory)+" ")]),_c('td',[_vm._v(_vm._s(track.track_price_tier))])])}),0)])])])])]),_c('div',{staticClass:"card-footer"},[(!_vm.release.is_submitted_to_drm)?_c('router-link',{staticClass:"btn btn-sm btn-outline-success",attrs:{"to":{
                  name: 'ReleaseTracksList',
                  params: { release_id: _vm.release.id },
                }}},[_c('edit-icon',{attrs:{"size":"1.0x"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-2"},[_vm._v("Edit Tracks")])],1):_vm._e()],1)])])]),_c('div')]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }