<template>
  <div
    class="modal fade"
    ref="countries_modal"
    tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            @click="modal.hide()"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div
            class="card"
            id="territory-card">
            <div class="card-header p-2">
              <div class="row">
                <div class="col"><h5>Distribution Territories</h5></div>
                <div class="col text-end">
                  <h5>
                    Selected: {{ release_territories.length }} of
                    {{ visible_territories.length }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div
                  v-for="region in regions"
                  :key="region"
                  class="col-xl-2 col-lg-3 col-md-4">
                  <h5>{{ region }}</h5>
                  <ul class="list-group">
                    <li class="list-group-item text-secondary text-center fw-bold">
                      <input
                        v-model="select_all_regions"
                        @click="select_all(region)"
                        class="form-check-input me-1"
                        type="checkbox"
                        :value="region"
                        aria-label="..." />
                      Select All
                    </li>
                    <li
                      v-for="territory in region_territories(region)"
                      :key="territory.territory"
                      :value="territory"
                      class="list-group-item">
                      <input
                        v-model="release_territories"
                        class="form-check-input me-1"
                        type="checkbox"
                        :value="territory"
                        aria-label="..." />
                      <span
                        class="ms-2 me-1"
                        :class="`fi fi-${territory.code}`"></span>
                      {{ territory.country }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-white modal-footer--sticky">
          <button
            type="button"
            class="btn btn-secondary"
            @click="modal.hide()">
            Cancel
          </button>
          <button
            @click="save_territories"
            type="button"
            class="btn btn-outline-success">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CountriesRegions from "../assets/CountriesRegions.json";
import { Modal } from "bootstrap";
import { orderBy } from "lodash";

export default {
  props: {
    release: Object,
  },

  data() {
    return {
      territories: CountriesRegions,
      release_territories: [],
      select_all_regions: [],
      modal: null,
    };
  },

  mounted() {
    // console.log("MOUNTED");
    // console.log(this.release);
    this.modal = new Modal(this.$refs.countries_modal);
    this.modal.hide();
  },

  methods: {
    open_modal() {
      this.get_release_territories();
      this.modal.show();
    },

    get_release_territories() {
      // console.log(this.release.release_territories_included);
      if (this.release.release_territories_included === []) {
        this.select_all_regions = [];
        this.release_territories = [];
        return;
      } else if (
        this.release.release_territories_included.length == 1 &&
        this.release.release_territories_included[0] == "Worldwide"
      ) {
        // console.log("WORLDWIDE!");
        this.release_territories = [...this.visible_territories];
        this.select_all_regions = [...this.regions];
        return;
      } else if (
        this.release.release_territories_included.length &&
        !this.release.release_territories_excluded.length
      ) {
        // console.log("FROM INCLUDED");
        this.release_territories = this.get_territories_from_included(this.release.release_territories_included);
      } else if (
        this.release.release_territories_excluded.length &&
        !this.release.release_territories_included.length
      ) {
        // console.log("FROM EXCLUDED");
        this.release_territories = this.get_territories_from_excluded(this.release.release_territories_excluded);
      }
    },

    region_territories(region) {
      return this.visible_territories.filter((c) => c.region === region);
    },
    select_all(region) {
      // reset selected territories
      this.release_territories = this.release_territories.filter((c) => c.region != region);
      // add back 'select all' region
      if (!this.select_all_regions.includes(region)) {
        let select_all_territories = this.visible_territories.filter((c) => c.region == region);

        this.release_territories = [...this.release_territories, ...select_all_territories];
      }
    },

    get_territories_from_included(codes) {
      return this.territories.filter((t) => codes.includes(t.code));
    },

    get_territories_from_excluded(codes) {
      return this.territories.filter((t) => !codes.includes(t.code));
    },

    save_territories() {
      let territories_update;

      // console.log("INCLUDED: " + this.included.length);
      // console.log("EXCLUDED: " + this.excluded.length);

      // NO TERRITORIES SELECTED
      if (this.release_territories.length < 1) {
        this.$toast.warning("You must select at least 1 territory.", {
          position: "top-right",
          timeout: 5000,
        });

        return;

        // ALL TERRITORIES SELECTED = 'WORLDWIDE'
      } else if (this.release_territories.length === this.visible_territories.length) {
        territories_update = {
          release_territories_included: ["Worldwide"],
          release_territories_excluded: [],
        };
      } else {
        territories_update = {
          release_territories_included: this.included.length < this.excluded.length ? this.included : [],
          release_territories_excluded: this.excluded.length <= this.included.length ? this.excluded : [],
        };
      }

      this.$emit("update_territories", territories_update);
      this.modal.hide();
    },
  },
  computed: {
    visible_territories() {
      let visible_territories = this.territories;
      visible_territories = orderBy(visible_territories, ["region", "territory"], ["asc", "asc"]);
      return visible_territories;
    },
    regions() {
      let regions_array = this.visible_territories.map((c) => c.region);
      let regions_set = new Set(regions_array);
      return [...regions_set];
    },
    included() {
      return this.release_territories.map((t) => t.code);
    },
    excluded() {
      let included = this.included;
      let all_territories_codes = this.territories.map((c) => c.code);
      let excluded_territories_codes = all_territories_codes.filter((c) => !included.includes(c));
      return excluded_territories_codes;
    },
  },

  watch: {
    excluded(excluded_codes) {
      // console.log(excluded_codes);

      let regions_array = this.visible_territories.filter((t) => this.excluded.includes(t.code)).map((t) => t.region);

      let regions_set = new Set(regions_array);

      this.select_all_regions = this.regions.filter((r) => !regions_set.has(r));
      // console.log(this.select_all_regions);
    },
  },
};
</script>
  
  <style scoped>
#territory-card {
  font-size: 0.8em !important;
}

#territory-card h5 {
  font-size: 1.2em !important;
}
</style>