 <template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <div class="text-center">
            <img
              class="drm-logo"
              src="../assets/logo.png"
              width="auto"
              height="150"
              alt />
            <h4 class="display-6 fw-bold m-3">DIGITAL RIGHTS MANAGEMENT</h4>

            <div class="col-xl-6 col-12 mx-auto">
              <div class="card p-3">
                <div class="card-body">
                  <h4 class="text-bold mb-3">WELCOME TO DRM!</h4>
                  <h5>Ready to Release?</h5>

                  <p>Ready to create a new release and submit it to our team?</p>
                  <p>
                    Click the "My Drafts" button on the menu bar, click "New Release" and follow the steps.<br />If you
                    need any help, please call us on 09 847 9253 or email us at
                    <a href="mailto:info@drm.co.nz">info@drm.co.nz</a>
                  </p>
                  <hr />

                  <h5>Royalty FAQs</h5>

                  <p>
                    It will typically take about 4 or 5 months to to receive your first royalty reports from the date of
                    release.<br />From then on, you will receive new reports every two months.
                  </p>
                  <p>
                    These reports will include a statement and a detailed spreadsheet and can be found in "My
                    Royalties".<br />If you need any support with your royalty statements, please contact our accounts
                    team at <a href="mailto:accounts@drm.co.nz">accounts@drm.co.nz</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";

// import { db } from "@/firebaseConfig";
// import { state } from "@/store";

// const orgs = db.collection("orgs");

export default {
  name: "dashboard",

  data() {
    return {
      display_orgs: [],
    };
  },
  components: { basicLayout },

  mounted() {
    console.log("mounted");
    document.body.classList.add("bg-c");
  },

  destroyed() {
    document.body.classList.remove("bg-c");
  },
};
</script>

<style scoped>
.drm-logo {
  max-width: 50%;
  height: auto;
}
</style>
