import { render, staticRenderFns } from "./ReleaseEdit.vue?vue&type=template&id=6d4fecdf&scoped=true&"
import script from "./ReleaseEdit.vue?vue&type=script&lang=js&"
export * from "./ReleaseEdit.vue?vue&type=script&lang=js&"
import style0 from "./ReleaseEdit.vue?vue&type=style&index=0&id=6d4fecdf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4fecdf",
  null
  
)

export default component.exports