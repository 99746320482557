 <template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col"><h6>Uploaded Contracts</h6></div>
        <div class="col text-end"></div>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="w-5"></th>
            <th>Name</th>

            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="file in filtered"
            :key="file.file_name"
            @click="select_file(file)">
            <td>
              <file-text-icon size="1.5x"></file-text-icon>
            </td>
            <td title="file">
              {{ file.file_name }}
            </td>
            <td
              v-show="target_file == file.name"
              class="text-center">
              <div
                class="spinner-border spinner-border-sm text-secondary"
                role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
            <td
              v-show="target_file != file.name"
              class="text-center">
              <span
                @click.prevent="get_blob(file)"
                role="button"
                title="download this file"
                ><download-icon
                  size="1.0x"
                  class="text-secondary"></download-icon>
              </span>
            </td>
            <td
              v-show="target_file != file.name"
              class="text-center">
              <span
                @click.prevent="delete_doc(file)"
                role="button"
                title="delete this file"
                ><trash-icon
                  size="1.0x"
                  class="text-danger"></trash-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import { db, storage } from "@/firebaseConfig";
// import { getters } from "@/store";
import { FolderIcon, FileTextIcon, DownloadIcon, TrashIcon, LoaderIcon, RefreshCwIcon } from "vue-feather-icons";

export default {
  props: {
    artist: Object,
  },
  data() {
    return {
      files: [],
      search: "",
      selected_file: {},
      target_file: "",
    };
  },
  components: { ConfirmDialog, FolderIcon, FileTextIcon, DownloadIcon, TrashIcon, LoaderIcon, RefreshCwIcon },

  methods: {
    select_file(file) {
      this.selected_file = file;
    },
    clear_search() {
      this.search = "";
    },
    get_blob: async function (file) {
      this.target_file = file.name;
      console.log(file.name);

      const storageRef = storage.ref().child(file.file_path);

      let download_url = await storageRef.getDownloadURL();
      let res = await fetch(download_url);
      let blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = file.file_name;
      anchor.click();
      this.target_file = "";
    },
    delete_doc: async function (file) {
      let confirm_delete = await this.$refs.confirmDialog.show({
        confirm_title: "Delete Contract",
        confirm_message: "Are you sure you want to delete this contract? This action cannot be undone.",
        confirm_ok_button: "Delete",
      });

      if (!confirm_delete) return;

      console.log(file.file_path);

      const storageRef = storage.ref().child(file.file_path);
      await storageRef.delete();
      const new_contracts_array = this.artist.contracts.filter((contract) => contract.file_name != file.file_name);

      console.log(this.artist.id);
      console.log(new_contracts_array);

      const contracts_update = {
        contracts: new_contracts_array,
        is_contract_signed: new_contracts_array.length,
      };

      return db.collection("artists").doc(this.artist.id).update(contracts_update);
    },
  },
  computed: {
    filtered: function () {
      let visible_docs = this.artist.contracts;

      // const search = this.search;

      // if (this.sort_column) {
      //   visible_docs = orderBy(visible_docs, this.sort_column, this.sort_order);
      // }

      // visible_docs = visible_docs.filter((doc) => {
      //   const s = doc.name + doc.link;
      //   const su = s.toUpperCase();
      //   return su.match(search.toUpperCase());
      // });
      return visible_docs;
    },
  },
};
</script>

<style scoped>
@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
