var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavSplash',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-9"},[(!_vm.complete)?_c('div',{staticClass:"row g-3 py-3 justify-content-center form-contact mt-2"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"width":"auto","height":"90px","alt":""}})]),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"card text-center bg-dark border-light text-white"},[_c('div',{staticClass:"card-header"},[_c('h2',[_vm._v("New music on the horizon? Send it to our team!")])]),_c('div',{staticClass:"card-body contact-message"},[_c('p',[_vm._v(" Every week, our team reviews new music submissions for distribution across 150 streaming platforms worldwide! "),_c('br'),_vm._v(" If you are not currently registered with DRM and would like to submit your music for distribution, please fill in the form below. "),_c('br'),_vm._v(" For the quickest response, please upload an audio file, otherwise include a link to your music online (when you press \"Next\" an upload/link option will appear). "),_c('br'),_vm._v(" If you are an existing member of DRM and require assistance, please reach out to our support team "),_c('a',{attrs:{"href":"mailto:info@drm.co.nz"}},[_vm._v("here")]),_vm._v(". ")])])])]),_c('div',{staticClass:"col-10"},[_c('form',{staticClass:"needs-validation text-light",attrs:{"novalidate":""}},[(!_vm.contact.id)?_c('div',[_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"release_title"}},[_vm._v("Your name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && !_vm.contact.name,
                      'is-valid': _vm.contact.name,
                    },attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid name.")])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"release_title"}},[_vm._v("Artist name / Band name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.artist_name),expression:"contact.artist_name"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && !_vm.contact.artist_name,
                      'is-valid': _vm.contact.artist_name,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.contact.artist_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "artist_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid name.")])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_org"}},[_vm._v("Artist's label (if they have one)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.supplied_label),expression:"contact.supplied_label"}],staticClass:"form-control",class:{
                      'is-valid': _vm.contact.supplied_label,
                    },attrs:{"name":"org","type":"text"},domProps:{"value":(_vm.contact.supplied_label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "supplied_label", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_name"}},[_vm._v("Your email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && (!_vm.valid_email || !_vm.contact.email),
                      'is-valid': _vm.contact.email && _vm.valid_email,
                    },attrs:{"name":"email","type":"email","required":""},domProps:{"value":(_vm.contact.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "email", $event.target.value)},_vm.validate_email]}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid email address.")])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_name"}},[_vm._v("Your mobile number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.mobile),expression:"contact.mobile"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && !_vm.contact.mobile,
                      'is-valid': _vm.contact.mobile,
                    },attrs:{"name":"mobile","type":"text","required":""},domProps:{"value":(_vm.contact.mobile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "mobile", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid mobile number.")])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_spotify_id"}},[_c('img',{attrs:{"src":require("../assets/spotify_logo.png"),"width":"auto","height":"24","alt":""}}),_c('span',{staticClass:"ms-2"},[_vm._v("Artist's Spotify Link (if they have one)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.spotify_id),expression:"contact.spotify_id"}],staticClass:"form-control",class:{
                      'is-valid': _vm.contact.spotify_id,
                    },attrs:{"placeholder":"https://open.spotify.com/artist/xxxxxxxxxxxx","name":"artist_spotify_id","type":"text"},domProps:{"value":(_vm.contact.spotify_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "spotify_id", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_apple_id"}},[_c('img',{attrs:{"src":require("../assets/apple_logo.png"),"width":"auto","height":"24","alt":""}}),_c('span',{staticClass:"ms-2"},[_vm._v("Artist's Apple Link (if they have one)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.apple_id),expression:"contact.apple_id"}],staticClass:"form-control",class:{
                      'is-valid': _vm.contact.apple_id,
                    },attrs:{"placeholder":"https://music.apple.com/artist/xxxxxxxxxxxx","name":"artist_apple_id","type":"text"},domProps:{"value":(_vm.contact.apple_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "apple_id", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"social_media_links"}},[_vm._v(" Artist's website & social media links")]),_vm._l((_vm.contact.social_media_links),function(link,i){return _c('div',{key:i},[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(link.url),expression:"link.url"}],staticClass:"form-control",attrs:{"type":"url","placeholder":`link (${i + 1})`,"aria-label":"Recipient's username","aria-describedby":"basic-addon2"},domProps:{"value":(link.url)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(link, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"button","title":"add another link"},on:{"click":function($event){$event.preventDefault();return _vm.add_line(_vm.contact.social_media_links, i)}}},[_vm._v(" + ")]),_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button","title":"remove this link"},on:{"click":function($event){$event.preventDefault();return _vm.delete_line(_vm.contact.social_media_links, i)}}},[_vm._v(" - ")])])])])])])})],2),_c('div',{staticClass:"form-group mb-1"},[_c('label',{attrs:{"for":"notes"}},[_vm._v(" Notes")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.notes),expression:"contact.notes"}],staticClass:"d-block w-100 m-1",class:{
                      'is-valid': _vm.contact.notes && _vm.contact.notes,
                    },attrs:{"type":"","id":"notes"},domProps:{"value":(_vm.contact.notes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "notes", $event.target.value)}}})]),(!_vm.contact.id)?_c('div',{staticClass:"text-end mt-3"},[_c('button',{staticClass:"btn btn-light",attrs:{"title":"now submit music"},on:{"click":function($event){$event.preventDefault();return _vm.create_contact.apply(null, arguments)}}},[_vm._v(" Next"),_c('chevrons-right-icon',{attrs:{"size":"1.5x"}})],1)]):_vm._e()]):_vm._e(),(_vm.contact.id)?_c('div',{staticClass:"card bg-transparent border-white"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('ContactAudioUpload',{attrs:{"contact":_vm.contact},on:{"add_audio_file_url":_vm.add_audio_file_url,"audio_file_upload":_vm.audio_file_upload}}),_c('hr'),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"artist_name"}},[_vm._v("...or, if you prefer, enter a link to a music sample online. ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.audio_link),expression:"contact.audio_link"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && !_vm.contact.audio_link && !_vm.contact.audio_file_url,
                          'is-valid': _vm.contact.audio_link,
                        },attrs:{"type":"url","required":""},domProps:{"value":(_vm.contact.audio_link)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contact, "audio_link", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid link.")])]),(_vm.contact.audio_file_url)?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.contact.audio_file_url,"type":"audio/wav","preload":"auto"}})])])]):_vm._e()],1)])]):_vm._e(),(_vm.contact.audio_file_url || _vm.contact.audio_link)?_c('div',{staticClass:"text-end mt-3"},[_c('button',{staticClass:"btn btn-light text-end",attrs:{"type":"submit","disabled":!_vm.contact.audio_file_url && !_vm.contact.audio_link},on:{"click":function($event){$event.preventDefault();return _vm.update_contact.apply(null, arguments)}}},[_vm._v(" Submit "),_c('chevrons-right-icon',{staticClass:"pulse",attrs:{"size":"1.5x"}})],1)]):_vm._e()])])]):_vm._e(),(_vm.complete)?_c('div',{staticClass:"row g-5 py-5 justify-content-center form-contact mt-1"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"width":"auto","height":"80px","alt":""}})]),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"card text-center bg-transparent border-light text-white"},[_c('div',{staticClass:"card-header"},[_c('h2',[_vm._v("Thank you!")])]),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v("We'll be in touch soon.")])])])])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }