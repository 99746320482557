<template>
  <div
    v-if="org_id"
    class="card border-dark">
    <div class="card-header">
      <h6>NOTES</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <table class="table table-hover table-sm">
                <thead class="table-dark">
                  <tr>
                    <th>Note</th>
                    <th class="w-20">Date</th>
                    <th class="w-5"></th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr
                      v-for="note in notes"
                      :key="note.id">
                      <td>{{ note.note }}</td>
                      <td class="text-center align-top w-5">{{ moment_short(note.date) }}</td>
                      <td class="text-center align-top w-5">
                        <x-circle-icon
                          @click="delete_note(note)"
                          role="button"
                          size="1.0x"
                          class="text-danger"></x-circle-icon>
                        <!-- <vue-feather
                            type="trash"
                            size="12"
                            class="align-middle"></vue-feather> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col m-3">
        <form>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Add Note</label>
            <textarea
              v-model="new_note"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"></textarea>
          </div>
          <button
            @click.prevent="save_note"
            type="submit"
            class="btn btn-sm btn-outline-success mt-2">
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import moment from "moment";
import { XCircleIcon } from "vue-feather-icons";
// import { collection, doc, onSnapshot, query, where, addDoc, deleteDoc } from "firebase/firestore";

export default {
  props: {
    org_id: String,
  },

  data() {
    return {
      notes: [],
      new_note: "",
    };
  },

  // created() {
  //   this.get_artist_notes_data(this.artist_id);
  // },

  firestore() {
    return {
      notes: db.collection("notes").where("org_id", "==", this.$route.params.org_id),
      // orgs: db.collection("orgs").orderBy("name"),
    };
  },
  components: {
    XCircleIcon,
  },

  methods: {
    // async get_artist_notes_data() {
    //   onSnapshot(query(collection(db, "notes"), where("artist_id", "==", this.artist_id)), (snap) => {
    //     const notes = [];
    //     snap.forEach((doc) => {
    //       const doc_data = doc.data();
    //       doc_data.id = doc.id;
    //       notes.push(doc_data);
    //     });
    //     this.notes = notes;
    //   });
    // },
    save_note() {
      const new_note_object = {
        date: new Date(),
        org_id: this.org_id,
        note: this.new_note,
      };

      db.collection("notes").add(new_note_object);
      // addDoc(collection(db, "notes"), new_note_object);
      this.new_note = "";
      // this.refresh();
    },
    delete_note(note) {
      db.delete(note.id);
      // deleteDoc(doc(db, "notes", note.id));
      // this.refresh();
    },

    // refresh() {
    //   this.notes = [];
    //   this.get_artist_notes_data(this.artist_id);
    // },

    moment_short: function (date) {
      return moment.utc(date.toDate()).local().format("l");
    },
  },
};
</script>

<style>
</style>