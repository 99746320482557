var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basicLayout',[_c('div',{staticClass:"container-fluid"},[(_vm.release.is_new)?_c('div',{staticClass:"row m-1"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("Release Metadata")]),_c('p',{staticClass:"lead"},[_vm._v(" Please enter all relevant details below and submit for initial validation. "),_c('br'),_vm._v("If you do not have all information to hand, you can save your draft and complete it later. ")])])]):_c('div',{staticClass:"row sticky-top bg-light m-1 p-3"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"text-uppercase"},[(!_vm.release.release_thumbnail_url)?_c('span',{attrs:{"title":"cover art missing for this release"}},[_c('image-icon',{staticClass:"custom-class",attrs:{"size":"1.5x"}})],1):_c('img',{staticClass:"pr-2",attrs:{"src":_vm.release.release_thumbnail_url,"alt":_vm.release.release_title,"width":"40px","loading":"lazy","title":"cover art"}}),(!_vm.release.release_thumbnail_url)?_c('span',[_vm._v(" "+_vm._s(_vm.release.release_title)+"  - "+_vm._s(_vm.release.release_main_artist && _vm.release.release_main_artist.name))]):_vm._e()])]),_c('div',{staticClass:"col"},[(_vm.isAdmin)?_c('router-link',{staticClass:"btn btn-sm btn-outline-success me-2",attrs:{"to":{
            name: 'ReleasesAdminList',
            query: _vm.$route.query,
          },"title":"Got To My Releases"}},[_c('arrow-left-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.2x"}}),_vm._v(" Releases")],1):_c('router-link',{staticClass:"btn btn-sm btn-outline-success me-2",attrs:{"to":{
            name: 'ReleasesMyDraftsList',
            query: _vm.$route.query,
          },"title":"Got To My Releases"}},[_c('arrow-left-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.2x"}}),_vm._v(" Drafts")],1),_c('router-link',{staticClass:"btn btn-sm btn-outline-success me-2",attrs:{"to":{
            name: 'ReleaseTracksList',
            params: { release_id: _vm.release.id },
          }}},[_vm._v("Tracks  "),_c('arrow-right-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.2x"}})],1)],1),(_vm.isAdmin && _vm.release.is_approved_by_drm)?_c('div',{staticClass:"col text-end"},[(_vm.isAdmin)?_c('router-link',{staticClass:"btn btn-sm text-nowrap routerlink me-3",class:_vm.release.fuga_id ? 'btn-success' : 'btn-outline-success',attrs:{"to":{
            name: 'ReleaseFugaUpload',
            params: { release_id: _vm.release.id },
          },"title":_vm.release.fuga_id ? `FUGA Id: ${_vm.release.fuga_id}` : `No FUGA Id`}},[_c('code-icon',{staticClass:"custom-class",attrs:{"size":"1.0x"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-2"},[_vm._v("FUGA")])],1):_vm._e(),(_vm.isAdmin)?_c('router-link',{staticClass:"btn btn-sm text-nowrap routerlink",class:_vm.release.is_incomplete
              ? 'btn-outline-secondary'
              : 'btn-outline-success',attrs:{"to":{
            name: 'ReleaseDDEXUpload',
            params: { release_id: _vm.release.id },
          }}},[_c('code-icon',{staticClass:"custom-class",attrs:{"size":"1.0x"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-2"},[_vm._v("DDEX")])],1):_vm._e()],1):_vm._e()])]),_c('hr'),_c('div',{staticClass:"row m-1"},[_c('div',{staticClass:"col-xl-6"},[_c('ReleaseFormNew',{attrs:{"release_id":_vm.release.id}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }