<template>
  <basicLayout>
    <div class="container-fluid">
      <div v-if="release.is_new" class="row m-1">
        <div class="col">
          <h2>Release Metadata</h2>
          <p class="lead">
            Please enter all relevant details below and submit for initial
            validation.
            <br />If you do not have all information to hand, you can save your
            draft and complete it later.
          </p>
        </div>
      </div>

      <div v-else class="row sticky-top bg-light m-1 p-3">
        <div class="col">
          <h5 class="text-uppercase">
            <span
              v-if="!release.release_thumbnail_url"
              title="cover art missing for this release"
            >
              <image-icon size="1.5x" class="custom-class"></image-icon>
            </span>
            <img
              v-else
              :src="release.release_thumbnail_url"
              :alt="release.release_title"
              width="40px"
              loading="lazy"
              class="pr-2"
              title="cover art"
            />
            <span v-if="!release.release_thumbnail_url"
              >&nbsp;{{ release.release_title }} &nbsp;-&nbsp;{{
                release.release_main_artist && release.release_main_artist.name
              }}</span
            >
          </h5>
        </div>
        <div class="col">
          <router-link
            v-if="isAdmin"
            :to="{
              name: 'ReleasesAdminList',
              query: $route.query,
            }"
            class="btn btn-sm btn-outline-success me-2"
            title="Got To My Releases"
          >
            <arrow-left-circle-icon
              size="1.2x"
              class="custom-class"
            ></arrow-left-circle-icon
            >&nbsp;Releases</router-link
          >

          <router-link
            v-else
            :to="{
              name: 'ReleasesMyDraftsList',
              query: $route.query,
            }"
            class="btn btn-sm btn-outline-success me-2"
            title="Got To My Releases"
          >
            <arrow-left-circle-icon
              size="1.2x"
              class="custom-class"
            ></arrow-left-circle-icon
            >&nbsp;Drafts</router-link
          >

          <!-- <span class=" mx-1"></span> -->
          <router-link
            :to="{
              name: 'ReleaseTracksList',
              params: { release_id: release.id },
            }"
            class="btn btn-sm btn-outline-success me-2"
            >Tracks&nbsp;
            <arrow-right-circle-icon
              size="1.2x"
              class="custom-class"
            ></arrow-right-circle-icon>
          </router-link>

          <!-- <button class="btn btn-sm btn-outline-success me-2">APPROVE</button> -->
        </div>
        <div v-if="isAdmin && release.is_approved_by_drm" class="col text-end">
          <router-link
            v-if="isAdmin"
            :to="{
              name: 'ReleaseFugaUpload',
              params: { release_id: release.id },
            }"
            class="btn btn-sm text-nowrap routerlink me-3"
            :class="release.fuga_id ? 'btn-success' : 'btn-outline-success'"
            :title="
              release.fuga_id ? `FUGA Id: ${release.fuga_id}` : `No FUGA Id`
            "
          >
            <code-icon size="1.0x" class="custom-class"></code-icon>

            <span class="d-none d-xl-inline-block ms-2">FUGA</span>
          </router-link>

          <!-- <button class="btn btn-sm btn-outline-success me-2">APPROVE</button> -->

          <router-link
            v-if="isAdmin"
            :to="{
              name: 'ReleaseDDEXUpload',
              params: { release_id: release.id },
            }"
            class="btn btn-sm text-nowrap routerlink"
            :class="
              release.is_incomplete
                ? 'btn-outline-secondary'
                : 'btn-outline-success'
            "
          >
            <code-icon size="1.0x" class="custom-class"></code-icon>

            <span class="d-none d-xl-inline-block ms-2">DDEX</span>
          </router-link>
        </div>
      </div>
    </div>

    <hr />
    <div class="row m-1">
      <div class="col-xl-6">
        <ReleaseFormNew :release_id="release.id"></ReleaseFormNew>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// https://travishorn.com/form-validation-with-vue-js-4d2e7ba8d2fc
// http://michaelsoriano.com/form-validation-vuejs-boostrap/
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import ReleaseFormNew from "@/components/ReleaseFormNew";
import ReleaseForm from "@/components/ReleaseForm";
import { db } from "@/firebaseConfig";
import {
  ImageIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  CodeIcon,
} from "vue-feather-icons";

export default {
  data: function () {
    return {
      release_id: this.$route.params.release_id,
      release: {
        id: "",
        release_territories_included: ["Worldwide"],
        release_territories_excluded: [],
        release_title: "",
        release_label: "",
        release_price_tier: "Front",
        release_production_year: "",
        release_original_release_date: "",
        release_release_date: "",
        is_new: true,
        is_incomplete: false,
      },
      show_thumbnail: true,
      missing_fields: [],
      invalid_fields: [],
    };
  },
  components: {
    basicLayout,
    ReleaseFormNew,
    ReleaseForm,
    ImageIcon,
    // SmileIcon,
    // MehIcon,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    CodeIcon,
  },
  firestore() {
    return (
      this.release_id && {
        release: db.collection("release_submissions").doc(this.release_id),
      }
    );
  },

  methods: {},

  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
    isSuperAdmin() {
      return this.userProfile.role === "superadmin";
    },
  },
};
</script>

<style scoped>
.container {
  width: 90% !important;
}
</style>
