<template>
  <NavSplash>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <div class="row g-5 py-5 justify-content-center form-contact mt-3">
            <div class="text-center">
              <img
                src="../assets/logo.png"
                width="auto"
                height="80px"
                alt />
            </div>
            <div class="col-10">
              <div class="card text-center bg-dark border-light text-white">
                <div class="card-header">
                  <h2>Welcome to the DRM whānau, e hoa!</h2>
                </div>
                <div class="card-body">
                  <p>
                    To complete your DRM profile, please provide the following information and accept the terms and
                    conditions.
                  </p>
                  <p>
                    You will then have full access to the DRM client portal where you can submit music for release setup
                    and access your royalty reports.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-10">
              <form
                class="needs-validation text-light"
                novalidate>
                <div class="form-group mb-3">
                  <label
                    for="release_title"
                    class="form-label text-light"
                    >Your name</label
                  >
                  <div class="form-control bg-dark text-light is-valid">
                    {{ contact.name }}
                  </div>
                </div>

                <div class="form-group mb-3">
                  <label
                    for="release_title"
                    class="form-label text-light"
                    >Artist name / Band name
                  </label>

                  <div class="form-control bg-dark text-light is-valid">
                    {{ contact.artist_name }}
                  </div>
                </div>

                <div class="form-group mb-3">
                  <label
                    for="artist"
                    class="form-label"
                    >Your email</label
                  >

                  <div class="form-control bg-dark text-light is-valid">
                    {{ contact.email }}
                  </div>
                </div>

                <div class="form-group mb-3">
                  <label
                    for="social_media_links"
                    class="form-label">
                    Website &amp; social media links</label
                  >
                  <ul class="list-group list-group-flush">
                    <li
                      v-for="(link, i) in contact.social_media_links"
                      :key="i"
                      class="list-group-item d-flex justify-content-between align-items-start bg-dark text-light">
                      <div class="form-control bg-dark text-light is-valid">
                        <facebook-icon
                          v-if="link_icon(link, 'facebook')"
                          size="1.2x"
                          class="me-2"></facebook-icon>
                        <instagram-icon
                          v-else-if="link_icon(link, 'instagram')"
                          size="1.2x"
                          class="me-2"></instagram-icon>
                        <youtube-icon
                          v-else-if="link_icon(link, 'youtube')"
                          size="1.2x"
                          class="me-2"></youtube-icon>
                        <link-icon
                          v-else
                          size="1.2x"
                          class="me-2"></link-icon>
                        <span
                          :title="link.url"
                          class="text-light"
                          >{{ link_hostname(link.url) }}</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
                <hr />

                <!-- FAKE FIELDS TO PREVENT AUTOFILL -->
                <input
                  style="display: none"
                  type="text"
                  name="fakeusernameremembered" />
                <input
                  style="display: none"
                  type="password"
                  name="fakepasswordremembered" />
                <div class="form-group mb-3">
                  <label
                    for="password"
                    class="form-label"
                    >Create password
                    <small class="text-muted"
                      >(this will be your password for the DRM Portal - pick a password that's hard to guess)</small
                    ></label
                  >

                  <input
                    v-model="contact.password"
                    type="password"
                    id="password"
                    name="contact_password"
                    class="form-control"
                    autocomplete="do-not-autofill"
                    :class="{
                      'is-invalid': submitted && (!contact.password || !isPasswordStrong),
                      'is-valid': contact.password && isPasswordStrong,
                    }"
                    required />
                  <div class="invalid-feedback">Please enter a valid passwords.</div>
                </div>
                <div class="col-xl-6">
                  <PasswordScore
                    v-if="contact.password && contact.password.length"
                    :value="contact.password"
                    class="mt-2 mb-6"
                    @passed="isPasswordStrong = true"
                    @failed="isPasswordStrong = false" />
                </div>

                <div class="d-grid gap-2 mb-3">
                  <button
                    type="button"
                    class="btn btn-block btn-outline-success bg-dark text-light"
                    @click="show_terms">
                    <file-text-icon
                      size="1.5x"
                      class="mr-2"></file-text-icon>
                    Terms and Conditions
                  </button>
                </div>
                <div class="form-group mb-3">
                  <div class="form-check">
                    <input
                      v-model="contact.is_bound_by_terms"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckIndeterminate" />
                    <label
                      class="form-check-label"
                      for="flexCheckIndeterminate">
                      You, <b>{{ contact.name }}</b
                      >, have read and agree to the <b>Terms and Conditions</b> for and on behalf of
                      <b>{{ contact.artist_name }}</b>
                    </label>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-xl-8 col-lg-10">
                    <div class="form-group">
                      <label
                        for="signature"
                        class="form-label">
                        Your signature</label
                      >

                      <VueSignaturePad
                        width="100%"
                        height="200px"
                        ref="signaturePad"
                        class="bg-white" />
                      <div class="text-end mt-2">
                        <button
                          @click.prevent="undo"
                          class="btn btn-sm btn-outline-light">
                          <rewind-icon
                            size="1.5x"
                            class="custom-class"></rewind-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <button
                    @click.prevent="update_contact"
                    class="btn btn-outline-light text-end">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TermsModal ref="terms_modal"></TermsModal>
    </div>
  </NavSplash>
</template>

<style>
</style>

<script>
import NavSplash from "@/components/NavSplash";
import TermsModal from "@/components/TermsModal";
import PasswordScore from "@/components/PasswordScore.vue";
// import { Modal } from "bootstrap";
import { fb, db } from "@/firebaseConfig";
import { actions } from "@/store";
import pdf_mixin from "@/mixins/pdf_mixin";
import { FacebookIcon, InstagramIcon, YoutubeIcon, LinkIcon, RewindIcon, FileTextIcon } from "vue-feather-icons";

export default {
  name: "createUser",
  components: {
    NavSplash,
    TermsModal,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
    RewindIcon,
    FileTextIcon,
    PasswordScore,
  },
  mixins: [pdf_mixin],
  data: function () {
    return {
      contact: { signature: "" },
      submitted: false,
      isPasswordStrong: false,
      user_created: false,
      pdfbase64: "",
      modal: null,
    };
  },

  firestore() {
    return {
      contact: db.collection("contacts").doc(this.$route.params.contact_id),
    };
  },

  methods: {
    show_terms() {
      this.$refs.terms_modal.open_modal();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // console.log("isEmpty: " + isEmpty);
      // console.log("sig data: " + data);
      this.contact.signature = data;
      this.contact.date_signed = new Date();
    },
    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        // console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },
    update_contact: async function () {
      actions.setLoading(true);

      // console.log("submitting form");

      let contact = this.contact;

      const { data } = this.$refs.signaturePad.saveSignature();

      // console.log("sig data: " + data);

      if (!data || !contact.is_bound_by_terms || !contact.password || !this.isPasswordStrong) {
        this.$toast.warning("You are missing some required information.  Please complete all required fields.", {
          position: "top-right",
          timeout: 5000,
        });
        this.submitted = true;
        actions.setLoading(false);
        return;
      }

      contact.signature = data;
      contact.date_signed = new Date();

      //CREATE SIGNED CONTRACT

      const signed_contract = await this.pdfgen();

      // console.log("SIGNED CONTRACT: " + JSON.stringify(signed_contract));

      if (signed_contract.success) {
        contact.signed_contract = signed_contract.contract;
        this.pdfbase64 = signed_contract.contract;
      } else {
        // console.log("PDF ERROR");
        this.$toast.error("There was a PDF ERROR", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      // UPDATE CONTACT

      await db.collection("contacts").doc(contact.id).update({
        // gst_number: (contact.gst_registered && contact.gst_number) || "",
        signed_contract: contact.signed_contract,
        is_bound_by_terms: contact.is_bound_by_terms,
        date_signed: contact.date_signed,
      });

      // CREATE NEW USER
      // ATTACH CONTRACT TO ARTIST

      const user_from_contact = fb.httpsCallable("user_from_contact");
      const res = await user_from_contact(contact);
      // console.log("user from contract res: " + JSON.stringify(res.data));

      if (res.data.success) {
        this.user_created = true;
        // this.submitted = false;
        this.$router.push({
          name: "Login",
        });
      } else {
        // console.log("user_from_contact error: " + JSON.stringify(res.data.error));
        this.$toast.warning(res.data.error.errorInfo.message, {
          position: "top-right",
          timeout: 5000,
        });
        // this.submitted = false;
      }
      actions.setLoading(false);
    },

    add_audio_file_url(url) {
      this.contact.audio_file_url = url;

      db.collection("contacts").doc(this.contact.id).update({
        audio_file_url: url,
      });
    },
  },
};
</script>

<style scoped>
.bg-b {
  /* height: 100vh; */
  margin-top: -80px;
  background-image: url("../assets/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
}

.form-contact {
  width: 100%;

  /* max-width: 800px; */
  padding-bottom: 85px;
  margin: auto;
  background-color: #13101e;
  opacity: 0.95;
}
</style>
