<template>
  <basicLayout>
    <div class="container-fluid">
      <!-- <div v-if="!Object.keys(new_release_record).length" class="row m-1"> -->
      <div class="row m-1">
        <div class="col">
          <h5 class="card-header text-uppercase">
            <button
              @click="$router.go(-1)"
              class="btn btn-sm btn-outline-success me-3"
              type="button"
            >
              <arrow-left-circle-icon
                size="1.0x"
                class="custom-class"
              ></arrow-left-circle-icon>
              Back
            </button>

            {{ release.release_title }} -
            {{
              release.release_main_artist && release.release_main_artist.name
            }}
            <span v-if="release.release_upc">({{ release.release_upc }})</span>
          </h5>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-12">
          <div class="card mt-3">
            <div class="card-header">
              <div class="row m-1">
                <div class="col-xl-6">
                  <h6 class="text-uppercase">
                    <router-link
                      :to="{
                        name: 'ReleaseEdit',
                        params: { release_id: release.id },
                      }"
                      class="me-3"
                    >
                      <button
                        class="btn btn-x-sm btn-outline-success"
                        type="button"
                      >
                        <edit-icon size="1.0x" class="custom-class"></edit-icon>
                        Edit
                      </button> </router-link
                    >RELEASE
                  </h6>
                </div>
                <div class="col-xl-6 text-end">
                  <h6
                    @click="fuga_product_record(release.fuga_id)"
                    class="text-success"
                  >
                    {{ release.fuga_id }}
                  </h6>
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-bordered table-sm track-list">
                <thead>
                  <tr>
                    <th scope="col" class="w-25"></th>
                    <th scope="col" class="w-15">FUGA ID</th>
                    <th scope="col" class="w-15">Apple ID</th>
                    <th scope="col" class="w-15">Spotify ID</th>
                    <th scope="col">Incomplete Records</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Label (Org)</td>

                    <td
                      v-if="release?.release_owner_org?.fuga_id"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td class="text-center">
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-secondary"
                      ></thumbs-up-icon>
                    </td>
                    <td class="text-center">
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-secondary"
                      ></thumbs-up-icon>
                    </td>

                    <td
                      v-if="!release?.release_owner_org?.fuga_id"
                      class="text-center"
                    >
                      {{ release?.release_owner_org?.name }}
                    </td>
                    <td v-else></td>
                  </tr>

                  <tr>
                    <td>Primary Artist</td>

                    <td
                      v-if="release?.release_primary_artist?.fuga_id"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="release?.release_primary_artist?.apple_id"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="release?.release_primary_artist?.spotify_id"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        !release?.release_primary_artist?.fuga_id ||
                        !release?.release_primary_artist?.apple_id ||
                        !release?.release_primary_artist?.spotify_id
                      "
                      class="text-start"
                    >
                      <router-link
                        :to="{
                          name: 'ArtistProfile',
                          params: {
                            artist_id: release?.release_primary_artist?.id,
                          },
                        }"
                      >
                        {{ release.release_primary_artist?.name }}
                      </router-link>
                    </td>
                    <td v-else></td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        class="col"
                        :title="
                          release.release_main_artists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Main Artists</span
                      >
                      <span class="badge bg-light text-dark ms-3 text-end">{{
                        release.release_main_artists?.filter((l) => l.name)
                          .length
                      }}</span>
                    </td>

                    <td
                      v-if="
                        release.release_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        release.release_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        release.release_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="artist in release?.release_main_artists?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="artist.id"
                      >
                        <router-link
                          :to="{
                            name: 'ArtistProfile',
                            params: { artist_id: artist.id },
                          }"
                        >
                          {{ artist.name }}
                        </router-link>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span
                        :title="
                          release.release_featured_artists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Featured Artists</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        release.release_featured_artists?.filter((l) => l.name)
                          .length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        release.release_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        release.release_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        release.release_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="artist in release?.release_featured_artists?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="artist.id"
                      >
                        <router-link
                          :to="{
                            name: 'ArtistProfile',
                            params: { artist_id: artist.id },
                          }"
                        >
                          {{ artist.name }}
                        </router-link>
                      </span>
                    </td>
                  </tr>

                  <tr v-if="release.fuga_id">
                    <td>Art Uploaded to FUGA</td>
                    <td
                      v-if="release.is_fuga_cover_image_uploaded"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-12">
          <div class="card mt-3">
            <div class="card-header">
              <h6 class="text-uppercase">TRACKS</h6>
            </div>

            <div class="card-body">
              <table class="table table-bordered table-sm track-list">
                <thead>
                  <tr>
                    <th scope="col" class="w-25"></th>
                    <th scope="col" class="w-15">FUGA</th>
                    <th scope="col" class="w-15">Apple</th>
                    <th scope="col" class="w-15">Spotify</th>
                    <th scope="col">Incomplete Records</th>
                  </tr>
                </thead>
                <tbody v-for="track in tracks" :key="track.track_number">
                  <tr class="table-light">
                    <td colspan="4">
                      <h6>
                        <router-link
                          :to="{
                            name: 'ReleaseTrackEdit',
                            params: { track_id: track.id },
                          }"
                        >
                          <button
                            class="btn btn-x-sm btn-outline-success me-3"
                            type="button"
                          >
                            <edit-icon
                              size="1.0x"
                              class="custom-class"
                            ></edit-icon>
                          </button>
                        </router-link>

                        <b
                          >{{ track.track_number }}&nbsp;{{
                            track.track_title
                          }}</b
                        >&nbsp;
                        <span v-if="release.release_upc"
                          >({{ track.track_isrc }})</span
                        >
                      </h6>
                    </td>

                    <td class="text-end">
                      <span
                        @click="fuga_asset_record(track.fuga_id)"
                        class="text-success"
                        >{{ track.fuga_id }}</span
                      >
                    </td>
                  </tr>
                  <tr v-if="track.is_existing_track">
                    <td>existing track {{ track.fuga_id }}</td>
                  </tr>
                  <tr v-if="!track.is_existing_track">
                    <td>
                      <span
                        :title="
                          track.track_main_artists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Main Artists</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        track.track_main_artists?.filter((l) => l.name).length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        track.track_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        track.track_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger text-center"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        track.track_main_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success text-center"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="artist in track?.track_main_artists?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="artist.id"
                      >
                        <router-link
                          :to="{
                            name: 'ArtistProfile',
                            params: { artist_id: artist.id },
                          }"
                        >
                          {{ artist.name }}
                        </router-link>
                      </span>
                    </td>
                  </tr>
                  <!-- featured artists -->
                  <tr v-if="!track.is_existing_track">
                    <td>
                      <span
                        :title="
                          track.track_featured_artists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Featured Artists</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        track.track_featured_artists?.filter((l) => l.name)
                          .length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        track.track_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        track.track_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        track.track_featured_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td>
                      {{
                        track?.track_featured_artists
                          ?.filter(
                            (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                          )
                          .map((a) => a.name)
                          .join("; ")
                      }}
                    </td>
                  </tr>

                  <!-- remix artists -->
                  <tr v-if="!track.is_existing_track">
                    <td>
                      <span
                        :title="
                          track.track_lyricists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Lyricists</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        track.track_remix_artists?.filter((l) => l.name).length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        track.track_remix_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        track.track_remix_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        track.track_remix_artists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="artist in track?.track_remix_artists?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="artist.id"
                      >
                        <router-link
                          :to="{
                            name: 'PersonProfile',
                            params: { person_id: artist.id },
                          }"
                        >
                          {{ artist.name }}
                        </router-link>
                      </span>
                    </td>
                  </tr>

                  <!-- composers -->
                  <tr v-if="!track.is_existing_track">
                    <td>
                      <span
                        :title="
                          track.track_composers
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Composers</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        track.track_composers?.filter((l) => l.name).length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        track.track_composers
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        track.track_composers
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        track.track_composers
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="composer in track?.track_composers?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="composer.id"
                      >
                        <router-link
                          :to="{
                            name: 'PersonProfile',
                            params: { person_id: composer.id },
                          }"
                        >
                          {{ composer.name }} </router-link
                        >&nbsp;
                      </span>
                    </td>
                  </tr>

                  <!-- lyricists -->
                  <tr v-if="!track.is_existing_track">
                    <td>
                      <span
                        :title="
                          track.track_lyricists
                            ?.filter((l) => l.name)
                            .map((l) => l.name)
                            .join('; ')
                        "
                        >Lyricists</span
                      >
                      <span class="badge bg-light text-dark ms-3">{{
                        track.track_lyricists?.filter((l) => l.name).length
                      }}</span>
                    </td>
                    <td
                      v-if="
                        track.track_lyricists
                          ?.filter((a) => a.name)
                          .every((a) => a.fuga_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                    <td
                      v-if="
                        track.track_lyricists
                          ?.filter((a) => a.name)
                          .every((a) => a.apple_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td
                      v-if="
                        track.track_lyricists
                          ?.filter((a) => a.name)
                          .every((a) => a.spotify_id)
                      "
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>

                    <td>
                      <span
                        v-for="lyricist in track?.track_lyricists?.filter(
                          (a) => !a.fuga_id || !a.apple_id || !a.spotify_id
                        )"
                        :key="lyricist.id"
                      >
                        <router-link
                          :to="{
                            name: 'PersonProfile',
                            params: { person_id: lyricist.id },
                          }"
                        >
                          {{ lyricist.name }}
                        </router-link>
                      </span>
                    </td>
                  </tr>

                  <tr v-if="track.fuga_id">
                    <td>
                      Contributors Uploaded to FUGA
                      {{ track.fuga_contributors_added }}
                    </td>
                    <td
                      v-if="track.fuga_contributors_added"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                  </tr>

                  <tr v-if="track.fuga_id">
                    <td>Audio Uploaded to FUGA</td>
                    <td
                      v-if="track.is_fuga_audio_track_uploaded"
                      class="text-center"
                    >
                      <thumbs-up-icon
                        size="1.0x"
                        class="text-success"
                      ></thumbs-up-icon>
                    </td>
                    <td v-else class="text-center">
                      <thumbs-down-icon
                        size="1.0x"
                        class="text-danger"
                      ></thumbs-down-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col text_start">
                  <button
                    @click="release_to_fuga_process"
                    class="btn btn-outline-success"
                    :class="{ disabled: disable_button }"
                  >
                    Release to FUGA
                  </button>
                </div>

                <div class="col text-end">
                  <button
                    @click="release_to_fuga_pre_sync"
                    class="btn btn-outline-success"
                  >
                    Pre-Sync
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <button @click="release_to_fuga_pre_sync" class="btn btn-outline-success">
        release_to_fuga_pre_sync
      </button>

      <button @click="release_to_fuga_prepare" class="btn btn-outline-success">
        release_to_fuga_prepare
      </button>

      <button @click="release_to_fuga_upload" class="btn btn-outline-success">
        release_to_fuga_upload
      </button>

      <button @click="release_to_fuga_link" class="btn btn-outline-success">
        release_to_fuga_link
      </button>

      <button
        @click="release_to_fuga_upload_contributors"
        class="btn btn-outline-success"
      >
        release_to_fuga_upload_contributors
      </button> -->
      <!-- <button
        @click="release_to_fuga_upload_media"
        class="btn btn-outline-success"
      >
        release_to_fuga_upload_media
      </button> -->
      <!-- tracks -->
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import { db, fb } from "@/firebaseConfig";
import {
  ArrowLeftCircleIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  EditIcon,
} from "vue-feather-icons";

export default {
  name: "DDEXUpload",
  data() {
    return {
      simulation: false,
      last_index: {},
      release: {},
      tracks: [],
      disable_button: false,
    };
  },

  firestore() {
    return {
      release: db
        .collection("release_submissions")
        .doc(this.$route.params.release_id),
      tracks: db
        .collection("release_submissions_tracks")
        .where("release_id", "==", this.$route.params.release_id)
        .orderBy("track_number"),
    };
  },

  components: {
    basicLayout,
    ArrowLeftCircleIcon,
    ThumbsUpIcon,
    ThumbsDownIcon,
    EditIcon,
  },

  methods: {
    fuga_product_record(id) {
      window.open(`https://next.fugamusic.com/product/${id}`, "_blank");
    },
    fuga_asset_record(id) {
      window.open(`https://next.fugamusic.com/asset/${id}`, "_blank");
    },

    release_to_fuga_process: async function () {
      console.log("release_to_fuga_process");
      const release_to_fuga_process = fb.httpsCallable(
        "release_to_fuga_process"
      );

      release_to_fuga_process(this.release.id);

      this.disable_button = true;
      await new Promise((resolve) => setTimeout(resolve, 10000));
      this.disable_button = false;
    },

    release_to_fuga_pre_sync: async function () {
      console.log("release_to_fuga_pre_sync");
      const release_to_fuga_pre_sync = fb.httpsCallable(
        "release_to_fuga_pre_sync"
      );
      release_to_fuga_pre_sync(this.release.id);
    },

    release_to_fuga_prepare: async function () {
      const release_to_fuga_prepare = fb.httpsCallable(
        "release_to_fuga_prepare"
      );
      release_to_fuga_prepare(this.release.id);
    },

    release_to_fuga_upload: async function () {
      const release_to_fuga_upload = fb.httpsCallable("release_to_fuga_upload");
      release_to_fuga_upload(this.release.id);
    },

    release_to_fuga_link: async function () {
      const release_to_fuga_link = fb.httpsCallable("release_to_fuga_link");
      release_to_fuga_link(this.release.id);
    },

    release_to_fuga_upload_contributors: async function () {
      const release_to_fuga_upload_contributors = fb.httpsCallable(
        "release_to_fuga_upload_contributors"
      );
      release_to_fuga_upload_contributors(this.release.id);
    },

    release_to_fuga_upload_media: async function () {
      const release_to_fuga_upload_media = fb.httpsCallable(
        "release_to_fuga_upload_media"
      );
      // release_to_fuga_upload_media(this.release.id);
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },

  watch: {
    release: {
      handler(release) {
        if (release.id) {
          this.release_to_fuga_pre_sync();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.sticky-header {
  z-index: 2;
}

.xml-pretty {
  color: rgb(164, 212, 196);
  background-color: rgb(36, 36, 40);
  z-index: 1;
}

.track-list {
  padding: 2px 5px 2px 5px;
  font-size: 0.9em;
}
</style>
