<template>
  <div
    v-if="selected_contact"
    class="card border-dark">
    <div
      class="card-header"
      :class="{
        'alert-success': selected_contact.is_accepted,
        'alert-danger': selected_contact.is_rejected,
      }">
      <div class="row">
        <div class="col-xl-10">
          <h4>{{ selected_contact.name }} ({{ selected_contact.artist_name }})</h4>
        </div>
        <div class="col-xl-2 text-end">
          <button
            @click="delete_contact()"
            class="btn btn-sm btn-outline-danger align-center"
            title="delete contact">
            <trash-icon
              size="1.2x"
              class="custom-class"></trash-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Name</div>
            {{ selected_contact.name }}
          </div>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Label (supplied by contact)</div>
            {{ selected_contact.supplied_label }}
          </div>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Artist name / Band name</div>
            {{ selected_contact.artist_name }}
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Email</div>
            <a
              class="text-secondary"
              :href="`mailto:${selected_contact.email}`"
              target="_blank"
              >{{ selected_contact.email }}</a
            >
          </div>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Mobile</div>
            <a
              class="text-secondary"
              :href="`mailto:${selected_contact.mobile}`"
              target="_blank"
              >{{ selected_contact.mobile }}</a
            >
          </div>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div
              :title="selected_contact.apple_id"
              class="fw-bold">
              Apple ID
            </div>
            <a
              :href="selected_contact.apple_id"
              target="_blank"
              >{{ url_id(selected_contact.apple_id) }}</a
            >
          </div>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div
              :title="selected_contact.spotify_id"
              class="fw-bold">
              Spotify ID
            </div>
            <a
              :href="selected_contact.spotify_id"
              target="_blank"
              >{{ url_id(selected_contact.spotify_id) }}</a
            >
          </div>
        </li>

        <li
          v-for="(link, i) in selected_contact.social_media_links"
          :key="link.url"
          class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div
              v-if="i == 0"
              class="fw-bold">
              Social media
            </div>
            <facebook-icon
              v-if="link_icon(link, 'facebook')"
              size="1.2x"
              class="me-2"></facebook-icon>
            <instagram-icon
              v-else-if="link_icon(link, 'instagram')"
              size="1.2x"
              class="me-2"></instagram-icon>
            <youtube-icon
              v-else-if="link_icon(link, 'youtube')"
              size="1.2x"
              class="me-2"></youtube-icon>
            <link-icon
              v-else
              size="1.2x"
              class="me-2"></link-icon>
            <a
              :href="link.url"
              target="_blank"
              >{{ link_hostname(link.url) }}</a
            >
          </div>
        </li>
        <li
          v-if="selected_contact.audio_link"
          class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Audio link</div>

            <audio
              v-show="url_file_extension(selected_contact.audio_link) == 'wav'"
              class="m-2"
              id="myAudio"
              controls
              preload="metadata"
              :src="selected_contact.audio_link">
              browser not supported
            </audio>
            <div>
              <link-icon
                size="1.2x"
                class="me-2"></link-icon>
              <a
                :href="selected_contact.audio_link"
                :title="selected_contact.audio_link"
                target="_blank"
                >{{ link_hostname(selected_contact.audio_link) }}</a
              >
            </div>
          </div>
        </li>
        <li
          v-if="selected_contact.audio_file_url"
          class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Audio upload</div>
            <div class="m-2">
              <audio controls>
                <source
                  :src="selected_contact.audio_file_url"
                  type="audio/wav"
                  preload="auto" />
              </audio>
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Notes</div>
            {{ selected_contact.notes }}
          </div>
        </li>
      </ul>
    </div>
    <div class="card-footer bg-transparent">
      <div class="row">
        <div
          v-if="!selected_contact.is_accepted && !selected_contact.is_rejected"
          class="col-xl-8">
          <button
            @click="contact_vote('yes')"
            class="btn btn-sm"
            :class="
              selected_contact.voters_yes &&
              selected_contact.voters_yes.length &&
              selected_contact.voters_yes.includes(this.userProfile.name)
                ? 'btn-success '
                : 'btn-outline-success'
            ">
            <smile-icon
              size="1.2x"
              class="custom-class"></smile-icon
            ><span
              class="badge bg-success ms-2"
              :title="selected_contact.voters_yes && selected_contact.voters_yes.join('\n')"
              >{{ (selected_contact.voters_yes && selected_contact.voters_yes.length) || 0 }}</span
            >
          </button>

          <button
            @click="contact_vote('maybe')"
            class="btn btn-sm ms-1"
            :class="
              selected_contact.voters_maybe &&
              selected_contact.voters_maybe.length &&
              selected_contact.voters_maybe.includes(this.userProfile.name)
                ? 'btn-warning '
                : 'btn-outline-warning'
            ">
            <meh-icon
              size="1.2x"
              class="custom-class"></meh-icon
            ><span
              class="badge bg-warning ms-2"
              :title="selected_contact.voters_maybe && selected_contact.voters_maybe.join('\n')"
              >{{ (selected_contact.voters_maybe && selected_contact.voters_maybe.length) || 0 }}</span
            >
          </button>
          <button
            @click="contact_vote('no')"
            class="btn btn-sm ms-1"
            :class="
              selected_contact.voters_no &&
              selected_contact.voters_no.length &&
              selected_contact.voters_no.includes(this.userProfile.name)
                ? 'btn-danger'
                : 'btn-outline-danger'
            ">
            <frown-icon
              size="1.2x"
              class="custom-class"></frown-icon>
            <span
              class="badge bg-danger ms-2"
              :title="selected_contact.voters_no && selected_contact.voters_no.join('\n')"
              >{{ (selected_contact.voters_no && selected_contact.voters_no.length) || 0 }}</span
            >
          </button>

          <button
            @click="contact_vote('clear')"
            class="btn btn-sm btn-outline-secondary ms-1">
            <x-circle-icon
              size="1.2x"
              class="custom-class"></x-circle-icon>
          </button>
        </div>
        <div
          v-if="!selected_contact.is_accepted && !selected_contact.is_rejected && !selected_contact.is_unrated"
          class="col text-end">
          <button
            @click="update_status('accept')"
            class="btn btn-sm btn-outline-success"
            title="accept">
            <check-icon
              size="1.2x"
              class="custom-class"></check-icon>
          </button>
          <button
            @click="update_status('reject')"
            class="btn btn-sm btn-outline-danger ms-1"
            title="reject">
            <x-icon
              size="1.2x"
              class="custom-class"></x-icon>
          </button>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </div>
</template>

<script>
// import { Popover } from "bootstrap";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import { auth } from "@/firebaseConfig.js";
import { fb, db } from "@/firebaseConfig";
import { state } from "@/store";

import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkIcon,
  SmileIcon,
  FrownIcon,
  MehIcon,
  XCircleIcon,
  CheckIcon,
  XIcon,
  TrashIcon,
} from "vue-feather-icons";

// const contacts = db.collection("contacts");

export default {
  name: "contacts",
  props: {
    contact_id: String,
  },
  data() {
    return {
      selected_contact: {},
    };
  },
  components: {
    ConfirmDialog,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
    SmileIcon,
    FrownIcon,
    MehIcon,
    XCircleIcon,
    CheckIcon,
    XIcon,
    TrashIcon,
  },

  methods: {
    select_contact(contact) {
      this.selected_contact = contact;
    },

    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        // console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },
    url_file_extension(url) {
      // console.log(url);
      try {
        let new_url = url && new URL(url);
        let filename = new_url && new_url.pathname.split("/").reverse()[0];
        let ext = (filename && filename.split(".")[1]) || "";
        return ext;
      } catch (e) {
        // console.log(e);
        return "";
      }
    },

    url_id(url) {
      // console.log(url);
      try {
        let new_url = url && new URL(url);
        let id = new_url && new_url.pathname.split("/").reverse()[0];
        return id;
      } catch (e) {
        // console.log(e);
        return url;
      }
    },

    update_status: async function (status) {
      if (status == "accept") {
        this.$router.push({
          name: "ContactProfile",
          params: { contact_id: this.selected_contact.id },
        });
      } else if (status == "reject") {
        let confirm_reject = await this.$refs.confirmDialog.show({
          confirm_title: "Reject Contact",
          confirm_message: "Are you sure you want to reject this contact? This action cannot be undone.",
          confirm_ok_button: "Reject",
        });

        // console.log("confirm_reject: " + confirm_reject);

        if (confirm_reject) {
          db.collection("contacts").doc(this.selected_contact.id).update({ is_rejected: true });
          const contact_reject_email = fb.httpsCallable("contact_reject_email");
          const res = await contact_reject_email(this.selected_contact.id);
          console.log(res.data);
          this.$toast.error("Contact rejected :(", {
            position: "top-right",
            timeout: 5000,
          });

          console.log("redirecting to rejected");

          this.$router.push("/contacts?filter=rejected");
        }
      }
    },

    contact_vote(status) {
      let user_name = this.userProfile.name;

      // reset votes to remove user name
      let voters_yes = this.selected_contact.voters_yes || [];
      voters_yes = voters_yes.filter((v) => v != user_name) || [];

      let voters_no = this.selected_contact.voters_no || [];
      voters_no = voters_no.filter((v) => v != user_name) || [];

      let voters_maybe = this.selected_contact.voters_maybe || [];
      voters_maybe = voters_maybe.filter((v) => v != user_name) || [];

      if (status == "yes") {
        voters_yes.push(user_name);
      } else if (status == "no") {
        voters_no.push(user_name);
      } else if (status == "maybe") {
        voters_maybe.push(user_name);
      }

      const is_unrated = ![...voters_yes, ...voters_maybe, ...voters_no].length;

      let filter = is_unrated ? "unrated" : "rated";

      // console.log("FILTER TO EMIT: " + filter);

      // this.$emit("update_filter", filter);

      db.collection("contacts").doc(this.selected_contact.id).update({
        voters_yes,
        voters_no,
        voters_maybe,
        is_unrated,
      });

      if (this.$route.query.filter !== filter) {
        this.$router.push(`contacts?filter=${filter}`);
      }
    },
    delete_contact: async function () {
      let confirm_delete = await this.$refs.confirmDialog.show({
        confirm_title: "Delete Contact",
        confirm_message: "Are you sure you want to delete this contact? This action cannot be undone.",
        confirm_ok_button: "Delete",
      });
      if (!confirm_delete) return;
      console.log("deleted " + this.selected_contact.id);
      db.collection("contacts").doc(this.selected_contact.id).delete();
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role === "superadmin";
    },
  },

  watch: {
    contact_id: {
      immediate: true,
      handler(id) {
        let query = db.collection("contacts").doc(id);
        this.$bind("selected_contact", query);
      },
    },
  },
};
</script>