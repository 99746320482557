var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basicLayout',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row m-3"},[_c('div',{staticClass:"col"},[(_vm.user.name)?_c('h2',[_vm._v(_vm._s(_vm.user.name))]):_c('h2',[_vm._v("New User")])])]),_c('hr'),_c('div',{staticClass:"row m-3"},[_c('div',{staticClass:"col-xl-6"},[_c('form',{staticClass:"needs-validation",attrs:{"novalidate":""}},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"release_title"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && !_vm.user.name,
                'is-valid': _vm.user.name,
              },attrs:{"type":"text"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid name.")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && !_vm.user.email,
                'is-valid': _vm.user.email,
              },attrs:{"type":"email","required":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid email address.")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Mobile")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.mobile),expression:"user.mobile"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && !_vm.user.mobile,
                'is-valid': _vm.user.mobile,
              },attrs:{"type":"mobile","required":""},domProps:{"value":(_vm.user.mobile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "mobile", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid mobile address.")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v(" Role ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.role),expression:"user.role"}],staticClass:"form-select d-block w-100 m-1",class:{
                'is-invalid': _vm.submitted && !_vm.user.role,
                'is-valid': _vm.user.role,
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.roles),function(role){return _c('option',{key:role},[_vm._v(" "+_vm._s(role)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please select a valid role.")])]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"user_org"}},[_vm._v("Org")]),_c('v-select',{attrs:{"options":_vm.display_orgs,"selectOnTab":true,"clearable":true},model:{value:(_vm.user.orgs[0]),callback:function ($$v) {_vm.$set(_vm.user.orgs, 0, $$v)},expression:"user.orgs[0]"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter org")])],1),_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.create_user.apply(null, arguments)}}},[_vm._v(" Save ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }