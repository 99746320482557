<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Fuga Products</h2>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Release Title, Track Title, Artist, Owner, UPC, ISRC ..."
            />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <!-- <button @click="download_mtl"></button> -->

      <div class="row m-1 mb-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-8">
                  <p>
                    <span class="h5">Releases</span>&nbsp;<span
                      class="small text-muted"
                      ><i
                        >click on a release to see all associated tracks
                        below.</i
                      >
                    </span>
                  </p>
                </div>
                <div class="col-xl-4 text-end">
                  <span v-if="releases?.length"
                    >{{ releases?.length }} results</span
                  >
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <table class="table table-sm table-hover border-top">
                    <thead class="table-dark sticky-top">
                      <tr>
                        <th>Title</th>
                        <th>FUGA Id</th>
                        <th>UPC</th>
                        <th>Artists</th>
                        <th>Genre | Subgenre</th>

                        <!-- <th
                          @click="sort_by('release_upc')"
                          role="button"
                          class="w-10">
                          UPC
                          <span v-if="sort_column === 'release_upc' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_upc' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="release in filtered"
                        @click="get_assets(release)"
                        :key="release.id"
                        class="align-left"
                        :class="row_class(release)"
                      >
                        <td>{{ release?.name }}</td>
                        <td @dblclick="fuga_product_record(release.id)">
                          {{ release?.id }}
                        </td>
                        <td>{{ release.upc }}</td>
                        <!-- <td>{{ release.display_artist }}</td> -->

                        <td>
                          {{ release?.artists?.map((a) => a.name).join("; ") }}
                        </td>
                        <td>
                          {{ release?.genre?.name }} |
                          {{ release?.subgenre?.name }}
                        </td>

                        <!-- <td
                          @dblclick="filter_by(release.release_upc)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_upc.value"></span>
                        </td>
                     
                        <td
                          @dblclick="filter_by(release.release_owner_org)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_owner_org.value.toUpperCase()"></span>
                        </td>
                        <td
                          @dblclick="filter_by(release.release_title)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_title.value"></span>
                        </td>
                    
                        <td
                          @dblclick="filter_by(release.release_main_artist)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_main_artist.value"></span>
                        </td>
                     
                        <td>{{ release.release_release_date }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selected_release_assets.length" class="row m-1">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h5>
                <!-- <img
                  :src="`https://next.fugamusic.com/api/v2/products/${selected_release.id}/artworks`"
                  :alt="selected_release.name"
                  width="40px"
                  loading="lazy" /> -->
                <span class="ms-3">
                  {{ selected_release?.name }} ({{ selected_release?.upc }}) -
                  {{ selected_release?.display_artist }}
                </span>
              </h5>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-sm table-hover">
                <thead>
                  <tr>
                    <th scope="col w-5">ISRC</th>
                    <th scope="col w-5">FUGA Id</th>
                    <th scope="col w-5">No.</th>
                    <th scope="col w-10">Track Title</th>
                    <th scope="col w-10">Display Artist</th>
                    <th scope="col w-5">Featuring</th>
                    <th scope="col w-5">Contributors</th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="asset in selected_release_assets" :key="asset.id">
                    <td>{{ asset.isrc }}</td>
                    <td @dblclick="fuga_asset_record(asset.id)">
                      {{ asset.id }}
                    </td>
                    <td>{{ asset.sequence }}</td>
                    <td>
                      {{ asset.name }}
                      <span v-if="asset.track_subtitle">{{
                        asset.track_subtitle
                      }}</span>
                    </td>
                    <td>{{ asset.display_artist }}</td>
                    <td>
                      {{
                        asset.artists
                          .filter((c) => c.secondary)
                          .map((c) => c.name)
                          .join(", ")
                      }}
                    </td>
                    <td>
                      {{
                        asset.contributors.map((c) => c.person.name).join(", ")
                      }}
                    </td>

                    <td scope="col " class="text-center w-15">
                      <audio v-if="asset.extra_1" height="12" controls>
                        <source
                          :src="asset.extra_1"
                          type="audio/wav"
                          preload="auto"
                        />
                      </audio>
                      <button
                        v-else
                        @click="download_flac(asset)"
                        class="btn btn-sm btn-outline-success"
                      >
                        <download-icon
                          size="1.0x"
                          class="text-secondary"
                        ></download-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>

      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage"
      />
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
// import { auth } from "@/firebaseConfig.js";
import { state, getters, actions } from "@/store";

import { fb, storage } from "@/firebaseConfig";
// import algoliasearch from "algoliasearch";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import {
  EditIcon,
  EyeIcon,
  TrashIcon,
  ShuffleIcon,
  ImageIcon,
  MusicIcon,
  CopyIcon,
  DownloadIcon,
  CodeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MoreHorizontalIcon,
  ThumbsUpIcon,
  ExternalLinkIcon,
  StarIcon,
  ArchiveIcon,
  InfoIcon,
} from "vue-feather-icons";

export default {
  name: "my_release_submissions",

  data() {
    return {
      selected_line_id: "",
      releases: [],
      selected_release: {},
      selected_release_assets: [],
      search: this.$route.query.search || "",
      debounce: null,
      sort_column: ["track_number"],
      sort_order: "asc",
      filters: "",
      selected_page: 1,
      searchClient: null,
      index: {},
      hits: {},
    };
  },

  components: {
    basicLayout,
    Pagination,
    EditIcon,
    EyeIcon,
    TrashIcon,
    ShuffleIcon,
    ImageIcon,
    MusicIcon,
    CodeIcon,
    CopyIcon,
    DownloadIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    MoreHorizontalIcon,
    ThumbsUpIcon,
    ExternalLinkIcon,
    StarIcon,
    ArchiveIcon,
    InfoIcon,
  },
  mixins: [filter_mixins, sort_mixins],

  mounted() {
    this.fuga_init();
  },

  methods: {
    fuga_init: async function () {
      if (!this.fugaCreds.token) {
        const fuga_login = fb.httpsCallable("fuga_login");
        let login_res = await fuga_login();
        console.log(login_res.data);
        let fugaCreds = {
          token: login_res.data.token,
          cookie: login_res.data.cookie[0],
        };
        actions.setFugaCreds(fugaCreds);
      }
      this.fuga_get_products({ page_size: 10 });
    },

    fuga_get_products: async function (params) {
      const fuga_products_get = fb.httpsCallable("fuga_get");

      let products_options = {
        cookie: this.fugaCreds.cookie,
        token: this.fugaCreds.token,
        org_ids: this.org_ids,
        end_point: "products",
        method: "get",
        params,
      };

      let products_get_res = await fuga_products_get(products_options);
      console.log(products_get_res.data);
      this.releases = products_get_res.data;
    },

    row_class(selected_line) {
      return {
        "alert alert-secondary":
          this.selected_line_id === selected_line.track_isrc,
      };
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 500);
    },

    clear_search() {
      this.search = "";
      this.selected_release_assets = [];
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
    updatePage(value) {
      this.selected_page = value;
    },

    get_assets: async function (release) {
      console.log(release);
      console.log(release.id);
      const fuga_assets_get = fb.httpsCallable("fuga_get");

      let assets_options = {
        cookie: this.fugaCreds.cookie,
        token: this.fugaCreds.token,
        end_point: `products/${release.id}/assets`,
        method: "get",
        params: { page_size: 10 },
      };

      let assets_get_res = await fuga_assets_get(assets_options);

      console.log(assets_get_res);
      this.selected_release = release;
      this.selected_release_assets = assets_get_res.data;
    },

    fuga_product_record(id) {
      window.open(`https://next.fugamusic.com/product/${id}`, "_blank");
    },

    fuga_asset_record(id) {
      window.open(`https://next.fugamusic.com/asset/${id}`, "_blank");
    },

    download_flac: async function (asset) {
      console.log(asset);

      const storageRef = storage.ref();
      const mtlRef = storageRef.child(
        `mtl_audio_tracks/${asset.audio.original_filename}`
      );
      const download_url = await mtlRef.getDownloadURL();
      console.log(download_url);
      asset.extra_1 = download_url;
    },
  },

  watch: {
    page: {
      handler(page, old_page) {
        if (page !== old_page) {
          this.selected_release_assets = [];
        }
      },
    },

    search: {
      handler(search, old_search) {
        if (search !== old_search) {
          this.selected_page = 1;
          let params = {
            search: search,
            page_size: 10,
          };
          this.fuga_get_products(params);
        }
      },
      immediate: true,
    },

    // userProfile: {
    //   immediate: true,
    //   handler(profile) {
    //     this.get_tracks(profile);
    //   },
    // },
  },

  computed: {
    filtered() {
      let visible_releases = this.releases;

      if (this.sort_column) {
        visible_releases = orderBy(
          visible_releases,
          this.sort_column || "",
          this.sort_order
        );
      }

      return visible_releases;
    },

    pages() {
      return this.hits.nbPages;
    },

    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
    fugaCreds() {
      return getters.fugaCreds();
    },
    org_ids() {
      return this.userProfile.orgs.map((o) => o.id);
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  vertical-align: middle;
}

td em {
  font-style: normal;
  font-weight: bold;
  background-color: #ceece5;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}
.btn-block {
  width: 100%;
}

audio {
  height: 32px;
}
</style>
