<template>
  <basicLayout :key="componentKey">
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>My Drafts</h2>
        </div>
      </div>
      <hr />
      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Title, Artist, Owner..."
            />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="col-xl-6 mt-2">
          <button @click="create_release" class="btn btn-outline-success mr-2">
            New Release
          </button>
          <!-- <router-link
            :to="{ name: 'ReleaseBulkMetaUpload' }"
            class="btn btn-outline-success ms-1"
            >Upload Release</router-link
          > -->
        </div>
      </div>

      <div class="row m-1 mb-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="btn-group" data-toggle="buttons">
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: lines_filter === 'all',
                  }"
                >
                  <input
                    v-model="lines_filter"
                    value="all"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  All
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: lines_filter === 'draft',
                  }"
                >
                  <input
                    v-model="lines_filter"
                    value="draft"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  Draft
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: lines_filter === 'is_submitted_to_drm' }"
                >
                  <input
                    v-model="lines_filter"
                    value="is_submitted_to_drm"
                    type="radio"
                    class="btn-check"
                    autocomplete="off"
                  />
                  Submitted to DRM
                </label>

                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: lines_filter === 'is_approved_by_drm' }"
                >
                  <input
                    v-model="lines_filter"
                    value="is_approved_by_drm"
                    type="radio"
                    class="btn-check"
                    autocomplete="off"
                  />
                  Approved by DRM
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: lines_filter === 'is_released' }"
                  title="Go to 'My Releases'"
                >
                  <input
                    v-model="lines_filter"
                    value="is_released"
                    type="radio"
                    class="btn-check"
                    autocomplete="off"
                  />
                  <router-link
                    class="list-group-item list-group-item-action"
                    :to="{ name: 'MasterTrackList' }"
                  >
                    Released
                  </router-link>
                </label>
              </div>
            </div>

            <div class="card-body">
              <div class="row m-1">
                <div class="col">
                  <table class="table table-sm table-responsive border-top">
                    <thead class="table-secondary sticky-top">
                      <tr>
                        <th
                          @click="sort_by('release_art_url')"
                          role="button"
                          class="text-center w-5"
                        >
                          <image-icon
                            size="1.2x"
                            class="align-middle"
                          ></image-icon>
                          <span
                            v-if="
                              sort_column === 'release_art_url' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_art_url' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <th
                          @click="sort_by('release_title')"
                          role="button"
                          class="w-15"
                        >
                          Release Title
                          <span
                            v-if="
                              sort_column === 'release_title' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_title' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <th
                          @click="sort_by('release_main_artist')"
                          role="button"
                          class="w-15"
                        >
                          Artist
                          <span
                            v-if="
                              sort_column === 'release_main_artist' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_main_artist' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <th class="w-10"></th>
                        <th class="w-10"></th>
                        <th class="w-10"></th>
                        <th class="w-10"></th>
                        <th class="w-15"></th>
                        <th class="w-10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="release in filtered"
                        :key="release.id"
                        class="align-left"
                      >
                        <td
                          v-if="release.release_art_url"
                          class="align-middle d-none d-sm-table-cell text-center"
                          title="cover art"
                        >
                          <img
                            :src="release.release_thumbnail_url"
                            :alt="release.release_title"
                            width="40px"
                            loading="lazy"
                          />
                        </td>
                        <td v-else class="text-secondary text-center">
                          <span title="cover art is missing for this release">
                            <image-icon
                              size="1.5x"
                              class="text-danger"
                            ></image-icon
                          ></span>
                        </td>
                        <td
                          @dblclick="filter_by(release.release_title)"
                          class="text-start"
                        >
                          {{ release.release_title }}
                          <span class="d-xl-none"
                            >&nbsp;-&nbsp;
                            {{
                              (release.release_main_artist &&
                                release.release_main_artist.name) ||
                              ""
                            }}</span
                          >
                        </td>

                        <td
                          @dblclick="
                            filter_by(release.release_main_artist.name)
                          "
                          class="d-none d-xl-table-cell text-start"
                        >
                          {{
                            (release.release_main_artist &&
                              release.release_main_artist.name) ||
                            ""
                          }}
                        </td>

                        <td class="" title="preview this release">
                          <router-link
                            :to="{
                              name: 'ReleasePreview',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-block btn-sm text-nowrap routerlink"
                            :class="
                              release.is_incomplete
                                ? 'btn-outline-secondary'
                                : 'btn-outline-success'
                            "
                          >
                            <eye-icon size="1.2x" class="me-2"></eye-icon>

                            <span class="d-none d-xl-inline-block ms-2"
                              >Preview</span
                            >
                          </router-link>
                        </td>

                        <td class="" title="edit this release">
                          <router-link
                            tag="button"
                            :to="{
                              name: 'ReleaseEdit',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-block btn-sm text-nowrap routerlink"
                            :class="
                              release.is_incomplete
                                ? 'btn-outline-secondary'
                                : 'btn-outline-success'
                            "
                            :disabled="release.is_approved_by_drm"
                          >
                            <edit-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></edit-icon>

                            <span class="d-none d-xl-inline-block ms-2"
                              >Edit Release</span
                            >
                          </router-link>
                        </td>
                        <!-- edit tracks -->
                        <td class="text-center" title="edit release tracks">
                          <ReleaseTracksLinkButton
                            :release_id="release.id"
                          ></ReleaseTracksLinkButton>

                          <!-- <router-link
                            tag="button"
                            :to="{
                              name: 'ReleaseTracksList',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-block btn-sm btn-outline-success text-nowrap routerlink"
                            :disabled="release.is_approved_by_drm"
                          >
                            <music-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></music-icon>
                            <span class="d-none d-xl-inline-block ms-2 me-2"
                              >Edit Tracks</span
                            >

                            <span
                              class="badge"
                              :class="{
                                'badge-success bg-success':
                                  tracks_complete(release),
                                'badge-warning bg-secondary':
                                  !tracks_complete(release) ||
                                  !tracks_count(release),
                              }"
                              :title="
                                !tracks_complete(release) ||
                                !tracks_count(release)
                                  ? 'missing or incomplete tracks'
                                  : 'tracks'
                              "
                              >{{ tracks_count(release) }}</span
                            >
                          </router-link> -->
                        </td>
                        <td
                          @click="copy_release(release)"
                          class="text-start d-none d-sm-table-cell align-center"
                          title="copy this release"
                        >
                          <button
                            class="btn btn-block btn-sm btn-outline-secondary align-center text-nowrap"
                          >
                            <copy-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></copy-icon
                            ><span class="d-none d-xl-inline-block ml-1"
                              >Copy</span
                            >
                          </button>
                        </td>
                        <td
                          v-if="!release.is_submitted_to_drm"
                          class="text-start"
                          title="Submit Release"
                        >
                          <button
                            @click="submit_to_drm(release)"
                            class="btn btn-block btn-sm btn-success"
                          >
                            <external-link-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></external-link-icon>
                            <span class="d-none d-xl-inline-block ms-2"
                              >Submit to DRM</span
                            >
                          </button>
                        </td>
                        <td
                          v-else-if="
                            release.is_submitted_to_drm &&
                            !release.is_approved_by_drm
                          "
                        >
                          <div class="progress align-middle progress-fill">
                            <div
                              class="btn btn-block btn-sm bg-success bg-opacity-75 progress-bar progress-bar-striped progress-bar-animated pe-none text-white"
                            >
                              <span>
                                <loader-icon
                                  size="1.2x"
                                  class="align-middle me-2"
                                ></loader-icon
                                >Approval Pending</span
                              >
                            </div>
                          </div>
                        </td>
                        <td
                          v-else-if="
                            release.is_approved_by_drm && !release.fuga_id
                          "
                        >
                          <div class="progress align-middle progress-fill">
                            <div
                              class="btn btn-block btn-sm bg-success progress-bar progress-bar-striped progress-bar-animated pe-none text-white"
                              role="progressbar"
                            >
                              <span>
                                <loader-icon
                                  size="1.2x"
                                  class="align-middle me-2"
                                ></loader-icon
                                >Approved - Release Pending</span
                              >
                            </div>
                          </div>
                        </td>

                        <td
                          v-if="!release.is_submitted_to_drm"
                          @click="delete_release(release)"
                          class="text-end d-none d-sm-table-cell"
                          title="delete this release"
                        >
                          <button
                            class="btn btn-block btn-sm btn-outline-danger text-nowrap"
                          >
                            <trash-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></trash-icon
                            ><span class="d-none d-xl-inline-block ml-1"
                              >Delete</span
                            >
                          </button>
                        </td>

                        <td
                          v-else-if="
                            release.is_approved_by_drm && release.fuga_id
                          "
                        >
                          <div
                            class="btn btn-block btn-sm bg-success pe-none text-white"
                          >
                            <span> Released</span>
                          </div>
                        </td>

                        <td v-else><span> </span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
import { auth } from "@/firebaseConfig.js";
import { state, actions } from "@/store";
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import ReleaseTracksLinkButton from "@/components/ReleaseTracksLinkButton";
import {
  EditIcon,
  TrashIcon,
  EyeIcon,
  ImageIcon,
  MusicIcon,
  CopyIcon,
  RepeatIcon,
  ExternalLinkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LoaderIcon,
} from "vue-feather-icons";

// const tracks = db.collection("release_submissions_tracks");
const releases = db.collection("release_submissions");

export default {
  name: "my_release_submissions",

  data() {
    return {
      display_releases: [],
      display_tracks: [],
      showModal: false,
      selected_release: {},
      lines_filter: this.$route.query.filter || "all",
      search: "",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
      componentKey: 0,
    };
  },

  components: {
    ReleaseTracksLinkButton,
    basicLayout,
    ConfirmDialog,
    EditIcon,
    TrashIcon,
    RepeatIcon,
    EyeIcon,
    ImageIcon,
    MusicIcon,
    CopyIcon,
    ExternalLinkIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    LoaderIcon,
  },
  mixins: [filter_mixins, sort_mixins],

  beforeRouteUpdate(to, from, next) {
    // console.log(to.query.filter);
    this.lines_filter = to.query.filter;
    next();
  },

  methods: {
    forceRerender() {
      this.componentKey += 1;
    },

    is_ready_to_submit(release) {
      return (
        !release.is_incomplete && release.release_art_url != ""
        // this.tracks_count(release) &&
        // this.tracks_complete(release)
      );
    },
    submit_to_drm(release) {
      console.log(release);

      if (!this.is_ready_to_submit(release)) {
        console.log("NOT READY");

        this.$toast.warning(
          "This release is not ready to submit.  Check the missing information and/or files.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );

        return;
      }

      let submission_update = {
        is_submitted_to_drm: true,
      };

      db.collection("release_submissions")
        .doc(release.id)
        .update(submission_update);

      let filter = "is_submitted_to_drm";

      if (this.$route.query.filter !== filter) {
        this.$router.replace({
          ...this.$route,
          query: {
            filter: filter,
          },
        });
      }
    },
    delete_release: async function (release) {
      let confirm_delete = await this.$refs.confirmDialog.show({
        confirm_title: "Delete Release",
        confirm_message:
          "Are you sure you want to delete this release (and all of its tracks)? This action cannot be undone.",
        confirm_ok_button: "Delete",
      });

      if (!confirm_delete) return;
      actions.setLoading(true);
      const release_delete = fb.httpsCallable("release_delete");
      const res = await release_delete(release.id);
      actions.setLoading(false);
      // console.log(res.data);
    },
    tracks_count(release) {
      const tracks = this.display_tracks.filter(
        (track) => track.release_id === release.id
      );
      return tracks.length;
    },
    tracks_complete(release) {
      const release_tracks = this.display_tracks.filter(
        (track) => track.release_id === release.id
      );
      const tracks_incomplete = release_tracks.some(
        (track) => !track.track_audio_url || track.is_incomplete
      );
      return release_tracks.length && !tracks_incomplete;
    },
    create_release: async function () {
      const release_owner_org = this.userProfile.orgs[0];

      const display_org = {
        id: release_owner_org.id,
        name: release_owner_org.name,
        description: release_owner_org.description || "",
        royalties_name_code: release_owner_org.royalties_name_code || "",
      };

      const release = {
        release_owner_org: display_org,
        release_created: new Date(),
        release_created_by: this.currentUser.uid,
        release_title: "[NEW RELEASE]",
        release_primary_artist: { name: "" },
        release_main_artists: [{ name: "" }],
        release_featured_artists: [{ name: "" }],
        release_territories: ["Worldwide"],
        release_label: "",
        release_format_type: "ALBUM",
        release_genre: { name: "" },
        release_subgenre: { name: "" },
        release_parental_advisory: false,
        release_production_year: "",
        release_production_owner: "",
        release_copyright_owner: "",
        release_price_tier: "",
        release_original_release_date: "",
        release_release_date: "",
        release_youtube_release_type: "combined",
        is_new: true,
        is_incomplete: true,
        is_submitted_to_drm: false,
        is_approved_by_drm: false,
        is_uploaded_pending_approval: false,
        is_submitted_to_youtube: false,
        is_approved_by_youtube: false,
        is_submitted_to_believe: false,
        is_approved_by_believe: false,
        is_submitted_to_fuga: false,
        is_approved_by_fuga: false,
        fuga_id: null,
        fuga_cover_image_id: null,
        is_released_to_master_track_list: false,
        is_archived: false,
      };

      const new_release = await db
        .collection("release_submissions")
        .add(release);
      const new_release_id = new_release.id;
      this.$router.push({
        name: "ReleaseEdit",
        params: { release_id: new_release_id },
      });
    },

    copy_release(release) {
      const copy_release = { ...release };

      const created_by_profile = {
        id: this.userProfile.id,
        name: userProfile.name || "",
        email: this.userProfile.email || "",
        mobile: this.userProfile.mobile || "",
      };

      copy_release.release_created = new Date();
      copy_release.release_created_by = created_by_profile;
      copy_release.release_art_url = "";
      copy_release.release_thumbnail_url = "";
      copy_release.release_title = `${release.release_title} [COPY]`;
      copy_release.is_new = true;
      copy_release.is_incomplete = true;
      copy_release.is_submitted_to_drm = false;
      copy_release.is_approved_by_drm = false;
      copy_release.is_updating = false;
      copy_release.is_submitted_to_youtube = false;
      copy_release.is_approved_by_youtube = false;
      copy_release.is_submitted_to_believe = false;
      copy_release.is_approved_by_believe = false;
      copy_release.is_submitted_to_fuga = false;
      copy_release.is_approved_by_fuga = false;
      copy_release.fuga_id = null;
      copy_release.fuga_cover_image_id = null;
      copy_release.is_released_to_master_track_list = false;
      // console.log(JSON.stringify(copy_release));
      db.collection("release_submissions").add(copy_release);
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 750);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      // console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      // console.log("FILTER BY: " + filter);
      this.search = filter;
    },
  },

  watch: {
    // release: {
    //   immediate: true,
    //   handler(release) {
    //     let query = tracks;
    //     if (release) {
    //       query = query.where("release_id", "==", release.id);
    //     }
    //     this.$bind("display_tracks", query);
    //   },
    // },

    lines_filter: {
      immediate: true,
      handler(filter) {
        if (this.userProfile && this.userProfile.orgs) {
          let query = releases.where(
            "release_owner_org.id",
            "in",
            this.userProfile.org_ids
          );

          if (filter && filter === "draft") {
            // console.log("is_submitted_to_drm");
            query = query.where("is_submitted_to_drm", "==", false);
          } else if (filter && filter === "is_submitted_to_drm") {
            // console.log("is_submitted_to_drm");
            query = query
              .where("is_submitted_to_drm", "==", true)
              .where("is_approved_by_drm", "==", false);
          } else if (filter && filter === "is_approved_by_drm") {
            // console.log("is_submitted_to_drm");
            query = query.where("is_approved_by_drm", "==", true);
          }

          this.$bind("display_releases", query);
        }

        if (this.$route.query.filter !== filter) {
          this.$router.replace({
            ...this.$route,
            query: {
              filter: filter,
            },
          });
        }
      },
    },
  },

  computed: {
    filtered() {
      let visible_releases = [...this.display_releases];

      visible_releases = visible_releases.filter(
        (r) => !r.is_released_to_master_track_list
      );

      const search = this.search;

      if (this.sort_column) {
        visible_releases = orderBy(
          visible_releases,
          (user) => user[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_releases = visible_releases.filter((release) => {
        const s =
          release.release_title +
          release.primary_artist?.name +
          release.release_main_artists?.map((a) => a.name) +
          release.release_label +
          release.release_owner_org?.name +
          release.release_upc +
          release.id;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });
      return visible_releases;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  vertical-align: middle;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}

.btn-block {
  width: 100%;
}

.progress.progress-fill {
  height: 100%;
}
</style>
