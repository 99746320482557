<template>
  <basicLayout>
    <div
      v-if="contact"
      class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">Contact Profile</h6>
          <h2 v-if="contact.name">{{ contact.name }}</h2>
          <h2 v-else>Contact</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-6">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <form
                    class="needs-validation"
                    novalidate>
                    <div class="form-group mb-3">
                      <label
                        for="release_title"
                        class="form-label"
                        >Name</label
                      >

                      <input
                        v-model="contact.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !contact.name,
                          'is-valid': contact.name,
                        }" />
                      <div class="invalid-feedback">Please enter a valid name.</div>
                    </div>

                    <div class="form-group mb-3">
                      <label
                        for="artist"
                        class="form-label"
                        >Artist name / Band name*</label
                      >
                      <div class="form-control is-valid">
                        {{ contact.artist_name }}
                      </div>
                    </div>

                    <div class="form-group mb-3">
                      <label
                        for="email"
                        class="form-label"
                        >Email</label
                      >

                      <input
                        v-model="contact.email"
                        type="email"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !contact.email,
                          'is-valid': contact.email,
                        }"
                        required />
                      <div class="invalid-feedback">Please enter a valid email address.</div>
                    </div>
                    <div
                      v-if="!show_new_org"
                      class="form-group mb-3">
                      <label class="form-label"> Org </label>
                      <div class="row">
                        <div class="col-xl-10">
                          <v-select
                            v-model="contact.org"
                            :options="display_orgs"
                            :selectOnTab="true"
                            :clearable="true"></v-select>
                        </div>
                        <div class="col-xl-2">
                          <button
                            @click="show_new_org = true"
                            class="btn btn-sm btn-outline-secondary ms-2">
                            new
                          </button>
                        </div>
                      </div>
                      <div class="invalid-feedback">Please select a valid org.</div>
                    </div>

                    <OrgCreateNew
                      v-if="show_new_org"
                      @select_new_org="select_new_org"
                      @close_new_org="close_new_org"></OrgCreateNew>

                    <div class="form-group mb-3">
                      <label
                        for="artist_spotify_id"
                        class="form-label">
                        Artist's Spotify ID (if they have one)</label
                      >

                      <input
                        v-model="contact.spotify_id"
                        name="spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': contact.spotify_id,
                        }" />
                    </div>

                    <div class="form-group mb-3">
                      <label
                        for="artist_spotify_id"
                        class="form-label">
                        Artist's Apple ID (if they have one)</label
                      >

                      <input
                        v-model="contact.apple_id"
                        name="spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': contact.spotify_id,
                        }" />
                    </div>

                    <div class="form-group mb-3">
                      <label
                        for="tier"
                        class="form-label">
                        Artist's Tier</label
                      >

                      <select
                        v-model="contact.tier"
                        class="form-select d-block w-100 m-1"
                        id="country"
                        :class="{
                          'is-invalid': submitted && !contact.tier,
                          'is-valid': contact.tier && contact.tier,
                        }">
                        <option>Tier 1</option>
                        <option>Tier 2</option>
                        <option>Tier 3</option>
                      </select>
                      <div class="invalid-feedback">Please select a tier.</div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <button
                          @click.prevent="save_contact"
                          type="submit"
                          class="btn btn-outline-secondary">
                          Save
                        </button>
                      </div>
                      <div class="col text-end">
                        <button
                          v-if="!contact.is_accepted"
                          @click.prevent="accept_contact"
                          type="submit"
                          class="btn btn-outline-success"
                          :disabled="!contact.org || !contact.org.name || !contact.tier || show_new_org">
                          Accept
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="row">
            <div class="col">
              <div
                v-if="contact.name"
                class="card border-dark">
                <div class="card-header">
                  <h4>{{ contact.name }}</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Name</div>
                        {{ contact.name }}
                      </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Artist name / Band name</div>
                        {{ contact.artist_name }}
                      </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Email</div>
                        <a
                          class="text-secondary"
                          :href="`mailto:${contact.email}`"
                          target="_blank"
                          >{{ contact.email }}</a
                        >
                      </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Mobile</div>
                        <a
                          class="text-secondary"
                          :href="`mailto:${contact.mobile}`"
                          target="_blank"
                          >{{ contact.mobile }}</a
                        >
                      </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Label (supplied by contact)</div>
                        {{ contact.supplied_label }}
                      </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Apple ID</div>
                        <a
                          :href="contact.apple_id"
                          target="_blank"
                          >{{ url_id(contact.apple_id) }}</a
                        >
                      </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Spotify ID</div>
                        <a
                          :href="contact.spotify_id"
                          target="_blank"
                          >{{ url_id(contact.spotify_id) }}</a
                        >
                      </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Country</div>
                        {{ contact.country }}
                      </div>
                    </li>

                    <li
                      v-for="(link, i) in contact.social_media_links"
                      :key="link.url"
                      class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div
                          v-if="i == 0"
                          class="fw-bold">
                          Social media
                        </div>
                        <facebook-icon
                          v-if="link_icon(link, 'facebook')"
                          size="1.2x"
                          class="me-2"></facebook-icon>
                        <instagram-icon
                          v-else-if="link_icon(link, 'instagram')"
                          size="1.2x"
                          class="me-2"></instagram-icon>
                        <youtube-icon
                          v-else-if="link_icon(link, 'youtube')"
                          size="1.2x"
                          class="me-2"></youtube-icon>
                        <link-icon
                          v-else
                          size="1.0x"
                          class="me-2"></link-icon>
                        <a
                          :href="link.url"
                          target="_blank"
                          >{{ link_hostname(link.url) }}</a
                        >
                      </div>
                    </li>
                    <li
                      v-if="contact.audio_link"
                      class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Audio link</div>

                        <audio
                          v-show="url_file_extension(contact.audio_link) == 'wav'"
                          class="m-2"
                          id="myAudio"
                          controls
                          preload="metadata"
                          :src="contact.audio_link">
                          browser not supported
                        </audio>
                        <div>
                          <link-icon
                            size="1.0x"
                            class="me-2"></link-icon>
                          <a
                            :href="contact.audio_link"
                            :title="contact.audio_link"
                            target="_blank"
                            >{{ link_hostname(contact.audio_link) }}</a
                          >
                        </div>
                      </div>
                    </li>
                    <li
                      v-if="contact.audio_file_url"
                      class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Audio upload</div>
                        <div class="m-2">
                          <audio controls>
                            <source
                              :src="contact.audio_file_url"
                              type="audio/wav"
                              preload="auto" />
                          </audio>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Notes</div>
                        {{ contact.notes }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import OrgCreateNew from "@/components/OrgCreateNew";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import { actions } from "@/store";
import { orderBy } from "lodash";
import { FacebookIcon, InstagramIcon, YoutubeIcon, LinkIcon } from "vue-feather-icons";

export default {
  name: "contactProfile",
  data() {
    return {
      contact: { org: { name: "", label: "" } },
      orgs: [{ name: "", label: "", royalties_name_code: "" }],
      show_new_org: false,
      submitted: false,
    };
  },
  firestore() {
    return {
      contact: db.collection("contacts").doc(this.$route.params.contact_id),
      orgs: db.collection("orgs").orderBy("name"),
    };
  },
  components: {
    basicLayout,
    OrgCreateNew,
    ConfirmDialog,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
  },

  methods: {
    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },
    url_file_extension(url) {
      console.log(url);
      try {
        let new_url = url && new URL(url);
        let filename = new_url && new_url.pathname.split("/").reverse()[0];
        let ext = (filename && filename.split(".")[1]) || "";
        return ext;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    url_id(url) {
      console.log(url);
      try {
        let new_url = url && new URL(url);
        let id = new_url && new_url.pathname.split("/").reverse()[0];
        return id;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    save_contact: async function () {
      console.log(this.contact);

      if (this.show_new_org || this.contact.org.id === "new_org_id") {
        this.$toast.warning("Please select a valid organisation", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      db.collection("contacts").doc(this.contact.id).set(this.contact);
      this.$router.push("/contacts");
    },

    accept_contact: async function () {
      let confirm_accept = await this.$refs.confirmDialog.show({
        confirm_title: "Accept Contact",
        confirm_message: "Are you sure you want to accept this contact? This action cannot be undone.",
        confirm_ok_button: "Accept",
      });

      console.log("confirm_accept: " + confirm_accept);

      if (!confirm_accept) return;

      actions.setLoading(true);
      const contact = this.contact;
      let new_artist_res = await this.create_artist();

      console.log(new_artist_res);

      await db
        .collection("contacts")
        .doc(contact.id)
        .update({ ...contact, is_accepted: true, date_accepted: new Date(), new_artist_id: new_artist_res.id });

      // CREATE APPROVED_CONTACTS RECORD
      // WE CHECK AGAINST THIS WHEN CREATING USER FROM CONTACT

      await db.collection("approved_contacts").doc(contact.id).set({
        name: contact.name,
        date_created: new Date(),
      });

      const contact_accept_email = fb.httpsCallable("contact_accept_email");
      const res = await contact_accept_email(this.contact.id);
      console.log(res.data);
      this.$toast.success("Contact accepted", {
        position: "top-right",
        timeout: 5000,
      });
      actions.setLoading(false);
      this.$router.push("/contacts?filter=accepted");
    },

    create_artist: async function () {
      const contact = this.contact;

      let new_artist_object = {
        name: contact.artist_name || "",
        social_media_links: contact.social_media_links || "",
        orgs: [contact.org] || [],
        org_ids: [contact.org.id] || [],
        apple_id: contact.apple_id || "",
        spotify_id: contact.spotify_id || "",
        tier: contact.tier || "",
        date_created: new Date() || "",
        created_by: "from contact",
        fuga_id: "",
        is_active: true,
      };

      console.log("new_artist_object: " + JSON.stringify(new_artist_object));

      // CREATE ARTIST STUB
      let new_artist = await db.collection("artists").add(new_artist_object);

      console.log("new_artist: " + JSON.stringify(new_artist));
      return new_artist;
    },

    select_new_org(org_id) {
      console.log(org_id);
      let new_org_select = this.orgs.find((o) => o.id === org_id);

      let display_org = {
        id: new_org_select.id,
        label: `${new_org_select.name} (${new_org_select.royalties_name_code})`,
        name: new_org_select.name,
        description: new_org_select.description || "",
        royalties_name_code: new_org_select.royalties_name_code,
      };
      this.contact.org = display_org;
      this.show_new_org = false;
    },

    close_new_org() {
      this.show_new_org = false;
      this.contact.org = {};
    },
  },

  computed: {
    display_orgs: function () {
      let orgs = this.orgs.slice();

      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description || "",
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },
  },

  watch: {
    "contact.org"(selected_org) {
      if (selected_org && selected_org.id === "new_org_id") {
        this.show_new_org = true;
        selected_org = {};
      } else {
        this.show_new_org = false;
      }
    },
    deep: true,
  },
};
</script>
