<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2 v-if="isAdmin">Master Track List</h2>
          <h2 v-else>My Releases</h2>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Release Title, Track Title, Artist, Owner, UPC, ISRC ..." />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
      <!-- <button @click="download_mtl"></button> -->

      <div class="row m-1 mb-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-8">
                  <p>
                    <span class="h5">Releases</span>&nbsp;<span class="small text-muted"
                      ><i>click on a release to see all associated tracks below.</i>
                    </span>
                  </p>
                </div>
                <div class="col-xl-4 text-end">
                  <span v-if="hits.nbHits">{{ hits.nbHits | number }} results</span>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <table class="table table-sm table-hover border-top">
                    <thead class="table-dark sticky-top">
                      <tr>
                        <th
                          @click="sort_by('release_upc')"
                          role="button"
                          class="w-10">
                          UPC
                          <span v-if="sort_column === 'release_upc' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_upc' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>

                        <!-- <th
                          @click="sort_by('track_isrc')"
                          role="button"
                          class="w-10">
                          ISRC
                          <span v-if="sort_column === 'track_isrc' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'track_isrc' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th> -->

                        <!-- owner -->
                        <th
                          @click="sort_by('release_owner_org')"
                          role="button"
                          class="w-10">
                          Owner
                          <span v-if="sort_column === 'release_owner_org' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_owner_org' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- title -->
                        <th
                          @click="sort_by('release_title')"
                          role="button"
                          class="w-10">
                          Release Title
                          <span v-if="sort_column === 'release_title' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_title' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- <th
                          @click="sort_by('track_title')"
                          role="button"
                          class="w-10">
                          Track Title
                          <span v-if="sort_column === 'track_title' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'track_title' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th> -->
                        <!-- main_artist -->
                        <th
                          @click="sort_by('release_main_artist')"
                          role="button"
                          class="w-10">
                          Artist
                          <span v-if="sort_column === 'release_main_artist' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_main_artist' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- release -->
                        <th
                          @click="sort_by('release_release_date')"
                          role="button"
                          class="w-15">
                          Released

                          <span v-if="sort_column === 'release_release_date' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'release_release_date' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>

                        <!-- preview -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="release in filtered"
                        @click="get_selected_release(release)"
                        :key="release.id"
                        class="align-left"
                        :class="row_class(release)">
                        <td
                          @dblclick="filter_by(release.release_upc)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_upc.value"></span>
                        </td>
                        <!-- <td><span v-html="release._highlightResult.track_isrc.value"></span></td> -->
                        <td
                          @dblclick="filter_by(release.release_owner_org)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_owner_org.value.toUpperCase()"></span>
                        </td>
                        <td
                          @dblclick="filter_by(release.release_title)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_title.value"></span>
                        </td>
                        <!-- <td @dblclick="filter_by(release.track_title)">
                          <span v-html="release._highlightResult.track_title.value"></span>
                        </td> -->
                        <td
                          @dblclick="filter_by(release.release_main_artist)"
                          class="text-truncate">
                          <span v-html="release._highlightResult.release_main_artist.value"></span>
                        </td>
                        <!-- release_date -->
                        <td>{{ release.release_release_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selected_releases.length"
        class="row m-1">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h5>
                <img
                  v-if="selected_releases[0].release_art_url"
                  :src="selected_releases[0].release_art_url"
                  :alt="selected_releases[0].release_title"
                  width="40px"
                  loading="lazy" />

                <span class="ms-3">
                  {{ selected_releases[0].release_main_artist }} - {{ selected_releases[0].release_title }} ({{
                    selected_releases[0].release_upc
                  }})</span
                >
              </h5>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-sm table-hover">
                <thead>
                  <tr>
                    <th scope="col w-5">ISRC</th>
                    <th scope="col w-5">No.</th>
                    <th scope="col w-10">Track Title</th>
                    <th scope="col w-10">Artists</th>
                    <th scope="col w-5">Featuring</th>
                    <th scope="col w-5">Released</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="release in selected_releases"
                    :key="release.track_isrc">
                    <td>{{ release.track_isrc }}</td>
                    <td>{{ release.track_number }}</td>
                    <td>
                      {{ release.track_title }} <span v-if="release.track_subtitle">{{ release.track_subtitle }}</span>
                    </td>
                    <td>{{ release.track_main_artists }}</td>
                    <td>{{ release.track_featured_artists }}</td>
                    <td>{{ release.release_release_date }}</td>
                    <td
                      scope="col "
                      class="text-center w-15">
                      <audio
                        v-if="release.track_audio_url"
                        height="12"
                        controls>
                        <source
                          :src="release.track_audio_url"
                          type="audio/wav"
                          preload="auto" />
                      </audio>
                      <button
                        v-else
                        @click="download_flac(release)"
                        class="btn btn-sm btn-outline-success">
                        <download-icon
                          size="1.0x"
                          class="text-secondary"></download-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>

      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
// import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import { fb, db, storage } from "@/firebaseConfig";
import algoliasearch from "algoliasearch";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import {
  EditIcon,
  EyeIcon,
  TrashIcon,
  ShuffleIcon,
  ImageIcon,
  MusicIcon,
  CopyIcon,
  DownloadIcon,
  CodeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MoreHorizontalIcon,
  ThumbsUpIcon,
  ExternalLinkIcon,
  StarIcon,
  ArchiveIcon,
  InfoIcon,
} from "vue-feather-icons";

const releases = db.collection("master_track_list");

export default {
  name: "my_release_submissions",

  data() {
    return {
      selected_line_id: "",
      selected_releases: [],
      search: this.$route.query.search || "",
      debounce: null,
      sort_column: ["track_number"],
      sort_order: "asc",
      filters: "",
      selected_page: 1,
      ALGOLIA_APP_ID: "LC1Z0QAV0G",
      searchClient: null,
      index: {},
      hits: {},
    };
  },

  components: {
    basicLayout,
    Pagination,
    EditIcon,
    EyeIcon,
    TrashIcon,
    ShuffleIcon,
    ImageIcon,
    MusicIcon,
    CodeIcon,
    CopyIcon,
    DownloadIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    MoreHorizontalIcon,
    ThumbsUpIcon,
    ExternalLinkIcon,
    StarIcon,
    ArchiveIcon,
    InfoIcon,
  },
  mixins: [filter_mixins, sort_mixins],

  methods: {
    get_tracks: async function (profile) {
      const generate_algolia_api_key = fb.httpsCallable("generate_algolia_api_key");
      const public_key_res = await generate_algolia_api_key(profile);
      const public_key = public_key_res.data.public_key;
      this.searchClient = algoliasearch("LC1Z0QAV0G", public_key);
      this.index = this.searchClient.initIndex("master_track_list");
      this.hits = await this.index.search(this.search);
    },

    row_class(selected_line) {
      return { "alert alert-secondary": this.selected_line_id === selected_line.track_isrc };
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 500);
    },

    clear_search() {
      this.search = "";
      this.selected_releases = [];
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
    updatePage(value) {
      this.selected_page = value;
    },

    get_selected_release(release) {
      console.log(release);
      this.selected_line_id = release.track_isrc;
      this.$bind("selected_releases", releases.where("release_upc", "==", release.release_upc).orderBy("track_number"));
    },

    download_flac: async function (release) {
      console.log(release);

      const storageRef = storage.ref();
      const mtlRef = storageRef.child(
        `mtl_audio_tracks/47_${release.release_upc}_01_${release.track_number.toString().padStart(3, "0")}.flac`
      );
      const download_url = await mtlRef.getDownloadURL();
      console.log(download_url);

      // let res = await fetch(download_url);
      release.track_audio_url = download_url;
    },

    download_release: async function (release) {
      let download_release = this.display_tracks
        .filter((track) => (track.release_id = release.id))
        .map((track) => {
          let release_track = { ...track };
          release_track.track_main_artists = track.track_main_artists.map((artist) => artist.name).join("; ");
          release_track.track_featured_artists = track.track_featured_artists.map((artist) => artist.name).join("; ");
          release_track.track_remix_artists = track.track_remix_artists.map((artist) => artist.name).join("; ");
          release_track.track_lyricists = track.track_lyricists.map((lyricist) => lyricist.name).join("; ");
          release_track.track_composers = track.track_composers.map((composer) => composer.name).join("; ");
          release_track.track_producers = track.track_producers.map((producer) => producer.name).join("; ");
          return { ...release, ...release_track };
        });

      const csv = Papa.unparse({
        fields: [
          "release_title",
          "release_main_artist",
          "release_label",
          "release_genre",
          "release_subgenre",
          "release_parental_advisory",
          "release_release_date",
          "release_original_release_date",
          "release_production_year",
          "release_production_owner",
          "release_copyright_owner",
          "release_upc",
          "release_art_url",
          "track_isrc",
          "track_title",
          "track_subtitle",
          "track_version_title",
          "track_title_language",
          "track_main_artists",
          "track_featured_artists",
          "track_remix_artists",
          "track_composers",
          "track_lyricists",
          "track_producers",
          "track_producers",
          "track_genre",
          "track_type",
          "track_lyric_language",
          "track_parental_advisory",
          "track_price_tier",
          "track_audio_url",
          "is_submitted_to_drm",
          "is_approved_by_drm",
          "is_submitted_to_believe",
          "is_approved_by_believe",
          "is_submitted_to_youtube",
          "is_approved_by_youtube",
        ],
        data: download_release,
        quotes: true,
      });
      const csv_name = `${release.id}.csv`;

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
  },

  watch: {
    page: {
      handler(page, old_page) {
        if (page !== old_page) {
          this.selected_releases = [];
        }
      },
    },

    search: {
      handler(search, old_search) {
        if (search !== old_search) {
          this.selected_page = 1;
          this.selected_releases = [];
        }
      },
      immediate: true,
    },

    search_query: {
      handler: async function (query, old_query) {
        this.hits = await this.index.search(query.search, {
          page: query.page,
          filters: query.filters,
        });
      },
    },

    userProfile: {
      immediate: true,
      handler(profile) {
        this.get_tracks(profile);
      },
    },
  },

  computed: {
    filtered() {
      let visible_releases = this.hits.hits;

      // visible_releases = visible_releases.map((r) => {
      //   r.track_number = Number(r.track_number);
      //   return r;
      // });

      if (this.sort_column) {
        visible_releases = orderBy(visible_releases, this.sort_column || "", this.sort_order);
      }

      return visible_releases;
    },

    pages() {
      return this.hits.nbPages;
    },

    search_query() {
      return {
        search: this.search,
        page: this.selected_page - 1,
        filters: this.filters,
      };
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role === "superadmin";
    },
  },
};
</script>
<style >
.table > tbody > tr > td {
  vertical-align: middle;
}

td em {
  font-style: normal;
  font-weight: bold;
  background-color: #ceece5;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}
.btn-block {
  width: 100%;
}

audio {
  height: 32px;
}
</style>
