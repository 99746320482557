<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Admin</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Upload Record</div>
            <div class="card-body">
              <div class="form-group mb-3">
                <label for="type">Record Type</label>

                <select
                  v-model="record_type"
                  class="form-select d-block w-100 m-1"
                  id="country"
                >
                  <option value="org">Orgs</option>
                  <option value="artist">Artists</option>
                  <option value="artist_identifiers">Artist Identifiers</option>
                  <option value="user">Users</option>
                  <option value="people">People</option>

                  <option value="tracks">Tracks</option>
                </select>
                <div class="invalid-feedback">Please select a record type.</div>
              </div>

              <div v-if="record_type" class="row">
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    type="file"
                    @change.prevent="file_picker"
                    accept=".csv, .json"
                  />
                </div>

                <div class="col" v-cloak @drop.prevent="drag" @dragover.prevent>
                  <input
                    class="form-control form-control-sm progress-bar-striped alert-dark"
                    placeholder="...or drag file here"
                    accept=".csv, .json"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div
                  v-if="upload_progress > 0 && upload_progress < 99"
                  class="col mt-3"
                >
                  <div class="progress">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      role="progressbar"
                      :style="{ width: upload_progress + '%' }"
                      :key="upload_progress"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Export MTL</div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-6">
                  <label for="basic-url" class="form-label"
                    >Release Date Range</label
                  >
                  <div class="input-group mb-3">
                    <input
                      v-model="mtl_start_date"
                      type="date"
                      class="form-control"
                      placeholder="From Release Date"
                      aria-label="From Release Date"
                    />
                    <span class="input-group-text">-</span>
                    <input
                      v-model="mtl_end_date"
                      type="date"
                      class="form-control"
                      placeholder="To Release Date"
                      aria-label="To Release Date"
                    />
                  </div>

                  <button
                    @click="mtl_download"
                    class="btn btn-outline-success"
                    :disabled="mtl_downloading"
                  >
                    Download
                    <download-icon
                      v-if="!mtl_downloading"
                      size="1.2x"
                      class="ms-2"
                    ></download-icon>
                    <div
                      v-show="mtl_downloading"
                      class="spinner-border spinner-border-sm text-success ms-2"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">More Admin</div>
            <div class="card-body">
              <button @click="add_to_algolia" class="btn btn-outline-success">
                Algolia
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { storage, fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { DownloadIcon, LoaderIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      record_type: "",
      resultURL: "",
      upload_progress: 0,
      email_template: "",
      mtl_start_date: "",
      mtl_end_date: "",
      mtl_downloading: false,
      mtl_downloaded: false,
    };
  },

  components: {
    basicLayout,
    DownloadIcon,
    LoaderIcon,
  },

  methods: {
    drag(event) {
      const file = event.dataTransfer.files[0];
      console.log(file.type);
      this.upload(file);
      event.target.value = "";
    },
    file_picker: async function (event) {
      console.log("file picker");
      const file = event.target.files[0];
      console.log(file.name);
      this.upload(file);
    },

    upload: async function (file) {
      const allowedExtensions = /(\.csv|\.json)$/i;

      if (!allowedExtensions.exec(file.name)) {
        this.$toast.warning("Please upload a CSV file.", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      const upload_url = await this.uploadTaskPromise(file);
    },

    uploadTaskPromise: async function (file) {
      const storageRef = storage.ref();
      const wavRef = storageRef.child(`record_uploads/${file.name}`);
      const metadata = {
        record_type: this.record_type,
      };
      console.log(metadata);
      const uploadTask = wavRef.put(file, { customMetadata: metadata });
      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        this.upload_progress = progress;
      });
      return uploadTask.then((snapshot) => {
        console.log("Upload is complete");
        return snapshot.ref.getDownloadURL();
      });
    },

    // mtl_download: async function () {
    //   this.mtl_downloading = true;
    //   const mtl_download = fb.httpsCallable("mtl_download");

    //   const mtl_search_object = {
    //     start_date: this.mtl_start_date,
    //     end_date: this.mtl_end_date,
    //   };

    //   console.log(mtl_search_object);

    //   const download_res = await mtl_download(mtl_search_object);
    //   console.log(download_res.data);

    //   if (download_res.data.error) {
    //     this.$toast.warning("MTL download error" + download_res.data.error, {
    //       position: "top-right",
    //       timeout: 5000,
    //     });
    //     this.mtl_downloading = false;
    //     return false;
    //   }

    //   const storageRef = storage.ref();
    //   const mtlRef = storageRef.child(`mtl_downloads/mtl_download.csv`);
    //   const download_url = await mtlRef.getDownloadURL();

    //   let res = await fetch(download_url);
    //   let blob = await res.blob();

    //   const url = URL.createObjectURL(blob);
    //   const anchor = document.createElement("a");
    //   anchor.href = url;
    //   anchor.download = "mtl_download.csv";
    //   anchor.click();
    //   this.target_file = "";

    //   console.log(url);
    //   this.mtl_downloading = false;
    // },

    // email_send: async function () {
    //   const email_send = fb.httpsCallable("email_send");

    //   const email_data = {
    //     to: "alex@alexwebster.com,jody@drm.co.nz",
    //     subject: "Welcome to DRM",
    //     data: { name: "Alex Webster II" },
    //     template: this.email_template,
    //   };

    //   email_send(email_send);

    //   const email_res = await email_send(email_data);
    //   console.log(email_res.data);
    // },

    // fix_submissions: async function () {
    //   const submissionsSnapshot = await db
    //     .collection("release_submissions")
    //     .get();
    //   const submissions = submissionsSnapshot.docs.map((doc) => {
    //     const submission_data = doc.id;
    //     return submission_data;
    //   });

    //   console.table(submissions);

    //   const submission_promises = submissions.map((o) => {
    //     return db.collection("release_submissions").doc(o).update({
    //       is_released_to_master_track_list: false,
    //     });
    //   });

    //   await Promise.all(submission_promises);
    // },

    // fix_orgs: async function () {
    //   const orgsSnapshot = await db.collection("orgs").get();
    //   const orgs = orgsSnapshot.docs.map((doc) => {
    //     const org_data = doc.id;
    //     return org_data;
    //   });

    //   // console.table(orgs);

    //   const org_promises = orgs.map((o) => {
    //     return db.collection("orgs").doc(o).update({
    //       is_contract_signed: false,
    //       contracts: [],
    //     });
    //   });

    //   await Promise.all(org_promises);
    // },

    // fix_artists: async function () {
    //   const artistsSnapshot = await db.collection("artists").get();
    //   const artists = artistsSnapshot.docs.map((doc) => {
    //     const artist_data = doc.id;
    //     return artist_data;
    //   });

    //   console.table(artists);

    //   const artist_promises = artists.map((o) => {
    //     return db.collection("artists").doc(o).update({
    //       is_contract_signed: false,
    //       contracts: [],
    //     });
    //   });

    //   await Promise.all(artist_promises);
    // },

    // fix_users: async function () {
    //   const usersSnapshot = await db.collection("users").get();
    //   const users = usersSnapshot.docs.map((doc) => {
    //     const user_data = doc.id;
    //     return user_data;
    //   });

    //   const user_promises = users.map((o) => {
    //     return db.collection("users").doc(o).update({
    //       is_contract_signed: false,
    //     });
    //   });

    //   await Promise.all(user_promises);
    // },

    // fix_mtl: async function () {
    //   const force_mtl_update = fb.httpsCallable("force_mtl_update");
    //   const mtl_res = await force_mtl_update();
    //   console.log(mtl_res.data);
    // },

    // add_to_algolia: async function () {
    //   const add_to_algolia = fb.httpsCallable("add_to_algolia");

    //   const algolia_data = {
    //     table: "people",
    //   };

    //   const email_res = await add_to_algolia(algolia_data);
    //   console.log(email_res.data);
    // },
  },
};
</script>

<style scoped>
textarea {
  border: 1px solid #999999;
  width: 100%;
  margin: 5px 0;
  padding: 3px;
}
.my-clipper {
  width: 100%;
  max-width: 700px;
}

.placeholder {
  text-align: center;
  padding: 20px;
  height: 300px;
  background-color: rgba(0, 123, 255, 0.11);
}
</style>
