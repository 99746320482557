
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

const config = {
  apiKey: 'AIzaSyB7rBxC5qQRXzpP4oNIfC8m91AE2g5B_rY',
  authDomain: 'drm-nz-portal.firebaseapp.com',
  projectId: 'drm-nz-portal',
  storageBucket: 'drm-nz-portal.appspot.com',
  // royaltiesBucket: 'drm_royalties',
  messagingSenderId: '726322842213',
  appId: '1:726322842213:web:7371baeb4953be6785548c',
  measurementId: 'G-ZDWDVQX51S'
}

firebase.initializeApp(config)

const db = firebase.firestore()
const fb = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage()
const royalties_storage = firebase.app().storage("gs://drm-nz-portal-royalties")
const currentUser = auth.currentUser

export {
  firebase,
  db,
  fb,
  auth,
  storage,
  royalties_storage,
  currentUser
}
