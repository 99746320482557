<template>
  <basicLayout>
    <div class="container-fluid">
      <!-- <div v-if="!Object.keys(new_release_record).length" class="row m-1"> -->
      <div class="row m-1">
        <div class="col-xl-6">
          <h2>Metadata Upload</h2>
          <p class="lead">
            Please upload a csv of the release and tracks metadata.
            <br />
            use the template provided and be sure to include all required fields <br />Once you have uploaded the
            metadata, you can upload multiple music (.wav) files.
          </p>
        </div>
        <div class="col-xl-6 d-flex align-items-center justify-content-center">
          <div class="">
            <a
              href="https://firebasestorage.googleapis.com/v0/b/drm-nz-portal.appspot.com/o/upload_templates%2Fdrm_good.csv?alt=media&token=2bf9a08b-4f41-4a37-acd9-1f85ffdee038"
              >TEMPLATE</a
            >
          </div>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-l-6">
          <div class="row">
            <div class="col-xl-6 mt-2 mt-xl-0">
              <input
                @change.prevent="file_picker"
                class="form-control"
                type="file"
                ref="fileupload"
                accept=".csv" />
            </div>

            <div
              class="col-xl-6 mt-2 mt-xl-0"
              v-cloak
              @drop.prevent="drag"
              @dragover.prevent
              @dragenter.prevent="dragging_toggle"
              @dragleave.prevent="dragging_toggle">
              <input
                class="form-control progress-bar-striped"
                :class="{
                  'alert-dark': !dragging,
                  'alert-success': dragging,
                }"
                placeholder="...or drag file here"
                accept=".csv" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="new_release_record.release_title"
        class="row m-1 mt-3">
        <div class="col-xl-6">
          <div class="card">
            <h5 class="card-header">Owner (Org)</h5>
            <div class="card-body">
              <div class="mb-3">
                <label for="release_owner_org">Owner (Org)</label>

                <v-select
                  v-model="new_release_record.release_owner_org"
                  :options="display_orgs"
                  :selectOnTab="true"
                  :clearable="true"></v-select>
                <div class="invalid-feedback">Please enter org</div>
              </div>
              <div class="mb-3">
                <label for="release_main_artist">Artist / Group / Band</label>

                <v-select
                  v-model="new_release_record.release_main_artist"
                  :options="display_org_artists"
                  :selectOnTab="true"
                  :clearable="true"></v-select>

                <!-- <select
                  v-model="new_release_record.release_main_artist"
                  class="form-select d-block w-100"
                  id="release_main_artist"
                  :class="{
                    'is-invalid': is_incomplete && !new_release_record.release_main_artist,
                    'is-valid': new_release_record.release_main_artist,
                  }"
                  required>
                  <option
                    v-for="artist in org_artists"
                    :key="artist.id"
                    :value="{ id: artist.id, ...artist }">
                    {{ artist.name }}
                  </option>
                </select> -->
                <div class="invalid-feedback">Please enter artist</div>
              </div>

              <div class="mb-3">
                <label for="release_genre">Genre</label>

                <v-select
                  v-model="new_release_record.release_genre"
                  :options="display_genres"
                  :selectOnTab="true"
                  :clearable="true"></v-select>
                <div class="invalid-feedback">Please enter valid genre</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="new_release_record.release_title">
        <div class="row m-1 mt-3">
          <div class="col">
            <div class="card">
              <h5 class="card-header">
                {{ new_release_record.release_title }} -
                {{ new_release_record.release_main_artist.name }}
              </h5>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="table-responsive">
                      <table class="table table-bordered table-hover table-sm">
                        <thead>
                          <tr>
                            <th>release_title</th>
                            <th>release_main_artist</th>
                            <th>release_label</th>
                            <th>release_genre</th>
                            <th>release_parental_advisory</th>
                            <th>release_release_date</th>
                            <th>release_original_release_date</th>
                            <th>release_production_year</th>
                            <th>release_production_owner</th>
                            <th>release_copyright_owner</th>
                            <th>release_upc</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_title'),
                              }">
                              {{ new_release_record.release_title }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_main_artist'),
                              }">
                              {{ new_release_record.release_main_artist.name }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_label'),
                              }">
                              {{ new_release_record.release_label }}
                            </td>

                            <!-- <td>
                              {{ new_release_record.release_genre.genre }} |
                              {{ new_release_record.release_genre.subgenre }}
                            </td> -->

                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_parental_advisory'),
                              }">
                              {{ new_release_record.release_parental_advisory }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_release_date'),
                              }">
                              {{ new_release_record.release_release_date }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_original_release_date'),
                              }">
                              {{ new_release_record.release_original_release_date }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_production_year'),
                              }">
                              {{ new_release_record.release_production_year }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_production_owner'),
                              }">
                              {{ new_release_record.release_production_owner }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_copyright_owner'),
                              }">
                              {{ new_release_record.release_copyright_owner }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_upc'),
                              }">
                              {{ new_release_record.release_upc }}
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-1 mt-2">
          <div class="col">
            <div class="card">
              <h5 class="card-header">Tracks</h5>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="table-responsive">
                      <table class="table table-bordered table-hover table-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>title</th>
                            <th>isrc</th>
                            <th>subtitle</th>
                            <th>version_title</th>
                            <th>title_language</th>
                            <th>main_artists</th>
                            <th>featured_artists</th>
                            <th>remix_artists</th>
                            <th>composers</th>
                            <th>lyricists</th>
                            <th>producers</th>
                            <th>genre</th>
                            <th>type</th>
                            <th>lyric_language</th>
                            <th>parental_advisory</th>
                            <th>price_tier</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="track in new_track_records"
                            :key="track.track_number">
                            <td>{{ track.track_number }}</td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_title,
                              }">
                              {{ track.track_title }}
                            </td>
                            <td>{{ track.track_isrc }}</td>
                            <td>{{ track.track_subtitle }}</td>
                            <td>{{ track.track_version_title }}</td>
                            <td>{{ track.track_title_language }}</td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && !track.track_main_artists && !track.track_main_artists[0].name,
                              }">
                              {{ track.track_main_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_featured_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_remix_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_remix_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_composers.map((composer) => composer.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_lyricists.map((lyricist) => lyricist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_producers.map((producer) => producer.name).join(", ") }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_genre,
                              }">
                              {{ new_release_record.release_genre.genre }} |
                              {{ new_release_record.release_genre.subgenre }}
                            </td>
                            <td>{{ track.track_type }}</td>

                            <td>{{ track.track_lyric_language }}</td>

                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_parental_advisory,
                              }">
                              {{ track.track_parental_advisory }}
                            </td>
                            <td>{{ track.track_price_tier }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-1">
          <div class="col">
            <button
              @click="upload_data"
              class="btn btn-sm btn-outline-secondary"
              type="button">
              Upload Data
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import Papa from "papaparse";
import moment from "moment";
import { orderBy } from "lodash";
import { db } from "@/firebaseConfig";
import Genres from "../assets/GenresBelieve.json";
// import csv from "raw-loader!../assets/drm.csv";

const orgs = db.collection("orgs");
const artists = db.collection("artists");

export default {
  name: "BulkMetaDataUpload",

  data() {
    return {
      genres: Genres,
      rows: [],
      new_release_record: {
        release_owner_org: { id: "", name: "", label: "" },
        release_main_artist: { id: "", name: "", label: "" },
      },
      new_track_records: [],
      is_incomplete: false,
      required_release: [
        "release_owner_org",
        "release_title",
        "release_main_artist",
        "release_label",
        "release_genre",
        "release_subgenre",
        "release_parental_advisory",
        "release_production_year",
        "release_original_production_year",
        "release_production_owner",
        "release_copyright_owner",
        "release_release_date",
      ],
      required_track: [
        "track_title",
        "track_number",
        "track_version_title",
        "track_title",
        "track_main_artists",
        "track_composers",
        "track_copyright_owner",
        "track_type",
        "track_lyric_language",
        "track_title_language",
        "track_parental_advisory",
        "track_release_date",
      ],
      missing_fields_release: [],
      visible_orgs: [{ name: "", label: "" }],
      org_artists: [{ name: "", label: "" }],
      dragging: false,
    };
  },

  components: { basicLayout },

  created() {
    if (this.isAdmin) {
      this.$bind("visible_orgs", orgs);
    } else {
      let query = orgs.where(
        "__name__",
        "in",
        this.userProfile.orgs.map((org) => org.id)
      );

      this.$bind("visible_orgs", query);
    }
  },
  methods: {
    dragging_toggle() {
      this.dragging = !this.dragging;
    },

    drag(event) {
      this.upload_csv(event.dataTransfer.files[0]);
      event.target.value = "";
      this.dragging_toggle();
    },
    file_picker(event) {
      this.upload_csv(event.target.files[0]);
    },

    upload_csv(file) {
      const pre_load_data = this.pre_load_data;
      Papa.parse(file, {
        header: true,
        transformHeader: function (h) {
          return h.replace(/[ ]*?\([^)]*\)|[ ]*?\[[^\]]*\]/g, "");
        },
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          pre_load_data(results.data);
        },
      });
    },

    pre_load_data: async function (data) {
      // console.log("PRE-LOAD DATA");
      this.is_incomplete = false; // reset
      const first_line = data[0];

      const release_release_date =
        moment(first_line.release_release_date, ["DD/MM/YYYYY"]).format("YYYY-MM-DD") || "[REQUIRED]";

      const release_original_release_date =
        moment(first_line.release_original_release_date, ["DD/MM/YYYYY"]).format("YYYY-MM-DD") || "[REQUIRED]";

      const release_parental_advisory =
        first_line.release_parental_advisory === "yes" ? "Explicit" : "NotExplicit" || "[REQUIRED]";

      const release_territories_included = ["Worldwide"];

      const new_release_record = {
        release_owner_org: (!this.isAdmin && this.userProfile.orgs[0]) || "",
        release_title: first_line.release_title || "[REQUIRED]",
        release_main_artist: "[REQUIRED]", // first_line.release_main_artist || "[REQUIRED]",
        release_production_owner: first_line.release_production_owner || "[REQUIRED]",
        release_copyright_owner: first_line.release_copyright_owner || "[REQUIRED]",
        release_genre: {}, //first_line.release_genre || {},
        release_production_year: first_line.release_production_year || "[REQUIRED]",
        release_label: first_line.release_label || "[REQUIRED]",
        release_price_tier: first_line.release_price_tier || "Front",
        release_territories_included,
        release_territories_excluded: [],
        release_upc: first_line.release_upc || "",
        release_release_date,
        release_original_release_date,
        release_parental_advisory,
      };
      this.new_release_record = new_release_record;

      const new_tracks_promises = data.map((track) => {
        const track_main_artists = (track.track_main_artists &&
          track.track_main_artists.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_featured_artists = (track.track_featured_artists &&
          track.track_featured_artists.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_remix_artists = (track.track_remix_artists &&
          track.track_remix_artists.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_lyricists = (track.track_lyricists &&
          track.track_lyricists.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_composers = (track.track_composers &&
          track.track_composers.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_producers = (track.track_producers &&
          track.track_producers.split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_parental_advisory = track.track_parental_advisory === "yes" ? "Explicit" : "NotExplicit";

        const new_track = {
          track_number: track.track_number,
          track_title: track.track_title,
          track_main_artists,
          track_featured_artists,
          track_remix_artists,
          track_lyricists,
          track_composers,
          track_producers,
          track_isrc: track.track_isrc || "",
          track_delivery_territories: ["Worldwide"],
          track_genre: first_line.release_genre || {},
          track_type: track.track_type || "Original",
          track_lyric_language: "en",
          track_title_language: "en",
          track_parental_advisory,
          track_price_tier: track.track_price_tier || "Front",
        };
        return new_track;
      });

      const new_tracks = await Promise.all(new_tracks_promises);

      this.new_track_records = new_tracks;

      let is_complete_release = this.is_complete_release();
      let is_complete_tracks = this.is_complete_tracks();

      let is_complete = is_complete_release && is_complete_tracks;

      if (!is_complete) {
        this.is_incomplete = true;
        this.$refs.fileupload.value = null;
        this.$toast.warning("You are missing some required information.  Please amend your csv and re-upload.", {
          position: "top-right",
          timeout: 5000,
        });
      }
    },

    is_complete_release: function () {
      const release = this.new_release_record;
      let is_complete;
      const entries = Object.entries(release);
      // console.log("ENTRIES: " + entries);
      const missing_fields = entries
        .filter(([k, v]) => {
          return k && (v === undefined || v === null || v === "" || v === [] || v === "[REQUIRED]");
        })
        .map(([k]) => k);

      // console.log(missing_fields);

      is_complete = !missing_fields.some((missing_field) => {
        return this.required_release.includes(missing_field);
      });

      this.missing_fields_release = missing_fields;

      // console.log("IS RELEASE DATA COMPLETE?: " + is_complete);

      return is_complete;
    },

    is_complete_tracks: function () {
      // console.log("CHECKING TRACKS ARE COMPLETE");
      const tracks = this.new_track_records;

      const all_complete = tracks
        .map((track) => {
          const entries = Object.entries(track);
          const missing_fields = entries
            .filter(([k, v]) => {
              return k && (v === undefined || v === null || v === "" || v === []);
            })
            .map(([k]) => k);

          // console.log(missing_fields);

          const is_complete = !missing_fields.some((missing_field) => {
            return this.required_track.includes(missing_field);
          });
          // console.log("IS TRACK COMPLETE?: " + is_complete);

          return is_complete;
        })
        .every((track) => track === true);

      // console.log("IS EVERY TRACK COMPLETE?: " + all_complete);

      return all_complete;
    },

    upload_data: async function () {
      const new_release = this.new_release_record;

      const created_by_profile = {
        id: this.userProfile.id,
        name: userProfile.name || "",
        email: this.userProfile.email || "",
        mobile: this.userProfile.mobile || "",
      };

      new_release.release_created = new Date();
      new_release.release_created_by = created_by_profile;
      new_release.is_new = false;
      new_release.is_incomplete = false;
      new_release.is_submitted_to_drm = false;
      new_release.is_approved_by_drm = false;
      new_release.is_uploaded_pending_approval = false;
      new_release.is_submitted_to_youtube = false;
      new_release.is_approved_by_youtube = false;
      new_release.is_submitted_to_believe = false;
      new_release.is_approved_by_believe = false;

      const new_release_record = await db.collection("release_submissions").add(new_release);

      // console.log(new_release_record);

      const new_release_id = new_release_record.id;

      const new_tracks_promises =
        this.new_track_records.length &&
        this.new_track_records.map((new_track) => {
          new_track.track_owner_org = new_release.release_owner_org;
          new_track.release_id = new_release_id;
          new_track.new_track_upload_progress = 0;
          new_track.is_new = true;
          new_track.is_incomplete = false;
          new_track.track_upload_progress = 0;
          return db.collection("release_submissions_tracks").add(new_track);
        });

      await Promise.all(new_tracks_promises);

      this.$toast.success("Metadata uploaded", {
        position: "top-right",
        timeout: 5000,
      });
      if (this.new_track_records.length) {
        this.$router.push({
          name: "ReleaseBulkAudioUpload",
          params: { release_id: new_release_id },
        });
      } else {
        this.$router.push({
          name: "ReleaseEdit",
          params: { release_id: new_release_id },
        });
      }
    },
  },

  watch: {
    "new_release_record.release_owner_org": function (owner_org) {
      if (owner_org.id) {
        this.$bind("org_artists", artists.where("org_ids", "array-contains", owner_org.id));
      }
    },
  },

  computed: {
    display_orgs: function () {
      let orgs = this.visible_orgs.slice();

      orgs = orgs.map((o) => {
        o.label = `${o.name} (${o.royalties_name_code})`;

        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },
    display_org_artists: function () {
      let org_artists = this.org_artists.slice();

      org_artists = org_artists.map((a) => {
        let display_artist = {
          id: a.id,
          label: a.name,
          name: a.name,
        };

        return display_artist;
      });
      org_artists = orderBy(org_artists, "name", "asc");
      return org_artists;
    },
    display_genres: function () {
      let genres = this.genres.slice();

      genres = genres.map((g) => {
        g.label = `${g.genre} ${g.subgenre && "|"} ${g.subgenre}`;
        return g;
      });
      genres = orderBy(genres, "genre", "asc");
      return genres;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role === "superadmin";
    },
  },
};
</script>
<style scoped>
.paste-area {
  height: 20vh;
}
.table-sm {
  font-size: 0.6rem;
}
</style>
