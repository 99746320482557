<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">User Profile</h6>
          <h2 v-if="user.name">{{ user.name }}</h2>
          <h2 v-else>User</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-8">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <form
                    class="needs-validation"
                    novalidate>
                    <div class="mb-3">
                      <label
                        for="release_title"
                        class="form-label"
                        >Name</label
                      >

                      <input
                        v-model="user.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': !user.name,
                          'is-valid': user.name,
                        }" />
                      <div class="invalid-feedback">Please enter a valid name.</div>
                    </div>

                    <div class="mb-3">
                      <label
                        for="email"
                        class="form-label"
                        >Email</label
                      >

                      <input
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        required
                        disabled />
                      <div class="invalid-feedback">Please enter a valid email address.</div>
                    </div>

                    <div class="mb-3">
                      <label
                        for="mobile"
                        class="form-label"
                        >Mobile</label
                      >

                      <input
                        v-model="user.mobile"
                        type="mobile"
                        class="form-control"
                        required />
                      <div class="invalid-feedback">Please enter a valid mobile address.</div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label"> Role </label>

                      <select
                        v-model="user.role"
                        class="form-select d-block w-100 m-1"
                        :class="{
                          'is-invalid': !user.role,
                          'is-valid': user.role,
                        }">
                        <option
                          v-for="role in roles"
                          :key="role">
                          {{ role }}
                        </option>
                      </select>
                      <div class="invalid-feedback">Please select a valid role.</div>
                    </div>

                    <div class="form-group mb-3">
                      <label class="form-label"> Add Connected Org </label>

                      <v-select
                        v-model="selected_org"
                        :options="display_orgs"
                        :selectOnTab="true"
                        :clearable="true"></v-select>

                      <div class="invalid-feedback">Please select a valid org.</div>
                    </div>

                    <div class="form-check">
                      <input
                        v-model="user.must_sign_new_contract"
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked" />
                      <label
                        class="form-check-label"
                        for="flexCheckChecked">
                        Must Sign New Contract
                      </label>
                    </div>

                    <div class="card border-success m-2">
                      <div class="card-header"><h6>CONNECTED ORGS</h6></div>
                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th
                                class="w-30"
                                scope="col">
                                Name
                              </th>
                              <th scope="col">Description</th>
                              <th
                                class="text-center w-5"
                                scope="col"></th>
                              <th
                                class="text-center w-5"
                                scope="col">
                                Remove
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="org in user.orgs"
                              :key="org.id">
                              <td>{{ org.name }}</td>
                              <td>{{ org.description }}</td>
                              <td>
                                <router-link
                                  :to="{ name: 'OrgProfile', params: { org_id: org.id } }"
                                  title="edit org">
                                  <edit-icon
                                    size="1x"
                                    class="text-success"></edit-icon>
                                </router-link>
                              </td>
                              <td class="text-center">
                                <x-circle-icon
                                  @click="remove_org(org)"
                                  role="button"
                                  size="1.0x"
                                  class="text-danger"></x-circle-icon>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <button
                          @click.prevent="save_user"
                          type="submit"
                          class="btn btn-outline-success">
                          Save
                        </button>
                      </div>
                      <div class="col text-end">
                        <button
                          @click.prevent="delete_user(user.id)"
                          type="submit"
                          class="btn btn-outline-danger">
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">
              <h4>{{ user.name }}</h4>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Name</div>
                    {{ user.name }}
                  </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Email</div>
                    <a
                      class="text-secondary"
                      :href="`mailto:${user.email}`"
                      target="_blank"
                      >{{ user.email }}</a
                    >
                  </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Created</div>
                    {{ moment_short(user.date_created) }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import moment from "moment";
// import dayjs from "dayjs";
import { orderBy } from "lodash";

// import localizedFormat from "dayjs/plugin/localizedFormat";
// dayjs.extend(localizedFormat);

import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkIcon,
  FileTextIcon,
  XCircleIcon,
  EditIcon,
} from "vue-feather-icons";

export default {
  name: "userProfile",
  data() {
    return {
      user: { orgs: [] },
      selected_org: { name: "", label: "" },
      orgs: [{ name: "" }],
      roles: ["user", "agent", "admin"],
      submitted: false,
    };
  },
  firestore() {
    return {
      user: db.collection("users").doc(this.$route.params.user_id),
      orgs: db.collection("orgs").orderBy("name"),
    };
  },
  components: {
    basicLayout,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
    FileTextIcon,
    XCircleIcon,
    EditIcon,
  },

  mounted() {
    document.addEventListener("keydown", this.ctrl_save);
  },

  destroyed() {
    document.removeEventListener("keydown", this.ctrl_save);
  },

  methods: {
    ctrl_save(e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        console.log("SAVING");
        e.preventDefault();
        e.stopPropagation();
        this.save_user();
      }
    },
    remove_org(org) {
      console.log(org);
      const new_org_list = this.user.orgs.filter((o) => o.id !== org.id);
      const new_org_ids_list = this.user.org_ids.filter((oid) => oid !== org.id);
      console.log(new_org_list);
      this.user.orgs = new_org_list;
      this.user.org_ids = new_org_ids_list;
    },
    save_user() {
      console.log(this.user.id);
      db.collection("users").doc(this.user.id).set(this.user);
      this.$router.push("/users?filter=" + this.user.role);
    },

    delete_user: async function (id) {
      // if (this.confirm_delete) {
      const deleteUser = fb.httpsCallable("deleteUser");
      let result = await deleteUser({ id: id });
      console.log(JSON.stringify(result));
      this.$router.push("/users");
      // this.confirm_delete = false
      return;
    },
    moment_short: function (date) {
      return date && moment.utc(date.toDate()).local().format("lll");
    },

    // date_short: function (date) {
    //   return this.$luxon(date);
    // },
  },

  computed: {
    display_orgs: function () {
      let orgs = this.orgs.slice();

      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },
  },

  watch: {
    selected_org(new_org) {
      if (!this.user.orgs) {
        this.user.orgs = [];
      }
      if (!this.user.org_ids) {
        this.user.org_ids = [];
      }

      this.user.orgs.push(new_org);
      this.user.org_ids.push(new_org.id);
      this.new_org = false;
      new_org = { id: "" };
    },
  },
};
</script>
