<template>
  <NavSplash>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <div
            v-if="!complete"
            class="row g-3 py-3 justify-content-center form-contact mt-2">
            <div class="text-center">
              <img
                src="../assets/logo.png"
                width="auto"
                height="90px"
                alt />
            </div>
            <div class="col-10">
              <div class="card text-center bg-dark border-light text-white">
                <div class="card-header"><h2>New music on the horizon? Send it to our team!</h2></div>
                <div class="card-body contact-message">
                  <p>
                    Every week, our team reviews new music submissions for distribution across 150 streaming platforms
                    worldwide!
                    <br />
                    If you are not currently registered with DRM and would like to submit your music for distribution,
                    please fill in the form below.
                    <br />
                    For the quickest response, please upload an audio file, otherwise include a link to your music
                    online (when you press "Next" an upload/link option will appear).
                    <br />
                    If you are an existing member of DRM and require assistance, please reach out to our support team
                    <a href="mailto:info@drm.co.nz">here</a>.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-10">
              <form
                class="needs-validation text-light"
                novalidate>
                <div v-if="!contact.id">
                  <div class="form-group mb-1">
                    <label
                      for="release_title"
                      class="form-label"
                      >Your name</label
                    >

                    <input
                      v-model="contact.name"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && !contact.name,
                        'is-valid': contact.name,
                      }" />
                    <div class="invalid-feedback">Please enter a valid name.</div>
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="release_title"
                      class="form-label"
                      >Artist name / Band name
                    </label>

                    <input
                      v-model="contact.artist_name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && !contact.artist_name,
                        'is-valid': contact.artist_name,
                      }" />
                    <div class="invalid-feedback">Please enter a valid name.</div>
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="artist_org"
                      class="form-label"
                      >Artist's label (if they have one)</label
                    >

                    <input
                      v-model="contact.supplied_label"
                      name="org"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-valid': contact.supplied_label,
                      }" />
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="artist_name"
                      class="form-label"
                      >Your email</label
                    >

                    <input
                      v-model="contact.email"
                      @input="validate_email"
                      name="email"
                      type="email"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && (!valid_email || !contact.email),
                        'is-valid': contact.email && valid_email,
                      }"
                      required />

                    <div class="invalid-feedback">Please enter a valid email address.</div>
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="artist_name"
                      class="form-label"
                      >Your mobile number</label
                    >

                    <input
                      v-model="contact.mobile"
                      name="mobile"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && !contact.mobile,
                        'is-valid': contact.mobile,
                      }"
                      required />

                    <div class="invalid-feedback">Please enter a valid mobile number.</div>
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="artist_spotify_id"
                      class="form-label">
                      <img
                        src="../assets/spotify_logo.png"
                        width="auto"
                        height="24"
                        alt /><span class="ms-2">Artist's Spotify Link (if they have one)</span></label
                    >

                    <input
                      v-model="contact.spotify_id"
                      placeholder="https://open.spotify.com/artist/xxxxxxxxxxxx"
                      name="artist_spotify_id"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-valid': contact.spotify_id,
                      }" />
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="artist_apple_id"
                      class="form-label">
                      <img
                        src="../assets/apple_logo.png"
                        width="auto"
                        height="24"
                        alt /><span class="ms-2">Artist's Apple Link (if they have one)</span></label
                    >

                    <input
                      v-model="contact.apple_id"
                      placeholder="https://music.apple.com/artist/xxxxxxxxxxxx"
                      name="artist_apple_id"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-valid': contact.apple_id,
                      }" />
                  </div>

                  <div class="form-group mb-1">
                    <label
                      for="social_media_links"
                      class="form-label">
                      Artist's website &amp; social media links</label
                    >

                    <div
                      v-for="(link, i) in contact.social_media_links"
                      :key="i">
                      <div class="row mb-1">
                        <div class="col">
                          <div class="input-group">
                            <input
                              v-model="link.url"
                              type="url"
                              class="form-control"
                              :placeholder="`link (${i + 1})`"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2" />
                            <div class="input-group-append">
                              <button
                                @click.prevent="add_line(contact.social_media_links, i)"
                                class="btn btn-outline-success"
                                type="button"
                                title="add another link">
                                +
                              </button>
                              <button
                                @click.prevent="delete_line(contact.social_media_links, i)"
                                class="btn btn-outline-danger"
                                type="button"
                                title="remove this link">
                                -
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-1">
                    <label for="notes"> Notes</label>

                    <textarea
                      v-model="contact.notes"
                      type=""
                      class="d-block w-100 m-1"
                      id="notes"
                      :class="{
                        'is-valid': contact.notes && contact.notes,
                      }">
                    </textarea>
                  </div>

                  <div
                    v-if="!contact.id"
                    class="text-end mt-3">
                    <button
                      @click.prevent="create_contact"
                      class="btn btn-light"
                      title="now submit music">
                      Next<chevrons-right-icon size="1.5x"></chevrons-right-icon>
                    </button>
                  </div>
                </div>
                <div
                  v-if="contact.id"
                  class="card bg-transparent border-white">
                  <div class="card-body">
                    <div>
                      <ContactAudioUpload
                        :contact="contact"
                        @add_audio_file_url="add_audio_file_url"
                        @audio_file_upload="audio_file_upload"></ContactAudioUpload>

                      <hr />

                      <div class="form-group mb-1">
                        <label
                          for="artist_name"
                          class="form-label"
                          >...or, if you prefer, enter a link to a music sample online.
                        </label>

                        <input
                          v-model="contact.audio_link"
                          type="url"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && !contact.audio_link && !contact.audio_file_url,
                            'is-valid': contact.audio_link,
                          }"
                          required />
                        <div class="invalid-feedback">Please enter a valid link.</div>
                      </div>

                      <div
                        v-if="contact.audio_file_url"
                        class="row text-center">
                        <div class="col">
                          <audio controls>
                            <source
                              :src="contact.audio_file_url"
                              type="audio/wav"
                              preload="auto" />
                          </audio>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="contact.audio_file_url || contact.audio_link"
                  class="text-end mt-3">
                  <button
                    @click.prevent="update_contact"
                    type="submit"
                    class="btn btn-light text-end"
                    :disabled="!contact.audio_file_url && !contact.audio_link">
                    <!-- <loader-icon
                      size="1.5x"
                      class="custom-class icon-spinner me-2"></loader-icon> -->
                    Submit
                    <chevrons-right-icon
                      size="1.5x"
                      class="pulse"></chevrons-right-icon>
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            v-if="complete"
            class="row g-5 py-5 justify-content-center form-contact mt-1">
            <div class="text-center">
              <img
                src="../assets/logo.png"
                width="auto"
                height="80px"
                alt />
            </div>
            <div class="col-10">
              <div class="card text-center bg-transparent border-light text-white">
                <div class="card-header"><h2>Thank you!</h2></div>
                <div class="card-body">
                  <p>We'll be in touch soon.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NavSplash>
</template>

<style>
</style>

<script>
// import { Spinner } from "bootstrap";
import NavSplash from "@/components/NavSplash";
import ContactAudioUpload from "@/components/ContactAudioUpload";
import { db, fb } from "@/firebaseConfig";
import { getters, actions } from "@/store";
import Countries from "../assets/Countries.json";
import { ChevronsRightIcon, LoaderIcon, UploadIcon, PlayIcon } from "vue-feather-icons";

export default {
  name: "createUser",
  components: {
    NavSplash,
    ContactAudioUpload,
    ChevronsRightIcon,
    LoaderIcon,
    UploadIcon,
    PlayIcon,
  },
  data: function () {
    return {
      contact: {
        id: "",
        name: "",
        artist_name: "",
        new_artist_id: "",
        country: "New Zealand",
        social_media_links: [{ url: "" }],
        audio_file_url: "",
      },
      countries: Countries,
      submitted: false,
      valid_email: false,
      complete: false,
      spinner: null,
      audio_uploading: false,
    };
  },

  methods: {
    add_line: function (items, i) {
      // console.log("i: " + i);

      items.splice(i + 1, 0, {});
    },
    delete_line(items, i) {
      if (items.length === 1) {
        return false;
      }
      items.splice(i, 1);
    },
    create_contact: async function () {
      actions.setLoading(true);
      let contact = this.contact;
      // console.log(this.contact);

      if (!contact.name || !contact.artist_name || !contact.email || !contact.mobile || !this.valid_email) {
        this.$toast.warning("You are missing some required information.", {
          position: "top-right",
          timeout: 5000,
        });
        this.submitted = true;
        return;
      }

      const is_existing_user = await this.is_existing_user(contact.email);

      console.log("is_existing_user: " + is_existing_user);
      if (is_existing_user) {
        this.$toast.warning("Sorry, an account already exists with this email address", {
          position: "top-right",
          timeout: 5000,
        });
        return;
      }

      const social_media_links = contact.social_media_links.filter((l) => l.url && l.url.length);

      contact.email = contact.email.toLowerCase();
      contact.social_media_links = social_media_links || [];
      contact.date_created = new Date();
      contact.is_bound_by_terms = false;
      contact.signature = "";
      contact.signed_contract = "";
      contact.is_user = false;
      contact.is_accepted = false;
      contact.is_rejected = false;
      contact.is_unrated = true;
      contact.org = {};
      contact.voters_no = [];
      contact.voters_yes = [];
      contact.voters_maybe = [];

      const new_contact = await db.collection("contacts").add(this.contact);

      // console.log(new_contact);
      this.contact.id = new_contact.id;
      this.submitted = false;
      actions.setLoading(false);

      this.$toast.success("Please upload an audio track", {
        position: "top-right",
        timeout: 5000,
      });
    },

    audio_file_upload() {
      actions.setLoading(true);
    },

    add_audio_file_url: async function (url) {
      this.contact.audio_file_url = url;

      await db.collection("contacts").doc(this.contact.id).update({
        audio_file_url: url,
      });

      actions.setLoading(false);
    },

    update_contact: async function () {
      let contact = this.contact;

      if (contact.audio_link)
        db.collection("contacts").doc(contact.id).update({
          audio_link: this.contact.audio_link,
        });
      this.submitted = true;
      this.complete = true;
      const contact_acknowledge_email = fb.httpsCallable("contact_acknowledge_email");
      const res = await contact_acknowledge_email(this.contact.id);
    },
    validate_email() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.contact.email)) {
        this.valid_email = true;
      } else {
        this.valid_email = false;
      }
    },

    is_existing_user: async function (email) {
      const check_for_existing_user = fb.httpsCallable("check_for_existing_user");
      const check_for_existing_user_res = await check_for_existing_user(email);
      const is_existing_user = check_for_existing_user_res.data.is_existing_user;
      console.log("is_existing_user: " + is_existing_user);
      return is_existing_user;
    },
  },
  computed: {
    is_loading() {
      return getters.isLoading();
    },
  },
};
</script>

<style >
.form-contact {
  width: 100%;
  padding-bottom: 85px;
  margin: auto;
  background-color: #13101e;
  opacity: 0.95;
}
.contact-message {
  font-size: 0.8em;
}
</style>
