<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col"><h6>FTP VIEWER</h6></div>
        <div class="col text-end">
          <button
            @click="ftp_directory"
            class="btn btn-outline-success">
            Refresh
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <tree-menu
        :label="release.release_believe_ddex_directory"
        :node="root_node"
        :nodes="list"
        :depth="0"></tree-menu>
    </div>
  </div>
</template>

<script>
import TreeMenu from "@/components/FTPTreeMenu";
import { fb } from "@/firebaseConfig";

export default {
  name: "edi_viewer",

  props: {
    release: Object,
  },

  components: { TreeMenu },
  data() {
    return {
      list: [],
      depth: 0,
      root_node: { name: this.release.release_upc, type: 2 },
    };
  },

  methods: {
    ftp_directory() {
      const ftp_dir_list_children = fb.httpsCallable("ftp_dir_list_children");
      const release = this.release;
      const directory = `/${release.release_believe_ddex_directory}/${release.release_upc}`;
      ftp_dir_list_children(directory).then((result) => {
        console.log(JSON.stringify(result));
        this.list = result.data.dir;
      });
    },
  },
  computed: {},
};
</script>
