<template>
  <div>
    <DashboardFirstLogin v-if="display_contract" />
    <DashboardDefault v-else />
    <!-- <div>
      {{ userProfile.role }}
      {{ display_contract }}
      {{ isAdmin }}
      {{ display_orgs.map((o) => o.is_contract_signed) }}
      {{ display_orgs.map((o) => o.is_exempt_new_contract) }}
    </div> -->
  </div>
</template>

<script>
import DashboardFirstLogin from "@/components/DashboardFirstLogin";
import DashboardDefault from "@/components/DashboardDefault";
import { state } from "@/store";
import { auth, db } from "@/firebaseConfig";

const orgs = db.collection("orgs");

export default {
  data() {
    return {
      display_orgs: [],
    };
  },
  components: {
    DashboardFirstLogin,
    DashboardDefault,
  },

  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role ==="superadmin";
    },
    display_contract() {
      const has_unsigned_orgs = this.display_orgs.some((org) => !org.is_contract_signed && !org.is_exempt_new_contract);
      const user_is_unsigned = !this.userProfile.is_contract_signed;

      return !this.isAdmin && user_is_unsigned && has_unsigned_orgs;
    },
  },

  watch: {
    "userProfile.org_ids": {
      immediate: true,
      handler(org_ids) {
        if (org_ids) {
          this.$bind("display_orgs", orgs.where("__name__", "in", org_ids));
        }
      },
    },
  },
};
</script>

<style>
</style>