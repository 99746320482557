<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">User Profile</h6>
          <h2>{{ user.name }}</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-8 col-lg-12">
          <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Name</div>
                    <div class="ms-2 me-auto">{{ user.name }}</div>
                  </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Email</div>
                    <div class="ms-2 me-auto">{{ user.email }}</div>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">My Orgs</div>
                  </div>
                </li>

                <li
                  v-for="org in display_orgs"
                  :key="org.id"
                  class="list-group-item d-flex justify-content-between align-items-start ms-3">
                  <div>{{ org.name }}</div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">My Artists</div>
                  </div>
                </li>

                <li
                  v-for="artist in display_artists"
                  :key="artist.id"
                  class="list-group-item d-flex justify-content-between align-items-start ms-3">
                  <div>{{ artist.name }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
// import { FacebookIcon, InstagramIcon, YoutubeIcon, LinkIcon, FileTextIcon, XCircleIcon } from "vue-feather-icons";

const orgs = db.collection("orgs");
const artists = db.collection("artists");

export default {
  name: "MyUserProfile",
  data() {
    return {
      user: { orgs: [] },
      display_orgs: [],
      display_artists: [],
    };
  },
  firestore() {
    return {
      user: db.collection("users").doc(this.$route.params.user_id),
    };
  },
  components: {
    basicLayout,
  },

  watch: {
    "user.org_ids": {
      immediate: true,
      handler(org_ids) {
        if (org_ids) {
          this.$bind("display_orgs", orgs.where("__name__", "in", org_ids));
          this.$bind("display_artists", artists.where("org_ids", "array-contains-any", org_ids));
        }
      },
    },
  },
};
</script>
