


<template>
  <div
    class="modal fade"
    ref="terms_modal"
    tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            @click="modal.hide()"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-start">
            <h4 class="text-center">DRM TERMS OF SERVICE</h4>

            <p>
              These DRM Terms of Service ("Terms") are legally binding between you and Digital Rights Management New
              Zealand Limited ("DRM", "we", "our", "us"). By clicking to agree to these Terms, you are accepting the
              terms and conditions below, so please read and understand them completely before doing so.
            </p>
            <h6>1. TERM AND TERRITORY</h6>
            <ol>
              <li value="1.1">
                These Terms will automatically become effective on the date you click to agree to these Terms (the
                "Effective Date") and shall continue until the 30th of November 2025 ("Initial Term"). After the Initial
                Term, these Terms will be renewed automatically for additional terms of twelve months (each an "Extended
                Term"), unless previously terminated. The Initial Term and any Extended Term shall be referred to
                collectively as the "Term".
              </li>
              <li value="1.2">
                The territory shall be Worldwide and shall cover all Services available in each country, unless
                otherwise agreed by us ("Territory").
              </li>
            </ol>
            <h6>2. GRANT OF RIGHTS</h6>
            <ol>
              <li value=" 2.1">
                You grant to us the exclusive right in the Territory during the Term to licence your Catalogue to
                Services for Digital Exploitation and to collect, administer, and distribute the proceeds from such
                licences.
              </li>
              <li value="2.2">
                You further grant to us the non-exclusive worldwide right to license the Recordings in synchronization
                with visual images. All such licenses for use in commercials, television or film productions are subject
                to your prior approval (which you will not withhold unreasonably).
              </li>
              <li value="2.3">
                We shall have the right to prepare and encode your Catalogue in any format now known or hereafter
                devised.
              </li>
            </ol>
            <h6>3. FEES</h6>
            <ol>
              <li value="3.1">
                We shall pay you seventy-five percent (75%) of Income, equivalent to a distribution fee of twenty-five
                perent (25%) unless otherwise agreed by us.
              </li>
              <li value="3.2">
                No payments shall be made for radio broadcast-like webcasts or interactive audio or audio-visual
                'streaming' transmission for the purpose of allowing consumers of any Service to preview tracks before
                they purchase or any other promotional uses.
              </li>
              <li value="3.3">
                We may charge you a storage fee of One New Zealand Dollar (NZ$1.00) per Sound Recording each calendar
                year ("Housing Fee"), if applicable.
              </li>
            </ol>
            <h6>4. DIGITAL DELIVERY</h6>
            <ol>
              <li value="4.1">
                Throughout the Term, you shall provide at your expense to us all completed, fully mixed, edited and
                equalised and mastered Recordings suitable for commercial release to the public, complete artwork and
                complete and accurate metadata, Digital Assets, all approvals and licences necessary for the sale,
                licence and/or exploitation of the Recordings, and any additional information needed for the release of
                the Recordings as reasonably required by us (together, the "Deliverables") by such date as may be
                determined by us. You hereby acknowledge that failure to provide all required Delivery Materials by the
                date notified to you by us may affect the proposed release date of the Recordings in question.
              </li>
              <li value="4.2">
                If you require non-standard encoding, formatting or subtitling, it may result in additional costs to
                you, which we may require in advance.
              </li>
              <li value="4.3">
                On satisfactory receipt of the Deliverables from you, we will deliver your Catalogue in digital file
                format to Services with whom we have entered into agreements.
              </li>
            </ol>
            <h6>5. PUBLISHING</h6>
            <ol>
              <li value="5.1">
                You are responsible for securing all copyright licences and clearances to enable us to use and reproduce
                your Catalogue in accordance with these Terms.
              </li>
              <li value="5.2">
                You assign the copyright in any Compositions not currently assigned to a third-party publisher, or that
                at any stage revert to you during the Term (subject only to any prior rights assigned to APRA) to us
                ("the Work(s)"). We shall then have the exclusive right during the Term to exploit those Works. Any use
                of the Work(s) in third party advertising or granting of synchronisation licences shall require your
                consent, such consent not to be unreasonably withheld.
              </li>
              <li value="5.3">
                If during the Term you wish to assign the Works to a third-party publisher, then you shall provide us
                with thirty (30) days' notice of your intent to do so, and those Work(s) shall revert to you at the end
                of the next July or January accounting periods (whichever is earlier) following the expiry of such
                notice period.
              </li>
              <li value="5.4">
                Wherever possible in granting licences to Services for use of your Catalogue, we will require that
                Service to bear the cost of and be responsible for mechanical copyright royalty payments. In the event
                however that any Service does not, then you shall be responsible for any such payments.
              </li>
            </ol>
            <h6>6. REPORTING</h6>
            <ol>
              <li value="6.1">
                Within forty-five (45) days of the end of the months of May, July, September, November, January and
                March we will provide you a report detailing all income received by us for you for that preceding
                period, together with a breakdown of all deductions, including any advances. Upon receipt of that
                report, you should prepare and forward to us an invoice. We will pay you on the 20th of the month
                following receipt of that invoice.
              </li>
              <li value="6.2">
                We will accrue to your account all Income, provided that no Income will be due and payable to you until
                such time as all Housing Fees have been fully recouped, if any.
              </li>
              <li value="6.3">
                If the aggregate Income is less than fifty New Zealand Dollars (NZ$50), the balance due to you will be
                rolled over until the reporting period in which an aggregate of at least fifty New Zealand Dollars
                (NZ$50) is due to you.
              </li>
              <li value="6.4">
                We are entitled to deduct any sums (such as withholding taxes) which we or our licensees or related
                entities are obliged to pay or withhold in any country by reason of any law or regulation. All payments
                to or charges to be made by us as per these Terms are expressed as exclusive of GST, which if applicable
                are payable by you in addition to the sums otherwise set out in these Terms.
              </li>
              <li value="6.5">
                At any time within twelve (12) months after an income statement is submitted to you, you shall have the
                right to appoint a certified accountant to examine our books and records relating solely to the income
                relating to your Catalogue, provided that such examination shall take place at our offices during normal
                business hours, on reasonable written notice, not more frequently than once in any calendar year and at
                your sole cost and expense. Such examination may be conducted only once with respect to a particular
                statement. We shall be entitled to rely on the accounting provided to us by the Services. Any lawsuit by
                you regarding any statement or payment must be commenced within one (1) year after the date the
                applicable statement covers. Upon the expiration of such year, you have no right to pursue any further
                legal action in connection with any such statement, in connection with the amounts received, or relating
                to the period such statement covers. You irrevocably waive any longer statute of limitations that would
                otherwise apply by law.
              </li>
            </ol>
            <h6>7. WARRANTIES BY YOU</h6>
            <ol>
              <li value="7.1">
                YOU WARRANT THAT: <br />(I) YOU ARE AT LEAST 18 YEARS OLD; <br />(II) YOU HAVE FULL POWER AND AUTHORITY
                TO ENTER INTO AND, PERFORM YOUR OBLIGATIONS AS PER THESE TERMS; <br />(III) YOU HAVE THE POWER TO GRANT
                TO US, THE LICENCES TO YOUR CATALOGUE GRANTED UNDER THESE TERMS; <br />(IV) THE EXERCISE BY US OF OUR
                LICENCES TO YOUR CATALOGUE AS PER THESE TERMS DOES NOT INFRINGE THE RIGHTS (INCLUDING INTELLECTUAL
                PROPERTY RIGHTS) OF ANY PERSON; AND <br />(V) THAT NO AGREEMENT OF ANY KIND PREVIOUSLY ENTERED INTO BY
                YOU OR ENTERED INTO BY YOU AFTER THE EFFECTIVE DATE WILL INTERFERE IN ANY MANNER WITH THE COMPLETE
                PERFORMANCE BY YOU OF THESE TERMS, OR INTERFERE WITH THE RIGHTS GRANTED BY YOU TO US HEREIN INCLUDING,
                BUT NOT LIMITED TO, THE REPRESENTATION RIGHTS TO YOUR CATALOGUE, OR ANY AGREEMENT ENTERED INTO BY US
                WITH A THIRD PARTY CONCERNING THE LICENSING AND/OR REPRESENTATION OF YOUR CATALOGUE.
              </li>
              <li value="7.2">
                IF YOU ARE USING OUR SERVICES ON BEHALF OF ONE OR MORE OTHER PEOPLE, A GROUP, OR A COMPANY OR OTHER
                ENTITY, THEN BY ACCEPTING THESE TERMS YOU REPRESENT AND WARRANT TO US THAT YOU ARE DULY AUTHORIZED TO DO
                SO ON BEHALF OF ALL SUCH PERSONS/ENTITIES AND TO BIND THEM TO THESE TERMS AND THAT DRM IS FULLY ENTITLED
                TO RELY ON THAT FACT (IN WHICH CASE, THE TERM "YOU" INCLUDES ALL SUCH PEOPLE AND ENTITIES) IN OUR
                PERFORMANCE UNDER THESE TERMS.
              </li>
              <li value="7.3">
                YOU SHALL BE SOLELY RESPONSIBLE FOR THE PAYMENT OF ANY AND ALL OF THE FOLLOWING: <br />(I) ALL FEES,
                INCOME, MONIES, ROYALTIES OR ANY OTHER COMPENSATION DUE TO ARTISTS, PRODUCERS, RECORDING COPYRIGHT
                OWNERS, AND ALL OTHER THIRD PERSONS WHOSE PERFORMANCES ARE EMBODIED IN A RECORDING OR IS OTHERWISE
                INVOLVED IN THE CREATION OF THE RECORDING THAT IS INCLUDED IN YOUR CATALOGUE; <br />(II), ALL MECHANICAL
                ROYALTIES PAYABLE TO PUBLISHERS, PUBLISHING ADMINISTRATORS, WRITERS AND CO-WRITERS OF COMPOSITIONS THAT
                ARE INCLUDED IN YOUR CATALOGUE; <br />(III) ALL PAYMENTS THAT MAY BE REQUIRED UNDER ANY COLLECTIVE
                BARGAINING AGREEMENTS APPLICABLE TO THE RECORDINGS AND COMPOSITIONS THAT COMPRISE YOUR CATALOGUE; AND
                <br />(IV) ANY OTHER FEES, COMPENSATION, ROYALTIES OR OTHER MONIES DUE AND OR PAYABLE WITH RESPECT TO
                THE DIGITAL ASSETS OR OTHER MATERIALS PROVIDED BY YOU TO US.
              </li>
              <li value="7.4">
                YOU INDEMNIFY AND HOLD US HARMLESS (INCLUDING OUR PARENTS, SUBSIDIARIES, SERVICES AND AFFILIATES, AND
                EACH OF THEIR RESPECTIVE STOCKHOLDERS, PREDECESSORS, SUCCESSORS, ASSIGNS, AGENTS, DIRECTORS, OFFICERS,
                ATTORNEYS, EMPLOYEES AND REPRESENTATIVES) FROM ALL LOSS, DAMAGE COSTS AND EXPENSES SUFFERED OR INCURRED
                BY US ARISING OUT OF OR IN CONNECTION WITH <br />(I) ANY CLAIM, ACTION OR PROCEEDINGS BY A PERSON
                ALLEGING THAT ANY ACT OR OMISSION OF US IN EXERCISING OUR LICENCES TO YOUR CATALOGUE UNDER THESE TERMS
                INFRINGES THE INTELLECTUAL PROPERTY RIGHTS OF THAT THIRD PARTY; AND <br />(II) ANY BREACH OF THE
                WARRANTIES GIVEN BY YOU UNDER THESE TERMS.
              </li>
            </ol>
            <h6>8. WARRANTIES BY DRM</h6>
            <ol>
              <li value="8.1">
                Subject to the warranties granted by you as per these Terms, we warrant that we have full power and
                authority to enter into and perform our obligations in accordance with these Terms, and that we will
                comply with all applicable laws in the performance of its obligations hereunder.
              </li>
              <li value="8.2">
                We will use our reasonable efforts but cannot guarantee that a Service will utilise your Catalogue or
                any of the Compositions or Recordings that comprise your Catalogue. We retain the right not to exploit
                your Catalogue or any portion thereof with any particular Service where we determine in our sole
                discretion that your Catalogue or any portion thereof is not suitable for that Service.
              </li>
              <li value="8.3">
                We are not responsible and are not liable to you for any unauthorised use, reproduction or communication
                of your Catalogue by any third party.
              </li>
            </ol>
            <h6>9. TERMINATION</h6>
            <ol>
              <li value="9.1">Either party may terminate these Terms:</li>
              <ul class="list-unstyled">
                <li>
                  (a) without cause by providing notice of its intention to terminate to the other party, no later than
                  sixty (60) days before the end of the Term; or
                </li>
                <li>
                  (b) by giving written notice to the other, if that other party breaches any material term of these
                  Terms and, having been given thirty (30) days' notice to rectify the breach, fails to do so.
                </li>
                <li>
                  Any such termination shall take effect at the end of the accounting period in which such notice
                  expires.
                </li>
              </ul>
              <li value="9.2">
                After the Term, if we receive any income from Services relating to your Catalogue, our sole liability to
                you will be the obligation to pay you in full as though these Terms were still in force, and to send
                renewed take-down notices to Services whenever requested and appropriate.
              </li>
            </ol>
            <h6>10. REMOVAL OF CATALOGUE ITEMS</h6>
            <ol>
              <li value="10.1">
                Without limiting your indemnification obligations, in the event you no longer have the necessary rights
                to an individual Recording or other item in your Catalogue or if an individual Recording or other item
                in your Catalogue is the subject of a third-party claim that reasonably risks exposing you or us to
                liability ("Deleted Work"), you must promptly inform us in writing so that we may notify Services to
                remove the Deleted Work. This is for single works only and shall not operate as a de-facto takedown
                arrangement. The Deleted Work must also be removed by you from any other service to which you had made
                the Deleted Work available.
              </li>
            </ol>
            <h6>11. GENERAL</h6>
            <ol>
              <li value="11.1">
                We shall have the right to assign, sublicense or otherwise dispose of or deal with any or all of our
                rights and responsibilities under these Terms to any person or entity, either wholly or partially. You
                will not, without our prior written consent, assign your rights or obligations under these Terms.
              </li>
              <li value="11.2">
                You retain ownership of all intellectual property rights subsisting in any Catalogue provided to us
                under these Terms.
              </li>
              <li value="11.3">
                These Terms are governed by the laws of New Zealand. The parties each submit to the exclusive
                jurisdiction of the courts of Auckland.
              </li>
              <li value="11.4">
                As to each other, you and us are independent contractors. Nothing in these Terms authorises any party as
                agent of the other, nor establishes a partnership or fiduciary relationship.
              </li>
              <li value="11.5">
                In the event any provision hereof shall for any reason be invalid, illegal, or unenforceable, then such
                provisions shall be deemed amended only to the extent necessary to eliminate such invalidity, illegality
                or unenforceability and in any such event, the same shall not affect the validity of the remaining
                portion and provisions hereof.
              </li>
              <li value="11.6">
                These Terms constitute the entire agreement between the parties regarding the matters set out in it and
                supersedes any prior representations, understandings, or arrangements between the parties, whether
                orally or in writing. These Terms may only be varied by written agreement between the parties. A
                condition of these Terms, or a right created by it, may only be waived by written notice signed by the
                party granting the waiver. A waiver of a breach of these Terms does not waive any other breach. Clauses
                that by their design are intended survive the termination or expiry of these Terms shall survive the
                termination of these Terms.
              </li>
              <li value="11.7">
                We may occasionally update these Terms. If we make significant changes, we will notify users in advance
                of the changes via the DRM portal or via other means, such as email. We encourage users to periodically
                review these Terms for the latest information on our service. Use of our services after an update
                constitutes consent to the updated Terms to the extent permitted by law. Notwithstanding the foregoing,
                any changes to the Term, Territory or our distribution fee shall require mutual agreement between you
                and us.
              </li>
              <li value="11.8">
                Headings are for convenience only and shall not limit or in any way affect the scope, meaning or intent
                of these Terms or any portion of it.
              </li>
              <li value="11.9">
                You represent and warrant that you have been advised of your rights to seek expert independent advice of
                your own choosing in connection with the negotiation and execution of these Terms.
              </li>
              <li value="11.10">
                A condition of these Terms, or a right created by it, may only be waived by written notice signed by the
                party granting the waiver. A waiver of a breach of these Terms does not waive any other breach.
              </li>
              <li value="11.11">
                These Terms may be executed in two or more counterparts each of which shall be deemed an original, but
                all of which together shall constitute one and same instrument.
              </li>
              <li value="11.12">
                All notices to us must be in writing to info@drm.co.nz and shall not be validly delivered until receipt
                is acknowledged.
              </li>
            </ol>
            <h6>12. DEFINITIONS</h6>
            <div>
              <p>
                <b>"Your Catalogue"</b> All sound and video recordings owned, controlled or made by or on behalf of you
                at any time during the Term, (the "Recording(s)"), all musical compositions embodied in the Recordings
                (subject to any existing rights assigned to APRA or your publisher, if any) (the "Compositions"), all
                other digital assets relating to the Recordings (including without limitation, album covers and other
                artwork, music videos, image files, marketing materials, trade names, trademarks, logos, likenesses,
                biographies and user generated content),collectively (the "Digital Assets") that you have the right to
                licence for promotional and/or commercial use.
              </p>

              <p>
                <b>"Digital Exploitation"</b>
                The distribution (or other authorisation of use) of your Catalogue by any current or future means or
                media as selected by DRM in good faith, including without limitation transmission, distribution,
                dissemination, or making available via telephone, satellite, broadcast, wireless, cable and/or the
                internet whether or not a direct or indirect charge is made, or any other use, excluding only the
                distribution of physical records.
              </p>
              <p>
                <b>"Income"</b>
                Any payment, royalties, proceeds or other monies or remuneration received by DRM attributable to the use
                of your Catalogue or any portion thereof, less any taxes, GST, Housing Costs, digitisation or encoding
                charges or public performance fees, if any.
              </p>
              <p>
                <b>"Services"</b>
                Any third party of any description that exploits sound or audiovisual recordings by any and all
                non-physical means and media (whether now known or existing in the future), including without limitation
                internet sites, telecommunications companies, broadcasters, on-line services and all other entities
                distributing entertainment products in a digital format via downloading, streaming, wireless, cable and
                or internet or other existing and future digital delivery methods, as selected by DRM in its sole
                discretion.
              </p>
              <hr />

              <p>
                You agree to the above Terms with us for our exclusive right to licence your Catalogue within the
                Territory during the Term.
              </p>
              <p>
                You agree to do such further acts and to execute and deliver such further documents as we may require to
                give full effect to these Terms.
              </p>
            </div>
          </div>
        </div>

        <!--
          <div>
            <h4>TERMS AND CONDITIONS</h4>
            <h6>GRANT OF RIGHTS</h6>

            <p>
              You grant us the exclusive worldwide right to licence the recordings that you deliver to us during the
              Term of this agreement to third parties for exploitation by any digital means now or hereafter known and
              to collect, administer, and distribute the proceeds from such licences.
            </p>
            <h6>TERM</h6>
            <p>
              This Agreement commences on the date you click on the box agreeing to these Terms and Conditions and
              continues initially for 1 year then renews automatically for additional rolling terms of 1 year each until
              terminated at the end of each period each of which will incorporated into and altogether comprise the
              Term.
            </p>
            <h6>COMMISSION</h6>
            <p>
              We will charge a commission of twenty five per cent on all income received by us on behalf of your
              recordings.
            </p>
            <h6>REPORTING</h6>

            <p>
              >Within forty five days of the end of the months of May, July, September, November, January and March we
              will provide you with a report detailing all income received by us for you for that preceding period
              together with a breakdown of all deductions. Upon receipt of that report you should prepare and forward to
              us an invoice. We will pay you on the 20th of the month following receipt of that invoice. If aggregate
              proceeds due to you are less than fifty Dollars ($50), the royalty balance will be rolled over until the
              reporting period in which an aggregate of at least fifty Dollars ($50) is due to you.
            </p>
            <p>
              At any time within twelve months after an income statement is submitted to you, you shall have the right
              to audit our books and records, at your sole cost and expense, but only with respect to such statements
              and payments related to the Catalogue. Usual audit provisions shall apply.
            </p>
            <h6>HOUSING CHARGES</h6>
            <p>
              We charge, and may deduct from any income, an annual fee of $1.00 per track for the housing of your
              recordings; In the event there are any fees outstanding as at the end of any accounting period and/or on
              the expiry of the Term, we shall be entitled to demand and you shall repay within thirty (30) days all and
              any such monies at such time(s).
            </p>
            <h6>WARRANTIES</h6>
            <p>YOU WARRANT THAT</p>
            <ul class="list-unstyled">
              <li class="m-1">
                (A) YOU HAVE FULL POWER AND AUTHORITY TO ENTER INTO AND, PERFORM YOUR OBLIGATIONS UNDER THIS AGREEMENT;
              </li>
              <li class="m-1">
                (B) YOU HAVE THE POWER TO GRANT TO US, THE LICENCES TO THE CATALOGUE GRANTED UNDER THIS AGREEMENT;
              </li>
              <li class="m-1">
                (C) THE EXERCISE BY US OF OUR LICENCES TO THE CATALOGUE UNDER THIS AGREEMENT DOES NOT INFRINGE THE
                RIGHTS (INCLUDING INTELLECTUAL PROPERTY RIGHTS) OF ANY PERSON OR OTHER THIRD PARTY,
              </li>
              <li class="m-1">
                (D) NO AGREEMENT OF ANY KIND PREVIOUSLY ENTERED INTO BY YOU OR ENTERED INTO BY YOU AFTER THE DATE OF
                THIS AGREEMENT WILL INTERFERE IN ANY MANNER WITH THE COMPLETE PERFORMANCE BY YOU OF THIS AGREEMENT;
              </li>
              <li class="m-1">
                (E) OR INTERFERE WITH THE RIGHTS GRANTED BY YOU TO US INCLUDING, BUT NOT LIMITED TO, THE REPRESENTATION
                RIGHTS TO THE CATALOGUE, OR ANY AGREEMENT ENTERED INTO BY US WITH A THIRD PARTY CONCERNING THE LICENSING
                AND/OR REPRESENTATION OF THE CATALOGUE.
              </li>
            </ul>
            <p>YOU SHALL BE SOLELY RESPONSIBLE FOR THE PAYMENT OF ANY AND ALL OF THE FOLLOWING:</p>
            <ul class="list-unstyled">
              <li class="m-1">
                (I) ALL FEES, INCOME, MONIES, ROYALTIES OR ANY OTHER COMPENSATION DUE ARTISTS, PRODUCERS, MASTER
                RECORDING COPYRIGHT OWNERS, AND ALL OTHER THIRD PERSONS WHOSE PERFORMANCE IS EMBODIED IN A MASTER SOUND
                RECORDING OR IS OTHERWISE INVOLVED IN THE CREATION OF THE MASTER SOUND RECORDING THAT IS INCLUDED IN THE
                CATALOGUE;
              </li>
              <li class="m-1">
                (II) ALL MECHANICAL ROYALTIES PAYABLE TO PUBLISHERS, PUBLISHING ADMINISTRATORS, WRITERS AND CO-WRITERS
                OF MUSICAL COMPOSITIONS THAT ARE INCLUDED IN THE CATALOGUE;
              </li>
              <li class="m-1">
                (III) ALL PAYMENTS THAT MAY BE REQUIRED UNDER ANY COLLECTIVE BARGAINING AGREEMENTS APPLICABLE TO THE
                MASTER SOUND RECORDINGS AND MUSICAL COMPOSITIONS THAT COMPRISE THE CATALOGUE; AND
              </li>
              <li class="m-1">
                (IV) ANY OTHER FEES, COMPENSATION, ROYALTIES OR OTHER MONIES DUE AND OR PAYABLE WITH RESPECT TO THE
                ARTWORK, METADATA, TRADEMARKS AND LOGOS IN THE CATALOGUE OR MATERIALS PROVIDED BY YOU TO US. YOU
                INDEMNIFY US AND HOLD US HARMLESS FROM ALL LOSS, DAMAGE COSTS AND EXPENSES SUFFERED OR INCURRED BY US
                ARISING OUT OF OR IN CONNECTION WITH ANY CLAIM, ACTION OR PROCEEDINGS BY A PERSON ALLEGING THAT ANY ACT
                OR OMISSION OF OURS IN EXERCISING ITS LICENCES TO THE CATALOGUE UNDER THIS AGREEMENT INFRINGES THE
                RIGHTS OF THAT THIRD PARTY.
              </li>
            </ul>
            <p>
              We warrant that we have full power and authority to enter into and, perform our obligations under this
              Agreement, and we will comply with all applicable laws in the performance of our obligations hereunder;
              that we will use all reasonable efforts to ensure that our service will comply at all times during the
              Term with the descriptions provided in these Terms.
            </p>
            <p>
              We will use our best commercial efforts but cannot guarantee that a Service will utilise the Catalogue or
              any of the individual musical compositions or master sound recordings that comprise the Catalogue.
            </p>
            <p>
              We are not responsible, and are not liable to you, for any unauthorised use, reproduction or communication
              of the Catalogue by our Customers.
            </p>
            <h6>TERMINATION</h6>

            <p>
              Either of us may terminate this Agreement without cause at the end of the initial 1 year period or any
              annual rollover by providing notice of its intention to terminate to the other party, no later than sixty
              days before the end of the relevant period.
            </p>
            <p>
              Without prejudice to any other rights it may have, either party may terminate all or any part of this
              Agreement immediately at any time by giving written notice of termination to the other party if the
              defaulting party is in breach of any material term of this Agreement and, if that breach is capable of
              remedy, does not rectify that breach within 30 days (or other mutually agreed time) after receipt of
              written notice from the Non-defaulting party to do so; the defaulting party is unable to pay its debts as
              they fall due, makes or commences negotiations with a view to making a general re-scheduling of its
              indebtedness, a general assignment, scheme of arrangement or composition with its creditors; the
              defaulting party takes any corporate action, or any steps are taken or legal proceedings are started for
              its winding up, dissolution, liquidation, or re-organisation, other than to reconstruct, merge or
              amalgamate while solvent; or the appointment of a controller, receiver, administrator, official manager,
              trustee, or other similar officer, of it or of any of its revenues or assets; or the defaulting party
              seeks or is granted protection from its creditors, under any applicable legislation.
            </p>
            <h6>GENERAL PROVISIONS</h6>
            <p>
              Either of us may assign or respective rights and responsibilities under this Agreement to an entity that
              acquires substantially all of its assets or to a subsidiary or other entity controlled or under the common
              control of said party. Except as aforementioned, neither of us may assign this Agreement or its rights and
              responsibilities without the prior written consent of the other party.
            </p>
            <p>
              You retain ownership of all intellectual property rights subsisting in any recordings provided to us under
              this Agreement.
            </p>
            <p>
              These Terms and Conditions are governed by the laws of New Zealand. The parties each submit to the
              non-exclusive jurisdiction of the courts of Auckland.
            </p>
            <p>
              This agreement may only be varied by written agreement between us. A condition of this Agreement, or a
              right created by it, may only be waived by written notice signed by the party granting the waiver. A
              waiver of a breach of this Agreement does not waive any other breach.
            </p>
            <p>
              All Notices must be in writing to info@drm.co.nz and shall not be validly delivered until receipt is
              acknowledged.
            </p>
          </div>
        </div>
        -->
        <div class="modal-footer modal-footer--sticky">
          <button
            type="button"
            class="btn btn-secondary"
            @click="modal.hide()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      modal: null,
    };
  },

  mounted() {
    // console.log("MOUNTED");
    // console.log(this.release);
    this.modal = new Modal(this.$refs.terms_modal);
    this.modal.hide();
  },

  methods: {
    open_modal() {
      this.modal.show();
    },
  },
};
</script>

<style scoped>
.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
}
</style>