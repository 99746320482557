<template>
  <form
    class="needs-validation"
    novalidate>
    <div class="row">
      <div class="col-md-3 mb-3 mt-0">
        <label
          for="track_number"
          class="form-label"
          >Track Number*</label
        >
        <input
          v-model="track.track_number"
          type="number"
          class="form-control"
          id="track_number"
          :class="{
            'is-invalid': is_incomplete && !track.track_number,
            'is-valid': track.track_number,
          }"
          required />
        <div class="invalid-feedback">please enter a track number</div>
      </div>
      <div class="col-md-3 mb-3">
        <label
          for="volume_number"
          class="form-label"
          >Volume Number*<BSPopover
            class="d-inline"
            content="Usually 1"
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>
        <input
          v-model="track.track_volume_number"
          type="number"
          class="form-control"
          id="volume_number"
          value="1"
          :class="{
            'is-invalid': is_incomplete && !track.track_volume_number,
            'is-valid': track.track_volume_number,
          }"
          required />
        <div class="invalid-feedback">Please enter a volume number.</div>
      </div>
    </div>

    <div class="mb-3">
      <label
        for="track_title"
        class="form-label"
        >Track Title*</label
      >
      <input
        v-model="track.track_title"
        type="text"
        class="form-control"
        id="track_title"
        :class="{
          'is-invalid': is_incomplete && !track.track_title,
          'is-valid': track.track_title,
        }"
        required />
      <div class="invalid-feedback">Please enter a track title.</div>
    </div>

    <div class="mb-3">
      <label
        for="track_version_title"
        class="form-label"
        >Version Title<BSPopover
          class="d-inline"
          content="Optional field to add further details to the track title e.g. 'Remastered', 'DJ Mix', 'Acoustic'.  This will appear in brackets after the title on the digital platforms."
          trigger="hover">
          <info-icon
            size="1.0x"
            class="text-success ms-2 mb-1"></info-icon> </BSPopover
      ></label>
      <input
        v-model="track.track_version_title"
        type="text"
        class="form-control"
        id="track_version_title"
        required />
      <div class="invalid-feedback">Please enter a version title.</div>
    </div>
    <div class="mb-3">
      <label
        for="track_subtitle"
        class="form-label"
        >Track Subtitle<BSPopover
          class="d-inline"
          content="Optional field to add further details to the track title e.g. 'Escape (The Piña Colada Song)'"
          trigger="hover">
          <info-icon
            size="1.0x"
            class="text-success ms-2 mb-1"></info-icon> </BSPopover
      ></label>
      <input
        v-model="track.track_subtitle"
        type="text"
        class="form-control"
        id="track_title"
        :class="{
          'is-valid': track.track_subtitle,
        }"
        required />
      <div class="invalid-feedback">Please enter a track subtitle.</div>
    </div>
    <div class="mb-3">
      <label
        for="track_main_artists"
        class="form-label">
        Main Artist Name(s)*
      </label>
    </div>
    <div
      v-for="(artist, i) in track.track_main_artists"
      :key="artist.id">
      <div class="row align-items-center">
        <div class="col-md-4">
          <label
            for="track_main_artist_name"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="artist.name"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': is_incomplete && !artist.name,
              'is-valid': artist.name,
            }"
            id="track_main_artist_name"
            placeholder="Name"
            required />
          <div class="invalid-feedback">Please provide an artist name.</div>
        </div>
        <div class="col-md-3">
          <label
            for="track_main_artist_apple_id"
            class="form-label text-muted"
            >Apple ID</label
          >
          <div class="input-group">
            <input
              v-model="artist.apple_id"
              type="text"
              class="form-control"
              id="track_main_artist_apple_id"
              placeholder="Apple ID"
              required />
            <a
              class="btn btn-outline-secondary"
              :href="artist.apple_id && `https://music.apple.com/artist/${artist.apple_id}`"
              :title="artist.apple_id && `https://music.apple.com/artist/${artist.apple_id}`"
              target="_blank"
              role="button">
              <external-link-icon size="1.0x"></external-link-icon>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <label
            for="track_main_artist_spotify_id"
            class="form-label text-muted"
            >Spotify ID</label
          >
          <div class="input-group">
            <input
              v-model="artist.spotify_id"
              type="text"
              class="form-control"
              id="track_main_artist_spotify_id"
              placeholder="Spotify ID"
              required />
            <a
              class="btn btn-outline-secondary"
              :href="artist.spotify_id && `https://open.spotify.com/artist/${artist.spotify_id}`"
              :title="artist.spotify_id && `https://open.spotify.com/artist/${artist.spotify_id}`"
              :disabled="!artist.spotify_id"
              target="_blank"
              role="button">
              <external-link-icon size="1.0x"></external-link-icon>
            </a>
          </div>
        </div>
        <div class="col-md-2">
          <button
            @click.prevent="add_line(track.track_main_artists, i)"
            @keydown.tab="add_line(track.track_main_artists, i)"
            class="btn btn-x-sm btn-outline-success me-1"
            title="add main artist">
            +
          </button>
          <button
            @click.prevent="delete_line(track.track_main_artists, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove main artist">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="mb-3">
      <label
        for="track_featured_artists"
        class="form-label">
        Featured Name(s)
      </label>
    </div>

    <div
      v-for="(artist, i) in track.track_featured_artists"
      :key="artist.id">
      <div class="row align-items-center">
        <div class="col-md-4">
          <label
            for="track_featured_artist_name"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="artist.name"
            type="text"
            class="form-control"
            id="track_featured_artist_name"
            placeholder="Name"
            required />
          <div class="invalid-feedback">Please provide an artist name.</div>
        </div>
        <div class="col-md-3">
          <label
            for="track_featured_artist_apple_id"
            class="form-label text-muted"
            >Apple ID</label
          >
          <div class="input-group">
            <input
              v-model="artist.apple_id"
              type="text"
              class="form-control"
              id="track_featured_artist_apple_id"
              placeholder="Apple ID"
              required />
            <a
              class="btn btn-outline-secondary"
              :href="artist.apple_id && `https://music.apple.com/artist/${artist.apple_id}`"
              :title="artist.apple_id && `https://music.apple.com/artist/${artist.apple_id}`"
              :disabled="!artist.apple_id"
              target="_blank"
              role="button">
              <external-link-icon size="1.0x"></external-link-icon>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <label
            for="track_featured_artist_spotify_id"
            class="form-label text-muted"
            >Spotify ID</label
          >

          <div class="input-group">
            <input
              v-model="artist.spotify_id"
              type="text"
              class="form-control"
              id="track_featured_artist_spotify_id"
              placeholder="Spotify ID"
              required />

            <a
              class="btn btn-outline-secondary"
              :href="artist.spotify_id && `https://open.spotify.com/artist/${artist.spotify_id}`"
              :title="artist.spotify_id && `https://open.spotify.com/artist/${artist.spotify_id}`"
              :disabled="!artist.spotify_id"
              target="_blank"
              role="button">
              <external-link-icon size="1.0x"></external-link-icon>
            </a>
          </div>
        </div>

        <div class="col-md-2">
          <button
            @click.prevent="add_line(track.track_featured_artists, i)"
            @keydown.tab="add_line(track.track_featured_artists, i)"
            class="btn btn-x-sm btn-outline-success me-1"
            title="add featured artist">
            +
          </button>
          <button
            @click.prevent="delete_line(track.track_featured_artists, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove featured artist">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />

    <div class="mb-3">
      <label
        for="track_remix_artist_name"
        class="form-label">
        Remix Artist(s)
      </label>
    </div>

    <div
      v-for="(remix_artist, i) in track.track_remix_artists"
      :key="remix_artist.id">
      <div class="row align-items-center mb-3">
        <div class="col-md-4">
          <label
            for="track_remix_artist_name"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="remix_artist.name"
            type="text"
            class="form-control"
            id="track_remix_artist_name"
            placeholder="Name"
            required />
          <div class="invalid-feedback">Please provide a remix artist name.</div>
        </div>
        <div class="col-md-2 mb-3 mt-auto">
          <button
            @click.prevent="add_line(track.track_remix_artists, i)"
            @keydown.tab="add_line(track.track_remix_artists, i)"
            title="add remix artist"
            class="btn btn-x-sm btn-outline-success me-1">
            +
          </button>
          <button
            @click.prevent="delete_line(track.track_remix_artists, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove remix artist">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />

    <div class="mb-3">
      <label
        for="track_producers"
        class="form-label">
        Producer(s)
      </label>
    </div>

    <div
      v-for="(producer, i) in track.track_producers"
      :key="producer.id">
      <div class="row">
        <div class="col-md-4">
          <label
            for="track_producer_name"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="producer.name"
            type="text"
            class="form-control"
            id="track_producer_name"
            placeholder="Name"
            required />
          <div class="invalid-feedback">Please provide an producer name.</div>
        </div>
        <div class="col-md-2 mb-3 mt-auto">
          <button
            @click.prevent="add_line(track.track_producers, i)"
            @keydown.tab="add_line(track.track_producers, i)"
            class="btn btn-x-sm btn-outline-success me-1"
            title="add producer">
            +
          </button>
          <button
            @click.prevent="delete_line(track.track_producers, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove producer">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />

    <div class="mb-3">
      <label
        for="track_composers"
        class="form-label">
        Composer(s)
      </label>
    </div>

    <div
      v-for="(composer, i) in track.track_composers"
      :key="composer.id">
      <div class="row">
        <div class="col-md-4">
          <label
            for="track_composer"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="composer.name"
            type="text"
            class="form-control"
            id="track_composer_name"
            placeholder="Composer Name"
            required />

          <div class="invalid-feedback">Please provide an composer name.</div>
        </div>
        <div class="col-md-2 mb-3 mt-auto">
          <button
            @click.prevent="add_line(track.track_composers, i)"
            @keydown.tab="add_line(track.track_composers, i)"
            class="btn btn-x-sm btn-outline-success me-1"
            title="add composer">
            +
          </button>

          <button
            @click.prevent="delete_line(track.track_composers, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove composer">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="mb-3">
      <label
        for="track_lyricists"
        class="form-label">
        Lyric Author(s)
      </label>
    </div>

    <div
      v-for="(lyricist, i) in track.track_lyricists"
      :key="lyricist.id">
      <div class="row mb-3">
        <div class="col-md-4">
          <label
            for="track_lyricist_name"
            class="form-label text-muted"
            >Name ({{ i + 1 }})</label
          >
          <input
            v-model="lyricist.name"
            type="text"
            class="form-control"
            id="track_lyricist_name"
            placeholder="Name"
            required />
          <div class="invalid-feedback">Please provide a lyricist.</div>
        </div>
        <div class="col-md-2 mb-3 mt-auto">
          <button
            @click.prevent="add_line(track.track_lyricists, i)"
            @keydown.tab="add_line(track.track_lyricists, i)"
            class="btn btn-x-sm btn-outline-success me-1"
            title="add lyricist">
            +
          </button>
          <button
            @click.prevent="delete_line(track.track_lyricists, i)"
            class="btn btn-x-sm btn-outline-danger"
            title="remove lyricist">
            -
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="mb-3">
      <label
        for="track_genre"
        class="form-label"
        >Genre*</label
      >

      <v-select
        v-model="track.track_genre"
        :options="display_genres"
        :selectOnTab="true"
        :clearable="true"></v-select>
      <div class="invalid-feedback">Please enter valid genre</div>
    </div>

    <div class="mb-3">
      <label
        for="track_type"
        class="form-label"
        >Track Type</label
      >
      <select
        v-model="track.track_type"
        class="form-select d-block w-100"
        id="track_type"
        :class="{
          'is-invalid': is_incomplete && !track.track_type,
          'is-valid': track.track_type,
        }"
        required>
        <option>Original</option>
        <option>Cover</option>
        <option>Karaoke</option>
        <option>Medley</option>
        <option>Cover by Cover Band</option>
      </select>
      <div class="invalid-feedback">Please specify track type</div>
    </div>

    <div class="mb-3">
      <label
        for="track_lyric_language"
        class="form-label">
        Track Lyric Language*
        <BSPopover
          class="d-inline"
          content="Please choose 'Instrumental' for instrumental tracks."
          trigger="hover">
          <info-icon
            size="1.0x"
            class="text-success ms-2 mb-1"></info-icon>
        </BSPopover>
      </label>
      <select
        v-model="track.track_lyric_language"
        class="form-select d-block w-100"
        id="track_lyric_language"
        :class="{
          'is-invalid': is_incomplete && !track.track_lyric_language,
          'is-valid': track.track_lyric_language,
        }"
        required>
        <option
          disabled
          value>
          Choose...
        </option>
        <option value="en">English</option>
        <option value="mi">Maori</option>
        <option value="instrumental">Instrumental</option>
        <option
          disabled
          value>
          ----------
        </option>

        <option
          v-for="(value, key) in language_tags"
          :value="key"
          :key="value[key]">
          {{ value }}
        </option>
      </select>
      <div class="invalid-feedback">Please select a valid language.</div>
    </div>

    <div class="mb-3">
      <label
        for="track_title_language"
        class="form-label">
        Track Title Language*
      </label>
      <select
        v-model="track.track_title_language"
        class="form-select d-block w-100"
        id="track_tile_language"
        :class="{
          'is-invalid': is_incomplete && !track.track_title_language,
          'is-valid': track.track_title_language,
        }"
        required>
        <option
          disabled
          value>
          Choose...
        </option>
        <option value="en">English</option>
        <option value="mi">Maori</option>
        <option
          disabled
          value>
          ----------
        </option>

        <option
          v-for="(value, key) in language_tags"
          :value="key"
          :key="value[key]">
          {{ value }}
        </option>
      </select>
      <div class="invalid-feedback">Please select a valid language.</div>
    </div>

    <div class="mb-3">
      <label
        for="track_type"
        class="form-label"
        >Parental Advisory Type</label
      >
      <select
        v-model="track.track_parental_advisory"
        class="form-select d-block w-100"
        id="track_parental_advisory"
        :class="{
          'is-invalid': is_incomplete && !track.track_parental_advisory,
          'is-valid': track.track_parental_advisory,
        }"
        required>
        <option value="NotExplicit">Not Explicit</option>
        <option value="Explicit">Explicit</option>
        <option value="Explicit">Cleaned / Edited</option>
      </select>
      <div class="invalid-feedback">Please specify parental advisory type</div>
    </div>

    <div class="row">
      <div class="col-md-3 mb-3 mt-0">
        <label
          for="description"
          class="form-label"
          >Preview Audio Start<BSPopover
            class="d-inline"
            :content="`The start point for a 90 second preview of this track.  Must be in the format mm:ss (i.e. minutes and seconds) for example 01:25`"
            trigger="hover">
            <info-icon
              size="1.0x"
              class="text-success ms-2 mb-1"></info-icon> </BSPopover
        ></label>

        <input
          v-model="track.track_preview_audio_start"
          type="text"
          pattern="([0]?[0-5][0-9]|[0-9]):([0-5][0-9])"
          inputmode="numeric"
          maxlength="5"
          class="form-control"
          :class="{
            'is-invalid': track.track_preview_audio_start && !is_start_time_valid(track.track_preview_audio_start),
            'is-valid': track.track_preview_audio_start && is_start_time_valid(track.track_preview_audio_start),
          }"
          placeholder="01:25"
          required />
        <div class="invalid-feedback">Please enter a valid preview start time.</div>
      </div>
    </div>

    <div class="mb-3">
      <label for="tier">
        Track Price Tier<BSPopover
          class="d-inline"
          content="This is the download price for a single track.  All tracks in the same release should have the same price tier.  Typically, you would choose FRONT."
          trigger="hover">
          <info-icon
            size="1.0x"
            class="text-success ms-2 mb-1"></info-icon> </BSPopover
      ></label>
      <select
        v-model="track.track_price_tier"
        class="form-select d-block w-100 m-1"
        id="track_price_tier"
        :class="{
          'is-invalid': is_incomplete && track.track_price_tier && !track.track_price_tier,
          'is-valid': track.track_price_tier && track.track_price_tier,
        }"
        required>
        <option
          v-for="tier in price_tiers"
          :key="tier.code"
          :value="tier.code">
          {{ tier.display }}
        </option>
      </select>
      <div class="invalid-feedback">Please select a valid price tier.</div>
    </div>

    <div class="mb-3">
      <label
        for="track_isrc"
        class="form-label">
        Track ISRC <i>(International Sound Recording Code)</i>
        <BSPopover
          class="d-inline"
          content="If you do not already have an ISRC leave this blank - an ISRC will be assigned on release."
          trigger="hover">
          <info-icon
            size="1.0x"
            class="text-success ms-2 mb-1"></info-icon>
        </BSPopover>
      </label>

      <input
        v-model="track.track_isrc"
        type="text"
        class="form-control"
        id="track_isrc"
        required />

      <div class="invalid-feedback">Please enter a valid ISRC.</div>
    </div>

    <div
      class="card mt-2"
      id="audio-upload">
      <div class="card-header">
        <h6 class="text-uppercase">Audio File</h6>
      </div>
      <div class="card-body text-center">
        <audio
          v-if="track.track_audio_url"
          controls>
          <source
            :src="this.track.track_audio_url"
            type="audio/wav"
            preload="auto" />
        </audio>

        <p v-else>
          Please upload a .wav file for this track
          <br /><small>Do not close or refresh this page while file is uploading</small>
        </p>
      </div>
      <div class="card-body">
        <MusicUploader
          :track="track"
          v-on:save_track="save_form"></MusicUploader>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <button
          v-on:click.prevent="submit_track"
          class="btn btn-success btn-sm mr-2">
          Validate and Save
        </button>

        <button
          v-if="is_incomplete"
          v-on:click.prevent="save_form(true)"
          class="btn btn-warning btn-sm ms-1">
          Save for Later
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { db } from "@/firebaseConfig.js";
import { orderBy } from "lodash";
import BSPopover from "@/components/BSPopover";
import MusicUploader from "@/components/ReleaseTrackMusicUploader";
import Countries from "../assets/Countries.json";
import Languages from "../assets/Languages.json";
import LanguageTags from "../assets/LanguageTags.json";
import Genres from "../assets/GenresBelieve.json";
import TrackPriceTiers from "../assets/TrackPriceTiers.json";
import { InfoIcon, ExternalLinkIcon } from "vue-feather-icons";

const tracks = db.collection("release_submissions_tracks");

export default {
  name: "release_track_form",
  props: {
    track_id: String,
  },
  data() {
    return {
      track: {},
      countries: Countries,
      languages: Languages,
      language_tags: LanguageTags,
      genres: Genres,
      price_tiers: TrackPriceTiers,
      last_index: {},
      required: [
        "track_title",
        "track_number",
        "track_volume_number",
        "track_main_artists",
        "track_genre",
        "track_type",
        "track_lyric_language",
        "track_title_language",
        "track_parental_advisory",
      ],
      is_incomplete: false,
    };
  },
  components: {
    MusicUploader,
    BSPopover,
    InfoIcon,
    ExternalLinkIcon,
  },
  firestore() {
    return {
      track: db.collection("release_submissions_tracks").doc(this.track_id),
      last_index: db.collection("isrc_codes").doc("last_index"),
    };
  },
  methods: {
    add_line: function (items, i) {
      console.log("i: " + i);

      items.splice(i + 1, 0, {});
    },
    delete_line(items, i) {
      if (items.length === 1) {
        return false;
      }
      items.splice(i, 1);
    },
    submit_track: function () {
      console.log("SUBMITTING TRACK");
      console.log("IS_COMPLETE: " + this.is_complete());
      if (!this.is_complete()) {
        this.is_incomplete = true;
        this.$toast.warning(
          "You are missing some required information.  Please complete the highlighted fields or, save this form and come back to it later.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.save_form(true);
      }
    },

    save_form: async function (close) {
      this.is_incomplete = false;
      console.log("UPDATING TRACK");
      this.update_track();
      console.log("CLOSE? " + close);

      this.$toast.success("Saved.", {
        position: "top-right",
        timeout: 5000,
      });

      if (close) {
        this.$router.push({
          name: "ReleaseTracksList",
          params: { release_id: this.track.release_id },
        });
      }
    },

    update_track: async function () {
      // this.track_isrc = this.track_isrc || this.generate_isrc();
      const track_update = {
        ...this.track,
        is_new: false,
        is_incomplete: !this.is_complete(),
      };
      console.log(track_update);
      await db.collection("release_submissions_tracks").doc(this.track.id).update(track_update);
    },

    is_complete: function () {
      console.log("CHECKING FORM IS COMPLETE");
      const track = this.track;
      const entries = Object.entries(track);

      console.log(entries);

      const missing_fields = entries
        // eslint-disable-next-line no-unused-vars
        .filter(([k, v]) => {
          return v === undefined || v === null || v === "" || v === [];
        })
        // eslint-disable-next-line no-unused-vars
        .map(([k, v]) => k);

      console.log("missing_fields: " + missing_fields);

      const is_complete = !missing_fields.some((missing_field) => {
        return this.required.includes(missing_field);
      });
      console.log("IS COMPLETE?: " + is_complete);

      return is_complete;
    },
    is_fullname(name) {
      const reg_name = /^(\w.+\s).+/;
      return reg_name.test(name);
    },

    is_start_time_valid(start_time_string) {
      const reg_start_time = /^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9]))$/;
      return reg_start_time.test(start_time_string);
    },
  },

  watch: {
    track_id: function (new_track) {
      console.log("track loaded");
      this.$bind("track", tracks.doc(new_track));
    },
  },

  computed: {
    years() {
      const year_start = new Date().getFullYear();
      const years_array = [];
      let i;

      for (i = 1960; i < year_start + 1; i++) {
        years_array.push(i);
      }

      return years_array.reverse();
    },
    display_genres: function () {
      let genres = this.genres.slice();

      genres = genres.map((g) => {
        g.label = `${g.genre} ${g.subgenre && "|"} ${g.subgenre}`;
        return g;
      });
      genres = orderBy(genres, "genre", "asc");
      return genres;
    },
  },
};
</script>

<style>
</style>
