<template>
  <NavSplash>
    <div class="login text-center">
      <div class="form-signin">
        <div class="text-center p-3">
          <img
            src="../assets/logo.png"
            width="auto"
            height="50px"
            alt />
        </div>
        <div>
          <form @submit.prevent="handleResetPassword">
            <div class="mb-3">
              <label
                for="inputPassword"
                class="form-label"
                >New Password</label
              >
              <input
                v-model="password"
                type="password"
                id="inputPassword"
                class="form-control"
                :class="{
                  'is-invalid': password && !isPasswordStrong,
                  'is-valid': password && isPasswordStrong,
                }"
                placeholder="new password"
                required />
              <PasswordScore
                v-if="password && password.length"
                :value="password"
                class="mt-2 mb-6"
                @passed="isPasswordStrong = true"
                @failed="isPasswordStrong = false" />
            </div>
            <div class="mb-3">
              <label
                for="inputConfirmPassword"
                class="form-label"
                >Confirm New Password</label
              >
              <input
                v-model="confirm_password"
                type="password"
                id="inputConfirmPassword"
                class="form-control"
                :class="{
                  'is-invalid': confirm_password && password !== confirm_password,
                  'is-valid': password.length && password === confirm_password,
                }"
                placeholder="confirm your new password"
                required />
            </div>
            <button
              :disabled="!is_password_good"
              class="btn btn-outline-secondary btn-block"
              type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </NavSplash>
</template>

<script>
import NavSplash from "@/components/NavSplash";
import { auth } from "@/firebaseConfig";
import PasswordScore from "@/components/PasswordScore.vue";
import { mutations, actions } from "@/store";

export default {
  name: "login",
  data: function () {
    return {
      actionCode: this.$route.query.oobCode,
      email: "",
      password: "",
      isPasswordStrong: false,
      confirm_password: "",
    };
  },
  components: {
    NavSplash,
    PasswordScore,
  },

  methods: {
    handleResetPassword: function () {
      auth
        .verifyPasswordResetCode(this.actionCode)
        .then((email) => {
          var newPassword = this.password;
          auth
            .confirmPasswordReset(this.actionCode, newPassword)
            .then((resp) => {
              auth.signInWithEmailAndPassword(email, newPassword).then(
                /* eslint-disable no-unused-vars */
                (user) => {
                  mutations.setCurrentUser(auth.currentUser);
                  actions.fetchCurrentUserProfile(auth.currentUser);
                  this.$router.push("/");
                },
                (err) => {
                  this.$toast.error(err.message, {
                    position: "top-right",
                    timeout: 5000,
                  });
                }
              );
            })
            .catch((error) => {
              console.log(error);

              this.$toast.error(error, {
                position: "top-center",
                timeout: 5000,
              });
            });
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top-center",
            timeout: 5000,
          });

          console.log(error);
        });
    },
  },
  computed: {
    is_password_good: function () {
      return this.password.length && this.password === this.confirm_password && this.isPasswordStrong;
    },
  },
};
</script>



<style scoped>
.login {
  /* color: #fff; */
  height: 100vh;
  margin-top: -80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url("../assets/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 25px;
  margin: auto;
  background-color: #e2e4e3;
  opacity: 0.9;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
