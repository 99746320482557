<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>FUGA Migration</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Upload Record</div>
            <div class="card-body">
              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Products Convert<br /><span class="fw-lighter small">convert MTL releeeees into products</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_to_products_convert"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_products_convert_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets Convert<br /><span class="fw-lighter small">convert MTL tracks into assets</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_to_assets_convert"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_convert_result }}</div>
              </div> -->

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    Artists Upload<br /><span class="fw-lighter small"
                      >Uploads aritsts to FUGA</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_artists_upload"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.artists_upload_result }}
                </div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA People Update<br /><span class="fw-lighter small"
                      >Adds Apple and Spotify IDs to People on FUGA</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_people_update"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.people_upload_result }}
                </div>
              </div>

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    ***FUGA Artists Update<br /><span class="fw-lighter small"
                      >Adds Apple and Spotify IDs to Artists on FUGA</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_artists_update"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.artists_upload_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    Label Upload<br /><span class="fw-lighter small">Uploads orgs to FUGA as labels</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_labels_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.labels_upload_result }}</div>
              </div> -->

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    People Upload<br /><span class="fw-lighter small"
                      >Uploads people to FUGA</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_people_upload"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.artists_upload_result }}
                </div>
              </div>

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    Subgenre Upload<br /><span class="fw-lighter small">Uploads subgenres to FUGA</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_subgenres_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.artists_upload_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Products Update<br /><span class="fw-lighter small"
                      >Updates MTL Products to include artist(s) FUGA id(s)</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_products_update"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_products_update_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Products Upload<br /><span class="fw-lighter small"
                      >Uploads MTL Products to FUGA as products</span
                    >
                  </p>
                </div>
                <div class="col">
                  <input
                    v-model="mtl_products_upload_number"
                    class="form-control"
                    type="number"
                    name="" />
                </div>
                <div class="col">
                  <button
                    @click="mtl_products_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_products_upload_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets Update<br /><span class="fw-lighter small">Updates MTL Assets with FUGA product id</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_assets_update"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_update_result }}</div>
              </div> -->
              <!-- 
              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets Upload<br /><span class="fw-lighter small">Uploads MTL Assets to FUGA as assets</span>
                  </p>
                </div>
                <div class="col">
                  <input
                    v-model="mtl_assets_upload_number"
                    class="form-control"
                    type="number"
                    name="" />
                </div>
                <div class="col">
                  <button
                    @click="mtl_assets_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_upload_result }}</div>
              </div> -->

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets Update<br /><span class="fw-lighter small"
                      >Associates FUGA Assets with FUGA Product</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_assets_update"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.fuga_assets_update_result }}
                </div>
              </div>
              <!-- 
              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Art Upload<br /><span class="fw-lighter small">Uploads cover art for each product (upc)</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_image_upload_process"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_update_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Audio Upload<br /><span class="fw-lighter small"
                      >Uploads audio file for each asset (isrc)</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_audio_upload_process"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_update_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets Get All<br /><span class="fw-lighter small"
                      >Gets FUGA Assets to update DRM Assets with missing FUGA ID</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_assets_get_all"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_get_all_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets add Contributors<br /><span class="fw-lighter small"
                      >Adds Contributor Records to MTL Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_assets_add_contributors"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_add_contributors_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets Upload Contributors<br /><span class="fw-lighter small"
                      >Adds Contributor Records to FUGA Assets</span
                    >
                  </p>
                </div>
                <div class="col">
                  <input
                    v-model="fuga_assets_contributors_upload_number"
                    class="form-control"
                    type="number"
                    name="" />
                </div>
                <div class="col">
                  <button
                    @click="fuga_assets_contributors_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_contributors_upload_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Language Code Fix<br /><span class="fw-lighter small"
                      >Add missing FUGA Language IDs to MTL Asset Record</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="mtl_assets_language_fix"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_language_fix }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets upload Language Code<br /><span class="fw-lighter small"
                      >Adds Language Code to FUGA Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_assets_language_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_language_upload }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets Upload Position/Sequence<br /><span class="fw-lighter small"
                      >Adds Position/Sequence to FUGA Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_assets_position_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_position_upload }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Artists Fix<br /><span class="fw-lighter small"
                      >Add missing FUGA IDs to MTL Artist Record</span
                    >
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_artists_fix"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_artists_fix }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL People Fix<br /><span class="fw-lighter small">Add missing FUGA IDs to MTL People Record</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_people_fix"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_people_fix }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Table Download<br /><span class="fw-lighter small"
                      >Download entire table from FUGA as JSON</span
                    >
                  </p>
                </div>
                <div class="col">
                  <input
                    v-model="fuga_download_table"
                    class="form-control"
                    type="string"
                    name="" />
                </div>

                <div class="col">
                  <button
                    @click="fuga_table_download"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_contributors_upload }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Aseets Find Gaps<br /><span class="fw-lighter small"
                      >Find gaps in the FUGA data and update MTL record status field</span
                    >
                  </p>
                </div>
                <div class="col">
                  <input
                    v-model="fuga_download_table"
                    class="form-control"
                    type="string"
                    name="" />
                </div>

                <div class="col">
                  <button
                    @click="mtl_assets_find_fuga_gaps"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_find_fuga_gaps }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets - Add Main Artists<br /><span class="fw-lighter small"
                      >Adding Main Artists to MTL Aseets</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="mtl_assets_add_main_artists"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_add_main_artists_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets - Add Featured Artists<br /><span class="fw-lighter small"
                      >Adding Featured Artists to MTL Aseets</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="mtl_assets_add_featured_artists"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.mtl_assets_add_featured_artists_result }}</div>
              </div> -->

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets - Merge ISRC<br /><span class="fw-lighter small"
                      >Merge Assets with Duplicate ISRCs</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="mtl_assets_merge_isrc"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.mtl_assets_merge_isrc_result }}
                </div>
              </div>

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets - Upload Featured Artists<br /><span class="fw-lighter small"
                      >Uploads Featured Artists to FUGA Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="fuga_assets_featured_artists_upload"
                    class="btn btn-sm btn-outline-success">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_assets_featured_artists_upload_result }}</div>
              </div> -->

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets - Add Products Array<br /><span
                      class="fw-lighter small"
                      >Adds Products Array to MTL Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="mtl_assets_add_products"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.mtl_assets_add_products_result }}
                </div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    MTL Assets - Group By Product<br /><span
                      class="fw-lighter small"
                      >Groups MTL Assets by Product to enable upload in
                      sequence</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="mtl_assets_group_by_product"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.mtl_assets_group_by_product_result }}
                </div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Assets - Upload Parental Advisory<br /><span
                      class="fw-lighter small"
                      >Upload edits to FUGA Assets</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="fuga_assets_parental_advisory_upload"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{
                    fuga_migration_status.fuga_assets_parental_advisory_upload_result
                  }}
                </div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Products - Upload Parental Advisory<br /><span
                      class="fw-lighter small"
                      >Upload edits to FUGA Products</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="fuga_products_parental_advisory_upload"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{
                    fuga_migration_status.fuga_products_parental_advisory_upload_result
                  }}
                </div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6">
                  <p class="m-0">
                    FUGA Products - Upload Edits<br /><span
                      class="fw-lighter small"
                      >Upload edits to FUGA Products</span
                    >
                  </p>
                </div>
                <div class="col"></div>

                <div class="col">
                  <button
                    @click="fuga_products_edits_upload"
                    class="btn btn-sm btn-outline-success"
                  >
                    Start
                  </button>
                </div>
                <div class="col">
                  {{ fuga_migration_status.fuga_products_edits_upload_result }}
                </div>
              </div>

              <!-- <div class="row p-2 border-bottom">
                <div class="col-6 text-danger">
                  <p class="m-0">
                    FUGA Artists Delete All<br /><span class="fw-lighter small">DELETES ALL ARTISTS</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_artists_delete_all"
                    class="btn btn-sm btn-outline-danger">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_artists_delete_all_result }}</div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6 text-danger">
                  <p class="m-0">
                    FUGA Labels Delete All<br /><span class="fw-lighter small">DELETES ALL LABELS</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_labels_delete_all"
                    class="btn btn-sm btn-outline-danger">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_labels_delete_all_result }}</div>
              </div>

              <div class="row p-2 border-bottom">
                <div class="col-6 text-danger">
                  <p class="m-0">
                    FUGA People Delete All<br /><span class="fw-lighter small">DELETES ALL PEOPLE</span>
                  </p>
                </div>
                <div class="col"></div>
                <div class="col">
                  <button
                    @click="fuga_people_delete_all"
                    class="btn btn-sm btn-outline-danger">
                    Start
                  </button>
                </div>
                <div class="col">{{ fuga_migration_status.fuga_people_delete_all_result }}</div>
              </div> -->

              <!-- <div class="row p-2 border-bottom"> 
                <div class="col-6 text-danger">
                  <p class="m-0">MTL Fix Artists IDs<br /><span class="fw-lighter small">Fixes IDs</span></p>
                </div>
                <div class="col">
                  <button
                    @click="mtl_ddex_fix_artists_ids"
                    class="btn btn-sm btn-outline-danger">
                    Start
                  </button>
                </div>
                <div class="col">{{ mtl_ddex_fix_artists_ids.v }}</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col"><Logs></Logs></div>
      </div>
      <div class="row mt-3">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Export DDEX Records</div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-6">
                  <label for="basic-url" class="form-label"
                    >Release Date Range</label
                  >
                  <div class="input-group mb-3">
                    <input
                      v-model="ddex_start_date"
                      type="date"
                      class="form-control"
                      placeholder="From Release Date"
                      aria-label="From Release Date"
                    />
                    <span class="input-group-text">-</span>
                    <input
                      v-model="ddex_end_date"
                      type="date"
                      class="form-control"
                      placeholder="To Release Date"
                      aria-label="To Release Date"
                    />
                  </div>

                  <button
                    @click="ddex_download"
                    class="btn btn-outline-success"
                    :disabled="ddex_downloading"
                  >
                    Download
                    <download-icon
                      v-if="!ddex_downloading"
                      size="1.0x"
                      class="ms-2"
                    ></download-icon>
                    <div
                      v-show="ddex_downloading"
                      class="spinner-border spinner-border-sm text-success ms-2"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Logs from "@/components/Logs";
import { DownloadIcon, LoaderIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      mtl_products_upload_number: 20,
      mtl_assets_upload_number: 20,
      fuga_assets_contributors_upload_number: 20,
      fuga_migration_status: {
        mtl_products_convert_result: "",
        mtl_assets_convert_result: "",
        artists_upload_result: "",
        labels_upload_result: "",
        mtl_products_update_result: "",
        mtl_assets_update_result: "",
        mtl_products_upload_result: "",
        mtl_assets_upload_result: "",
        fuga_assets_get_all_result: "",
      },
      ddex_start_date: "",
      ddex_end_date: "",
      ddex_downloading: false,
      ddex_downloaded: false,
      fuga_download_table: "assets",
    };
  },

  firestore: {
    fuga_migration_status: db
      .collection("fuga_migration_status")
      .doc("fuga_migration_status"),
  },

  components: {
    basicLayout,
    Logs,
    DownloadIcon,
    LoaderIcon,
  },

  methods: {
    mtl_to_products_convert: async function () {
      const mtl_to_products_convert = fb.httpsCallable(
        "mtl_to_products_convert"
      );
      const res = await mtl_to_products_convert();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_products_convert_result: status });
    },

    mtl_to_assets_convert: async function () {
      const mtl_to_assets_convert = fb.httpsCallable("mtl_to_assets_convert");
      const res = await mtl_to_assets_convert();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_assets_convert_result: status });
    },

    fuga_artists_upload: async function () {
      const fuga_artists_upload = fb.httpsCallable("fuga_artists_upload");
      const res = await fuga_artists_upload();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ fuga_artists_upload_result: status });
    },

    fuga_artists_update: async function () {
      const fuga_artists_update = fb.httpsCallable("fuga_artists_update");
      const res = await fuga_artists_update();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ fuga_artists_update_result: status });
    },

    fuga_people_update: async function () {
      const fuga_people_update = fb.httpsCallable("fuga_people_update");
      const res = await fuga_people_update();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status")
      //   .doc("fuga_migration_status")
      //   .update({ fuga_person_update_result: status });
    },

    fuga_labels_upload: async function () {
      const fuga_labels_upload = fb.httpsCallable("fuga_labels_upload");
      const res = await fuga_labels_upload();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ fuga_labels_upload_result: status });
    },

    fuga_people_upload: async function () {
      const fuga_people_upload = fb.httpsCallable("fuga_people_upload");
      const res = await fuga_people_upload();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ fuga_people_upload_result: status });
    },

    mtl_assets_language_fix: async function () {
      const mtl_assets_language_fix = fb.httpsCallable(
        "mtl_assets_language_fix"
      );
      const res = await mtl_assets_language_fix();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_assets_language_fix_result: status });
    },

    fuga_subgenres_upload: async function () {
      const fuga_subgenres_upload = fb.httpsCallable("fuga_subgenres_upload");
      const res = await fuga_subgenres_upload();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ fuga_subgenres_upload_result: status });
    },

    mtl_products_update: async function () {
      const mtl_products_update = fb.httpsCallable("mtl_products_update");
      const res = await mtl_products_update();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_products_update_result: status });
    },

    mtl_products_upload: async function () {
      const mtl_products_upload = fb.httpsCallable("mtl_products_upload");
      const res = await mtl_products_upload(
        Number(this.mtl_products_upload_number)
      );
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_products_upload_result: status });
    },

    mtl_assets_update: async function () {
      const mtl_assets_update = fb.httpsCallable("mtl_assets_update");
      const res = await mtl_assets_update();
      console.log(res.data);
      const status = res.data.success && res.data.records;
      db.collection("fuga_migration_status")
        .doc("fuga_migration_status")
        .update({ mtl_assets_update_result: status });
    },

    mtl_assets_upload: async function () {
      const mtl_assets_upload = fb.httpsCallable("mtl_assets_upload");
      const res = await mtl_assets_upload(
        Number(this.mtl_assets_upload_number)
      );
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ mtl_assets_upload_result: status });
    },

    fuga_assets_update: async function () {
      const fuga_assets_update = fb.httpsCallable("fuga_assets_update");
      const res = await fuga_assets_update();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_assets_update_result: status });
    },

    fuga_audio_upload_process: async function () {
      const fuga_audio_upload_process = fb.httpsCallable(
        "fuga_audio_upload_process"
      );
      // const res = await fuga_audio_upload_process({ start_at: 0 });
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_image_upload_process: async function () {
      const fuga_image_upload_process = fb.httpsCallable(
        "fuga_image_upload_process"
      );
      const res = await fuga_image_upload_process({ start_at: 0 });
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_artists_delete_all: async function () {
      const fuga_artists_delete_all = fb.httpsCallable(
        "fuga_artists_delete_all"
      );
      const res = await fuga_artists_delete_all();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_labels_delete_all: async function () {
      const fuga_labels_delete_all = fb.httpsCallable("fuga_labels_delete_all");
      const res = await fuga_labels_delete_all();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_people_delete_all: async function () {
      const fuga_people_delete_all = fb.httpsCallable("fuga_people_delete_all");
      const res = await fuga_people_delete_all();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_get_all: async function () {
      const fuga_assets_get_all = fb.httpsCallable("fuga_assets_get_all");
      const res = await fuga_assets_get_all();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_add_contributors: async function () {
      const mtl_assets_add_contributors = fb.httpsCallable(
        "mtl_assets_add_contributors"
      );
      const res = await mtl_assets_add_contributors();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_contributors_upload: async function () {
      const fuga_assets_contributors_upload = fb.httpsCallable(
        "fuga_assets_contributors_upload"
      );
      const res = await fuga_assets_contributors_upload(
        Number(this.fuga_assets_contributors_upload_number)
      );
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_language_upload: async function () {
      const fuga_assets_language_upload = fb.httpsCallable(
        "fuga_assets_language_upload"
      );
      const res = await fuga_assets_language_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_position_upload: async function () {
      const fuga_assets_position_upload = fb.httpsCallable(
        "fuga_assets_position_upload"
      );
      const res = await fuga_assets_position_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_artists_fix: async function () {
      const fuga_artists_fix = fb.httpsCallable("fuga_artists_fix");
      const res = await fuga_artists_fix();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_people_fix: async function () {
      const fuga_people_fix = fb.httpsCallable("fuga_people_fix");
      const res = await fuga_people_fix();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_find_fuga_gaps: async function () {
      const mtl_assets_find_fuga_gaps = fb.httpsCallable(
        "mtl_assets_find_fuga_gaps"
      );
      const res = await mtl_assets_find_fuga_gaps();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_add_main_artists: async function () {
      const mtl_assets_add_main_artists = fb.httpsCallable(
        "mtl_assets_add_main_artists"
      );
      const res = await mtl_assets_add_main_artists();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_add_featured_artists: async function () {
      const mtl_assets_add_featured_artists = fb.httpsCallable(
        "mtl_assets_add_featured_artists"
      );
      const res = await mtl_assets_add_featured_artists();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_featured_artists_upload: async function () {
      const fuga_assets_featured_artists_upload = fb.httpsCallable(
        "fuga_assets_featured_artists_upload"
      );
      const res = await fuga_assets_featured_artists_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_merge_isrc: async function () {
      console.log("MERGING");
      const mtl_assets_merge_isrc = fb.httpsCallable("mtl_assets_merge_isrc");
      const res = await mtl_assets_merge_isrc();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_add_products: async function () {
      console.log("MERGING");
      const mtl_assets_add_products = fb.httpsCallable(
        "mtl_assets_add_products"
      );
      const res = await mtl_assets_add_products();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_assets_group_by_product: async function () {
      console.log("MERGING");
      const mtl_assets_group_by_product = fb.httpsCallable(
        "mtl_assets_group_by_product"
      );
      const res = await mtl_assets_group_by_product();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_products_edits_upload: async function () {
      console.log("products edit");
      const fuga_products_edits_upload = fb.httpsCallable(
        "fuga_products_edits_upload"
      );
      const res = await fuga_products_edits_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_assets_parental_advisory_upload: async function () {
      console.log("products edit");
      const fuga_assets_parental_advisory_upload = fb.httpsCallable(
        "fuga_assets_parental_advisory_upload"
      );
      const res = await fuga_assets_parental_advisory_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_products_parental_advisory_upload: async function () {
      console.log("products edit");
      const fuga_products_parental_advisory_upload = fb.httpsCallable(
        "fuga_products_parental_advisory_upload"
      );
      const res = await fuga_products_parental_advisory_upload();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_table_download: async function () {
      const fuga_table_download = fb.httpsCallable("fuga_table_download");

      let download_options = {
        end_point: this.fuga_download_table,
        page_size: 500,
      };

      console.log(download_options);

      const fuga_table_res = await fuga_table_download(download_options);
      console.log(fuga_table_res.data);

      // let end_point = "assets";

      // const storageRef = storage.ref();
      // const mtlRef = storageRef.child(`fuga_downloads/fuga_${end_point}.json`);
      // const download_url = await mtlRef.getDownloadURL();

      // let res = await fetch(download_url);
      // let blob = await res.blob();

      // const url = URL.createObjectURL(blob);
      // const anchor = document.createElement("a");
      // anchor.href = url;
      // anchor.download = "mtl_download.csv";
      // anchor.click();

      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    mtl_ddex_fix_artists_ids: async function () {
      const mtl_ddex_fix_artists_ids = fb.httpsCallable(
        "mtl_ddex_fix_artists_ids"
      );
      const res = await mtl_ddex_fix_artists_ids();
      console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_audio_upload_process_result: status });
    },

    fuga_audio_upload_file: async function () {
      const asset = {
        product_upc: "9420058725570",
        track_number: 1,
      };
      const fuga_audio_upload_file = fb.httpsCallable("fuga_audio_upload_file");
      const res = await fuga_audio_upload_file({ asset });

      console.log(JSON.stringify(res.data));

      while (res.data.length) {
        res.splice(0, 10).map((i) => console.log(i));
      }

      // console.log(res.data);
      // const status = res.data.success && res.data.records;
      // db.collection("fuga_migration_status").doc("fuga_migration_status").update({ fuga_assets_update_result: status });
    },

    ddex_download: async function () {
      console.log("downloading");
      this.ddex_downloading = true;
      const ddex_download = fb.httpsCallable("ddex_download");

      const ddex_search_object = {
        start_date: this.ddex_start_date,
        end_date: this.ddex_end_date,
      };

      console.log(ddex_search_object);

      const download_res = await ddex_download(ddex_search_object);
      console.log(download_res.data);

      if (download_res.data.error) {
        this.$toast.warning("DDEX download error" + download_res.data.error, {
          position: "top-right",
          timeout: 5000,
        });
        this.mtl_downloading = false;
        return false;
      }

      const storageRef = storage.ref();
      const ddexRef = storageRef.child(`ddex_downloads/ddex_download.csv`);
      const download_url = await ddexRef.getDownloadURL();

      let res = await fetch(download_url);
      let blob = await res.blob();

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "ddex_download.csv";
      anchor.click();
      this.target_file = "";

      console.log(url);
      this.ddex_downloading = false;
    },
  },
};
</script>

<style scoped>
textarea {
  border: 1px solid #999999;
  width: 100%;
  margin: 5px 0;
  padding: 3px;
}
.my-clipper {
  width: 100%;
  max-width: 700px;
}

.placeholder {
  text-align: center;
  padding: 20px;
  height: 300px;
  background-color: rgba(0, 123, 255, 0.11);
}
</style>
