<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h5 class="text-uppercase">
            <span
              v-if="!release.release_thumbnail_url"
              title="cover art missing for this release"
            >
              <image-icon size="1.5x" class="custom-class"></image-icon>
            </span>
            <img
              v-else
              :src="release.release_thumbnail_url"
              :alt="release.release_title"
              width="40px"
              loading="lazy"
              class="pr-2"
              title="cover art"
            />&nbsp;<span v-if="release.release_title">{{
              release.release_title
            }}</span
            ><span v-if="release.release_main_artist"
              >&nbsp;-&nbsp;{{
                release.release_main_artist && release.release_main_artist.name
              }}</span
            >
          </h5>
        </div>
        <div class="row m-1 mb-2">
          <div class="col">
            <router-link
              :to="{ name: 'ReleaseEdit', params: { release_id: release.id } }"
              class="btn btn-sm btn-outline-success text-nowrap routerlink ms-1"
            >
              <edit-icon size="1.0x"></edit-icon>
              <span class="d-none d-xl-inline-block mx-xl-1"></span>
              <span class="d-none d-xl-inline-block">Edit Release</span>
            </router-link>
          </div>
        </div>
        <hr />

        <div class="row m-1 mt-2">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Search...</span>
              </div>
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="Title, Artist"
              />
              <div class="input-group-append">
                <button
                  @click="clear_search"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div class="col">
            <button @click="create_track" class="btn btn-outline-success">
              Add Track
            </button>

            <router-link
              v-if="tracks.length"
              :to="{
                name: 'ReleaseBulkAudioUpload',
                params: { release_id: release.id },
              }"
              class="btn btn-outline-success routerlink ms-1"
            >
              Upload Audio
            </router-link>
          </div>
        </div>
        <div class="row m-1 mt-2">
          <div class="col">
            <table class="table border-top">
              <thead class="table-dark sticky-top">
                <tr>
                  <th @click="sort_by('track_number')">Track #</th>
                  <th @click="sort_by('track_title')">Track Title</th>
                  <!-- <th @click="sort_date(release_submissions, 'artist_name')">
            Artist Name
          </th> -->
                  <!-- <th>Submitted</th>
          <th>Approved</th> -->
                  <th>Audio File</th>
                  <th>Bit Rate | Sample Rate</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="track in filtered"
                  :key="track.id"
                  class="align-middle"
                >
                  <td>
                    {{ track.track_number }}
                  </td>
                  <td @dblclick="filter_by(track.track_title)">
                    {{ track.track_title }}
                    <span v-if="track.track_subtitle"
                      >&nbsp;({{ track.track_subtitle }})
                    </span>
                  </td>

                  <td
                    v-if="track.track_audio_url"
                    class="align-middle d-none d-sm-table-cell text-left"
                  >
                    <audio v-if="track.track_audio_url" controls>
                      <source
                        :src="track.track_audio_url"
                        type="audio/wav"
                        preload="auto"
                      />
                    </audio>
                  </td>
                  <td v-else class="text-secondary text-center">
                    <span title="audio file missing for this track">
                      <music-icon
                        size="  1.0x"
                        class="text-danger me-2"
                      ></music-icon
                      >Audio File Missing</span
                    >
                  </td>
                  <td>
                    <span v-if="track.track_metadata">
                      {{
                        track.track_metadata &&
                        track.track_metadata.bitrate / 1000
                      }}
                      kb/s |
                      {{
                        track.track_metadata &&
                        track.track_metadata.sampleRate / 1000
                      }}
                      kHz
                    </span>
                  </td>
                  <td class="text-center" title="edit this track">
                    <router-link
                      :to="{
                        name: 'ReleaseTrackEdit',
                        params: { track_id: track.id },
                      }"
                      class="btn btn-sm btn-outline-success"
                    >
                      <edit-icon size="1.0x" class="custom-class"></edit-icon
                      >&nbsp; Edit Track
                    </router-link>
                  </td>
                  <td class="text-center d-none d-sm-table-cell">
                    <button
                      class="btn btn-sm btn-outline-success"
                      @click="copyItem(track)"
                      title="copy this track"
                    >
                      <copy-icon size="1.0x" class="custom-class"></copy-icon
                      >&nbsp;Copy Track
                    </button>
                  </td>
                  <td class="text-center d-none d-sm-table-cell">
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="deleteItem(track)"
                      title="delete this track"
                    >
                      <trash-icon size="1.0x" class="custom-class"></trash-icon>
                      &nbsp;Delete Track
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
import { auth } from "@/firebaseConfig.js";
import { state, actions } from "@/store";
import { orderBy } from "lodash";
import { db, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import filter_mixins from "@/mixins/filter_mixins";

import {
  EditIcon,
  TrashIcon,
  MusicIcon,
  CopyIcon,
  ArrowLeftCircleIcon,
  ImageIcon,
} from "vue-feather-icons";

export default {
  name: "release_tracks_list",

  data() {
    return {
      release: {},
      tracks: [],
      search: "",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
    };
  },
  components: {
    basicLayout,
    ConfirmDialog,
    EditIcon,
    TrashIcon,
    MusicIcon,
    CopyIcon,
    ImageIcon,
    ArrowLeftCircleIcon,
  },
  mixins: [filter_mixins],

  firestore() {
    return {
      release: db
        .collection("release_submissions")
        .doc(this.$route.params.release_id),
      tracks: db
        .collection("release_submissions_tracks")
        .where("release_id", "==", this.$route.params.release_id)
        .orderBy("track_number"),
    };
  },

  methods: {
    deleteItem: async function (track) {
      let confirm_delete = await this.$refs.confirmDialog.show({
        confirm_title: "Delete Release",
        confirm_message:
          "Are you sure you want to delete this track? This action cannot be undone.",
        confirm_ok_button: "Delete",
      });

      if (!confirm_delete) return;
      actions.setLoading(true);
      db.collection("release_submissions_tracks").doc(track.id).delete();
      this.deleteAudio(track);
      actions.setLoading(false);
    },

    deleteAudio(track) {
      const storageRef = storage.ref();
      const audio = storageRef.child(
        `tracks/${track.release_id}/${track.id}.wav`
      );
      audio.delete();
    },

    create_track: async function () {
      const highest_track_number =
        (this.tracks.length &&
          Math.max(...this.tracks.map((s) => s.track_number))) ||
        0;
      const track = {
        release_id: this.release.id,
        track_owner_org_id: this.release.release_owner_org.id,
        track_created_by: this.currentUser.uid,
        track_number: Number(highest_track_number + 1),
        track_volume_number: 1,
        track_created: new Date(),
        track_isrc: "",
        track_title: `[NEW TRACK ${highest_track_number + 1}]`,
        track_version_title: "",
        track_main_artists: (this.release.release_primary_artist && [
          { ...this.release.release_primary_artist },
        ]) || [{ name: "" }],
        track_featured_artists: [{ name: "" }],
        track_remix_artists: [{ name: "" }],
        track_composers: [{ name: "" }],
        track_lyricists: [{ name: "" }],
        track_producers: [{ name: "" }],
        track_genre: this.release.release_genre || { name: "" },
        track_subgenre: this.release.release_subgenre || { name: "" },
        track_type: "Original",
        track_parental_advisory: false,
        track_preview_audio_start: 0,
        track_preview_audio_length: 60,
        track_upload_progress: 0,
        is_existing_track: false,
        is_incomplete: true,
      };

      const new_track = await db
        .collection("release_submissions_tracks")
        .add(track);
      const new_track_id = new_track.id;
      this.$router.push({
        name: "ReleaseTrackEdit",
        params: { track_id: new_track_id },
      });
    },

    copyItem(track) {
      const highest_track_number = Number(
        Math.max(...this.tracks.map((s) => s.track_number))
      );

      const copy_track = { ...track };
      copy_track.track_created = new Date();
      copy_track.track_created_by = this.currentUser.uid;
      copy_track.track_number = Number(highest_track_number + 1);
      copy_track.track_title = `${track.track_title} [COPY]`;
      copy_track.track_volume_number = 1;
      copy_track.track_audio_url = "";
      copy_track.track_preview_audio_start = 0;
      copy_track.track_preview_audio_length = 60;
      copy_track.track_isrc = "";

      db.collection("release_submissions_tracks").add(copy_track);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      this.search = filter;
    },
  },

  computed: {
    filtered() {
      let visible_tracks = this.tracks.slice();

      const search = this.search;

      visible_tracks = orderBy(visible_tracks, "track_number", "asc"); // becuause some old tracks have track_number as string

      if (this.sort_column) {
        visible_tracks = orderBy(
          visible_tracks,
          (user) => user[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_tracks = visible_tracks.filter((track) => {
        const s = track.track_title + track.track_main_artist;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      return visible_tracks;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
  },
};
</script>
<style scoped>
.table > tbody > tr > td {
  vertical-align: middle;
}
</style>
