<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Contacts</h2>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-6">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Name or Email"
            />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="col-xl-3">
          <button @click="download_contacts" class="btn btn-outline-secondary">
            Export Contacts
          </button>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-8">
          <div class="card mb-3">
            <div class="card-header">
              <div class="btn-group" data-toggle="buttons">
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: contacts_filter === 'unrated',
                  }"
                >
                  <input
                    v-model="contacts_filter"
                    value="unrated"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  Unrated
                </label>

                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: contacts_filter === 'rated',
                  }"
                >
                  <input
                    v-model="contacts_filter"
                    value="rated"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  Rated
                </label>

                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: contacts_filter === 'accepted',
                  }"
                >
                  <input
                    v-model="contacts_filter"
                    value="accepted"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  Accepted
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: contacts_filter === 'user',
                  }"
                >
                  <input
                    v-model="contacts_filter"
                    value="user"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  User
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: contacts_filter === 'rejected' }"
                >
                  <input
                    v-model="contacts_filter"
                    value="rejected"
                    type="radio"
                    class="btn-check"
                    autocomplete="off"
                  />
                  Rejected
                </label>

                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: contacts_filter === 'all',
                  }"
                >
                  <input
                    v-model="contacts_filter"
                    value="all"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off"
                  />
                  All
                </label>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-sm table-responsive table-hover">
                <thead class="table-dark sticky-top">
                  <tr>
                    <th
                      @click="sort_by('is_accepted')"
                      class="d-none d-xl-table-cell w-5"
                    ></th>
                    <th
                      @click="sort_by('date_created')"
                      role="button"
                      class="text-left w-20"
                      scope="col"
                      title="sort by date created"
                    >
                      Submitted
                      <span
                        v-if="
                          sort_column === 'date_created' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'date_created' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('name')"
                      role="button"
                      class="text-left w-20"
                      scope="col"
                      title="sort by name"
                    >
                      Name
                      <span
                        v-if="sort_column === 'name' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'name' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('artist_name')"
                      role="button"
                      class="text-left w-20"
                      scope="col"
                      title="sort by artist"
                    >
                      Artist
                      <span
                        v-if="
                          sort_column === 'artist_name' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'artist_name' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('email')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-20"
                      scope="col"
                    >
                      Email
                      <span
                        v-if="sort_column === 'email' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'email' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th class="w-5">Edit</th>
                    <th class="d-none d-xl-table-cell w-5">Contract</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contact in filtered"
                    :key="contact.id"
                    @click="select_contact(contact)"
                    :class="{
                      'alert alert-secondary':
                        selected_contact.id === contact.id,
                    }"
                  >
                    <td class="d-none d-xl-table-cell w-5">
                      <span
                        v-if="contact.is_accepted && !contact.is_user"
                        class="badge rounded-pill bg-success"
                        >A</span
                      >
                      <span
                        v-else-if="contact.is_rejected"
                        class="badge rounded-pill bg-danger"
                        >R</span
                      >
                      <span
                        v-else-if="contact.is_user"
                        class="badge rounded-pill bg-primary"
                        >U</span
                      >
                      <span v-else class="badge rounded-pill bg-secondary"
                        >-</span
                      >
                    </td>
                    <td class="text-left">
                      {{ contact.date_created | moment_short }}
                    </td>
                    <td class="text-left">{{ contact.name }}</td>
                    <td class="text-left">{{ contact.artist_name }}</td>

                    <td
                      class="d-none d-xl-table-cell text-left text-secondary"
                      title="email contact"
                    >
                      <a
                        class="text-secondary"
                        :href="`mailto:${contact.email}`"
                        ><mail-icon size="1.0x" class="me-2"></mail-icon></a
                      >{{ contact.email }}
                    </td>

                    <td
                      v-if="contact.user_id"
                      title="edit user"
                      class="w-5 text-center"
                    >
                      <router-link
                        :to="{
                          name: 'UserProfile',
                          params: { user_id: contact.user_id },
                        }"
                      >
                        <edit-icon size="1x" class="text-primary"></edit-icon>
                      </router-link>
                    </td>
                    <td v-else title="edit contact" class="w-5 text-center">
                      <router-link
                        :to="{
                          name: 'ContactProfile',
                          params: { contact_id: contact.id },
                        }"
                      >
                        <edit-icon size="1x" class="text-success"></edit-icon>
                      </router-link>
                    </td>

                    <td class="d-none d-xl-table-cell w-5 text-center">
                      <router-link
                        v-if="contact.is_accepted && !contact.is_user"
                        title="sign contract"
                        :to="{
                          name: 'ContactUpdate',
                          params: { contact_id: contact.id },
                        }"
                      >
                        <feather-icon
                          size="1x"
                          class="text-success"
                        ></feather-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="row sticky-top">
            <div class="col">
              <ContactCard :contact_id="selected_contact.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// import { Popover } from "bootstrap";
import { auth } from "@/firebaseConfig.js";
import { db } from "@/firebaseConfig";
import { state } from "@/store";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import ContactCard from "@/components/ContactCard";
import filter_mixins from "@/mixins/filter_mixins";
import {
  EditIcon,
  // TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  // MusicIcon,
  MailIcon,
  FeatherIcon,
} from "vue-feather-icons";

const contacts = db.collection("contacts");

export default {
  name: "contacts",
  data() {
    return {
      display_contacts: [],
      selected_contact: {},
      selected_contact_id: "",
      search: "",
      contacts_filter: this.$route.query.filter || "unrated",
      debounce: null,
      sort_column: ["date_created"],
      sort_order: "desc",
    };
  },
  components: {
    basicLayout,
    ContactCard,
    EditIcon,
    // TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    // MusicIcon,
    MailIcon,
    FeatherIcon,
  },
  mixins: [filter_mixins],

  beforeRouteUpdate(to, from, next) {
    console.log("route update query to filter: " + to.query.filter);
    console.log("route update query from filter: " + from.query.filter);
    this.contacts_filter = to.query.filter;
    next();
  },

  // mounted() {
  //   console.log("mounted query filter: " + this.$route.query.filter);
  //   this.contacts_filter = this.$route.query.filter || "unrated";
  // },

  methods: {
    data_update(e) {
      console.log(e);
    },
    select_contact(contact) {
      this.selected_contact = contact;
      this.selected_contact_id = contact.id;
    },

    delete_contact(id) {
      db.collection("contacts").doc(id).delete();
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 750);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },
    url_file_extension(url) {
      console.log(url);
      try {
        let new_url = url && new URL(url);
        let filename = new_url && new_url.pathname.split("/").reverse()[0];
        let ext = (filename && filename.split(".")[1]) || "";
        return ext;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    update_status(status) {
      if (status == "accept") {
        db.collection("contacts")
          .doc(this.selected_contact.id)
          .update({ is_accepted: true });

        this.$router.push({
          name: "ContactProfile",
          params: { contact_id: this.selected_contact.id },
        });
      } else if (status == "reject") {
        db.collection("contacts")
          .doc(this.selected_contact.id)
          .update({ is_rejected: true });
      }
    },

    contact_vote(status) {
      let user_name = this.userProfile.name;

      console.log(status);

      db.collection("contacts")
        .doc(this.selected_contact.id)
        .update({ name: user_name });
    },

    download_contacts() {
      const download_contacts = this.filtered.map((contact) => {
        const artist = (contact.artist && contact.artist_name) || "";
        const org = (contact.org && contact.org.name) || "";
        const voters_yes =
          (contact.voters_yes.length && contact.voters_yes.join("; ")) || "";
        const voters_no =
          (contact.voters_no.length && contact.voters_no.join("; ")) || "";
        const voters_maybe =
          (contact.voters_maybe.length && contact.voters_maybe.join("; ")) ||
          "";

        return {
          name: contact.name,
          email: contact.email,
          notes: contact.notes,
          voters_yes,
          voters_no,
          voters_maybe,
          is_unrated: contact.is_unrated,
          is_accepted: contact.is_accepted,
          is_rejected: contact.is_rejected,
          is_bound_by_terms: contact.is_bound_by_terms,
          is_user: contact.is_user,
          artist,
          org,
        };
      });

      const csv = Papa.unparse({
        data: download_contacts,
        fields: [
          "name",
          "email",
          "artist",
          "notes",
          "org",
          "voters_yes",
          "voters_no",
          "voters_maybe",
          "is_unrated",
          "is_accepted",
          "is_rejected",
          "is_user",
        ],
        quotes: true,
      });
      const csv_name = "contacts.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
  },
  computed: {
    filtered: function () {
      let visible_contacts = this.display_contacts.slice();

      const search = this.search;

      // return orderBy(visible_contacts, "role", "desc");

      if (this.sort_column) {
        visible_contacts = orderBy(
          visible_contacts,
          (contact) => contact[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_contacts = visible_contacts.filter((contact) => {
        const s = contact.name + contact.email + contact.artist_name;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });
      return visible_contacts;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
  },

  watch: {
    contacts_filter: {
      immediate: true,
      handler(filter) {
        let query = contacts;
        if (filter && filter === "unrated") {
          query = query
            .where("is_unrated", "==", true)
            .where("is_accepted", "==", false)
            .where("is_rejected", "==", false);
        } else if (filter && filter === "rated") {
          query = query
            .where("is_unrated", "==", false)
            .where("is_accepted", "==", false)
            .where("is_rejected", "==", false);
        } else if (filter && filter === "accepted") {
          query = query
            .where("is_accepted", "==", true)
            .where("is_user", "==", false);
        } else if (filter && filter === "rejected") {
          query = query.where("is_rejected", "==", true);
        } else if (filter && filter === "user") {
          query = query.where("is_user", "==", true);
        }
        this.$bind("display_contacts", query);

        if (this.$route.query.filter !== filter) {
          this.$router.replace({
            ...this.$route,
            query: {
              filter: filter,
            },
          });
        }
      },
    },

    filtered(new_filter) {
      let contact_in_view = new_filter.filter(
        (c) => c.id === this.selected_contact_id
      );
      this.selected_contact = contact_in_view.length && contact_in_view[0];
    },
  },
};
</script>
