 <template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Royalties</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-6">
          <div class="imb-3">
            <label for="inputGroupSelect01">Select an Organisation</label>

            <v-select
              v-model="owner_org"
              :options="display_orgs"
              :selectOnTab="true"
              :clearable="true"></v-select>
          </div>
        </div>
      </div>

      <div
        v-if="owner_org.royalties_name_code"
        class="row m-3">
        <div class="col-xl-8">
          <div class="card">
            <div class="card-header">
              <div
                class="btn-group"
                data-toggle="buttons">
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: royalties_filter === 'statement',
                  }">
                  <input
                    v-model="royalties_filter"
                    value="statement"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off" />
                  Statement
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: royalties_filter === 'report' }">
                  <input
                    v-model="royalties_filter"
                    value="report"
                    type="radio"
                    class="btn-check"
                    autocomplete="off" />
                  Report
                </label>
                <label
                  class="btn btn-sm btn-outline-secondary"
                  :class="{
                    active: royalties_filter === 'all',
                  }">
                  <input
                    v-model="royalties_filter"
                    value="all"
                    type="radio"
                    class="btn-check nav-link"
                    autocomplete="off" />
                  All
                </label>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-hover">
                <thead class="table-dark">
                  <tr>
                    <th class="w-5"></th>
                    <th>Name</th>
                    <th class="text-center w-10">Type</th>
                    <th class="text-center w-10">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="file in filtered"
                    :key="file.name"
                    @click="select_file(file)">
                    <td>
                      <file-text-icon
                        size="1.5x"
                        :class="file_class(file)"></file-text-icon>
                    </td>
                    <td>
                      {{ file.name }}
                    </td>
                    <td class="text-center">{{ file_extension(file) }}</td>
                    <td
                      v-show="target_file == file.name"
                      class="text-center">
                      <div
                        class="spinner-border spinner-border-sm text-secondary"
                        role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </td>
                    <td
                      v-show="target_file != file.name"
                      class="text-center">
                      <span
                        @click.prevent="get_blob(file)"
                        role="button"
                        title="download this file"
                        ><download-icon
                          size="1.5x"
                          class="text-secondary"></download-icon>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import { db } from "@/firebaseConfig";
import { royalties_storage } from "@/firebaseConfig";
import { state } from "@/store";
import { orderBy } from "lodash";
import { FolderIcon, FileTextIcon, DownloadIcon, LoaderIcon } from "vue-feather-icons";

const orgs = db.collection("orgs");

export default {
  name: "dashboard",
  data() {
    return {
      visible_orgs: [{ name: "", label: "" }],
      owner_org: { name: "", label: "" },
      files: [],
      search: "",
      royalties_filter: "statement",
      selected_file: {},
      target_file: "",
    };
  },
  components: { basicLayout, FolderIcon, FileTextIcon, DownloadIcon, LoaderIcon },

  methods: {
    get_storage_files: async function (royalties_name_code) {
      // console.log("fetching files");
      const storageRef = royalties_storage.ref().child(royalties_name_code);
      const list = await storageRef.listAll();
      this.files = list.items;
    },

    select_file(file) {
      this.selected_file = file;
    },
    clear_search() {
      this.search = "";
    },
    file_extension(file) {
      // console.log(file);
      return file && file.name && file.name.includes(".pdf") ? "statement" : "report";
    },
    file_class(file) {
      // console.log(file);
      return file && file.name && file.name.includes(".pdf") ? "text-danger opacity-75" : "text-success opacity-75";
    },

    get_blob: async function (file) {
      this.target_file = file.name;
      console.log(file.name);
      let download_url = await file.getDownloadURL();
      let res = await fetch(download_url);
      let blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = file.name;
      anchor.click();
      this.target_file = "";
    },
  },
  computed: {
    display_orgs: function () {
      let orgs = this.visible_orgs.slice();

      orgs = orgs.map((o) => {
        o.label = `${o.name} (${o.royalties_name_code})`;

        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },

    filtered: function () {
      let visible_royalties = this.files.filter((r) => !r.name.includes(".php")).slice();

      const search = this.search;

      if (this.royalties_filter == "statement") {
        visible_royalties = visible_royalties.filter((r) => r.name.includes(".pdf"));
      } else if (this.royalties_filter == "report") {
        visible_royalties = visible_royalties.filter((r) => r.name.includes(".xls"));
      }

      visible_royalties = visible_royalties.map((r) => {
        r.number = Number(r.name.match(/No(\d+)/)[1]);
        console.log(r.number);
        return r;
      });

      visible_royalties = orderBy(visible_royalties, "number", "desc");

      visible_royalties = visible_royalties.filter((org) => {
        const s = org.name + org.link;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });
      return visible_royalties;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role === "superadmin";
    },
  },

  created() {
    if (this.isAdmin) {
      this.$bind("visible_orgs", orgs);
    } else {
      let query = orgs.where(
        "__name__",
        "in",
        this.userProfile.orgs.map((org) => org.id)
      );
      this.$bind("visible_orgs", query);
    }
  },

  watch: {
    owner_org: {
      immediate: true,
      handler(org) {
        if (org.royalties_name_code) {
          this.get_storage_files(org.royalties_name_code);
        } else {
          this.files = [];
        }
      },
    },
  },
};
</script>

<style scoped>
@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
