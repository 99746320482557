var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{staticClass:"btn btn-sm btn-block btn-outline-success text-nowrap routerlink",attrs:{"to":{
      name: 'ReleaseTracksList',
      params: { release_id: _vm.release_id },
    }}},[_c('music-icon',{attrs:{"size":"1.2x"}}),_c('span',{staticClass:"d-none d-xl-inline-block ms-2"},[_vm._v("Edit Tracks")]),_c('span',{staticClass:"mx-1"}),_c('span',{staticClass:"badge",class:{
        'badge-success bg-success': _vm.tracks_complete(_vm.release_id),
        'badge-warning bg-secondary':
          !_vm.tracks_complete(_vm.release_id) || !_vm.tracks_count(_vm.release_id),
      },attrs:{"title":!_vm.tracks_complete(_vm.release_id) || !_vm.tracks_count(_vm.release_id)
          ? 'missing or incomplete tracks'
          : ''}},[_vm._v(_vm._s(_vm.tracks_count(_vm.release_id)))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }