<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Users</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">Search...</span>

                <input
                  v-model="search"
                  type="text"
                  class="form-control"
                  placeholder="Name or Email"
                />

                <button
                  @click="clear_search"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
            <div class="col-xl-3">
              <router-link
                :to="{ name: 'UserCreate' }"
                type="button"
                class="btn btn-outline-secondary"
                >Create User</router-link
              >
              <!-- <button
                @click="download_users"
                class="btn btn-outline-secondary ms-3">
                Export Users
              </button> -->

              <div class="btn-group ms-3" role="group">
                <button
                  id="btnGroupDrop1"
                  type="button"
                  class="btn btn-outline-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Export
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <span
                      @click="download_users('selection')"
                      class="dropdown-item"
                    >
                      <download-icon
                        size="1.2x"
                        class="custom-class me-2"
                      ></download-icon
                      ><span class="d-none d-xl-inline-block ml-1"
                        >Export Selection</span
                      ><span class="badge bg-light text-dark ms-2">{{
                        filtered.length
                      }}</span></span
                    >
                  </li>
                  <li>
                    <span @click="download_users('all')" class="dropdown-item">
                      <download-icon
                        size="1.2x"
                        class="custom-class me-2"
                      ></download-icon
                      ><span class="d-none d-xl-inline-block ml-1"
                        >Export All</span
                      ><span class="badge bg-light text-dark ms-2">{{
                        display_users.length
                      }}</span></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-6">
                  <div class="btn-group" data-toggle="buttons">
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: users_filter === 'user' }"
                    >
                      <input
                        v-model="users_filter"
                        value="user"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      User
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: users_filter === 'agent' }"
                    >
                      <input
                        v-model="users_filter"
                        value="agent"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Agent
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: users_filter === 'admin',
                      }"
                    >
                      <input
                        v-model="users_filter"
                        value="admin"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Admin
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: users_filter === 'inactive',
                      }"
                    >
                      <input
                        v-model="users_filter"
                        value="inactive"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Inactive
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: users_filter === 'all',
                      }"
                    >
                      <input
                        v-model="users_filter"
                        value="all"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      All
                    </label>
                  </div>
                </div>

                <div class="col-xl-6 text-end">
                  Showing {{ filtered.length }} of {{ display_users.length }}
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-sm table-hover">
                <thead class="table-dark sticky-top">
                  <tr>
                    <th
                      @click="sort_by('name')"
                      role="button"
                      class="text-left w-10"
                      scope="col"
                    >
                      Name
                      <span
                        v-if="sort_column === 'name' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'name' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('email')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-10"
                      scope="col"
                    >
                      Email
                      <span
                        v-if="sort_column === 'email' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'email' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      class="d-none d-xl-table-cell text-left w-10"
                      scope="col"
                    >
                      Mobile
                    </th>
                    <th
                      class="d-none d-xl-table-cell text-left w-20"
                      scope="col"
                    >
                      Orgs
                    </th>
                    <th
                      @click="sort_by('role')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-5"
                      scope="col"
                    >
                      Role
                      <span
                        v-if="sort_column === 'role' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'role' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('last_login')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-10"
                      scope="col"
                    >
                      Last Login
                      <span
                        v-if="
                          sort_column === 'last_login' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'last_login' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in filtered" :key="user.order_id">
                    <td class="text-left">{{ user.name }}</td>
                    <td class="d-none d-xl-table-cell text-left text-secondary">
                      <a
                        class="text-secondary"
                        :href="`mailto:${user.email}`"
                        title="email user"
                        ><mail-icon size="1.0x" class="me-2"></mail-icon></a
                      >{{ user.email }}
                    </td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ user.mobile }}
                    </td>
                    <!-- <td
                      class="d-none d-xl-table-cell text-left"
                      :title="user.orgs && user.orgs.map((o) => o.name).join('\n')">
                      {{ user.orgs && user.orgs.length }}
                    </td> -->

                    <td
                      class="d-none d-xl-table-cell text-left"
                      :title="
                        user.orgs && user.orgs.map((o) => o.name).join('\n')
                      "
                    >
                      <span class="badge bg-light text-dark ms-2">{{
                        user.orgs && user.orgs.length
                      }}</span>
                      {{ orgs_string(user.orgs) }}
                    </td>

                    <td
                      @dblclick="filter_by(user.role)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ user.role }}
                    </td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ moment_short(user.last_login) }}
                    </td>
                    <td class="w-5 text-center" title="edit user">
                      <router-link
                        :to="{
                          name: 'UserProfile',
                          params: { user_id: user.id },
                        }"
                      >
                        <edit-icon size="1.0x" class="text-success"></edit-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage"
      />
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import moment from "moment";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import {
  EditIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MailIcon,
  UserXIcon,
  UserCheckIcon,
  DownloadIcon,
} from "vue-feather-icons";

const users = db.collection("users");

export default {
  name: "Users",
  data() {
    return {
      display_users: [],
      search: "",
      users_filter: this.$route.query.filter || "user",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
      page_limit: 30,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    EditIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    MailIcon,
    UserXIcon,
    UserCheckIcon,
    DownloadIcon,
  },

  // mounted() {
  //   this.users_filter = this.$route.query.filter || "user";
  // },

  beforeRouteUpdate(to, from, next) {
    console.log(to.query.filter);
    this.users_filter = to.query.filter;
    next();
  },

  methods: {
    delete_user(id) {
      // if (this.confirm_delete) {
      const deleteUser = fb.httpsCallable("deleteUser");
      deleteUser({ id: id }).then((result) => {
        console.log(JSON.stringify(result));
      });
    },

    deactivate_user(id) {
      db.collection("users").doc(id).update({ is_active: false });
      this.users_filter = "inactive";
    },

    reactivate_user(id) {
      db.collection("users").doc(id).update({ is_active: true });
      this.users_filter = "all";
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 750);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },

    download_users(selection) {
      let download_users;

      if (selection === "selection") {
        download_users = [...this.filtered];
      } else if (selection === "all") {
        download_users = [...this.display_users];
      }

      download_users = download_users.slice().map((u) => {
        console.log(u);
        u.orgs_string = u.orgs.map((o) => o.name).join(";");
        u.last_login_date =
          (u.last_login &&
            new Date(u.last_login.seconds * 1000).toLocaleString("en-NZ")) ||
          "";
        u.date_created_string =
          (u.date_created &&
            new Date(u.date_created.seconds * 1000).toLocaleString("en-NZ")) ||
          "";

        return u;
      });

      const csv = Papa.unparse({
        data: download_users,
        fields: [
          "id",
          "name",
          "email",
          "mobile",
          "role",
          "orgs_string",
          "date_created_string",
          "is_active",
          "last_login_date",
        ],
        quotes: true,
      });
      const csv_name = "users.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
    moment_short: function (date) {
      return (date && moment.utc(date.toDate()).local().format("lll")) || "";
    },
    date_short: function (date) {
      return (date && moment.utc(date).local().format("lll")) || "";
    },
    orgs_string(orgs) {
      let orgs_string = orgs.map((o) => o.name).join("; ");

      if (orgs_string.length > 50) {
        return `${orgs_string.substring(0, 50)}...`;
      } else {
        return orgs_string;
      }
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
  },
  computed: {
    filtered: function () {
      let visible_users = this.display_users.slice();

      const search = this.search;

      let sort_column = this.sort_column;

      if (sort_column) {
        visible_users = orderBy(
          visible_users,
          sort_column || "",
          this.sort_order
        );
      }

      visible_users = visible_users.filter((user) => {
        const s =
          user.name +
          user.email +
          (user.orgs && user.orgs.map((o) => o.name)) +
          user.role;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_users = visible_users.slice(start, end);
      }

      return visible_users;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.display_users;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
  watch: {
    users_filter: {
      immediate: true,
      handler(filter) {
        let query = users;
        if (filter && filter === "admin") {
          query = query.where("role", "==", "admin");
        } else if (filter && filter === "agent") {
          query = query.where("role", "==", "agent");
        } else if (filter && filter === "user") {
          query = query.where("role", "==", "user");
        } else if (filter && filter === "inactive") {
          query = query.where("is_active", "==", false);
        }
        this.$bind("display_users", query);

        if (this.$route.query.filter !== filter) {
          this.$router.replace({
            ...this.$route,
            query: {
              filter: filter,
            },
          });
        }
      },
    },
  },
};
</script>
