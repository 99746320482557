import { render, staticRenderFns } from "./ContactAudioUpload.vue?vue&type=template&id=2d6a4b58&scoped=true&"
import script from "./ContactAudioUpload.vue?vue&type=script&lang=js&"
export * from "./ContactAudioUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d6a4b58",
  null
  
)

export default component.exports