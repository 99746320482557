<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Pending Releases</h2>
        </div>
      </div>
      <hr />
      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Owner, Title, Artist, Tier, UPC, ID ..."
            />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="col-xl-6 mt-2">
          <button @click="create_release" class="btn btn-outline-success mr-2">
            New Release
          </button>
          <router-link
            :to="{ name: 'ReleaseBulkMetaUpload' }"
            class="btn btn-outline-success ms-1"
            >Upload Release</router-link
          >
        </div>
      </div>

      <div class="row m-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-8">
                  <div class="btn-group" data-toggle="buttons">
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'draft',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="draft"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Draft
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'is_submitted_to_drm',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="is_submitted_to_drm"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Submitted to DRM
                    </label>

                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: lines_filter === 'is_approved_by_drm' }"
                    >
                      <input
                        v-model="lines_filter"
                        value="is_approved_by_drm"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Approved by DRM
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'is_uploaded',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="is_uploaded"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Uploaded
                    </label>

                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'is_archived',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="is_archived"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Archived
                    </label>

                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'all',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="all"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      All
                    </label>
                    <!-- <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: lines_filter === 'is_residual',
                      }"
                    >
                      <input
                        v-model="lines_filter"
                        value="is_residual"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Residual
                    </label> -->
                  </div>
                </div>
                <div class="col text-end">
                  Showing {{ filtered.length }} of {{ display_releases.length }}
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row m-1">
                <div class="col">
                  <table class="table table-sm">
                    <thead class="table-secondary sticky-top">
                      <tr>
                        <!-- cover -->
                        <th
                          @click="sort_by('release_art_url')"
                          role="button"
                          class="text-center w-5"
                        >
                          <image-icon
                            size="1.2x"
                            class="custom-class"
                          ></image-icon>
                          <span
                            v-if="
                              sort_column === 'release_art_url' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_art_url' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- owner -->
                        <th
                          @click="sort_by('release_owner_org.name')"
                          role="button"
                          class="w-10"
                        >
                          Owner
                          <span
                            v-if="
                              sort_column === 'release_owner_org.name' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_owner_org.name' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- UPC -->
                        <th
                          v-if="
                            lines_filter === 'is_uploaded' ||
                            lines_filter === 'is_released_to_mtl'
                          "
                          @click="sort_by('release_upc')"
                          role="button"
                          class="w-10"
                        >
                          UPC
                          <span
                            v-if="
                              sort_column === 'release_upc' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_upc' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- title -->
                        <th
                          @click="sort_by('release_title')"
                          role="button"
                          class="w-15"
                        >
                          Title
                          <span
                            v-if="
                              sort_column === 'release_title' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_title' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- main_artist -->
                        <th
                          @click="sort_by('release_main_artist.name')"
                          role="button"
                          class="w-10"
                        >
                          Artist
                          <span
                            v-if="
                              sort_column === 'release_primary_artist.name' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_primary_artist.name' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- release -->
                        <th
                          @click="sort_by('release_release_date')"
                          role="button"
                          class="w-5"
                        >
                          Release

                          <span
                            v-if="
                              sort_column === 'release_release_date' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_release_date' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- tier -->
                        <th
                          @click="sort_by('release_primary_artist.tier')"
                          role="button"
                          class="w-5"
                        >
                          Tier

                          <span
                            v-if="
                              sort_column === 'release_primary_artist.tier' &&
                              sort_order === 'desc'
                            "
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-down-icon
                          ></span>
                          <span
                            v-if="
                              sort_column === 'release_primary_artist.tier' &&
                              sort_order === 'asc'
                            "
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"
                            ></chevron-up-icon
                          ></span>
                        </th>
                        <!-- preview -->
                        <th class="w-5"></th>
                        <!-- edit release-->
                        <th class="w-5"></th>
                        <!-- edit tracks -->
                        <th class="w-5"></th>
                        <!-- ddex -->
                        <th class="w-5"></th>
                        <!-- dropdown -->
                        <th class="w-5"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="release in filtered"
                        :key="release.id"
                        class="align-left"
                      >
                        <!-- cover -->
                        <td
                          v-if="release.release_art_url"
                          class="align-middle d-none d-sm-table-cell text-center"
                          title="cover art"
                        >
                          <img
                            :src="release.release_thumbnail_url"
                            :alt="release.release_title"
                            width="30px"
                          />
                        </td>

                        <td v-else class="text-secondary text-center">
                          <span title="cover art is missing for this release">
                            <image-icon
                              size="1.5x"
                              class="custom-class"
                            ></image-icon
                          ></span>
                        </td>
                        <!-- owner -->
                        <td
                          @dblclick="filter_by(release.release_owner_org.name)"
                        >
                          {{
                            (release.release_owner_org &&
                              release.release_owner_org.name) ||
                            ""
                          }}
                        </td>
                        <!-- upc -->
                        <td
                          v-if="
                            lines_filter === 'is_uploaded' ||
                            lines_filter === 'is_released_to_mtl'
                          "
                        >
                          <a
                            v-if="lines_filter === 'is_released_to_mtl'"
                            class="text-secondary"
                            :href="`/master-track-list?search=${release.release_upc}`"
                            title="See on MTL"
                            ><archive-icon size="1x" class="me-2"></archive-icon
                          ></a>

                          {{ release.release_upc }}
                        </td>
                        <!-- title -->
                        <td @dblclick="filter_by(release.release_title)">
                          {{ release.release_title }}
                        </td>
                        <!-- artist -->
                        <td
                          @dblclick="
                            filter_by(release.release_primary_artist.name)
                          "
                          class="d-none d-xl-table-cell"
                        >
                          {{
                            (release.release_primary_artist &&
                              release.release_primary_artist.name) ||
                            ""
                          }}
                        </td>
                        <!-- release_date -->
                        <td>{{ release.release_release_date }}</td>
                        <!-- tier -->
                        <td
                          @dblclick="
                            filter_by(release.release_primary_artist.tier)
                          "
                          class="d-none d-xl-table-cell"
                        >
                          {{
                            (release.release_primary_artist &&
                              release.release_primary_artist.tier) ||
                            ""
                          }}
                        </td>
                        <!-- preview -->
                        <td class="text-center" title="preview this release">
                          <router-link
                            :to="{
                              name: 'ReleasePreview_Fuga',
                              params: { release_id: release.id },
                              query: $route.query,
                            }"
                            class="btn btn-sm btn-block text-nowrap routerlink"
                            :class="
                              release.is_incomplete
                                ? 'btn-outline-secondary'
                                : 'btn-outline-success'
                            "
                          >
                            <eye-icon
                              size="1.2x"
                              class="custom-class"
                            ></eye-icon>

                            <span class="ms-2">Preview</span>
                          </router-link>
                        </td>
                        <!-- edit_release -->
                        <td class="text-center" title="edit this release">
                          <router-link
                            :to="{
                              name: 'ReleaseEdit',
                              params: { release_id: release.id },
                              query: $route.query,
                            }"
                            class="btn btn-sm btn-block text-nowrap routerlink"
                            :class="
                              release.is_incomplete
                                ? 'btn-outline-secondary'
                                : 'btn-outline-success'
                            "
                          >
                            <edit-icon size="1.2x"></edit-icon>

                            <span class="d-none d-xl-inline-block ms-2"
                              >Edit Release</span
                            >
                          </router-link>
                        </td>
                        <!-- edit tracks -->
                        <td class="text-center" title="edit release tracks">
                          <ReleaseTracksLinkButton
                            :release_id="release.id"
                          ></ReleaseTracksLinkButton>
                        </td>
                        <!-- approve / ddex -->

                        <td
                          v-if="
                            release.is_submitted_to_drm &&
                            !release.is_approved_by_drm
                          "
                          class="text-center"
                        >
                          <button
                            @click="approve_release(release)"
                            class="btn btn-sm btn-block text-nowrap btn-success"
                          >
                            <thumbs-up-icon
                              size="1.2x"
                              class="me-2"
                            ></thumbs-up-icon>
                            <span class="d-none d-xl-inline-block ms-2"
                              >Approve</span
                            >
                          </button>
                        </td>

                        <td
                          v-else-if="release.is_approved_by_drm"
                          class="text-center"
                        >
                          <div
                            class="btn-group btn-block"
                            role="group"
                            aria-label="Basic example"
                          >
                            <router-link
                              :to="{
                                name: 'ReleaseFugaUpload',
                                params: { release_id: release.id },
                                query: $route.query,
                              }"
                              class="btn btn-sm text-nowrap"
                              :class="{
                                'btn-success': release.fuga_id,
                                'btn-outline-success': !release.fuga_id,
                                'btn-danger': release.is_fuga_upload_error,
                              }"
                              :title="`FUGA Id: ${release.fuga_id}`"
                            >
                              <code-icon size="1.2x" class="me-2"></code-icon>

                              <span class="d-none d-xl-inline-block ms-2"
                                >FUGA</span
                              >
                            </router-link>

                            <router-link
                              :to="{
                                name: 'ReleaseDDEXUpload',
                                params: { release_id: release.id },
                                query: $route.query,
                              }"
                              class="btn btn-sm text-nowrap"
                              :class="status_class(release)"
                            >
                              <code-icon size="1.2x" class="me-2"></code-icon>
                              <span class="d-none d-xl-inline-block ms-2"
                                >DDEX</span
                              >
                            </router-link>

                            <button
                              @click="archive(release)"
                              type="button"
                              class="btn btn-sm btn-outline-secondary text-nowrap"
                              title="archive release"
                            >
                              <archive-icon size="1.2x"></archive-icon>
                            </button>
                          </div>
                        </td>

                        <!-- <td
                          v-else-if="
                            release.is_uploaded_pending_approval &&
                            (!release.is_believe_status_ok || !release.is_believe_status_ok)
                          "
                          class="text-center">
                          <router-link
                            :to="{
                              name: 'ReleaseDDEXUpload',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-sm btn-block btn-success text-nowrap routerlink">
                            <span class="spinner-border spinner-border-sm text-white me-2"></span>

                            <span class="d-none d-xl-inline-block ms-2">DDEX</span>
                          </router-link>
                        </td> -->

                        <!-- <td v-else-if="release.is_uploaded_pending_approval">
                          <div
                            class="btn-group btn-block"
                            role="group"
                            aria-label="Basic example"
                          >
                            <router-link
                              :to="{
                                name: 'ReleaseFugaUpload',
                                params: { release_id: release.id },
                              }"
                              class="btn btn-sm btn-outline-success text-nowrap"
                            >
                              <span
                                v-if="
                                  release.is_submitted_to_fuga &&
                                  !release.is_fuga_status_ok
                                "
                                class="spinner-border spinner-border-sm me-2"
                              ></span>
                              <code-icon
                                v-else
                                size="1.2x"
                                class="me-2"
                              ></code-icon>

                              <span class="d-none d-xl-inline-block ms-2"
                                >FUGA</span
                              >
                            </router-link>

                            <router-link
                              :to="{
                                name: 'ReleaseDDEXUpload',
                                params: { release_id: release.id },
                              }"
                              class="btn btn-sm btn-outline-success text-nowrap">
                              <span
                                v-if="
                                  (release.is_submitted_to_believe && !release.is_believe_status_ok) ||
                                  (release.is_submitted_to_youtube && !release.is_believe_status_ok)
                                "
                                class="spinner-border spinner-border-sm me-2"></span>
                              <code-icon
                                v-else
                                size="1.2x"
                                class="me-2"></code-icon>

                              <span class="d-none d-xl-inline-block ms-2">DDEX</span>
                            </router-link> 
                            <button
                              @click="release_to_master_track_list(release)"
                              type="button"
                              class="btn btn-sm btn-outline-success text-nowrap"
                            >
                              <archive-icon
                                size="1.2x"
                                class="me-2"
                              ></archive-icon>
                              MTL
                            </button>
                          </div>
                        </td>-->

                        <td v-else class="text-start" title="Submit Release">
                          <button
                            @click="submit_to_drm(release)"
                            class="btn btn-block btn-sm btn-success text-nowrap"
                          >
                            <external-link-icon
                              size="1.2x"
                              class="align-middle me-2"
                            ></external-link-icon>
                            <span class="d-none d-xl-inline-block ms-2"
                              >Submit to DRM</span
                            >
                          </button>
                        </td>

                        <!-- dropdown -->
                        <td class="text-end">
                          <div class="dropdown">
                            <a
                              class="btn btn-block btn-outline-secondary btn-sm dropdown-toggle"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <more-horizontal-icon
                                size="1.5x"
                                class="custom-class"
                              ></more-horizontal-icon>
                            </a>

                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <span
                                  @click="send_back_release(release)"
                                  class="dropdown-item"
                                >
                                  <rewind-icon
                                    size="1.2x"
                                    class="custom-class me-2"
                                  ></rewind-icon
                                  ><span class="d-none d-xl-inline-block ml-1"
                                    >Send Back</span
                                  ></span
                                >
                              </li>

                              <li>
                                <span
                                  @click="copy_release(release)"
                                  class="dropdown-item"
                                >
                                  <copy-icon
                                    size="1.2x"
                                    class="custom-class me-2"
                                  ></copy-icon
                                  ><span class="d-none d-xl-inline-block ml-1"
                                    >Copy Release</span
                                  ></span
                                >
                              </li>
                              <li>
                                <span
                                  @click="download_release(release)"
                                  class="dropdown-item"
                                >
                                  <download-icon
                                    size="1.2x"
                                    class="custom-class me-2"
                                  ></download-icon
                                  ><span class="d-none d-xl-inline-block ml-1"
                                    >Download Release</span
                                  ></span
                                >
                              </li>
                              <li>
                                <hr class="dropdown-divider" />
                              </li>
                              <li>
                                <span
                                  @click="delete_release(release)"
                                  class="dropdown-item text-danger"
                                >
                                  <trash-icon
                                    size="1.2x"
                                    class="custom-class me-2"
                                  ></trash-icon
                                  ><span class="d-none d-xl-inline-block ml-1"
                                    >Delete Release</span
                                  ></span
                                >
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          v-if="pages"
          :pages="pages"
          :selected_page="selected_page"
          v-on:updatePage="updatePage"
        />
      </div>
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
// import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import BSPopover from "@/components/BSPopover";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";

import ReleaseTracksLinkButton from "@/components/ReleaseTracksLinkButton";

import {
  EditIcon,
  EyeIcon,
  TrashIcon,
  ShuffleIcon,
  ImageIcon,
  MusicIcon,
  CopyIcon,
  DownloadIcon,
  CodeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MoreHorizontalIcon,
  ThumbsUpIcon,
  ExternalLinkIcon,
  StarIcon,
  ArchiveIcon,
  InfoIcon,
  RewindIcon,
} from "vue-feather-icons";

const releases = db.collection("release_submissions");
// const tracks = db.collection("release_submissions_tracks");
const orgs = db.collection("orgs");

export default {
  name: "my_release_submissions",

  data() {
    return {
      // display_tracks: [],
      display_releases: [],
      display_orgs: [],
      showModal: false,
      selected_release: {},
      lines_filter: this.$route.query.filter || "draft",
      search: this.$route.query.search || "",
      debounce: null,
      sort_column: [],
      sort_order: "asc",
      showChangedModal: false,
      mod_ddex: null,
      page_limit: 30,
      selected_page: 1,
    };
  },

  // mounted() {
  //   this.lines_filter = this.$route.query.filter || "draft";
  // },

  components: {
    ReleaseTracksLinkButton,
    basicLayout,
    Pagination,
    BSPopover,
    EditIcon,
    EyeIcon,
    TrashIcon,
    ShuffleIcon,
    ImageIcon,
    MusicIcon,
    CodeIcon,
    CopyIcon,
    DownloadIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    MoreHorizontalIcon,
    ThumbsUpIcon,
    ExternalLinkIcon,
    StarIcon,
    ArchiveIcon,
    InfoIcon,
    RewindIcon,
  },
  mixins: [filter_mixins, sort_mixins],

  // beforeRouteUpdate(to, from, next) {
  //   console.log("route update query to filter: " + to.query.search);
  //   console.log("route update query from filter: " + from.query.search);
  //   this.search = to.query.search;
  //   next();
  // },

  methods: {
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    mod_ddexshow() {
      this.mod_ddex.show();
    },
    status_class(release) {
      if (
        release.youtube_filestatus &&
        release.youtube_filestatus === "fileok"
      ) {
        return "btn-success";
      } else if (
        release.youtube_filestatus &&
        release.youtube_filestatus !== "fileok"
      ) {
        return "btn-outline-success";
      } else {
        return "btn-outline-secondary";
      }
    },
    is_ready_to_submit(release) {
      return (
        !release.is_incomplete && release.release_art_url != ""
        // this.tracks_count(release) &&
        // this.tracks_complete(release)
      );
    },

    approve_release(release) {
      db.collection("release_submissions").doc(release.id).update({
        is_approved_by_drm: true,
        is_pending_resubmission: false,
        is_resubmitted_to_drm: false,
      });

      this.lines_filter = "approved_by_drm";
    },

    submit_to_drm(release) {
      if (!this.is_ready_to_submit(release)) {
        this.$toast.warning(
          "This release is not ready to submit.  Check the missing information and/or files.",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
        return;
      }

      let submission_update = {
        is_submitted_to_drm: true,
      };

      db.collection("release_submissions")
        .doc(release.id)
        .update(submission_update);
      this.lines_filter = "submitted_to_drm";
    },

    send_back_release: async function (release) {
      let submission_update = {
        is_submitted_to_drm: false,
        is_approved_by_drm: false,
        is_uploaded_pending_approval: false,
        is_submitted_to_youtube: false,
        is_approved_by_youtube: false,
        is_submitted_to_believe: false,
        is_approved_by_believe: false,
        is_released_to_master_track_list: false,
      };
      db.collection("release_submissions")
        .doc(release.id)
        .update(submission_update);
      this.lines_filter = "draft";
    },

    delete_release: async function (release) {
      const release_delete = fb.httpsCallable("release_delete");
      const res = await release_delete(release.id);
    },
    tracks_count(release) {
      const tracks = this.display_tracks_filtered.filter(
        (track) => track.release_id === release.id
      );
      return tracks.length;
    },
    tracks_complete(release) {
      const release_tracks = this.display_tracks_filtered.filter(
        (track) => track.release_id === release.id
      );
      const tracks_incomplete = release_tracks.some(
        (track) => !track.track_audio_url || track.is_incomplete
      );
      return release_tracks.length && !tracks_incomplete;
    },
    create_release: async function () {
      const release_owner_org = this.userProfile.orgs[0];

      const display_org = {
        id: release_owner_org.id,
        name: release_owner_org.name,
        description: release_owner_org.description || "",
        royalties_name_code: release_owner_org.royalties_name_code || "",
      };

      const release = {
        release_owner_org: display_org,
        release_upc: "",
        release_created: new Date(),
        release_created_by: this.currentUser.uid,
        release_title: "[NEW RELEASE]",
        release_primary_artist: { name: "" },
        release_main_artists: [{ name: "" }],
        release_featured_artists: [{ name: "" }],
        release_territories: ["Worldwide"],
        release_label: "",
        release_format_type: "ALBUM",
        release_genre: { name: "" },
        release_subgenre: { name: "" },
        release_parental_advisory: false,
        release_production_year: "",
        release_production_owner: "",
        release_copyright_owner: "",
        release_price_tier: "",
        release_original_release_date: "",
        release_release_date: "",
        release_youtube_release_type: "combined",
        is_new: true,
        is_incomplete: true,
        is_submitted_to_drm: false,
        is_approved_by_drm: false,
        is_uploaded_pending_approval: false,
        is_submitted_to_youtube: false,
        is_approved_by_youtube: false,
        is_submitted_to_believe: false,
        is_approved_by_believe: false,
        is_submitted_to_believe: false,
        is_approved_by_believe: false,
        is_submitted_to_fuga: false,
        is_approved_by_fuga: false,
        is_released_to_master_track_list: false,
        is_archived: false,
      };

      const new_release = await db
        .collection("release_submissions")
        .add(release);
      const new_release_id = new_release.id;
      this.$router.push({
        name: "ReleaseEdit",
        params: { release_id: new_release_id },
        query: this.$route.query,
      });
    },

    copy_release(release) {
      const copy_release = { ...release };
      copy_release.id = "";
      copy_release.release_art_url = "";
      copy_release.release_thumbnail_url = "";
      copy_release.release_title = `${release.release_title} [COPY]`;
      copy_release.release_upc = "";
      copy_release.is_new = true;
      copy_release.is_incomplete = true;
      copy_release.is_draft = true;
      copy_release.is_submitted_to_drm = false;
      copy_release.is_approved_by_drm = false;
      copy_release.is_re_submitted_to_drm = false;
      copy_release.is_re_approved_by_drm = false;
      copy_release.is_uploaded_pending_approval = false;
      copy_release.is_submitted_to_youtube = false;
      copy_release.is_approved_by_youtube = false;
      copy_release.is_re_submitted_to_youtube = false;
      copy_release.is_submitted_to_believe = false;
      copy_release.is_approved_by_believe = false;
      copy_release.is_submitted_to_fuga = false;
      copy_release.is_approved_by_fuga = false;
      copy_release.fuga_id = null;
      copy_release.fuga_cover_image_id = null;
      copy_release.is_released_to_master_track_list = false;

      db.collection("release_submissions").add(copy_release);
    },

    upload_status(release) {
      let status_string;

      if (release.is_believe_status_ok) {
        status_string = "Believe status: OK";
      }

      if (release.is_youtube_status_ok) {
        status_string += "Youtube status: OK";
      }

      return status_string;
    },

    debounce_search(event) {
      console.log(event);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 750);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      this.search = filter;
    },

    // download_release: async function (release) {
    //   let download_release = this.display_tracks
    //     .filter((track) => (track.release_id = release.id))
    //     .map((track) => {
    //       let release_track = { ...track };
    //       release_track.track_main_artists = track.track_main_artists
    //         .map((artist) => artist.name)
    //         .join(", ");
    //       release_track.track_featured_artists = track.track_featured_artists
    //         .map((artist) => artist.name)
    //         .join(", ");
    //       release_track.track_remix_artists = track.track_remix_artists
    //         .map((artist) => artist.name)
    //         .join(", ");
    //       release_track.track_lyricists = track.track_lyricists
    //         .map((lyricist) => lyricist.name)
    //         .join(", ");
    //       release_track.track_composers = track.track_composers
    //         .map((composer) => composer.name)
    //         .join(", ");
    //       release_track.track_producers = track.track_producers
    //         .map((producer) => producer.name)
    //         .join(", ");
    //       release_track.track_fuga_id = track.fuga_id;
    //       return { ...release, ...release_track };
    //     });

    //   const csv = Papa.unparse({
    //     fields: [
    //       "release_title",
    //       "release_primary_artist",
    //       "release_main_artists",
    //       "release_featured_artists",
    //       "release_label",
    //       "release_genre",
    //       "release_subgenre",
    //       "release_parental_advisory",
    //       "release_release_date",
    //       "release_original_release_date",
    //       "release_production_year",
    //       "release_production_owner",
    //       "release_copyright_owner",
    //       "release_upc",
    //       "fuga_id",
    //       "release_art_url",
    //       "track_isrc",
    //       "track_title",
    //       "track_subtitle",
    //       "track_version_title",
    //       "track_title_language",
    //       "track_main_artists",
    //       "track_featured_artists",
    //       "track_remix_artists",
    //       "track_composers",
    //       "track_lyricists",
    //       "track_producers",
    //       "track_producers",
    //       "track_genre",
    //       "track_type",
    //       "track_lyric_language",
    //       "track_parental_advisory",
    //       "track_price_tier",
    //       "track_audio_url",
    //       "track_fuga_id",
    //       "is_submitted_to_drm",
    //       "is_approved_by_drm",
    //       "is_submitted_to_believe",
    //       "is_approved_by_believe",
    //       "is_submitted_to_youtube",
    //       "is_approved_by_youtube",
    //     ],
    //     data: download_release,
    //     quotes: true,
    //   });
    //   const csv_name = `${release.id}.csv`;

    //   console.log(csv_name);
    //   this.downloading = false;
    //   const anchor = document.createElement("a");
    //   anchor.href = "data:text/csv," + encodeURIComponent(csv);
    //   anchor.download = csv_name;
    //   anchor.click();
    // },

    release_to_archive: async function (release) {
      db.collection("release_submissions").doc(release.id).update({
        is_archived: true,
      });
    },

    release_to_master_track_list: async function (release) {
      const release_to_master_track_list = fb.httpsCallable(
        "release_to_master_track_list"
      );
      const res = await release_to_master_track_list(release.id);
    },

    // release_to_archive: async function (release) {
    //   const { id, ...rest } = release;
    //   await db.collection("release_submissions_archive").doc(id).set(rest);
    // },
  },

  watch: {
    // release: {
    //   immediate: true,
    //   handler(release) {
    //     let query = tracks;
    //     if (release) {
    //       query = query.where("release_id", "==", release.id);
    //     }
    //     this.$bind("display_tracks", query);
    //   },
    // },

    lines_filter: {
      immediate: true,
      handler(filter) {
        let query = releases.orderBy("release_release_date");
        if (filter && filter === "draft") {
          query = query
            .where("is_archived", "==", false)
            .where("is_submitted_to_drm", "==", false);
        } else if (filter && filter === "is_submitted_to_drm") {
          query = query
            .where("is_archived", "==", false)
            .where("is_submitted_to_drm", "==", true)
            .where("is_approved_by_drm", "==", false);
        } else if (filter && filter === "is_approved_by_drm") {
          query = query
            .where("is_archived", "==", false)
            .where("is_approved_by_drm", "==", true)
            .where("is_uploaded_pending_approval", "==", false);
        } else if (filter && filter === "is_uploaded") {
          query = query
            .where("is_archived", "==", false)
            .where("is_uploaded_pending_approval", "==", true)
            .where("is_released_to_master_track_list", "==", false);
        } else if (filter && filter === "is_archived") {
          query = query.where("is_archived", "==", true);
        }
        this.$bind("display_releases", query);

        if (this.$route.query.filter !== filter) {
          this.$router.replace({
            ...this.$route,
            query: {
              filter: filter,
              search: this.search,
            },
          });
        }
      },
    },
  },

  computed: {
    filtered() {
      let visible_releases = this.display_releases.slice();

      if (this.lines_filter === "is_approved_by_drm") {
        visible_releases = visible_releases.filter((release) => {
          return !Object.keys(release).includes("is_residual_release");
        });
      }

      const search = this.search;

      if (this.sort_column) {
        visible_releases = orderBy(
          visible_releases,
          [this.sort_column || "", "fuga_id"],
          this.sort_order,
          "asc"
        );
      }
      visible_releases = visible_releases.filter((release) => {
        const s =
          release.release_title +
          release.primary_artist?.name +
          release.release_main_artists?.map((a) => a.name) +
          release.release_label +
          release.release_owner_org?.name +
          release.release_upc +
          release.id;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      let page_limit = search ? visible_releases.length : this.page_limit;

      const start = (this.selected_page - 1) * page_limit;
      const end = start + page_limit;
      visible_releases = visible_releases.slice(start, end);

      return visible_releases;
    },

    display_tracks_filtered() {
      return this.display_tracks.filter((track) =>
        this.filtered.map((r) => r.id).includes(track.release_id)
      );
    },

    pages() {
      const pages_array = this.search ? this.filtered : this.display_releases;
      return Math.ceil(pages_array.length / this.page_limit);
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  vertical-align: middle;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}

.btn-block {
  width: 100%;
}
</style>
