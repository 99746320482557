<template>
  <div class="card">
    <div class="card-header"><h6>Upload Contract</h6></div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="file"
            @change.prevent="file_picker"
            accept=".pdf" />
        </div>

        <div
          class="col"
          v-cloak
          @drop.prevent="drag"
          @dragover.prevent>
          <input
            class="form-control form-control-sm progress-bar-striped alert-dark"
            placeholder="...or drag file here"
            accept=".csv" />
        </div>
      </div>
      <div class="row mt-2">
        <div
          v-if="upload_progress > 0 && upload_progress < 99"
          class="col mt-3">
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated bg-success"
              role="progressbar"
              :style="{ width: upload_progress + '%' }"
              :key="upload_progress"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { firebase, storage, db } from "@/firebaseConfig";
import { actions } from "@/store";

export default {
  props: {
    artist_id: String,
  },
  data() {
    return {
      record_type: "",
      resultURL: "",
      upload_progress: 0,
    };
  },

  methods: {
    drag(event) {
      const file = event.dataTransfer.files[0];
      console.log(file.type);
      this.upload(file);
      event.target.value = "";
    },
    file_picker: async function (event) {
      console.log("file picker");
      const file = event.target.files[0];
      console.log(file.name);
      this.upload(file);
    },

    upload: async function (file) {
      const artist_id = this.artist_id;
      const d = new Date().toISOString().split("T");
      const YYYYMMDDHHMM = d[0].split("-").join("").concat("_", d[1].split(":")[0], d[1].split(":")[1]);
      const contract_name = `contract_${YYYYMMDDHHMM}.pdf`;
      const file_path = `artist_contracts/by_artist/${artist_id}/${contract_name}`;

      const contract_object = {
        file_name: contract_name,
        file_path: file_path,
        file_date: new Date(),
      };

      const upload_url = await this.uploadTaskPromise(file, contract_object);

      console.log(`Successfully uploaded file and got download link - ${upload_url}`);
      db.collection("artists")
        .doc(this.artist_id)
        .update({
          contracts: firebase.firestore.FieldValue.arrayUnion(contract_object),
          is_contract_signed: true,
        });
    },

    uploadTaskPromise: async function (file, contract_object) {
      const storageRef = storage.ref();
      const docRef = storageRef.child(contract_object.file_path);
      const metadata = contract_object;
      console.log(metadata);
      const uploadTask = docRef.put(file, { customMetadata: metadata });
      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        this.upload_progress = progress;
      });
      return uploadTask.then((snapshot) => {
        console.log("Upload is complete");
        actions.set_refresh_docs(true);
        return snapshot.ref.getDownloadURL();
      });
    },
  },
};
</script>
  
  <style scoped>
</style>
  