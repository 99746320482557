<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Orgs</h2>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-6">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>

            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Name or Email"
            />

            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="col-xl-6">
          <router-link
            :to="{ name: 'OrgCreate' }"
            type="button"
            class="btn btn-outline-secondary"
            >Create Org</router-link
          >
          <!-- <button
            @click="download_orgs"
            class="btn btn-outline-secondary ms-3">
            Export Orgs
          </button> -->

          <div class="btn-group ms-3" role="group">
            <button
              id="btnGroupDrop1"
              type="button"
              class="btn btn-outline-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <span @click="download_orgs('selection')" class="dropdown-item">
                  <download-icon
                    size="1.2x"
                    class="custom-class me-2"
                  ></download-icon
                  ><span class="d-none d-xl-inline-block ml-1"
                    >Export Selection</span
                  ><span class="badge bg-light text-dark ms-2">{{
                    filtered.length
                  }}</span></span
                >
              </li>
              <li>
                <span @click="download_orgs('all')" class="dropdown-item">
                  <download-icon
                    size="1.2x"
                    class="custom-class me-2"
                  ></download-icon
                  ><span class="d-none d-xl-inline-block ml-1">Export All</span
                  ><span class="badge bg-light text-dark ms-2">{{
                    display_orgs.length
                  }}</span></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-6">
                  <div class="btn-group" data-toggle="buttons">
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: orgs_filter === 'all',
                      }"
                    >
                      <input
                        v-model="orgs_filter"
                        value="all"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      All
                    </label>

                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: orgs_filter === 'agent',
                      }"
                    >
                      <input
                        v-model="orgs_filter"
                        value="agent"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Agent
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: orgs_filter === 'label' }"
                    >
                      <input
                        v-model="orgs_filter"
                        value="label"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Label
                    </label>

                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: orgs_filter === 'manager' }"
                    >
                      <input
                        v-model="orgs_filter"
                        value="manager"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Manager
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{ active: orgs_filter === 'inactive' }"
                    >
                      <input
                        v-model="orgs_filter"
                        value="inactive"
                        type="radio"
                        class="btn-check"
                        autocomplete="off"
                      />
                      Inactive
                    </label>
                  </div>
                </div>

                <div class="col-xl-6 text-end">
                  Showing {{ filtered.length }} of {{ display_orgs.length }}
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-sm table-hover">
                <thead class="table-dark sticky-top">
                  <tr>
                    <th
                      @click="sort_by('name')"
                      role="button"
                      class="text-left w-10"
                      scope="col"
                      title="sort by name"
                    >
                      Name
                      <span
                        v-if="sort_column === 'name' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'name' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('description')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-20"
                      scope="col"
                      title="sort by description"
                    >
                      Description
                      <span
                        v-if="
                          sort_column === 'description' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'description' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('royalties_id')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-10"
                      scope="col"
                      title="sort by royalties namecode"
                    >
                      Royalties ID
                      <span
                        v-if="
                          sort_column === 'royalties_id' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'royalties_id' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('royalties_name_code')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-10"
                      scope="col"
                      title="sort by royalties namecode"
                    >
                      Royalties Name Code
                      <span
                        v-if="
                          sort_column === 'royalties_name_code' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'royalties_name_code' &&
                          sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('type')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-5"
                      scope="col"
                      title="sort by type"
                    >
                      Type
                      <span
                        v-if="sort_column === 'type' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'type' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('is_exempt_new_contract')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-5"
                      scope="col"
                      title="sort by is_exempt_new_contract"
                    >
                      Exempt
                      <span
                        v-if="
                          sort_column === 'is_exempt_new_contract' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'is_exempt_new_contract' &&
                          sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('fuga_id')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-5"
                      scope="col"
                    >
                      FUGA Id
                      <span
                        v-if="
                          sort_column === 'fuga_id' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'fuga_id' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="org in filtered" :key="org.order_id">
                    <td class="text-left">{{ org.name }}</td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ org.description }}
                    </td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ org.royalties_id }}
                    </td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ org.royalties_name_code }}
                    </td>
                    <td
                      @dblclick="filter_by(org.type)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ org.type }}
                    </td>
                    <td
                      @dblclick="filter_by(org.is_exempt_new_contract)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      <check-square-icon
                        v-if="org.is_exempt_new_contract"
                        size="0.9x"
                        class="muted ms-1"
                      ></check-square-icon>
                    </td>
                    <td
                      @dblclick="fuga_record(org.fuga_id)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ org.fuga_id }}
                    </td>
                    <td class="w-5 text-center">
                      <router-link
                        :to="{ name: 'OrgProfile', params: { org_id: org.id } }"
                        title="edit org"
                      >
                        <edit-icon size="1x" class="text-success"></edit-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            v-if="pages"
            :pages="pages"
            :selected_page="selected_page"
            v-on:updatePage="updatePage"
          />
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import {
  EditIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCheckIcon,
  UserXIcon,
  DownloadIcon,
  CheckSquareIcon,
} from "vue-feather-icons";

const orgs = db.collection("orgs");

export default {
  name: "orgs",
  data() {
    return {
      display_orgs: [],
      orgs_filter: "all",
      search: "",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
      page_limit: 30,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    EditIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    UserCheckIcon,
    UserXIcon,
    DownloadIcon,
    CheckSquareIcon,
  },

  // firestore() {
  //   return {
  //     orgs: db.collection("orgs"),
  //   };
  // },
  methods: {
    deactivate_org(id) {
      db.collection("orgs").doc(id).update({ is_active: false });
      this.orgs_filter = "inactive";
    },
    reactivate_org(id) {
      db.collection("orgs").doc(id).update({ is_active: true });
      this.orgs_filter = "all";
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 300);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },

    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    fuga_record(id) {
      window.open(`https://next.fugamusic.com/label/${id}`, "_blank");
    },

    download_orgs(selection) {
      let download_orgs;

      if (selection === "selection") {
        download_orgs = [...this.filtered];
      } else if (selection === "all") {
        download_orgs = [...this.display_orgs];
      }

      download_orgs = download_orgs.map((o) => {
        o.date_created_string =
          (o.date_created &&
            new Date(o.date_created.seconds * 1000).toLocaleString("en-NZ")) ||
          "";
        return o;
      });

      const csv = Papa.unparse({
        data: download_orgs,

        fields: [
          "id",
          "name",
          "description",
          "royalties_id",
          "royalties_name_code",
          "type",
          "country",
          "commission",
          "gst_number",
          "date_created_string",
          "is_active",
        ],
        quotes: true,
      });
      const csv_name = "orgs.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
  },
  computed: {
    filtered: function () {
      let visible_orgs = this.display_orgs.slice();

      const search = this.search;

      visible_orgs = orderBy(visible_orgs, "name", "asc");

      if (this.sort_column) {
        visible_orgs = orderBy(
          visible_orgs,
          (org) => org[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_orgs = visible_orgs.filter((org) => {
        const s =
          org.name +
          org.description +
          org.type +
          org.royalties_id +
          org.royalties_name_code;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_orgs = visible_orgs.slice(start, end);
      }

      return visible_orgs;
    },

    pages() {
      const pages_array = this.search ? this.filtered : this.display_orgs;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
  watch: {
    orgs_filter: {
      immediate: true,
      handler(filter) {
        let query = orgs;
        if (filter && filter === "agent") {
          query = query.where("type", "==", "Agent");
        } else if (filter && filter === "label") {
          query = query.where("type", "==", "Label");
        } else if (filter && filter === "manager") {
          query = query.where("type", "==", "Manager");
        } else if (filter && filter === "inactive") {
          query = query.where("is_active", "==", false);
        }
        this.$bind("display_orgs", query);
      },
    },
  },
};
</script>

<style>
.sticky-group {
  position: sticky;
  align-self: flex-start;
  top: 100;
  overflow-y: auto;
}

.col-xl-12 .sticky-top {
  align-self: flex-start;
}
</style>
