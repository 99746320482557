<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>My Releases</h2>
        </div>
      </div>
      <hr />
      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Title, Artist, ID"
            />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div class="row m-1 mb-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-body">
              <div class="row m-1">
                <div class="col">
                  <table class="table border-top">
                    <thead class="table-dark sticky-top">
                      <tr>
                        <th
                          @click="sort_by('track_number')"
                          class="text-center w-5"
                        >
                          <image-icon
                            size="1.0x"
                            class="custom-class"
                          ></image-icon>
                        </th>
                        <th @click="sort_by('track_title')" class="w-10">
                          Release Title
                        </th>
                        <th @click="sort_by('track_title')" class="w-10">
                          Artist
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="release in filtered"
                        :key="release.id"
                        class="align-left"
                      >
                        <td
                          v-if="release.release_art_url"
                          class="align-middle d-none d-sm-table-cell text-center"
                          title="cover art"
                        >
                          <img
                            :src="release.release_thumbnail_url"
                            :alt="release.release_title"
                            width="40px"
                            loading="lazy"
                          />
                        </td>
                        <td v-else class="text-secondary text-center">
                          <span title="cover art is missing for this release">
                            <image-icon
                              size="1.5x"
                              class="custom-class"
                            ></image-icon
                          ></span>
                        </td>
                        <td @dblclick="filter_by(release.release_title)">
                          {{ release.release_title }}
                          <span class="d-xl-none"
                            >&nbsp;-&nbsp;
                            {{ release.release_main_artist }}</span
                          >
                        </td>

                        <td
                          @dblclick="filter_by(release.release_main_artist)"
                          class="d-none d-xl-table-cell"
                        >
                          {{ release.release_main_artist }}
                        </td>

                        <td class="text-center" title="edit this release">
                          <router-link
                            :to="{
                              name: 'ReleaseEdit',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-sm text-nowrap routerlink"
                            :class="
                              release.is_incomplete
                                ? 'btn-outline-secondary'
                                : 'btn-outline-success'
                            "
                          >
                            <edit-icon size="1.0x"></edit-icon>

                            <span class="d-none d-xl-inline-block ms-2"
                              >Edit Release</span
                            >
                          </router-link>
                        </td>

                        <td class="text-center" title="edit release tracks">
                          <router-link
                            :to="{
                              name: 'ReleaseTracksList',
                              params: { release_id: release.id },
                            }"
                            class="btn btn-sm btn-outline-success text-nowrap routerlink"
                          >
                            <music-icon size="1.0x"></music-icon>
                            <span class="d-none d-xl-inline-block ms-2"
                              >Edit Tracks</span
                            >
                            <span class="mx-1"></span>

                            <span
                              class="badge"
                              :class="{
                                'badge-success bg-success':
                                  tracks_complete(release),
                                'badge-warning bg-secondary':
                                  !tracks_complete(release) ||
                                  !tracks_count(release),
                              }"
                              :title="
                                !tracks_complete(release) ||
                                !tracks_count(release)
                                  ? 'missing or incomplete tracks'
                                  : ''
                              "
                              >{{ tracks_count(release) }}</span
                            >
                          </router-link>
                        </td>

                        <td>
                          {{ is_ready_to_submit(release) }}
                          <span
                            class="badge rounded-pill"
                            :class="
                              release.is_submitted_to_drm
                                ? 'bg-success'
                                : 'bg-secondary'
                            "
                            title="submitted to DRM"
                            >SD</span
                          >
                          <span
                            class="badge rounded-pill"
                            :class="
                              release.is_approved_by_drm
                                ? 'bg-success'
                                : 'bg-secondary'
                            "
                            title="approved by DRM"
                            >AD</span
                          >
                          <span
                            class="badge rounded-pill"
                            :class="
                              release.is_approved_by_drm
                                ? 'bg-success'
                                : 'bg-secondary'
                            "
                            title="submitted for publication"
                            >SP</span
                          >
                        </td>
                        <td
                          @click="copy_release(release)"
                          class="text-center d-none d-sm-table-cell"
                          title="copy this release"
                        >
                          <button
                            class="btn btn-sm btn-outline-secondary text-nowrap"
                          >
                            <copy-icon
                              size="1.0x"
                              class="custom-class me-2"
                            ></copy-icon
                            ><span class="d-none d-xl-inline-block ml-1"
                              >Copy Release</span
                            >
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import { EditIcon, ImageIcon, MusicIcon, CopyIcon } from "vue-feather-icons";

const releases = db
  .collection("release_submissions")
  .where('"is_released', "==", true);

export default {
  name: "my_release_submissions",

  data() {
    return {
      tracks: [],
      display_releases: [],
      // showModal: false,
      selected_release: {},
      lines_filter: "all",
      search: "",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
    };
  },

  components: {
    basicLayout,
    EditIcon,
    ImageIcon,
    MusicIcon,
    CopyIcon,
  },
  mixins: [filter_mixins],

  firestore() {
    return {
      tracks: db
        .collection("release_submissions_tracks")
        .orderBy("track_number"),
    };
  },
  methods: {
    status_class(release) {
      if (
        release.youtube_filestatus &&
        release.youtube_filestatus === "fileok"
      ) {
        return "btn-outline-success";
      } else if (
        release.youtube_filestatus &&
        release.youtube_filestatus !== "fileok"
      ) {
        return "btn-outline-warning";
      } else {
        return "btn-outline-secondary";
      }
    },
    submit_draft(release) {
      console.log(release);

      db.collection("release_submissions").doc(release.id).update({
        is_submitted_to_drm: true,
      });
    },
    delete_release: async function (release) {
      const release_delete = fb.httpsCallable("release_delete");
      const res = await release_delete(release.id);
      console.log(res.data);
    },
    tracks_count(release) {
      const tracks = this.tracks.filter(
        (track) => track.release_id === release.id
      );
      return tracks.length;
    },
    tracks_complete(release) {
      const tracks_complete = this.tracks
        .filter((track) => track.release_id === release.id)
        .every((track) => !track.is_incomplete && track.audio_track_url != "");
      return tracks_complete;
    },

    copy_release(release) {
      const copy_release = { ...release };
      copy_release.id = "";
      copy_release.release_art_url = "";
      copy_release.release_thumbnail_url = "";
      copy_release.release_title = `${release.release_title} [COPY]`;
      copy_release.is_new = true;
      copy_release.is_incomplete = true;
      copy_release.is_submitted_to_drm = false;
      copy_release.is_approved_by_drm = false;
      // copy_release.is_pending_resubmission = false;
      // copy_release.is_resubmitted_to_drm = false;
      copy_release.is_submitted_to_youtube = false;
      copy_release.is_approved_by_youtube = false;
      copy_release.is_submitted_to_believe = false;
      copy_release.is_approved_by_believe = false;
      console.log(JSON.stringify(copy_release));
      db.collection("release_submissions").add(copy_release);
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 750);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
  },

  watch: {
    lines_filter: {
      immediate: true,
      handler(filter) {
        let query = releases;
        if (filter && filter === "draft") {
          console.log("is_submitted_to_drm");
          query = query.where("is_submitted_to_drm", "==", false);
        } else if (filter && filter === "is_submitted_to_drm") {
          console.log("is_submitted_to_drm");
          query = query.where("is_submitted_to_drm", "==", true);
        } else if (filter && filter === "is_approved_by_drm") {
          console.log("is_submitted_to_drm");
          query = query.where("is_approved_by_drm", "==", true);
        }
        this.$bind("display_releases", query);
      },
    },
  },

  computed: {
    filtered() {
      let visible_releases = this.display_releases.slice();

      const search = this.search;

      if (this.sort_column) {
        visible_releases = orderBy(
          visible_releases,
          (user) => user[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_releases = visible_releases.filter((release) => {
        const s =
          release.release_title +
          release.release_main_artist +
          release.release_label;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });
      return visible_releases;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  vertical-align: middle;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}
</style>
