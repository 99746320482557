<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row sticky-top bg-light m-1">
        <div class="col">
          <h5 class="text-uppercase">
            <span
              v-if="!release.release_thumbnail_url"
              title="cover art missing for this release">
              <image-icon
                size="1.5x"
                class="custom-class"></image-icon>
            </span>
            <img
              v-else
              :src="release.release_thumbnail_url"
              :alt="release.release_title"
              width="40px"
              loading="lazy"
              class="pr-2"
              title="cover art" />&nbsp;{{ release.release_title }}&nbsp;-&nbsp;{{ release.release_main_artist.name }}
          </h5>
        </div>
      </div>
      <div class="row m-1 mt-3">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Release</div>
            <div class="card-body">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th
                      class="w-30"
                      scope="col ">
                      Field
                    </th>
                    <th
                      class="w-30"
                      scope="col ">
                      Old Value
                    </th>
                    <th scope="col">New Value</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="field in changed_fields.changed_release_fields"
                    :key="field.key"
                    class="align-middle">
                    <td>{{ field.key }}</td>
                    <td>{{ field.original_value }}</td>
                    <td>{{ field.new_value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-1 mt-3">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">Tracks</div>
            <div class="card-body">
              <div
                v-for="track in changed_fields.changed_tracks_fields"
                :key="track.name"
                class="row">
                <div class="col">
                  <div>{{ track.title }}</div>
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th
                          class="w-30"
                          scope="col ">
                          Field
                        </th>
                        <th
                          class="w-30"
                          scope="col ">
                          Old Value
                        </th>
                        <th scope="col">New Value</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="field in track.changes"
                        :key="field.key"
                        class="align-middle">
                        <td>{{ field.key }}</td>
                        <td>{{ field.original_value }}</td>
                        <td>{{ field.new_value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import { isEqual } from "lodash";
import basicLayout from "@/components/basicLayout";
import { db } from "@/firebaseConfig";

import { ImageIcon } from "vue-feather-icons";

export default {
  name: "ReleasePreviewChanges",

  data() {
    return {
      release: {},
      tracks: [],
    };
  },

  components: { basicLayout, ImageIcon },

  firestore() {
    return {
      release: db.collection("release_submissions").doc(this.$route.params.release_id),
      tracks: db
        .collection("release_submissions_tracks")
        .where("release_id", "==", this.$route.params.release_id)
        .orderBy("track_number"),
    };
  },

  methods: {},
  computed: {
    changed_fields() {
      console.log("CHECKING IF FORM VALUES HAVE CHANGED");

      let changed_fields_object;

      const release = this.release;
      const tracks = this.tracks;

      const entries = Object.entries(release);

      console.log(JSON.stringify(entries));

      const excluded_fields = ["is_updating", "is_submitted_to_drm", "original_release", "original_track"];

      const changed_release_fields = entries
        /* eslint-disable no-unused-vars */
        .filter(([k, v]) => !excluded_fields.includes(k))
        .filter(([k, v]) => {
          console.log(k);
          return !isEqual(v, release.original_release[k]);
        })
        .map(([k, v]) => {
          return {
            key: k,
            original_value: release.original_release[k],
            new_value: v,
          };
        });

      const changed_tracks_fields = tracks
        .filter((track) => track.release_id === release.id)
        .map((track) => {
          const entries = Object.entries(track);
          const changes = entries
            /* eslint-disable no-unused-vars */
            .filter(([k, v]) => !excluded_fields.includes(k))
            .filter(([k, v]) => {
              console.log(k);
              return !isEqual(v, track.original_track[k]);
            })
            .map(([k, v]) => {
              return {
                key: k,
                original_value: track.original_track[k],
                new_value: v,
              };
            });

          return { title: track.track_title, changes };
        });

      changed_fields_object = { changed_release_fields, changed_tracks_fields };

      console.log(JSON.stringify(changed_fields_object));

      return changed_fields_object;
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style scoped>
.table-sm {
  font-size: 0.6rem;
}

.card .list-group-item {
  font-size: 0.6rem;
}
</style>
