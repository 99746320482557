<template>
  <NavSplash>
    <div class="login text-center">
      <div class="form-signin">
        <div class="text-center p-3">
          <img src="../assets/logo.png" width="auto" height="50px" alt />
        </div>
        <div v-if="!reset">
          <div v-if="!has_reset">
            <h1 class="h3 text-uppercase">Welcome to the DRM Portal</h1>
            <!-- <div v-if="!hide_reset_prompt">
              <h1 class="h3 text-uppercase">Welcome to the New DRM Portal</h1>
              <hr />
              <h5 class="lead">
                <b>If this is the first time you have used the new portal<br />you will need to reset your password.</b>
              </h5>

              <button
                type="button"
                @click="forgot"
                class="btn btn-block btn-success">
                RESET PASSWORD NOW
              </button>
              <hr />
            </div> -->
            <form @submit.prevent="login">
              <div class="mb-3">
                <div class="login_fields">
                  <label for="inputEmail" class="form-label"
                    >Email address</label
                  >
                  <input
                    v-model="email"
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    :class="{ 'apply-shake': shake_email }"
                    placeholder="Email address"
                    required
                    autofocus
                  />

                  <div class="mb-3">
                    <label for="inputPassword" class="form-label"
                      >Password</label
                    >
                    <input
                      v-model="password"
                      type="password"
                      id="inputPassword"
                      class="form-control"
                      :class="{ 'apply-shake': shake_password }"
                      placeholder="Password"
                      required
                    />
                  </div>

                  <button class="btn btn-secondary btn-block" type="submit">
                    Sign in
                  </button>
                </div>
              </div>

              <div class="p-3">
                <a href="#" @click="forgot" class="text-secondary"
                  >Forgot Password?</a
                >
              </div>
            </form>
          </div>
          <div v-else>
            <div class="p-3 h1 lead text-bold">
              <p>
                Please check your email inbox <br />for a reset email from DRM.
              </p>
            </div>
            <div class="p-3">
              <a @click="back_to_login" href="#">Back to Login</a>
            </div>
          </div>
        </div>
        <form v-else @submit.prevent="reset_password">
          <h1 class="h3 mb-2 font-weight-normal">PASSWORD RESET</h1>
          <h1 class="h5 mb-2 lead">Please enter your login email address</h1>
          <!-- <label
            for="inputEmail"
            class="text-start sr-only"
            >Email address</label
          > -->

          <input
            v-model="email"
            type="email"
            id="inputEmail"
            class="form-control"
            style="margin-bottom: 10px"
            placeholder="Email address"
            required
            autofocus
          />

          <button type="submit" class="btn btn-lg btn-success btn-block">
            Reset
          </button>
          <div class="p-3">
            <a @click="back_to_login" href="#">Back to Login</a>
          </div>
          <div class="p-3 h1 lead text-bold">
            <p>
              After clicking 'Reset', check your email inbox for a reset email
              from DRM.
            </p>
          </div>
        </form>
      </div>
    </div>
  </NavSplash>
</template>

<script>
import NavSplash from "@/components/NavSplash";
import { auth } from "@/firebaseConfig";
import { mutations, actions } from "@/store";

export default {
  name: "login",
  data: function () {
    return {
      email: this.$route.query.email || "",
      password: "",
      reset: this.$route.query.reset || false,
      has_reset: false,
      hide_reset_prompt: false,
      shake_email: false,
      shake_password: false,
    };
  },
  components: {
    NavSplash,
  },

  methods: {
    login: function () {
      // console.log("logging in");
      auth
        .signInWithEmailAndPassword(this.email.toLowerCase(), this.password)
        .then(
          /* eslint-disable no-unused-vars */
          (user) => {
            mutations.setCurrentUser(auth.currentUser);
            actions.fetchCurrentUserProfile(auth.currentUser);

            this.$router.push("/");
          },
          (err) => {
            console.log(err);

            let message = err.message;

            if (err.message.includes("auth/wrong-password")) {
              message = "Sorry, that password is invalid.";
              this.shakeAnimation("password");
            } else if (err.message.includes("auth/user-not-found")) {
              message =
                "Sorry, there is no user account associated with that email address.";
              this.shakeAnimation("email");
            }

            this.$toast.error(message, {
              position: "top-center",
              timeout: 5000,
            });
          }
        );
    },

    forgot: function () {
      // console.log(this.reset);
      this.reset = !this.reset;
      this.has_reset = !this.has_reset;
    },

    back_to_login() {
      console.log(this.reset);
      this.reset = false;
      this.has_reset = false;
      this.hide_reset_prompt = true;
    },

    reset_password: function () {
      auth.sendPasswordResetEmail(this.email).then(
        (user) => {
          // console.log(user);

          this.$toast.success(
            "Check your email inbox for a reset password email.",
            {
              position: "top-center",
              timeout: 10000,
            }
          );
          this.reset = false;
          this.has_reset = true;
          return user;
        },
        (err) => {
          this.$toast.warning("err", {
            position: "top-right",
            timeout: 5000,
          });
          return err;
        }
      );
    },
    shakeAnimation(error) {
      if (error === "email") {
        this.shake_email = true;
        setTimeout(() => {
          this.shake_email = false;
        }, 820);
      }
      if (error === "password") {
        this.shake_password = true;
        setTimeout(() => {
          this.shake_password = false;
        }, 820);
      }
    },
  },
};
</script>

<style scoped>
.login {
  /* color: #fff; */
  height: 100vh;
  margin-top: -80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-image: url("../assets/background.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login_fields {
  width: 80%;
  max-width: 400px;
  min-width: 250px;
  margin: auto;
}

.form-signin {
  width: 40vw;
  min-width: 350px;
  padding: 5%;
  margin: auto;
  background-color: #e2e4e3;
  opacity: 0.9;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
