<template>
  <basicLayout>
    <div class="container-fluid">
      <!-- <div v-if="!Object.keys(new_release_record).length" class="row m-1"> -->
      <div class="row m-1">
        <div class="col">
          <h5 class="card-header text-uppercase">
            <button
              @click="$router.go(-1)"
              class="btn btn-sm btn-outline-success me-3"
              type="button"
            >
              <arrow-left-circle-icon
                size="1.0x"
                class="custom-class"
              ></arrow-left-circle-icon>
              Back
            </button>

            {{ release.release_title }} -
            {{
              release.release_main_artist && release.release_main_artist.name
            }}
            <span v-if="release.release_upc">({{ release.release_upc }})</span>
          </h5>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-6">
          <div class="card mt-3">
            <div class="card-header">
              <h6 class="text-uppercase">DDEX STATUS</h6>
            </div>

            <div class="card-body">
              <div class="row border-bottom p-1">
                <div class="col">SUBMITTED TO YOUTUBE</div>
                <div v-if="release.is_submitted_to_youtube" class="col">
                  <thumbs-up-icon
                    size="1.0x"
                    class="text-success"
                  ></thumbs-up-icon>
                </div>
                <div v-else class="col">
                  <thumbs-down-icon
                    size="1.0x"
                    class="text-danger"
                  ></thumbs-down-icon>
                </div>
              </div>

              <div class="row p-1">
                <div class="col">YOUTUBE STATUS</div>
                <div v-if="release.is_youtube_status_ok" class="col">
                  <thumbs-up-icon
                    size="1.0x"
                    class="text-success"
                  ></thumbs-up-icon>
                </div>
                <div v-else class="col">
                  <thumbs-down-icon
                    size="1.0x"
                    class="text-danger"
                  ></thumbs-down-icon>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col">
                  <button
                    @click.prevent="ddex_status_youtube"
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    Check YouTube Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-6">
          <div class="card mt-3">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h6 class="text-uppercase">YOUTUBE DDEX UPLOAD</h6>
                </div>
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input
                      v-model="simulation"
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                    <label class="form-check-label" for="flexSwitchCheckChecked"
                      >Simulation
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="mb-3">
                <label for="youtube_release_type" class="form-label text-muted"
                  >YouTube Release Type</label
                >
                <select
                  v-model="youtube_release_type"
                  class="form-select d-block w-50"
                  id="youtube_release_type"
                  required
                >
                  <option value="premium_only">YouTube Premium Only</option>
                  <option value="content_id_only">
                    YouTube Content ID Only
                  </option>
                  <option value="combined">Combined</option>
                </select>
                <div class="invalid-feedback">
                  Please specify YouTube release type
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col">
                  <button
                    @click.prevent="ddex_upload"
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    :disabled="!this.youtube_release_type"
                  >
                    Upload DDEX
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="result" class="row m-1 mt-3">
        <div class="col">
          <div class="card mt-1">
            <div class="card-header sticky-top sticky-header">
              <div class="row">
                <div class="col-xl-6">XML</div>
                <div class="col-xl-6 text-end">
                  <button
                    @click.prevent="xml_download"
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    :disabled="!this.result"
                  >
                    Download XML
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <pre class="p-3 m-3 xml-pretty">{{ result }}</pre>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-1 mt-3">
        <div class="col-xl-6">
          <FTPViewer :release="release" />
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import FTPViewer from "@/components/FTPViewer";
import { db, fb } from "@/firebaseConfig";
import {
  ArrowLeftCircleIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "vue-feather-icons";

export default {
  name: "DDEXUpload",
  data() {
    return {
      simulation: false,
      last_index: {},
      release: {},
      youtube_release_type: "",
      ddex_target: "",
      submitted: false,
      result: "",
    };
  },

  firestore() {
    return {
      release: db
        .collection("release_submissions")
        .doc(this.$route.params.release_id),
      // last_index: db.collection("upc_codes").doc("last_index"),
      // prefix: db.collection("upc_codes").doc("prefix"),
    };
  },
  components: {
    basicLayout,
    FTPViewer,
    ArrowLeftCircleIcon,
    ThumbsUpIcon,
    ThumbsDownIcon,
  },

  methods: {
    ddex_status_youtube: async function (release_id) {
      const ddex_status_youtube = fb.httpsCallable("ddex_status_youtube");
      const res = await ddex_status_youtube(this.release.id);

      console.log(res.data);
    },

    ddex_upload: async function () {
      let res;

      let ddex_uploader;

      if (this.youtube_release_type) {
        await db.collection("release_submissions").doc(this.release.id).update({
          youtube_release_type: this.youtube_release_type,
        });

        if (this.simulation) {
          ddex_uploader = fb.httpsCallable("ddex_upload_youtube_simulator");
        } else {
          ddex_uploader = fb.httpsCallable("ddex_upload_youtube");
        }
      }

      res = await ddex_uploader(this.release.id);

      console.log(JSON.stringify(res.data));

      if (res.data.success) {
        console.log("SUCCESS");
        let xml = res.data.ddex;
        this.result = this.format_xml(xml);
      } else {
        console.log("ERROR");
        this.$toast.error("DDEX Error: " + res.data.error, {
          position: "top-right",
          timeout: 5000,
        });
      }
    },

    xml_download() {
      const xml_name = `release_xml.xml`;

      console.log(xml_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/xml," + encodeURIComponent(this.result);
      anchor.download = xml_name;
      anchor.click();
    },
    format_xml(xml) {
      const PADDING = " ".repeat(2); // set desired indent size here
      const reg = /(>)(<)(\/*)/g;
      let pad = 0;

      xml = xml.replace(reg, "$1\r\n$2$3");

      return xml
        .split("\r\n")
        .map((node) => {
          let indent = 0;
          if (node.match(/.+<\/\w[^>]*>$/)) {
            indent = 0;
          } else if (node.match(/^<\/\w/) && pad > 0) {
            pad -= 1;
          } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
            indent = 1;
          } else {
            indent = 0;
          }

          pad += indent;

          return PADDING.repeat(pad - indent) + node;
        })
        .join("\r\n");
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style>
.sticky-header {
  z-index: 2;
}
.xml-pretty {
  color: rgb(164, 212, 196);
  background-color: rgb(36, 36, 40);
  z-index: 1;
}
</style>
