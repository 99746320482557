<template>
  <basicLayout>
    <div class="container-fluid">
      <div v-if="track.is_new" class="row m-1">
        <div class="col">
          <h5>Track Metadata</h5>

          <p class="lead">
            Please enter all relevant details below and submit for initial
            validation.
            <br />If you do not have all information to hand, you can save your
            draft and complete it later.
          </p>
        </div>
      </div>
      <div v-else class="row sticky-top bg-light m-1 pb-3">
        <div class="col">
          <h5 class="text-uppercase">
            <span v-if="track.track_number">{{ track.track_number }}.</span>
            {{ track.track_title }}
          </h5>

          <div class="row py-2">
            <div class="col">
              <router-link
                :to="{
                  name: 'ReleaseTracksList',
                  params: { release_id: track.release_id },
                }"
                class="btn btn-sm btn-outline-success me-2"
                title="Back to Tracks"
              >
                <arrow-left-circle-icon
                  size="1.0x"
                  class="custom-class"
                ></arrow-left-circle-icon
                >&nbsp;Back to Tracks</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row m-1">
        <div class="col-xl-8">
          <ReleaseTrackFormNew :track_id="track_id"></ReleaseTrackFormNew>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// https://travishorn.com/form-validation-with-vue-js-4d2e7ba8d2fc
// http://michaelsoriano.com/form-validation-vuejs-boostrap/
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import ReleaseTrackFormNew from "@/components/ReleaseTrackFormNew";
import ReleaseTrackForm from "@/components/ReleaseTrackForm";
// import MusicUploader from "@/components/ReleaseTrackMusicUploader";
import { db } from "@/firebaseConfig";
import Papa from "papaparse";
import { ArrowLeftCircleIcon } from "vue-feather-icons";

export default {
  name: "release_track_edit",
  data: function () {
    return {
      track_id: this.$route.params.track_id,
      track: {
        id: "",
        track_number: "",
        track_title: "",
        track_volume_number: 1,
        track_main_artists: [{ name: "" }],
        track_featured_artists: [{ name: "" }],
        track_remix_artists: [{ name: "" }],
        track_composers: [{ name: "" }],
        track_lyricists: [{ name: "" }],
        track_producers: [{ name: "" }],
        track_genre: "",
        track_track_type: "Original",
        track_lyric_language: "",
        track_title_language: "",
        track_parental_advisory: "Not Explicit",
        track_price_tier: "FRONT",
        track_upload_progress: 0,
        is_new: true,
        is_incomplete: false,
      },

      show_thumbnail: true,
      show_player: true,
      missing_fields: [],
      invalid_fields: [],
    };
  },
  components: {
    basicLayout,
    ReleaseTrackFormNew,
    ReleaseTrackForm,
    ArrowLeftCircleIcon,
  },
  firestore() {
    return (
      this.track_id && {
        track: db.collection("release_submissions_tracks").doc(this.track_id),
      }
    );
  },

  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload(file) {
      console.log("upload");
      console.log(file.name);
      const upload_tracks = this.upload_tracks;

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          upload_tracks(results.data);
        },
      });
    },

    upload_tracks: async function (data) {
      data.map(async (line) => {
        const track_upload = {
          ...line,
          created_by: auth.currentUser.email,
          approved: false,
          submitted: new Date(),
          release_art_url: null,
          track_audio_url: null,
        };
        const new_track = await db
          .collection("release_submissions_tracks")
          .add(track_upload);
        console.log(new_track);
      });
      this.$router.push("/my-submissions");
    },
    save_audio: async function (info) {
      const track_audio_url = info.secure_url;

      await db
        .collection("release_submissions_tracks")
        .doc(this.track.id)
        .update({ track_audio_url });
    },
  },

  computed: {
    submission_complete() {
      // return true;
      return (
        this.track.release_art_url &&
        this.track.track_audio_url &&
        !this.track.is_incomplete
      );
    },
    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return (
        this.userProfile.role === "admin" ||
        this.userProfile.role === "superadmin"
      );
    },
    isSuperAdmin() {
      return this.userProfile.role === "superadmin";
    },
  },
};
</script>

<style scoped>
.container {
  width: 90% !important;
}
</style>
