<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row sticky-top bg-light m-1">
        <div class="col">
          <h5 class="text-uppercase">
            <span
              v-if="!release.release_thumbnail_url"
              title="cover art missing for this release">
              <image-icon
                size="1.5x"
                class="custom-class"></image-icon>
            </span>
            <img
              v-else
              :src="release.release_thumbnail_url"
              :alt="release.release_title"
              width="40px"
              loading="lazy"
              class="pr-2"
              title="cover art" />&nbsp;{{ release.release_title }}&nbsp;-&nbsp;{{
              release.release_main_artist && release.release_main_artist.name
            }}
          </h5>

          <button
            @click="$router.go(-1)"
            class="btn btn-sm btn-outline-success"
            type="button">
            <arrow-left-circle-icon
              size="1.0x"
              class="custom-class"></arrow-left-circle-icon>
            Back
          </button>
        </div>
      </div>
      <hr />

      <div v-if="Object.keys(release).length">
        <div
          v-if="release"
          class="row m-1 mt-3">
          <div class="col">
            <div class="card">
              <h5 class="card-header">
                {{ release.release_title }} -
                {{ release.release_main_artist && release.release_main_artist.name }}
              </h5>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="table-responsive">
                      <table class="table table-bordered table-hover table-sm">
                        <thead>
                          <tr>
                            <th>release_title</th>
                            <th>release_main_artist</th>
                            <th>release_label</th>
                            <th>release_genre</th>
                            <th>release_parental_advisory</th>
                            <th>release_release_date</th>
                            <th>release_original_release_date</th>
                            <th>release_production_year</th>
                            <th>release_upc</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_title'),
                              }">
                              {{ release.release_title }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_main_artist'),
                              }">
                              {{ release.release_main_artist && release.release_main_artist.name }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_label'),
                              }">
                              {{ release.release_label }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_genre'),
                              }">
                              {{ release.release_genre.genre }} | {{ release.release_genre.subgenre }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_parental_advisory'),
                              }">
                              {{ release.release_parental_advisory }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_release_date'),
                              }">
                              {{ release.release_release_date }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_original_release_date'),
                              }">
                              {{ release.release_original_release_date }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_production_year'),
                              }">
                              {{ release.release_production_year }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && missing_fields_release.includes('release_upc'),
                              }">
                              {{ release.release_upc }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <router-link
                  v-if="!release.is_submitted_to_drm"
                  :to="{
                    name: 'ReleaseEdit',
                    params: { release_id: release.id },
                  }"
                  class="btn btn-sm text-nowrap routerlink"
                  :class="release.is_incomplete ? 'btn-outline-secondary' : 'btn-outline-success'">
                  <edit-icon size="1.0x"></edit-icon>

                  <span class="d-none d-xl-inline-block ms-2">Edit Release</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-1 mt-2">
          <div class="col">
            <div class="card">
              <h5 class="card-header">Tracks</h5>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="table-responsive">
                      <table class="table table-bordered table-hover table-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>audio_file</th>
                            <th>isrc</th>
                            <th>title</th>
                            <th>subtitle</th>
                            <th>version_title</th>
                            <th>title_language</th>
                            <th>main_artists</th>
                            <th>featured_artists</th>
                            <th>composers</th>
                            <th>lyricists</th>
                            <!-- <th>producers</th> -->
                            <th>genre</th>
                            <th>type</th>
                            <th>lyric_language</th>
                            <th>parental_advisory</th>
                            <th>price_tier</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="track in tracks"
                            :key="track.track_number">
                            <td>{{ track.track_number }}</td>
                            <td
                              v-if="track.track_audio_url"
                              class="text-center">
                              <play-circle-icon
                                size="1.2x"
                                class="custom-class"></play-circle-icon>
                            </td>
                            <td
                              v-else
                              class="text-center">
                              <slash-icon
                                size="1.2x"
                                class="custom-class"></slash-icon>
                            </td>

                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_isrc,
                              }">
                              {{ track.track_isrc }}
                            </td>
                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_title,
                              }">
                              {{ track.track_title }}
                            </td>
                            <td>{{ track.track_subtitle }}</td>
                            <td>{{ track.track_version_title }}</td>
                            <td>{{ track.track_title_language }}</td>

                            <td
                              :class="{
                                'bg-danger bg-opacity-25':
                                  is_incomplete && !track.track_main_artists && !track.track_main_artists[0].name,
                              }">
                              {{ track.track_main_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_featured_artists.map((artist) => artist.name).join(", ") }}
                            </td>
                            <!-- <td>
                              {{ track.track_remix_artists.map((artist) => artist.name).join(", ") }}
                            </td> -->
                            <td>
                              {{ track.track_composers.map((composer) => composer.name).join(", ") }}
                            </td>
                            <td>
                              {{ track.track_lyricists.map((lyricist) => lyricist.name).join(", ") }}
                            </td>

                            <!-- <td>
                              {{ track.track_producers.map((producer) => producer.name).join(", ") }}
                            </td> -->
                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_genre,
                              }">
                              {{ track.track_genre.genre }} | {{ track.track_genre.subgenre }}
                            </td>
                            <td>{{ track.track_type }}</td>

                            <td>{{ track.track_lyric_language }}</td>

                            <td
                              :class="{
                                'bg-danger bg-opacity-25': is_incomplete && !track.track_parental_advisory,
                              }">
                              {{ track.track_parental_advisory }}
                            </td>
                            <td>{{ track.track_price_tier }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <router-link
                  v-if="!release.is_submitted_to_drm"
                  :to="{
                    name: 'ReleaseTracksList',
                    params: { release_id: release.id },
                  }"
                  class="btn btn-sm btn-outline-success">
                  <edit-icon size="1.0x"></edit-icon>
                  <span class="d-none d-xl-inline-block ms-2">Edit Tracks</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import { db } from "@/firebaseConfig";

import { ImageIcon, ArrowLeftCircleIcon, EditIcon, SlashIcon, PlayCircleIcon } from "vue-feather-icons";

export default {
  name: "ReleasePreview",

  data() {
    return {
      release: {},
      tracks: [],
      required_release: [
        "release_title",
        "release_main_artist",
        "release_label",
        "release_genre",
        "release_parental_advisory",
        "release_production_year",
        "release_original_production_year",
        "release_release_date",
      ],
      required_track: [
        "track_title",
        "track_number",
        "track_version_title",
        "track_title",
        "track_main_artists",
        "track_composers",
        "track_genre",
        "track_type",
        "track_lyric_language",
        "track_title_language",
        "track_parental_advisory",
        "track_delivery_territories",
        "track_release_date",
      ],
      missing_fields_release: [],
    };
  },

  components: {
    basicLayout,
    ImageIcon,
    ArrowLeftCircleIcon,
    EditIcon,
    SlashIcon,
    PlayCircleIcon,
  },

  firestore() {
    return {
      release: db.collection("release_submissions").doc(this.$route.params.release_id),
      tracks: db
        .collection("release_submissions_tracks")
        .where("release_id", "==", this.$route.params.release_id)
        .orderBy("track_number"),
    };
  },

  methods: {
    is_complete_release: function () {
      const release = this.release;
      console.log(Object.entries(release));
      let is_complete;

      const entries = Object.entries(release);
      const missing_fields = entries
        .filter(([k, v]) => {
          return k && (v === undefined || v === null || v === "" || v === [] || v === "[REQUIRED]");
        })
        .map(([k]) => k);

      console.log("MISSING FIELDS RELEASE: " + missing_fields);

      is_complete = !missing_fields.some((missing_field) => {
        return this.required_release.includes(missing_field);
      });

      this.missing_fields_release = missing_fields;

      console.log("IS RELEASE DATA COMPLETE?: " + is_complete);

      return is_complete;
    },

    is_complete_tracks: function () {
      console.log("CHECKING TRACKS ARE COMPLETE");
      const tracks = this.tracks;

      const all_complete = tracks
        .map((track) => {
          const entries = Object.entries(track);
          const missing_fields = entries
            .filter(([k, v]) => {
              return k && (v === undefined || v === null || v === "" || v === []);
            })
            .map(([k]) => k);

          console.log(missing_fields);

          const is_complete = !missing_fields.some((missing_field) => {
            return this.required_track.includes(missing_field);
          });
          console.log("IS TRACK COMPLETE?: " + is_complete);

          return is_complete;
        })
        .every((track) => track === true);

      console.log("IS EVERY TRACK COMPLETE?: " + all_complete);

      return all_complete;
    },
  },
  computed: {
    is_incomplete() {
      return !this.is_complete_release() || !this.is_complete_tracks();
    },

    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style scoped>
.paste-area {
  height: 20vh;
}
.table-sm {
  font-size: 0.6rem;
}

.card .list-group-item {
  font-size: 0.6rem;
}
</style>
