import { render, staticRenderFns } from "./ReleaseBulkArtUpload.vue?vue&type=template&id=1e83d1b6&"
import script from "./ReleaseBulkArtUpload.vue?vue&type=script&lang=js&"
export * from "./ReleaseBulkArtUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports