var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card border-success m-3"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.artist.name),expression:"artist.name"}],staticClass:"form-control form-control-sm",class:{
          'is-invalid': _vm.artist_submitted && !_vm.artist.name,
          'is-valid': _vm.artist.name,
        },attrs:{"id":"artist_name","type":"text","placeholder":"Artist Name"},domProps:{"value":(_vm.artist.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.artist, "name", $event.target.value)}}}),_c('label',{attrs:{"for":"artist_name"}},[_vm._v("Name")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid name.")])]),_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.artist.apple_id),expression:"artist.apple_id"}],staticClass:"form-control form-control-sm",class:{
          'is-invalid': _vm.artist_submitted && !_vm.artist.apple_id,
          'is-valid': _vm.artist.apple_id,
        },attrs:{"id":"apple_id","type":"text","placeholder":"1234567890"},domProps:{"value":(_vm.artist.apple_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.artist, "apple_id", $event.target.value)}}}),_c('label',{attrs:{"for":"apple_id"}},[_vm._v("Apple Id")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid ID.")])]),_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.artist.spotify_id),expression:"artist.spotify_id"}],staticClass:"form-control form-control-sm",attrs:{"id":"spotify_id","type":"text","placeholder":"1Ab2c34dFG5h67i8jKl9mn0"},domProps:{"value":(_vm.artist.spotify_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.artist, "spotify_id", $event.target.value)}}}),_c('label',{attrs:{"for":"spotify_id"}},[_vm._v("Spotify Id")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid ID.")])])]),_c('div',{staticClass:"card-footer text-end"},[_c('button',{staticClass:"btn btn-sm btn-outline-secondary me-2",on:{"click":function($event){$event.preventDefault();return _vm.cancel_create_artist.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-sm btn-outline-success",on:{"click":function($event){$event.preventDefault();return _vm.create_artist.apply(null, arguments)}}},[_vm._v(" Save Artist ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h6',[_vm._v("NEW ARTIST")])])
}]

export { render, staticRenderFns }