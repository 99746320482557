import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import logo from "../assets/logo.png"
// import { AlignCenterIcon } from 'vue-feather-icons'

pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  methods: {


    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          const dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = error => {
          reject(error);
        };

        img.src = url;
      });
    },


    pdfgen: async function (contract) {

      try {

        const dd = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 60, 30, 30],

          content: [

            {
              text: 'DRM TERMS OF SERVICE\n\n',
              style: 'header'
            },
            'These DRM Terms of Service ("Terms") are legally binding between you and Digital Rights Management New Zealand Limited ("DRM", "we", "our", "us"). By clicking to agree to these Terms, you are accepting the terms and conditions below, so please read and understand them completely before doing so.\n\n',

            {
              text: '1.  TERM AND TERRITORY\n',
              style: 'subheader'
            },
            '1.1	These Terms will automatically become effective on the date you click to agree to these Terms (the "Effective Date") and shall continue until the 30th of November 2025 ("Initial Term"). After the Initial Term, these Terms will be renewed automatically for additional terms of twelve months (each an "Extended Term"), unless previously terminated. The Initial Term and any Extended Term shall be referred to collectively as the "Term".\n\n',
            '1.2	The territory shall remain unchanged from the territory specified in your existing arrangement with us as at the Effective Date and shall cover all Services available in each such country, unless otherwise agreed by us ("Territory").\n\n',
            {
              text: '2.  GRANT OF RIGHTS\n',
              style: 'subheader'
            },
            '2.1	You grant to us the exclusive right in the Territory during the Term to licence your Catalogue to Services for Digital Exploitation and to collect, administer, and distribute the proceeds from such licences.\n\n',
            '2.2	You further grant to us the non-exclusive worldwide right to license the Recordings in synchronization with visual images. All such licenses for use in commercials, television or film productions are subject to your prior approval (which you will not withhold unreasonably).\n\n',
            '2.3	We shall have the right to prepare and encode your Catalogue in any format now known or hereafter devised.\n\n',
            {
              text: '3.  FEES\n',
              style: 'subheader'
            },
            '3.1	We shall pay you a percentage of Income (retaining the remaining percentage of Income as a distribution fee) at the same rates as specified in your existing agreement with us as at the Effective Date ("Fee").\n\n',
            '3.2	No payments shall be made for radio broadcast-like webcasts or interactive audio or audio-visual "streaming" transmission for the purpose of allowing consumers of any Service to preview tracks before they purchase or any other promotional uses.\n\n',
            '3.3	We may charge you a storage fee of One New Zealand Dollar (NZ$1.00) per Sound Recording each calendar year ("Housing Fee"), if applicable.\n\n',
            {
              text: '4.  DIGITAL DELIVERY\n',
              style: 'subheader'
            },
            '4.1	Throughout the Term, you shall provide at your expense to us all completed, fully mixed, edited and equalised and mastered Recordings suitable for commercial release to the public, complete artwork and complete and accurate metadata, Digital Assets, all approvals and licences necessary for the sale, licence and/or exploitation of the Recordings, and any additional information needed for the release of the Recordings as reasonably required by us (together, the "Deliverables") by such date as may be determined by us. You hereby acknowledge that failure to provide all required Delivery Materials by the date notified to you by us may affect the proposed release date of the Recordings in question.\n\n',
            '4.2	If you require non-standard encoding, formatting or subtitling, it may result in additional costs to you, which we may require in advance.\n\n',
            '4.3	On satisfactory receipt of the Deliverables from you, we will deliver your Catalogue in digital file format to Services with whom we have entered into agreements.\n\n',
            {
              text: '5.  PUBLISHING\n',
              style: 'subheader'
            },
            '5.1	You are responsible for securing all copyright licences and clearances to enable us to use and reproduce your Catalogue in accordance with these Terms.\n\n',
            '5.2	You assign the copyright in any Compositions not currently assigned to a third-party publisher, or that at any stage revert to you during the Term (subject only to any prior rights assigned to APRA) to us ("the Work(s)").  We shall then have the exclusive right during the Term to exploit those Works.  Any use of the Work(s) in third party advertising or granting of synchronisation licences shall require your consent, such consent not to be unreasonably withheld.\n\n',
            '5.3	If during the Term you wish to assign the Works to a third-party publisher, then you shall provide us with thirty (30) days’ notice of your intent to do so, and those Work(s) shall revert to you at the end of the next July or January accounting periods (whichever is earlier) following the expiry of such notice period.\n\n',
            '5.4	Wherever possible in granting licences to Services for use of your Catalogue, we will require that Service to bear the cost of and be responsible for mechanical copyright royalty payments.  In the event however that any Service does not, then you shall be responsible for any such payments.\n\n',
            {
              text: '6.  REPORTING\n',
              style: 'subheader'
            },
            '6.1	Within forty-five (45) days of the end of the months of May, July, September, November, January and March we will provide you a report detailing all income received by us for you for that preceding period, together with a breakdown of all deductions, including any advances.  Upon receipt of that report, you should prepare and forward to us an invoice.  We will pay you on the 20th of the month following receipt of that invoice.\n\n',
            '6.2	We will accrue to your account all Income, provided that no Income will be due and payable to you until such time as all Housing Fees have been fully recouped, if any.\n\n',
            '6.3	If the aggregate Income is less than Fifty New Zealand Dollars (NZ$50), the balance due to you will be rolled over until the reporting period in which an aggregate of at least Fifty New Zealand Dollars (NZ$50) is due to you.\n\n',
            '6.4	We are entitled to deduct any sums (such as withholding taxes) which we or our licensees or related entities are obliged to pay or withhold in any country by reason of any law or regulation. All payments to or charges to be made by us as per these Terms are expressed as exclusive of GST, which if applicable are payable by you in addition to the sums otherwise set out in these Terms.\n\n',
            '6.5	At any time within twelve (12) months after an income statement is submitted to you, you shall have the right to appoint a certified accountant to examine our books and records relating solely to the income relating to your Catalogue, provided that such examination shall take place at our offices during normal business hours, on reasonable written notice, not more frequently than once in any calendar year and at your sole cost and expense. Such examination may be conducted only once with respect to a particular statement. We shall be entitled to rely on the accounting provided to us by the Services. Any lawsuit by you regarding any statement or payment must be commenced within one (1) year after the date the applicable statement covers. Upon the expiration of such year, you have no right to pursue any further legal action in connection with any such statement, in connection with the amounts received, or relating to the period such statement covers. You irrevocably waive any longer statute of limitations that would otherwise apply by law.\n\n',
            {
              text: '7.  WARRANTIES BY YOU\n',
              style: 'subheader'
            },
            '7.1	YOU WARRANT THAT:\n',
            '(I) YOU ARE AT LEAST 18 YEARS OLD;\n',
            '(II) YOU HAVE FULL POWER AND AUTHORITY TO ENTER INTO AND, PERFORM YOUR OBLIGATIONS AS PER THESE TERMS;\n',
            '(III) YOU HAVE THE POWER TO GRANT TO US, THE LICENCES TO YOUR CATALOGUE GRANTED UNDER THESE TERMS;\n',
            '(IV) THE EXERCISE BY US OF OUR LICENCES TO YOUR CATALOGUE AS PER THESE TERMS DOES NOT INFRINGE THE RIGHTS (INCLUDING INTELLECTUAL PROPERTY RIGHTS) OF ANY PERSON; AND\n',
            '(V) THAT NO AGREEMENT OF ANY KIND PREVIOUSLY ENTERED INTO BY YOU OR ENTERED INTO BY YOU AFTER THE EFFECTIVE DATE WILL INTERFERE IN ANY MANNER WITH THE COMPLETE PERFORMANCE BY YOU OF THESE TERMS, OR INTERFERE WITH THE RIGHTS GRANTED BY YOU TO US HEREIN INCLUDING, BUT NOT LIMITED TO, THE REPRESENTATION RIGHTS TO YOUR CATALOGUE, OR ANY AGREEMENT ENTERED INTO BY US WITH A THIRD PARTY CONCERNING THE LICENSING AND/OR REPRESENTATION OF YOUR CATALOGUE.\n\n',
            '7.2	IF YOU ARE USING OUR SERVICES ON BEHALF OF ONE OR MORE OTHER PEOPLE, A GROUP, OR A COMPANY OR OTHER ENTITY, THEN BY ACCEPTING THESE TERMS YOU REPRESENT AND WARRANT TO US THAT YOU ARE DULY AUTHORIZED TO DO SO ON BEHALF OF ALL SUCH PERSONS/ENTITIES AND TO BIND THEM TO THESE TERMS AND THAT DRM IS FULLY ENTITLED TO RELY ON THAT FACT (IN WHICH CASE, THE TERM "YOU" INCLUDES ALL SUCH PEOPLE AND ENTITIES) IN OUR PERFORMANCE UNDER THESE TERMS.\n\n',
            '7.3	YOU SHALL BE SOLELY RESPONSIBLE FOR THE PAYMENT OF ANY AND ALL OF THE FOLLOWING:\n',
            '(I) ALL FEES, INCOME, MONIES, ROYALTIES OR ANY OTHER COMPENSATION DUE TO ARTISTS, PRODUCERS, RECORDING COPYRIGHT OWNERS, AND ALL OTHER THIRD PERSONS WHOSE PERFORMANCES ARE EMBODIED IN A RECORDING OR IS OTHERWISE INVOLVED IN THE CREATION OF THE RECORDING THAT IS INCLUDED IN YOUR CATALOGUE;\n',
            '(II) ALL MECHANICAL ROYALTIES PAYABLE TO PUBLISHERS, PUBLISHING ADMINISTRATORS, WRITERS AND CO-WRITERS OF COMPOSITIONS THAT ARE INCLUDED IN YOUR CATALOGUE;\n',
            '(III) ALL PAYMENTS THAT MAY BE REQUIRED UNDER ANY COLLECTIVE BARGAINING AGREEMENTS APPLICABLE TO THE RECORDINGS AND COMPOSITIONS THAT COMPRISE YOUR CATALOGUE; AND\n',
            '(IV) ANY OTHER FEES, COMPENSATION, ROYALTIES OR OTHER MONIES DUE AND OR PAYABLE WITH RESPECT TO THE DIGITAL ASSETS OR OTHER MATERIALS PROVIDED BY YOU TO US.\n\n',
            '7.4	YOU INDEMNIFY AND HOLD US HARMLESS (INCLUDING OUR PARENTS, SUBSIDIARIES, SERVICES AND AFFILIATES, AND EACH OF THEIR RESPECTIVE STOCKHOLDERS, PREDECESSORS, SUCCESSORS, ASSIGNS, AGENTS, DIRECTORS, OFFICERS, ATTORNEYS, EMPLOYEES AND REPRESENTATIVES) FROM ALL LOSS, DAMAGE COSTS AND EXPENSES SUFFERED OR INCURRED BY US ARISING OUT OF OR IN CONNECTION WITH\n',
            '(I) ANY CLAIM, ACTION OR PROCEEDINGS BY A PERSON  ALLEGING THAT ANY ACT OR OMISSION OF US IN EXERCISING OUR LICENCES TO YOUR CATALOGUE UNDER THESE TERMS INFRINGES THE INTELLECTUAL PROPERTY RIGHTS OF THAT THIRD PARTY; AND\n',
            '(II) ANY BREACH OF THE WARRANTIES GIVEN BY YOU UNDER THESE TERMS.\n\n',
            {
              text: '8.  WARRANTIES BY DRM\n',
              style: 'subheader'
            },
            '8.1	Subject to the warranties granted by you as per these Terms, we warrant that we have full power and authority to enter into and perform our obligations in accordance with these Terms, and that we will comply with all applicable laws in the performance of its obligations hereunder.\n\n',
            '8.2	We will use our reasonable efforts but cannot guarantee that a Service will utilise your Catalogue or any of the Compositions or Recordings that comprise your Catalogue. We retain the right not to exploit your Catalogue or any portion thereof with any particular Service where we determine in our sole discretion that your Catalogue or any portion thereof is not suitable for that Service.\n\n',
            '8.3	We are not responsible and are not liable to you for any unauthorised use, reproduction or communication of your Catalogue by any third party.\n\n',
            {
              text: '9.  TERMINATION\n',
              style: 'subheader'
            },
            '9.1	Either party may terminate these Terms:\n\n',
            '(a)	without cause by providing notice of its intention to terminate to the other party, no later than sixty (60) days before the end of the Term; or\n\n',
            '(b)	by giving written notice to the other, if that other party breaches any material term of these Terms and, having been given thirty (30) days\'s notice to rectify the breach, fails to do so.\n\n',
            'Any such termination shall take effect at the end of the accounting period in which such notice expires.\n\n',
            '9.2	After the Term, if we receive any income from Services relating to your Catalogue, our sole liability to you will be the obligation to pay you in full as though these Terms were still in force, and to send renewed take-down notices to Services whenever requested and appropriate.\n\n',
            {
              text: '10.  REMOVAL OF CATALOGUE ITEMS\n',
              style: 'subheader'
            },
            '10.1	Without limiting your indemnification obligations, in the event you no longer have the necessary rights to an individual Recording or other item in your Catalogue or if an individual Recording or other item in your Catalogue is the subject of a third-party claim that reasonably risks exposing you or us to liability ("Deleted Work"), you must promptly inform us in writing so that we may notify Services to remove the Deleted Work.  This is for single works only and shall not operate as a de-facto takedown arrangement.  The Deleted Work must also be removed by you from any other service to which you had made the Deleted Work available.\n\n',
            {
              text: '11.  GENERAL\n',
              style: 'subheader'
            },
            '11.1	We shall have the right to assign, sublicense or otherwise dispose of or deal with any or all of our rights and responsibilities under these Terms to any person or entity, either wholly or partially. You will not, without our prior written consent, assign your rights or obligations under these Terms.\n\n',
            '11.2	You retain ownership of all intellectual property rights subsisting in any Catalogue provided to us under these Terms.\n\n',
            '11.3	These Terms are governed by the laws of New Zealand. The parties each submit to the exclusive jurisdiction of the courts of Auckland.\n\n',
            '11.4	As to each other, you and us are independent contractors. Nothing in these Terms authorises any party as agent of the other, nor establishes a partnership or fiduciary relationship.\n\n',
            '11.5	In the event any provision hereof shall for any reason be invalid, illegal, or unenforceable, then such provisions shall be deemed amended only to the extent necessary to eliminate such invalidity, illegality or unenforceability and in any such event, the same shall not affect the validity of the remaining portion and provisions hereof.\n\n',
            '11.6	Save for the Territory and Fee which shall each remain unchanged from your existing agreement with us as at the Effective Date, these Terms constitute the entire agreement between the parties regarding the matters set out in it and supersedes any prior representations, understandings, or arrangements between the parties, whether orally or in writing. These Terms may only be varied by written agreement between the parties.  A condition of these Terms, or a right created by it, may only be waived by written notice signed by the party granting the waiver. A waiver of a breach of these Terms does not waive any other breach. Clauses that by their design are intended survive the termination or expiry of these Terms shall survive the termination of these Terms.\n\n',
            '11.7	We may occasionally update these Terms. If we make significant changes, we will notify users in advance of the changes via the DRM portal or via other means, such as email. We encourage users to periodically review these Terms for the latest information on our service. Use of our services after an update constitutes consent to the updated Terms to the extent permitted by law. Notwithstanding the foregoing, any changes to the Term, Territory or our distribution fee shall require mutual agreement between you and us.\n\n',
            '11.8	Headings are for convenience only and shall not limit or in any way affect the scope, meaning or intent of these Terms or any portion of it.\n\n',
            '11.9	You represent and warrant that you have been advised of your rights to seek expert independent advice of your own choosing in connection with the negotiation and execution of these Terms.\n\n',
            '11.10	A condition of these Terms, or a right created by it, may only be waived by written notice signed by the party granting the waiver. A waiver of a breach of these Terms does not waive any other breach.\n\n',
            '11.11	These Terms may be executed in two or more counterparts each of which shall be deemed an original, but all of which together shall constitute one and same instrument.\n\n',
            '11.12	All notices to us must be in writing to info@drm.co.nz and shall not be validly delivered until receipt is acknowledged.\n\n',

            {
              text: '12.  DEFINITIONS\n',
              style: 'subheader'
            },

            '"Your Catalogue"',
            'All sound and video recordings owned, controlled or made by or on behalf of you at any time during the Term, (the "Recording(s)"), all musical compositions embodied in the Recordings (subject to any existing rights assigned to APRA or your publisher, if any) (the "Compositions"), all other digital assets relating to the Recordings (including without limitation, album covers and other artwork, music videos, image files, marketing materials, trade names, trademarks, logos, likenesses, biographies and user generated content),collectively (the "Digital Assets") that you have the right to licence for promotional and/or commercial use.\n\n',
            '"Digital Exploitation"',
            'The distribution (or other authorisation of use) of your Catalogue by any current or future means or media as selected by DRM in good faith, including without limitation transmission, distribution, dissemination, or making available via telephone, satellite, broadcast, wireless, cable and/or the internet whether or not a direct or indirect charge is made, or any other use, excluding only the distribution of physical records.\n\n',
            '"Income"',
            'Any payment, royalties, proceeds or other monies or remuneration received by DRM attributable to the use of your Catalogue or any portion thereof, less any taxes, GST, Housing Costs, digitisation or encoding charges or public performance fees, if any.\n\n',
            '"Services"',
            'Any third party of any description that exploits sound or audiovisual recordings by any and all non-physical means and media (whether now known or existing in the future), including without limitation internet sites, telecommunications companies, broadcasters, on-line services and all other entities distributing entertainment products in a digital format via downloading, streaming, wireless, cable and or internet or other existing and future digital delivery methods, as selected by DRM in its sole discretion.\n\n',

            'You agree to the above Terms with us for our exclusive right to licence your Catalogue within the Territory during the Term. You agree to do such further acts and to execute and deliver such further documents as we may require to give full effect to these Terms.\n\n',



            {
              image: contract.signature,
              width: 150
            },
            '________________________________',
            {
              text: `SIGNED BY: ${contract.name.toUpperCase()}`,
              style: 'subheader'
            },

            {
              text: `DATED: ${contract.date_signed.toLocaleString('en-NZ')}`,
              style: 'subheader'
            },

          ],

          header: {
            image: await this.getBase64ImageFromURL(logo),
            width: 40,
            style: 'headerImage',
            margin: [10, 10, 10, 10]


          },
          footer: function (currentPage, pageCount) {
            return {
              text: currentPage.toString() + ' of ' + pageCount,
              alignment: "right",
              margin: [10, 10, 10, 10]
            }
          },

          defaultStyle: {
            alignment: "justify",
            fontSize: 10,
            lineHeight: 1.4

          },
          styles: {
            headerImage: {
              alignment: "right",
              lineHeight: 1.5

            },
            header: {
              alignment: "center",
              fontSize: 16,
              lineHeight: 1.5,
              bold: true
            },
            subheader: {
              fontSize: 12,
              bold: true
            },
            quote: {
              italics: true
            },
            small: {
              fontSize: 8
            }
          }
        }

        // return pdfMake.createPdf(dd).open();

        let pdfDocGenerator = pdfMake.createPdf(dd);

        const base_64_promise = () => {
          return new Promise((resolve, reject) => {
            pdfDocGenerator.getBase64((data) => {
              resolve(data);
            });
          })
        }

        let base_64 = await base_64_promise()

        return { success: true, contract: base_64, }

      } catch (error) {
        console.log("pdfgen error: " + error)
        return { success: false, contract: null, error }
      }
    }
  }
}