<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <div class="col">
            <h2>Audio Files Upload</h2>
            <p class="lead">
              Please select one or multiple audio files (.wav) for upload.
              <br />When you have selected the audio files, match them to the correct track.<br />Once you have
              successfully uploaded the audio files, you can upload cover art (.jpg only) for your release.
            </p>
          </div>
        </div>
      </div>

      <div class="row m-1 mb-3">
        <div class="col-xl-12">
          <div class="row">
            <div class="col">
              <input
                @change.prevent="file_picker"
                class="form-control"
                type="file"
                accept=".wav"
                multiple />
            </div>

            <div
              class="col"
              v-cloak
              @drop.prevent="drag"
              @dragover.prevent>
              <input
                class="form-control progress-bar-striped alert-dark"
                placeholder="...or drag files here"
                accept=".wav"
                multiple />
            </div>
          </div>
        </div>
      </div>

      <div class="row m-1">
        <div class="col">
          <div class="card">
            <div class="card-header">Match Audio Files to Tracks...</div>
            <div class="card-body">
              <div
                class="row"
                :class="{ 'disabled-div': is_just_open }">
                <div class="col">
                  <table class="table border-top">
                    <thead class="table-dark sticky-top">
                      <tr>
                        <th
                          @click="sort_string(release_submissions, 'track_number')"
                          class="w-5">
                          Track #
                        </th>
                        <th
                          @click="sort_number(release_submissions, 'track_title')"
                          class="w-35">
                          Track Title
                        </th>

                        <th
                          class="w-35"
                          title="Please match the imported files to the correct track and then click 'Upload Files'.">
                          Select Audio File to Upload
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="track in tracks"
                        :key="track.id"
                        class="align-middle">
                        <td @dblclick="filter_by(track.track_number)">
                          {{ track.track_number }}
                        </td>
                        <td @dblclick="filter_by(track.track_title)">
                          {{ track.track_title }}
                          <span v-if="track.track_subtitle">&nbsp;({{ track.track_subtitle }}) </span>
                        </td>
                        <td>
                          <v-select
                            placeholder="Select correct audio file for this track"
                            v-model="track.track_audio_file"
                            :disabled="!track_audio_files.length"
                            :options="track_audio_files"
                            :selectOnTab="true"
                            :clearable="true"></v-select>
                        </td>
                        <td
                          v-if="is_uploading"
                          class="align-middle d-none d-sm-table-cell text-center">
                          <div class="progress">
                            <div
                              class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                              role="progressbar"
                              :style="{
                                width: track.track_upload_progress + '%',
                              }"
                              :key="track.track_upload_progress"
                              aria-valuemin="0"
                              aria-valuemax="100"></div>
                          </div>
                        </td>
                        <td
                          v-else-if="track.track_audio_url && !is_uploading"
                          class="align-middle d-none d-sm-table-cell text-center">
                          <audio controls>
                            <source
                              :src="track.track_audio_url"
                              type="audio/wav"
                              preload="auto" />
                          </audio>
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <button
                    @click="pre_upload_files"
                    :disabled="!track_audio_files.length"
                    class="btn btn-sm btn-outline-success"
                    type="button">
                    Upload Files
                  </button>

                  <router-link
                    :to="{
                      name: 'ReleaseEdit',
                      params: { release_id: this.release.id },
                    }"
                    class="btn btn-outline-secondary btn-sm text-nowrap routerlink ms-1">
                    Edit Release
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>


<script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
import { auth } from "@/firebaseConfig.js";
import { state, getters, actions } from "@/store";
import { db, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";

export default {
  name: "release_tracks_list",

  data() {
    return {
      release: {},
      tracks: [],
      track_audio_files: [],
      is_just_open: true,
      is_uploading: false,
    };
  },
  components: {
    basicLayout,
    //  ArrowLeftCircleIcon,
    // EditIcon,
    // TrashIcon,
    // CopyIcon,
    // SmileIcon,
    // MehIcon,
    // MusicIcon,

    // ImageIcon
    // draggable
  },
  mixins: [filter_mixins, sort_mixins],

  firestore() {
    return {
      release: db.collection("release_submissions").doc(this.$route.params.release_id),
      tracks: db
        .collection("release_submissions_tracks")
        .where("release_id", "==", this.$route.params.release_id)
        .orderBy("track_number"),
    };
  },

  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },

    is_loading() {
      return getters.isLoading();
    },
  },

  methods: {
    drag(event) {
      const files = event.dataTransfer.files;
      this.import_files(files);
    },
    file_picker: async function (event) {
      const files = event.target.files;
      this.import_files(files);
    },

    import_files: function (files) {
      let track_audio_files = [];
      for (const file of files) {
        let file_size = `${(file.size / 1048576).toFixed(0).toLocaleString("en-NZ")} MB`;
        let track_audio_file = {
          file: file,
          label: file.name,
          name: file.name,
          size: file_size,
          type: file.type,
        };

        // console.log(track_audio_file);
        track_audio_files.push(track_audio_file);
      }

      const is_only_wav_files = track_audio_files.every((file) => {
        const allowedExtensions = /(\.wav|\.m4a)$/i;
        return allowedExtensions.exec(file.name);
      });

      if (!is_only_wav_files) {
        this.$toast.warning("Please upload a WAV file or M4A file only.", {
          position: "top-right",
          timeout: 5000,
        });
        this.is_just_open = true;
        return false;
      }

      this.$toast.success("Please match the imported files to the correct track and then click 'Upload Files'.", {
        position: "top-right",
        timeout: 5000,
      });
      this.is_just_open = false;
      this.track_audio_files = track_audio_files;
    },

    pre_upload_files: function () {
      let upload_files_selected = this.tracks
        .filter((track) => track.track_audio_file)
        .map((track) => track.track_audio_file.name);

      if (!upload_files_selected.length) {
        this.$toast.warning("You have not selected any audio files for upload", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      // console.log(JSON.stringify(upload_files_selected));
      let duplicates = new Set(upload_files_selected).size !== upload_files_selected.length;

      if (duplicates) {
        this.$toast.warning("You have selected the same audio file for more than one track", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      this.$toast.success("Please do not leave or refresh this page during upload.", {
        position: "top-right",
        timeout: 5000,
      });

      this.upload_files();
      actions.setLoading(true);
    },

    upload_files: async function () {
      this.is_uploading = true;
      this.track_audio_files = [];
      let upload_files_promises = this.tracks
        .filter((track) => track.track_audio_file)
        .map(async (track) => {
          const track_audio_url = await this.uploadTaskPromise(track);
          // console.log(`Successfully uploaded file and got download link - ${track_audio_url}`);

          const track_duration = await this.compute_duration(track.track_audio_file.file);

          return db.collection("release_submissions_tracks").doc(track.id).update({ track_audio_url, track_duration });
        });

      await Promise.all(upload_files_promises);
      this.is_uploading = false;
      actions.setLoading(false);
      this.$router.push({
        name: "ReleaseBulkArtUpload",
        params: { release_id: this.release.id },
      });
      return;
    },

    uploadTaskPromise: async function (track) {
      // return new Promise((resolve, reject) => {
      const storageRef = storage.ref();
      const file_path = `tracks/${track.release_id}/${track.id}.wav`;
      const wavRef = storageRef.child(file_path);
      const metadata = {
        release_id: this.release.id,
        track_id: track.id,
        record_type: "track_audio",
      };
      // console.log(metadata);
      const uploadTask = wavRef.put(track.track_audio_file.file, { customMetadata: metadata });
      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload for track " + track.track_number + " is " + progress + "%");
        // this.progress = progress;
        track.track_upload_progress = progress;
      });
      return uploadTask.then((snapshot) => {
        // console.log("Upload is complete");
        return snapshot.ref.getDownloadURL();
      });
    },

    compute_duration(file) {
      return new Promise((resolve) => {
        var objectURL = URL.createObjectURL(file);
        var mySound = new Audio([objectURL]);
        mySound.addEventListener(
          "canplaythrough",
          () => {
            URL.revokeObjectURL(objectURL);
            // console.log(JSON.stringify(mySound.duration));
            resolve(mySound.duration);
          },
          false
        );
      });
    },
  },
};
</script>

<style scoped>
.disabled-div {
  pointer-events: none;
  opacity: 0.4;
}
</style>