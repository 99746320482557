<template>
  <div>
    <router-link
      :to="{
        name: 'ReleaseTracksList',
        params: { release_id: release_id },
      }"
      class="btn btn-sm btn-block btn-outline-success text-nowrap routerlink"
    >
      <music-icon size="1.2x"></music-icon>
      <span class="d-none d-xl-inline-block ms-2">Edit Tracks</span>
      <span class="mx-1"></span>

      <span
        class="badge"
        :class="{
          'badge-success bg-success': tracks_complete(release_id),
          'badge-warning bg-secondary':
            !tracks_complete(release_id) || !tracks_count(release_id),
        }"
        :title="
          !tracks_complete(release_id) || !tracks_count(release_id)
            ? 'missing or incomplete tracks'
            : ''
        "
        >{{ tracks_count(release_id) }}</span
      >
    </router-link>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import { MusicIcon } from "vue-feather-icons";

const tracks = db.collection("release_submissions_tracks");

export default {
  props: {
    release_id: String,
  },

  data() {
    return {
      display_tracks: [],
    };
  },

  components: {
    MusicIcon,
  },

  watch: {
    release_id: {
      immediate: true,
      handler(release_id) {
        let query = tracks;
        if (release_id) {
          query = query.where("release_id", "==", release_id);
        }
        this.$bind("display_tracks", query);
      },
    },
  },

  //   firestore() {
  //     return {
  //       notes: db
  //         .collection("notes")
  //         .where("org_id", "==", this.$route.params.org_id),
  //     };
  //   },

  methods: {
    tracks_count(release_id) {
      const tracks = this.display_tracks.filter(
        (track) => track.release_id === release_id
      );
      return tracks.length;
    },
    tracks_complete(release_id) {
      const release_tracks = this.display_tracks.filter(
        (track) => track.release_id === release_id
      );
      const tracks_incomplete = release_tracks.some(
        (track) => !track.track_audio_url || track.is_incomplete
      );
      return release_tracks.length && !tracks_incomplete;
    },
  },
};
</script>

<style></style>
