<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>MTL PRODUCTS</h2>
        </div>
      </div>

      <div class="row m-1">
        <div class="col-xl-6 mt-2">
          <div class="input-group mb-3">
            <span class="input-group-text">Search...</span>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Release Title, Track Title, Artist, Owner, UPC, ISRC ..." />
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
      <button
        @click="import_mtl_products"
        class="btn btn-outline-secondary"
        type="button">
        IMPORT MTL PRODUCTS
      </button>

      <button
        @click="import_mtl_assets"
        class="btn btn-outline-secondary"
        type="button">
        IMPORT MTL ASSETS
      </button>

      <div class="row m-1 mb-3">
        <div class="col mt-2">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-8">
                  <p>
                    <span class="h5">PRODUCTS</span>&nbsp;<span class="small text-muted"
                      ><i>click on a product to see all associated assets below.</i>
                    </span>
                  </p>
                </div>
                <div class="col-xl-4 text-end">
                  <span v-if="filtered">{{ filtered.length }} results</span>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <table class="table table-sm table-hover border-top">
                    <thead class="table-dark sticky-top">
                      <tr>
                        <th
                          @click="sort_by('upc')"
                          role="button"
                          class="w-10">
                          UPC
                          <span v-if="sort_column === 'upc' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'upc' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>

                        <!-- <th
                            @click="sort_by('track_isrc')"
                            role="button"
                            class="w-10">
                            ISRC
                            <span v-if="sort_column === 'track_isrc' && sort_order === 'desc'"
                              ><chevron-down-icon
                                size="0.9x"
                                class="muted ms-1"></chevron-down-icon
                            ></span>
                            <span v-if="sort_column === 'track_isrc' && sort_order === 'asc'"
                              ><chevron-up-icon
                                size="0.9x"
                                class="muted ms-1"></chevron-up-icon
                            ></span>
                          </th> -->

                        <!-- owner -->
                        <th
                          @click="sort_by('owner_org')"
                          role="button"
                          class="w-10">
                          Owner
                          <span v-if="sort_column === 'owner_org' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'owner_org' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- title -->
                        <th
                          @click="sort_by('title')"
                          role="button"
                          class="w-10">
                          Product Title
                          <span v-if="sort_column === 'title' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'title' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- <th
                            @click="sort_by('track_title')"
                            role="button"
                            class="w-10">
                            Track Title
                            <span v-if="sort_column === 'track_title' && sort_order === 'desc'"
                              ><chevron-down-icon
                                size="0.9x"
                                class="muted ms-1"></chevron-down-icon
                            ></span>
                            <span v-if="sort_column === 'track_title' && sort_order === 'asc'"
                              ><chevron-up-icon
                                size="0.9x"
                                class="muted ms-1"></chevron-up-icon
                            ></span>
                          </th> -->
                        <!-- main_artist -->
                        <th
                          @click="sort_by('main_artist')"
                          role="button"
                          class="w-10">
                          Artist
                          <span v-if="sort_column === 'main_artist' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'main_artist' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>
                        <!-- release -->
                        <th
                          @click="sort_by('date')"
                          role="button"
                          class="w-15">
                          Released

                          <span v-if="sort_column === 'date' && sort_order === 'desc'"
                            ><chevron-down-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-down-icon
                          ></span>
                          <span v-if="sort_column === 'date' && sort_order === 'asc'"
                            ><chevron-up-icon
                              size="0.9x"
                              class="muted ms-1"></chevron-up-icon
                          ></span>
                        </th>

                        <!-- preview -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="product in filtered"
                        @click="get_assets(product)"
                        :key="product.id"
                        class="align-left"
                        :class="row_class(product)">
                        <td
                          @dblclick="filter_by(product.upc)"
                          class="text-truncate">
                          {{ product.upc }}
                        </td>
                        <!-- <td><span v-html="product._highlightResult.track_isrc.value"></span></td> -->
                        <td
                          @dblclick="filter_by(product.owner_org)"
                          class="text-truncate">
                          {{ product.owner_org }}
                        </td>
                        <td
                          @dblclick="filter_by(product.title)"
                          class="text-truncate">
                          {{ product.title }}
                        </td>
                        <!-- <td @dblclick="filter_by(product.track_title)">
                            <span v-html="product._highlightResult.track_title.value"></span>
                          </td> -->
                        <td
                          @dblclick="filter_by(product.main_artist)"
                          class="text-truncate">
                          {{ product.main_artists }}
                        </td>
                        <!-- product_date -->
                        <td>{{ product.release_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="product_assets.length"
        class="row m-1">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h5>
                <!-- <img
                    v-if="assets[0].release_art_url"
                    :src="assets[0].release_art_url"
                    :alt="assets[0].release_title"
                    width="40px"
                    loading="lazy" /> -->
                <!-- <span class="ms-3">
                    {{ assets[0].release_main_artist }} - {{ assets[0].release_title }} ({{
                      assets[0].release_upc
                    }})</span
                  > -->
              </h5>
            </div>
            <div class="card-body">
              <table class="table table-bordered table-sm table-hover">
                <thead>
                  <tr>
                    <th scope="col w-5">ISRC</th>
                    <th scope="col w-5">No.</th>
                    <th scope="col w-10">Track Title</th>
                    <th scope="col w-10">Artists</th>
                    <th scope="col w-5">Featuring</th>
                    <th scope="col w-5">Released</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="asset in product_assets"
                    :key="asset.isrc">
                    <td>{{ asset.isrc }}</td>
                    <td>{{ asset.number }}</td>
                    <td>
                      {{ asset.title }} <span v-if="asset.subtitle">{{ asset.subtitle }}</span>
                    </td>
                    <td>{{ asset.main_artists }}</td>
                    <td>{{ asset.featured_artists }}</td>
                    <td>{{ asset.release_date }}</td>
                    <td>
                      <audio
                        v-if="asset.audio_url"
                        height="12"
                        controls>
                        <source
                          :src="asset.audio_url"
                          type="audio/wav"
                          preload="auto" />
                      </audio>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
      </div>

      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>
  
  <script>
// https://w3lessons.info/bootstrap-fixed-table-header-using-css/
// import { Toast, Modal } from "bootstrap";
//   import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import { fb, db } from "@/firebaseConfig";
// import algoliasearch from "algoliasearch";
import { orderBy } from "lodash";
// import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import {
  EditIcon,
  EyeIcon,
  TrashIcon,
  ShuffleIcon,
  ImageIcon,
  MusicIcon,
  CopyIcon,
  DownloadIcon,
  CodeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MoreHorizontalIcon,
  ThumbsUpIcon,
  ExternalLinkIcon,
  StarIcon,
  ArchiveIcon,
  InfoIcon,
} from "vue-feather-icons";

const products = db.collection("mtl_products");
const assets = db.collection("mtl_assets");

export default {
  name: "my_release_submissions",

  data() {
    return {
      selected_line_id: "",
      display_products: [],
      product_assets: [],
      search: this.$route.query.search || "",
      debounce: null,
      sort_column: ["track_number"],
      sort_order: "asc",
      filters: "",
      page_limit: 10,
      selected_page: 1,
      // ALGOLIA_APP_ID: "LC1Z0QAV0G",
      //   searchClient: null,
      index: {},
    };
  },

  components: {
    basicLayout,
    Pagination,
    EditIcon,
    EyeIcon,
    TrashIcon,
    ShuffleIcon,
    ImageIcon,
    MusicIcon,
    CodeIcon,
    CopyIcon,
    DownloadIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    MoreHorizontalIcon,
    ThumbsUpIcon,
    ExternalLinkIcon,
    StarIcon,
    ArchiveIcon,
    InfoIcon,
  },
  mixins: [filter_mixins, sort_mixins],

  mounted() {
    let query = products;

    query = query.limit(500);
    this.$bind("display_products", query);
  },

  methods: {
    import_mtl_products: async function () {
      const mtl_to_products_convert = fb.httpsCallable("mtl_to_products_convert");
      mtl_to_products_convert();
    },

    import_mtl_assets: async function () {
      const mtl_to_assets_convert = fb.httpsCallable("mtl_to_assets_convert");
      mtl_to_assets_convert();
    },

    row_class(selected_line) {
      return { "alert alert-secondary": this.selected_line_id === selected_line.upc };
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 500);
    },

    clear_search() {
      this.search = "";
      this.assets = [];
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
    updatePage(value) {
      this.selected_page = value;
    },

    get_assets(product) {
      this.selected_line_id = product.upc;
      this.$bind("product_assets", assets.where("product_upc", "==", product.upc).orderBy("track_number"));
    },
  },

  watch: {
    page: {
      handler(page, old_page) {
        if (page !== old_page) {
          this.assets = [];
        }
      },
    },

    search: {
      handler(search, old_search) {
        if (search !== old_search) {
          this.selected_page = 1;
          this.assets = [];
        }
      },
      immediate: true,
    },

    //   userProfile: {
    //     immediate: true,
    //     handler(profile) {
    //       this.get_tracks(profile);
    //     },
    //   },
  },

  computed: {
    filtered() {
      let visible_products = this.display_products;

      let search = this.search;

      if (this.sort_column) {
        visible_products = orderBy(visible_products, this.sort_column || "", this.sort_order);
      }

      visible_products = visible_products.filter((product) => {
        const s = product.name + product.drm_id;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      const start = (this.selected_page - 1) * this.page_limit;
      const end = start + this.page_limit;
      visible_products = visible_products.slice(start, end);

      return visible_products;
    },

    pages() {
      const pages_array = this.search ? this.filtered : this.display_products;
      return Math.ceil(pages_array.length / this.page_limit);
    },

    userProfile() {
      return state.userProfile;
    },
    isAdmin() {
      return this.userProfile.role === "admin" || this.userProfile.role === "superadmin";
    },
  },
};
</script>

<style >
.table > tbody > tr > td {
  vertical-align: middle;
}

td em {
  font-style: normal;
  font-weight: bold;
  background-color: #ceece5;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.routerlink {
  display: inline-block;
  text-decoration: none;
}
.btn-block {
  width: 100%;
}

audio {
  height: 32px;
}
</style>
  