import Vue from 'vue'
import {
  db
} from '@/firebaseConfig'
import LogRocket from 'logrocket'

export const state = Vue.observable({
  currentUser: {},
  userProfile: {},
  loading: false,
  refresh_docs: false,
  fugaCreds: {}

})

export const getters = {
  currentUser: () => state.currentUser,
  userProfile: () => state.userProfile,
  isLoading: () => state.loading,
  is_refreshing_docs: () => state.refresh_docs,
  fugaCreds: () => state.fugaCreds,
}


export const mutations = {
  setCurrentUser: (val) => state.currentUser = val,
  setUserProfile: (val) => state.userProfile = val,
  setLoading: (val) => state.loading = val,
  set_refresh_docs: (val) => state.refresh_docs = val,
  setFugaCreds: (val) => state.fugaCreds = val,

}

export const actions = {
  fetchCurrentUserProfile(user) {
    db.collection('users').doc(user.uid).get()
      .then(res => {
        const user_profile = res.data()
        mutations.setUserProfile({ id: user.uid, ...user_profile })
        // capture for LogRocket
        LogRocket.identify(user.uid, {
          name: user_profile.name,
          email: user_profile.email
        })
        // set last_login on user record
        db.collection("users").doc(user.uid).update({ last_login: new Date() })
      })
      .catch(err => {
        console.log(err)
      })
  },
  clearUser() {
    mutations.setUserProfile({})
    mutations.setCurrentUser({})
  },
  setLoading(loadingState) {
    mutations.setLoading(loadingState)
  },
  set_refresh_docs(refresh_state) {
    mutations.set_refresh_docs(refresh_state)
  },
  setFugaCreds(fugaCreds) {
    mutations.setFugaCreds(fugaCreds)
  },

}

// export const store = {
//   state: {
//     currentUser: {
//       email: "alex@alexwebster.com"
//     }
//   },
//   setUser(user) {
//     this.state.currentUser = user;
//   },
//   clearUser() {
//     this.state.currentUser = {};
//   }
// };
