<template>
  <div class="mb-3">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        :class="description.class"
        role="progressbar"
        :style="{
          width: score.percentage_score + '%',
        }"
        :key="score.percentage_score"
        aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
    <small class="text-muted"
                      >{{description.label}}</small
                    >
    </p>
  </div>
</template>
<script>
import { zxcvbn } from "@zxcvbn-ts/core";

// const options = {
//   dictionary: {
//     ...zxcvbnCommonPackage.dictionary,
//     ...zxcvbnEnPackage.dictionary,
//   },
//   graphs: zxcvbnCommonPackage.adjacencyGraphs,
//   translations: zxcvbnEnPackage.translations,
// };
// zxcvbnOptions.setOptions(options);

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    descriptions: function () {
      return [
        {
          color: "bg-red-600",
          class: "bg-danger",
          label: "very weak",
        },
        { color: "bg-red-300", class: "bg-danger bg-opacity-75", label: "weak" },
        { color: "bg-yellow-400", class: "bg-danger bg-opacity-75", label: "not safe" },
        { color: "bg-green-200", class: "bg-success bg-opacity-50", label: "safe" },
        {
          color: "bg-green-400",
          class: "bg-success",
          label: "very strong",
        },
      ];
    },

    description() {
      return this.value && this.value.length > 0
        ? this.descriptions[this.score.raw_score]
        : {
            color: "bg-transparent",
            class: "bg-transparent",
            label: "Start typing to check your password",
          };
    },

    score() {
      const hasValue = this.value && this.value.length > 0;

      if (!hasValue) {
        return 0;
      }
      console.log(JSON.stringify(zxcvbn(this.value).score));
      let raw_score = zxcvbn(this.value).score;
      let integer_score = raw_score + 1;
      let percentage_score = (integer_score / this.descriptions.length) * 100;

      return { raw_score, integer_score, percentage_score };
    },

    isPasswordStrong() {
      return this.score.raw_score >= 3; // safely unguessable
    },
  },

  watch: {
    isPasswordStrong: {
      immediate: true,
      handler(value) {
        value ? this.$emit("passed") : this.$emit("failed");
      },
    },
  },
};
</script>
