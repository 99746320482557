<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-1">
        <div class="col">
          <h2>Artists</h2>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-xl-6">
          <!-- <div class="row">
            <div class="col-xl-9"> -->
          <div class="input-group flex-wrap mb-3">
            <span class="input-group-text">Search...</span>

            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Name, Email or FUGA Id"
            />

            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>

        <div class="col-xl-6">
          <router-link
            :to="{ name: 'ArtistCreate' }"
            type="button"
            class="btn btn-outline-secondary"
            >Create Artist</router-link
          >
          <div class="btn-group ms-3" role="group">
            <button
              id="btnGroupDrop1"
              type="button"
              class="btn btn-outline-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <span
                  @click="download_artists('selection')"
                  class="dropdown-item"
                >
                  <download-icon
                    size="1.2x"
                    class="custom-class me-2"
                  ></download-icon
                  ><span class="d-none d-xl-inline-block ml-1"
                    >Export Selection</span
                  ><span class="badge bg-light text-dark ms-2">{{
                    filtered.length
                  }}</span></span
                >
              </li>
              <li>
                <span @click="download_artists('all')" class="dropdown-item">
                  <download-icon
                    size="1.2x"
                    class="custom-class me-2"
                  ></download-icon
                  ><span class="d-none d-xl-inline-block ml-1">Export All</span
                  ><span class="badge bg-light text-dark ms-2">{{
                    display_artists.length
                  }}</span></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- </div> -->

      <div class="row m-3">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xl-6">
                  <div class="btn-group" data-toggle="buttons">
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: artists_filter === 'all',
                      }"
                    >
                      <input
                        v-model="artists_filter"
                        value="all"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      All
                    </label>
                    <label
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: artists_filter === 'inactive',
                      }"
                    >
                      <input
                        v-model="artists_filter"
                        value="inactive"
                        type="radio"
                        class="btn-check nav-link"
                        autocomplete="off"
                      />
                      Inactive
                    </label>
                  </div>
                </div>

                <div class="col-xl-6 text-end">
                  Showing {{ filtered.length }} of {{ display_artists.length }}
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-sm table-hover">
                <thead class="table-dark sticky-top">
                  <tr>
                    <th
                      @click="sort_by('name')"
                      role="button"
                      class="text-left w-20"
                      scope="col"
                    >
                      Name
                      <span
                        v-if="sort_column === 'name' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'name' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('orgs[0].name')"
                      role="button"
                      class="d-none d-xl-table-cell text-left w-30"
                      scope="col"
                    >
                      Orgs
                      <span
                        v-if="
                          sort_column === 'orgs.name' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'orgs.name' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('tier')"
                      role="button"
                      class="d-none d-xl-table-cell text-left"
                      scope="col"
                    >
                      Tier
                      <span
                        v-if="sort_column === 'tier' && sort_order === 'desc'"
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'tier' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('date_created')"
                      role="button"
                      class="d-none d-xl-table-cell text-left"
                      scope="col"
                    >
                      Created
                      <span
                        v-if="
                          sort_column === 'date_created' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'date_created' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('is_contract_signed')"
                      role="button"
                      class="d-none d-xl-table-cell text-left"
                      scope="col"
                    >
                      Contract
                      <span
                        v-if="
                          sort_column === 'is_contract_signed' &&
                          sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'is_contract_signed' &&
                          sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>
                    <th
                      @click="sort_by('created_by')"
                      role="button"
                      class="d-none d-xl-table-cell text-left"
                      scope="col"
                    >
                      Created By
                      <span
                        v-if="
                          sort_column === 'created_by' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="
                          sort_column === 'created_by' && sort_order === 'asc'
                        "
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th
                      @click="sort_by('fuga_id')"
                      role="button"
                      class="d-none d-xl-table-cell text-left"
                      scope="col"
                    >
                      FUGA Id
                      <span
                        v-if="
                          sort_column === 'fuga_id' && sort_order === 'desc'
                        "
                        ><chevron-down-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-down-icon
                      ></span>
                      <span
                        v-if="sort_column === 'fuga_id' && sort_order === 'asc'"
                        ><chevron-up-icon
                          size="0.9x"
                          class="muted ms-1"
                        ></chevron-up-icon
                      ></span>
                    </th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="artist in filtered" :key="artist.order_id">
                    <td class="text-left">{{ artist.name }}</td>

                    <td
                      class="d-none d-xl-table-cell text-left"
                      :title="
                        artist.orgs && artist.orgs.map((o) => o.name).join('\n')
                      "
                    >
                      <span class="badge bg-light text-dark ms-2">{{
                        artist.orgs && artist.orgs.length
                      }}</span>
                      {{ orgs_string(artist.orgs) }}
                    </td>
                    <td
                      @dblclick="filter_by(artist.tier)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ artist.tier }}
                    </td>
                    <td class="d-none d-xl-table-cell text-left">
                      {{ artist.date_created | moment_short }}
                    </td>
                    <td
                      class="d-none d-xl-table-cell text-left"
                      @dblclick="filter_by(artist.is_contract_signed)"
                    >
                      <file-text-icon
                        v-if="artist.is_contract_signed"
                        size="1x"
                        class="text-secondary"
                      ></file-text-icon>
                    </td>
                    <td
                      @dblclick="filter_by(artist.created_by)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ artist.created_by }}
                    </td>
                    <td
                      @dblclick="fuga_record(artist.fuga_id)"
                      class="d-none d-xl-table-cell text-left"
                    >
                      {{ artist.fuga_id }}
                    </td>
                    <td class="w-5 text-center">
                      <router-link
                        :to="{
                          name: 'ArtistProfile',
                          params: { artist_id: artist.id },
                        }"
                        title="edit artist"
                      >
                        <edit-icon size="1x" class="text-success"></edit-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            v-if="pages"
            :pages="pages"
            :selected_page="selected_page"
            v-on:updatePage="updatePage"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import Pagination from "@/components/Pagination";
import {
  EditIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserXIcon,
  MailIcon,
  UserCheckIcon,
  StarIcon,
  DownloadIcon,
  FileTextIcon,
} from "vue-feather-icons";

const artists = db.collection("artists");

export default {
  name: "Artists",
  data() {
    return {
      display_artists: [],
      search: "",
      artists_filter: "all",
      debounce: null,
      sort_column: ["name"],
      sort_order: "asc",
      page_limit: 30,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    EditIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    UserXIcon,
    MailIcon,
    UserCheckIcon,
    StarIcon,
    DownloadIcon,
    FileTextIcon,
  },

  mixins: [filter_mixins],

  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 1000);
    },

    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      console.log("FILTER BY: " + filter);
      this.search = filter;
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    download_artists(selection) {
      let download_artists;

      if (selection === "selection") {
        download_artists = [...this.filtered];
      } else if (selection === "all") {
        download_artists = [...this.display_artists];
      }

      download_artists = download_artists.map((a) => {
        a.orgs_string = a.orgs?.map((o) => o.name).join(";");
        a.royalties_name_codes = a.orgs
          ?.map((o) => o.royalties_name_code)
          .join(";");

        const latest_contract_date =
          (a.contracts &&
            Math.max(
              a.contracts?.map((c) => {
                // console.log(c.file_date.seconds);
                return c.file_date.seconds;
              })
            )) ||
          0;

        console.log(
          new Date(latest_contract_date * 1000).toLocaleString("en-NZ")
        );
        a.latest_contract_date =
          (latest_contract_date &&
            new Date(latest_contract_date * 1000).toLocaleString("en-NZ")) ||
          "";

        a.date_created_string =
          (a.date_created &&
            new Date(a.date_created.seconds * 1000).toLocaleString("en-NZ")) ||
          "";
        return a;
      });

      const csv = Papa.unparse({
        data: download_artists,
        fields: [
          "id",
          "name",
          "apple_id",
          "spotify_id",
          "tier",
          "orgs_string",
          "royalties_name_codes",
          "latest_contract_date",
          "date_created_string",
          "is_active",
        ],
        quotes: true,
      });
      const csv_name = "artists.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
    orgs_string(orgs) {
      let orgs_string = orgs?.map((o) => o.name).join("; ") || "";

      if (orgs_string.length > 50) {
        return `${orgs_string.substring(0, 50)}...`;
      } else {
        return orgs_string;
      }
    },

    fuga_record(id) {
      window.open(`https://next.fugamusic.com/artist/${id}`, "_blank");
    },
  },
  computed: {
    filtered: function () {
      let visible_artists = [...this.display_artists];

      const search = this.search;

      let sort_column = this.sort_column;

      if (sort_column) {
        visible_artists = orderBy(
          visible_artists,
          sort_column || "",
          this.sort_order
        );
      }

      visible_artists = visible_artists.filter((artist) => {
        // console.log(artist);
        const s = artist.name + artist.email + artist.fuga_id; //+ (artist.orgs && artist.orgs.map((o) => o.name)) + artist.role;
        const su = s.toUpperCase();
        // console.log(su);
        return su.match(search.toUpperCase());
      });

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_artists = visible_artists.slice(start, end);
      }

      return visible_artists;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.display_artists;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
  watch: {
    artists_filter: {
      immediate: true,
      handler(filter) {
        let query = artists;
        if (filter && filter === "admin") {
          query = query.where("role", "==", "admin");
        } else if (filter && filter === "agent") {
          query = query.where("role", "==", "agent");
        } else if (filter && filter === "artist") {
          query = query.where("role", "==", "artist");
        } else if (filter && filter === "inactive") {
          query = query.where("is_active", "==", false);
        }
        // query = query.limit(5500);
        this.$bind("display_artists", query);
      },
    },
  },
};
</script>

<style>
.table-fixed thead {
  width: 97%;
}
.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}
.table-fixed tbody td,
.table-fixed thead > tr > th {
  float: left;
  border-bottom-width: 0;
}
</style>
