<template>
  <basicLayout>
    <div class="container-fluid">
      <!-- <div v-if="!Object.keys(new_release_record).length" class="row m-1"> -->
      <div class="row m-1">
        <div class="col">
          <h2>Cover Art Upload</h2>
          <p class="lead">
            Please upload an image (.jpg only) for this release.<br />Once you have uploaded the metadata, you can
            review and submit the release.
          </p>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header bg-transparent">
              <h6 class="text-uppercase">Upload Cover Art</h6>
              <p>Please upload cover art file in the format .jpg, minimum 1500 x 1500 pixels</p>
            </div>

            <div
              v-if="release.release_art_url"
              class="card-body text-center">
              <img
                :src="release.release_art_url"
                width="300px"
                height="auto" />
            </div>

            <div class="card-footer text-center">
              <button
                @click.prevent="upload_image"
                type="button"
                class="btn btn-sm btn-outline-secondary">
                Import Art
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-1">
        <div class="col">
          <router-link
            :to="{
              name: 'ReleaseEdit',
              params: { release_id: release.id },
            }"
            class="btn btn-outline-secondary btn-sm text-nowrap routerlink ms-1">
            Edit Release
          </router-link>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { state } from "@/store";
import basicLayout from "@/components/basicLayout";
import { db } from "@/firebaseConfig";

export default {
  name: "BulkArtUpload",
  data() {
    return {
      release: {},
    };
  },

  firestore() {
    return {
      release: db.collection("release_submissions").doc(this.$route.params.release_id),
    };
  },
  components: { basicLayout },

  methods: {
    upload_image() {
      window.cloudinary
        .openUploadWidget(
          {
            cloud_name: "drm-nz-portal",
            uploadPreset: "album_cover",
            clientAllowedFormats: ["jpg"],
            cropping: true,
            croppingAspectRatio: 1.0,
            minImageWidth: 1500,
            minImageHeight: 1500,
            croppingShowDimensions: true,
            croppingValidateDimensions: true,
            croppingShowBackButton: true,
            showSkipCropButton: false,
            theme: "minimal",
            showPoweredBy: false,
            // inlineContainer: "#cloudinary",
            buttonClass: "btn btn-outline-success",
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              this.save_image(result.info);
            }
          }
        )
        .open();
    },
    save_image: async function (info) {
      // console.log(info);
      const release_art_url = info.secure_url;
      const release_thumbnail_url = info.thumbnail_url;
      const release_art_info = {
        height: info.height,
        width: info.width,
        format: info.format,
        bytes: info.bytes,
        hashsum: info.etag,
      };

      await db
        .collection("release_submissions")
        .doc(this.release.id)
        .update({ release_art_url, release_thumbnail_url, release_art_info });
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
<style>
</style>
