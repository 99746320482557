<template>
  <NavSplash>
    <div class="container-fluid">
      <div class="login text-center">
        <div class="form-signin">
          <div class="row">
            <div>
              <h1 class="h3 text-uppercase">Welcome to the New DRM Portal</h1>

              <div class="text-start">
                <p>
                  The team have been working hard on our new client portal that will now make it a lot easier for you to
                  submit your music and meta data for your future releases.
                </p>

                <h6>What's changed?</h6>
                <ul>
                  <li>You will now be able to add your new release metadata and upload artwork and wav files.</li>
                  <li>Monitor your music's progress through the review and submission process.</li>
                  <li>
                    We can assign multiple users to your account (handy if you are a label with multiple team members).
                  </li>
                  <li>
                    If you have multiple labels or artists you will be able to view all of their releases and royalty
                    statements using the same login.
                  </li>
                  <li>You can view all your past releases including the ISRC and UPC numbers we create for you.</li>
                </ul>
                <h6>Update to our Terms &amp; Conditions</h6>

                <p>We've also updated our T&amp;Cs to give you new services.</p>

                <p>
                  This update includes a publishing service for all clients who do not currently have a publisher (if
                  you do already have a third party publisher then the publishing clause will not apply to you).&nbsp;<a
                    data-bs-toggle="collapse"
                    href="#collapse_pub"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse_pub"
                    >More...</a
                  >
                </p>

                <div
                  class="collapse"
                  id="collapse_pub">
                  <div class="card card-body m-2">
                    <p>
                      DRM is now a registered publisher and can manage your publishing rights and assist with
                      synchronisation deals. In some cases we can even get your publishing royalties faster.
                    </p>
                    <p>
                      Our new Terms &amp; Conditions will only apply to clients who do not have a current publisher.
                    </p>
                    <p>
                      If in the future you want to move your publishing rights to another third party publisher then you
                      are able to do this without any effect on the terms in this update.
                    </p>
                  </div>
                </div>

                <p>
                  We've also provided updated information regarding our term rollover dates.&nbsp;<a
                    data-bs-toggle="collapse"
                    href="#collapse_rollover"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse"
                    >More...</a
                  >
                </p>

                <div
                  class="collapse"
                  id="collapse_rollover">
                  <div class="card card-body m-2">
                    Over the last 10 years DRM has had lots of different term dates. This makes accounting from our end
                    difficult. We are making this simpler by putting everyone on the same term, starting from 30th
                    November 2022, and all will be for 12 month terms (some have been 2 or 3 years). We have also tidied
                    up the rollover provisions.
                  </div>
                </div>

                <h6>What about the commission rate?</h6>
                <p>Your current commission rate with us will remain the same!</p>
                <h6>Review and sign</h6>
                <p>
                  You can review our updated T&amp;Cs
                  <span title="Open T&amp;Cs"
                    ><b
                      ><a
                        class="text-bold"
                        href="#"
                        @click.prevent="open_modal"
                        >here</a
                      ></b
                    ></span
                  >.
                </p>
                <p>This update will be effective on November 30th, 2023.</p>
                <p>
                  If you have any queries before signing into the portal, please email us at
                  <a href="mailto:info@drm.co.nz">info@drm.co.nz</a> or call us on 09 847 9253
                </p>
              </div>
            </div>

            <div class="row text-start">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <div class="form-group mb-3">
                      <div class="form-check">
                        <input
                          v-model="contract.is_accepted"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminate" />
                        <label
                          class="form-check-label"
                          for="flexCheckIndeterminate">
                          You, <b>{{ user.name }}</b
                          >, have read and agree to the <b>Terms and Conditions</b>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-xl-8 col-lg-12">
                        <div class="form-group">
                          <label
                            for="signature"
                            class="form-label">
                            Your signature</label
                          >

                          <VueSignaturePad
                            width="100%"
                            height="200px"
                            ref="signaturePad"
                            class="bg-light border border-secondary rounded" />
                          <div class="text-end mt-2">
                            <button
                              @click.prevent="undo"
                              class="btn btn-sm btn-outline-success">
                              undo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                    <button
                      @click.prevent="update_user"
                      class="btn btn-outline-success text-end">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TermsFirstLoginModal ref="terms_modal"></TermsFirstLoginModal>
    </div>
  </NavSplash>
</template>

<script>
// import { Modal } from "bootstrap";
import { actions } from "@/store";
import { auth, fb } from "@/firebaseConfig";
import TermsFirstLoginModal from "@/components/TermsFirstLoginModal";
import NavSplash from "@/components/NavSplash";
import pdf_mixin from "@/mixins/pdf_first_login_terms_mixin";
import { FileTextIcon } from "vue-feather-icons";
import { state } from "@/store";

export default {
  data() {
    return {
      modal: null,
      contract: { accepted: false },
    };
  },

  components: {
    NavSplash,
    TermsFirstLoginModal,
    FileTextIcon,
  },
  mixins: [pdf_mixin],

  methods: {
    open_modal() {
      this.$refs.terms_modal.open_modal();
    },

    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    update_user: async function () {
      actions.setLoading(true);

      const user = this.user;

      const { data } = this.$refs.signaturePad.saveSignature();

      const contract = this.contract;

      if (!data || !contract.is_accepted) {
        this.$toast.warning("Please accept thet terms and conditions and sign", {
          position: "top-right",
          timeout: 5000,
        });
        this.submitted = true;
        actions.setLoading(false);
        return;
      }

      contract.name = user.name;
      contract.signature = data;
      contract.date_signed = new Date();

      //CREATE SIGNED CONTRACT

      const signed_contract = await this.pdfgen(contract);

      // console.log("SIGNED CONTRACT: " + JSON.stringify(signed_contract));

      if (signed_contract.success) {
        user.signed_contract = signed_contract.contract;
      } else {
        // console.log("PDF ERROR");
        this.$toast.error("There was a PDF ERROR", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      // UPOAD CONTRACTS

      console.log({ contract_string: signed_contract.contract, user_id: user.id });

      const user_contract_upload = fb.httpsCallable("user_contract_upload");
      const res = await user_contract_upload({ contract_string: signed_contract.contract, user_id: user.id });
      actions.fetchCurrentUserProfile(auth.currentUser);
      console.log(res.data);
      actions.setLoading(false);
    },
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    user() {
      return state.userProfile;
    },
    display_contract() {
      return this.display_orgs.some((org) => !org.is_contract_signed && !org.is_exempt_new_contract);
    },
  },
};
</script>

<style scoped>
.login {
  /* color: #fff; */
  height: 100vh;
  margin-top: -30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-image: url("../assets/background.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-signin {
  width: 60vw;
  min-width: 350px;
  padding: 5%;
  margin: auto;
  background-color: #e2e4e3;
  opacity: 0.95;
}
</style>