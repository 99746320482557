<template>
  <div>
    <div class="row">
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="file"
          @change.prevent="file_picker"
          accept=".wav" />
      </div>

      <div
        class="col"
        v-cloak
        @drop.prevent="drag"
        @dragover.prevent>
        <input
          class="form-control form-control-sm progress-bar-striped alert-dark"
          placeholder="...or drag file here"
          accept=".wav" />
      </div>
    </div>
    <div class="row mt-2">
      <div
        v-if="upload_progress > 0 && upload_progress < 99"
        class="col mt-3">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            :style="{ width: upload_progress + '%' }"
            :key="upload_progress"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage, db } from "@/firebaseConfig";
import { getters, actions } from "@/store";

export default {
  props: {
    track: Object,
  },
  data() {
    return {
      track_audio_url: this.track.downloadURL || "",
      track_duration: 0,
      resultURL: "",
      upload_progress: 0,
    };
  },

  methods: {
    drag(event) {
      const file = event.dataTransfer.files[0];
      console.log(file.type);
      this.upload(file);
      event.target.value = "";
    },
    file_picker: async function (event) {
      console.log("file picker");
      const file = event.target.files[0];
      console.log(file.name);
      this.upload(file);
    },

    compute_duration(file) {
      return new Promise((resolve) => {
        const objectURL = URL.createObjectURL(file);
        const mySound = new Audio([objectURL]);
        mySound.addEventListener(
          "canplaythrough",
          () => {
            URL.revokeObjectURL(objectURL);
            console.log(JSON.stringify(mySound.duration));
            resolve(mySound.duration);
          },
          false
        );
      });
    },

    upload: async function (file) {
      console.log("CHECK FILE EXT: " + JSON.stringify(file));
      actions.setLoading(true);
      this.$emit("save_track");

      const allowedExtensions = /(\.wav)$/i;

      if (!allowedExtensions.exec(file.name)) {
        this.$toast.warning("Please upload a WAV file or M4A file only.", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      const duration = await this.compute_duration(file);

      const track_audio_url = await this.uploadTaskPromise(file);
      console.log(`Successfully uploaded file and got download link - ${track_audio_url}`);
      await db
        .collection("release_submissions_tracks")
        .doc(this.track.id)
        .update({ track_duration: duration, track_audio_url });
      actions.setLoading(false);
    },

    uploadTaskPromise: async function (file) {
      const storageRef = storage.ref();
      const wavRef = storageRef.child(`tracks/${this.track.release_id}/${this.track.id}.wav`);
      const metadata = {
        release_id: this.track.release_id,
        track_id: this.track.id,
        record_type: "track_audio",
      };
      console.log(metadata);
      const uploadTask = wavRef.put(file, { customMetadata: metadata });
      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        this.upload_progress = progress;
      });
      return uploadTask.then((snapshot) => {
        console.log("Upload is complete");

        return snapshot.ref.getDownloadURL();
      });
    },
  },
  computed: {
    is_loading() {
      return getters.isLoading();
    },
  },
};
</script>

<style scoped>
.my-clipper {
  width: 100%;
  max-width: 700px;
}

.placeholder {
  text-align: center;
  padding: 20px;
  height: 300px;
  background-color: rgba(0, 123, 255, 0.11);
}
</style>
