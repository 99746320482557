<template>
  <basicLayout>
    <div class="p-2">
      <div v-if="!pasted_table" class="row">
        <div class="col">
          <h5>
            <grid-icon size="1x" class="custom-class"></grid-icon>&nbsp;UPLOAD
            ORDER FROM EMAIL
          </h5>
        </div>
      </div>
      <div v-if="!pasted_table" class="row pt-3">
        <div class="col">
          <h6 class="text-secondary">Paste table in the area below</h6>
          <div
            @paste="paste_html"
            class="paste-area progress-bar progress-bar-striped progress-bar bg-secondary"
          ></div>
        </div>
      </div>
      <hr />
      <div class="row mb-3 mt-3">
        <div class="col">
          <button
            v-if="rows.length"
            @click.prevent="upload_data()"
            class="btn btn-sm btn-outline-success"
            type="button"
          >
            Upload Release
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th v-for="col in columns" :key="col.id">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in rows" :key="row.id">
                  <td v-for="col in columns" :key="col.id">
                    {{ row[col] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import Papa from "papaparse";
import moment from "moment";
import { db } from "@/firebaseConfig";

export default {
  name: "dashboard",
  data() {
    return {
      data_preview: [],
      pasted_table: "",
      rows: [],
    };
  },

  components: { basicLayout },

  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload_data: async function () {
      const data = this.rows;
      const first_line = data[0];
      const release_parental_advisory =
        first_line["Parental Advisory"] === "yes" ? "Explicit" : "Not Explicit";
      const release_release_date = moment(first_line["Release Date"], [
        "MM/DD/YYYYY",
      ]).format("YYYY-MM-DD");
      console.log(release_release_date);

      const new_release = {
        release_title: first_line["Release/Album Title"],
        release_copyright_owner: first_line["Copyright Owner"],
        release_production_year: first_line["Production Year"],
        release_territories_included: ["Worldwide"],
        release_territories_excluded: [],
        release_upc: first_line["Release UPC"],
        release_release_date,
        release_original_release_date: "",
        release_parental_advisory,
        is_new: true,
        is_incomplete: true,
        is_submitted_to_drm: false,
        is_approved_by_drm: false,
        is_uploaded_pending_approval: false,
        is_submitted_to_youtube: false,
        is_approved_by_youtube: false,
        is_submitted_to_believe: false,
        is_approved_by_believe: false,
        is_released_to_master_track_list: false,
        is_archived: false,
      };

      console.log(new_release);

      let new_release_record;

      new_release_record = await db
        .collection("release_submissions")
        .add(new_release);

      console.log(new_release_record);

      const new_release_id = new_release_record.id;

      const new_tracks_promises = data.map((track) => {
        const track_main_artists =
          track["Artist Name"] &&
          track["Artist Name"].split(",").map((name) => {
            return { name: name.trim() };
          });

        const track_featured_artists = (track["Featured Artists Name"] &&
          track["Featured Artists Name"].split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_remix_artists = (track["Remix Artists Name"] &&
          track["Remix Artists Name"].split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_lyricists = (track["Lyric Authors"] &&
          track["Lyric Authors"].split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_composers = (track["Song Composers"] &&
          track["Song Composers"].split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_producers = (track["Song Producers"] &&
          track["Song Producers"].split(",").map((name) => {
            return { name: name.trim() };
          })) || [{ name: "" }];

        const track_parental_advisory =
          track["Parental Advisory"] === "yes" ? "Explicit" : "Not Explicit";

        // const track_release_date = moment(track["Release Date"], ["MM/DD/YYYYY"]).format("YYYY-MM-DD");

        const new_track = {
          release_id: new_release_id,
          track_number: track["track #"],
          track_title: track["Track Title"],
          track_main_artists,
          track_featured_artists,
          track_remix_artists,
          track_lyricists,
          track_composers,
          track_producers,
          track_isrc: track["Track ISRC"],
          track_genre: track.Genre,
          track_lyric_language: "en",
          track_title_language: "en",
          track_parental_advisory,
          track_price_tier: track["Track price tier"] || "Front",
          track_created: new Date(),
          is_incomplete: true,
        };

        console.log(new_track);

        db.collection("release_submissions_tracks").add(new_track);

        return new_track;
      });

      await Promise.all(new_tracks_promises);

      this.$router.push({
        name: "ReleaseEdit",
        params: { release_id: new_release_id },
      });
    },

    preview_data: function (data) {
      console.log("preview_data");
      this.rows = data;
    },

    paste_html: function (e) {
      const preview_data = this.preview_data;
      const items = e.clipboardData.getData("text");
      console.log(items);
      Papa.parse(items, {
        header: true,
        transformHeader: function (h) {
          return h.replace(/[ ]*?\([^)]*\)|[ ]*?\[[^\]]*\]/g, "");
        },
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          preview_data(results.data);
        },
      });
    },
  },
  computed: {
    columns: function columns() {
      if (this.rows.length == 0) {
        return [];
      }
      return Object.keys(this.rows[0]);
    },
  },
};
</script>
<style scoped>
.paste-area {
  height: 40vh;
}
.table-sm {
  font-size: 0.6rem;
}
</style>
