<template>
  <div class="nav-public">
    <Spinner v-if="isLoading" />
    <nav class="navbar navbar-expand-sm navbar-dark bg-dark border-bottom fixed-top">
      <a
        href="https://drm.co.nz/"
        class="navbar-brand text-center ms-3">
        <img
          src="../assets/logo.png"
          width="auto"
          height="50"
          alt />
      </a>
    </nav>
    <!-- Page Content -->
    <div id="page-content-wrapper">
      <slot />
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</template>

<script>
import { getters } from "@/store";

import Spinner from "@/components/Spinner";
export default {
  name: "NavSplash",
  components: {
    Spinner,
  },

  mounted() {
    console.log("mounted");
    document.body.classList.add("bg-b");
  },

  destroyed() {
    document.body.classList.remove("bg-b");
  },
  computed: {
    isLoading() {
      return getters.isLoading();
    },
  },
};
</script>

<style scoped>
.nav-public {
  min-height: 100vh;
}
#page-content-wrapper {
  margin-top: 40px;
  height: 100vh;
  width: 100vw;
}
.sticky-top {
  top: 80px;
}
</style>
