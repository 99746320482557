var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavSplash',[_c('div',{staticClass:"login text-center"},[_c('div',{staticClass:"form-signin"},[_c('div',{staticClass:"text-center p-3"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"width":"auto","height":"50px","alt":""}})]),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleResetPassword.apply(null, arguments)}}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputPassword"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                'is-invalid': _vm.password && !_vm.isPasswordStrong,
                'is-valid': _vm.password && _vm.isPasswordStrong,
              },attrs:{"type":"password","id":"inputPassword","placeholder":"new password","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.password && _vm.password.length)?_c('PasswordScore',{staticClass:"mt-2 mb-6",attrs:{"value":_vm.password},on:{"passed":function($event){_vm.isPasswordStrong = true},"failed":function($event){_vm.isPasswordStrong = false}}}):_vm._e()],1),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputConfirmPassword"}},[_vm._v("Confirm New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"form-control",class:{
                'is-invalid': _vm.confirm_password && _vm.password !== _vm.confirm_password,
                'is-valid': _vm.password.length && _vm.password === _vm.confirm_password,
              },attrs:{"type":"password","id":"inputConfirmPassword","placeholder":"confirm your new password","required":""},domProps:{"value":(_vm.confirm_password)},on:{"input":function($event){if($event.target.composing)return;_vm.confirm_password=$event.target.value}}})]),_c('button',{staticClass:"btn btn-outline-secondary btn-block",attrs:{"disabled":!_vm.is_password_good,"type":"submit"}},[_vm._v(" Submit ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }