import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { actions } from '@/store'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'

import 'flag-icons'
// import 'bootstrap/scss/bootstrap.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'flag-icons/css/flag-icons.min.css'
import './styles/style.css'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueSignaturePad from 'vue-signature-pad';
import LogRocket from 'logrocket'
import VueLuxon from "vue-luxon";
import VueLazyload from 'vue-lazyload'

import { firestorePlugin } from 'vuefire'
import { auth } from '@/firebaseConfig'

const toast_options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 10,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}

LogRocket.init('souawn/drm');

Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
Vue.component('v-select', vSelect)
Vue.use(firestorePlugin)
Vue.use(Toast, toast_options)
Vue.use(VueSignaturePad);
Vue.use(VueLuxon);
Vue.use(VueLazyload)

let app

auth.onAuthStateChanged(user => {
  if (user) {
    actions.fetchCurrentUserProfile(user)
  }
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
