<template>
  <div class="card border-success m-3">
    <div class="card-header"><h6>NEW ARTIST</h6></div>
    <div class="card-body">
      <div class="form-floating mb-3">
        <input
          v-model="artist.name"
          id="artist_name"
          type="text"
          class="form-control form-control-sm"
          placeholder="Artist Name"
          :class="{
            'is-invalid': artist_submitted && !artist.name,
            'is-valid': artist.name,
          }" />
        <label for="artist_name">Name</label>
        <div class="invalid-feedback">Please enter a valid name.</div>
      </div>

      <div class="form-floating mb-3">
        <input
          v-model="artist.apple_id"
          id="apple_id"
          type="text"
          class="form-control form-control-sm"
          placeholder="1234567890"
          :class="{
            'is-invalid': artist_submitted && !artist.apple_id,
            'is-valid': artist.apple_id,
          }" />
        <label for="apple_id">Apple Id</label>
        <div class="invalid-feedback">Please enter a valid ID.</div>
      </div>

      <div class="form-floating mb-3">
        <input
          v-model="artist.spotify_id"
          id="spotify_id"
          type="text"
          class="form-control form-control-sm"
          placeholder="1Ab2c34dFG5h67i8jKl9mn0" />
        <label for="spotify_id">Spotify Id</label>
        <div class="invalid-feedback">Please enter a valid ID.</div>
      </div>
    </div>
    <div class="card-footer text-end">
      <button
        @click.prevent="cancel_create_artist"
        class="btn btn-sm btn-outline-secondary me-2">
        Cancel
      </button>
      <button
        @click.prevent="create_artist"
        class="btn btn-sm btn-outline-success">
        Save Artist
      </button>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";

export default {
  props: {
    new_record: Object,
  },
  data() {
    return {
      artist: { name: "" },
      artist_submitted: false,
    };
  },

  mounted() {
    // pass bootstrap popover options from props
    this.artist = { ...this.new_record };
  },

  methods: {
    create_artist: async function () {
      const artist = this.artist;

      if (!artist.name) {
        artist_submitted = true;
        console.log("INCOMPLETE");
        return;
      }

      let new_artist_object = {
        name: (artist.name && artist.name.toString().trim()) || "",
        orgs: [{ name: "", label: "" }],
        org_ids: [],
        apple_id: artist.apple_id,
        spotify_id: artist.spotify_id,
        fuga_id: "",
        date_created: new Date(),
        is_active: true,
        tier: "Tier 3",
        social_media_links: [{ url: "" }],
      };

      let new_artist_record = await db.collection("artists").add(new_artist_object);
      console.log(new_artist_record);
      console.log(new_artist_record.id);
      new_artist_object.id = new_artist_record.id;
      return this.$emit("select_new_artist", new_artist_object);
    },

    cancel_create_artist() {
      this.org = { country: "New Zealand" };
      this.artist_submitted = false;
      return this.$emit("close_new_artist");
    },
  },
};
</script>

<style>
</style>